<!-- 审核详情 -->
<template>
    <el-dialog class="check-details"
        :visible="viewIsReady"
        @close="handleClose"
        title="审核详情"
        width="500px">

        <ul class="summary">
            <li><span class="tag">申请人</span>
                <span class="value">{{ placeItem.createEmpname }}</span>
            </li>
            <li><span class="tag">申请日期</span>
                <span class="value">{{ placeItem.createTimeName }}</span>
            </li>
            <li><span class="tag">外部渠道商</span>
                <span class="value">{{ placeItem.commName }}</span>
            </li>
            <li><span class="tag">钉钉附件</span>
                <thumbnail-list
                    style="margin-top: 0"
                    :images="placeItem.imagePath"
                    text-type="index"
                ></thumbnail-list>
            </li>
            <li><span class="tag">审核时间</span>
                <span class="value">{{ placeItem.checkTimeName }}</span>
            </li>
            <li><span class="tag">审核人</span>
                <span class="value">{{ placeItem.checkEmpName }}</span>
            </li>
            <li><span class="tag">审核状态</span>
                <span class="value" :class="placeItem.commState < 2 ? 'green' : 'red'">{{ placeItem.commStateName }}</span>
            </li>
            <li><span class="tag">备注</span>
                <span class="value">{{ placeItem.checkRemark }}</span>
            </li>
        </ul>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'CheckingDetails',
    props: [ "item" ],

    data () {
        return {
            viewIsReady: false,
        }
    },

    computed: {
        placeItem () {
            return this.item || {imagePath: []}
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                item.imagePath = item.imagePath || item.path.split(",").map(
                    path => {
                        return {
                            name: path.slice(path.lastIndexOf("/")+1),
                            path: path,
                            url: this.$baseURL + "/" + path,
                        }
                    });
                this.viewIsReady = true;
            }
        },
    },

    methods: {

        handleClose () {
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>


<style lang='scss' scoped>
    .summary {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // align-items: center;
        // margin-left: 30px;

        li {
            // flex: 0 0 48%;
            display: flex;
            margin-bottom: 10px;
        }

        .tag {
            flex: 0 0 6em;
            white-space: nowrap;
            color: gray;
            margin-right: 8px;
            text-align: right;

            &:after {
                content: ":"
            }
        }

        .red {
            color: red;
        }
        .green {
            color: green;
        }
    }
</style>
<!-- 汇款情况与缴费列表 -->
<template>
    <div class="order-part7 private">
        <ul class="order-summary">
            <li><span class="tag">认购总金额</span><span class="value">{{ orderInitInfo.contractMoney }}</span></li>
            <li><span class="tag">资料齐日期</span><span class="value">{{ orderInitInfo.allFileTimeName }}</span></li>
            <li><span class="tag">资料翻译完成日期</span><span class="value">{{ orderInitInfo.translateTimeName }}</span></li>
            <li><span class="tag">通知打款日期</span><span class="value">{{ orderInitInfo.noticePayTimeName }}</span></li>
            <li><span class="tag">回款10%日期</span><span class="value">{{ orderInitInfo.sumPay10Time }}</span></li>
            <li><span class="tag">全款齐日期</span><span class="value">{{ orderInitInfo.sumPayAllTime }}</span></li>
            <li><span class="tag">已回款金额</span><span class="value">{{ orderInitInfo.allpaymoney }}</span></li>
            <li><span class="tag">待回款金额</span><span class="value">{{ orderInitInfo.surplusPayMoney }}</span></li>
        </ul>

        <div class="section-title">
            <h3>回款信息</h3>
            <el-button
                v-if="auth.addPay"
                @click="handleAddPayBtn"
                type="primary"
            >添加回款</el-button>
            <el-button
                v-if="auth.deletePay"
                @click="handleDeletePayBtn"
                :disabled="!checkedlist.length"
            >批量作废</el-button>
        </div>

        <el-table :data="orderPayList"
            @selection-change="handleSelectionChange"
        >
            <el-table-column width="55"
                type="selection"></el-table-column>
            <el-table-column label="缴费方式"
                prop="payTypeName"></el-table-column>
            <el-table-column label="票据时间"
                prop="payTimeName"></el-table-column>
            <el-table-column label="金额">
                <template slot-scope="scope">
                    {{ scope.row.money }} ({{ scope.row.moneyTypeName }})
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTimeName">
                <template slot-scope="scope">
                    <span v-html="scope.row.createTimeName"></span>
                </template>
            </el-table-column>
            <el-table-column label="缴费快照">
                <template slot-scope="scope">
                    <thumbnail-list
                        :images="scope.row.path"
                        type="text"
                        text-type="index"
                    ></thumbnail-list>
                </template>
            </el-table-column>
            <el-table-column label="备注"
                prop="remark"></el-table-column>
        </el-table>

        <AddPayments :item.sync="payingItem"
            :payTypeList="payTypeList"
            :moneyTypeList="moneyTypeList"
            @change="changeData()"></AddPayments>
    </div>
</template>

<script>
import AddPayments    from "./AddPayments.vue";
// import UploadFile from '../../../components/UploadFile/UploadFile.vue';
export default {
    name: 'FormPart7',
    // components: {UploadFile},
    props: ["orderMap", "payTypeList", "moneyTypeList","orderInitInfo"],
    components: {
		AddPayments,
    },

    data () {
        return {
            auth: {
                addPay: false,
                deletePay: false,
            },
            orderId: "",
            payMap: {
                sumPay10Time     : "",//回款10%日期
                noticePayTimeName: "",//通知打款日期
                sumPayAllTime    : "",//全款齐日期
                allpaymoney      : "",//已回款金额
                translateTimeName: "",//资料翻译完成日期
                allFileTimeName  : "",//资料齐日期
                id               : "",
                contractMoney    : "",//认购总金额
                surplusPayMone   : ""
            },
            orderPayList: [],
            checkedlist: [],
            payingItem: null,
            // uploadAccept: "image/*,application/pdf,application/x-pdf",
            // PB: {
            //     orderId: 0
            // },
            // rule: [
            //     {required: true},
            //     {
            //         validator (r,v,c) {
            //             console.log(v)
            //             if (v.path.length) c();
            //             else c("请上传文件")
            //         }
            //     }
            // ],
            // isAllReady: false,
        }
    },

    created () {
		this.auth.addPay = this.$hasAuthFor("api/order/saveOrderPay");
		this.auth.deletePay = this.$hasAuthFor("api/order/deleteOrderPay");
    },

    mounted () {
        const id = Number(this.$route.query.id);
        if (id) {
            this.orderId = id;
            this.fetchData();
        }
    },

    methods: {
        changeData(){
            this.fetchData()
            this.$emit("change");
        },
        async fetchData () {
             const loadingRef = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/order/queryOrderPayList",
                    method: "post",
                    data: {
                        orderId: this.orderId,
                        isTotal: 1
                    }
                });
                loadingRef && loadingRef.close();
                if (res.code !== 2000 && res.code !== 1003) throw res;

                const { payMap, orderPayList } = res.data;
                this.payMap = payMap;
                this.orderPayList = orderPayList.map(item => {
                    item.createTimeName = item.createTimeName.replace(" ", "<br>");
                    if (item.path) item.path =
                        item.path.split(",").filter(Boolean).map(
                            path => {
                                return {
                                    name: path.slice(path.lastIndexOf("/")+1),
                                    path: path,
                                    url: this.$baseURL + "/" + path,
                                }
                            })
                    return item;
                });

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });	
            }
        },

        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        handleAddPayBtn () {
            // if(!this.payMap.noticePayTimeName){
            if(this.orderInitInfo.processCheckState!=7){
                this.$message.closeAll();
                this.$message({
                    message: "填写通知打款日期后才能添加回款",
                    type: "warning"
                });
                return
            }
            this.payingItem = this.orderMap;
        },

        async handleDeletePayBtn () {

			let loadingRef;
			try {
				await this.$confirm("确定要作废吗", "系统提示")
				loadingRef = this.$loading({
                    target: ".add-payments .el-dialog"
                });
				const res = await this.$axios({
					url: "/api/order/deleteOrderPay",
					method: "post",
					data: {
						orderPayIds: this.checkedlist.map(it=>it.id).join()
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "作废缴费记录成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
                    this.$emit("change");
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "作废失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
        },
    }
}
</script>

<style lang='scss' scoped>

    .order-summary {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 75%;

        li {
            margin-right: 68px;
            line-height: 2.5;
            color: #333333;
        }

        .tag {
            &:after {
                content: ": ";
            }
        }
    }

    .section-title {
        display: flex;
        align-items: center;
        margin: 30px 0;

        h3 {
            font-size: 1em;
            margin-right: 24px;
        }
    }
</style>
<!-- 审核渠道结佣 -->
<template>
    <el-dialog class="check-commission"
        :visible="viewIsReady"
        @close="handleClose"
        title="审核渠道结佣"
        width="500px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="90px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >

            <ul class="summary">
                <li><span class="tag">申请人</span>
                    <span class="value">{{ placeItem.createEmpname }}</span>
                </li>
                <li><span class="tag">申请日期</span>
                    <span class="value">{{ placeItem.createTimeName }}</span>
                </li>
                <li><span class="tag">外部渠道商</span>
                    <span class="value">{{ placeItem.commName }}</span>
                </li>
                <li><span class="tag">钉钉附件</span>
                    <thumbnail-list
                        style="margin-top: 0"
                        :images="placeItem.imagePath"
                        text-type="index"
                    ></thumbnail-list>
                </li>
            </ul>

            <el-form-item label="订单审核" prop="commState">
                <el-radio-group v-model="PB.commState">
                    <el-radio :label="1">通过</el-radio>
                    <el-radio :label="0">未通过</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item
                label="备注" prop="checkRemark">
                <el-input v-model="PB.checkRemark"
                    type="textarea"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'CheckCommission',
    props: [ "item" ],

    data () {
        return {
            viewIsReady: false,
            PB: {
                commState  : "", // 审核状态 1通过 0不通过
                checkRemark: "",
            },
            rules: {
                commState : {required: true, message: "请选择审核结果"},
            }
        }
    },

    computed: {
        placeItem () {
            return this.item || {imagePath: []}
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                item.imagePath = item.imagePath || item.path.split(",").map(
                    path => {
                        return {
                            name: path.slice(path.lastIndexOf("/")+1),
                            path: path,
                            url: this.$baseURL + "/" + path,
                        }
                    });
                this.viewIsReady = true;
            }
            else this.viewIsReady = false;
        },
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".check-commission .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/orderChanComm/saveCheckChanComm",
                    method: "post",
                    data: {
                        id: this.item.id,
                        ...this.PB
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            console.log(33)
            this.PB.commState   = "";
            this.PB.checkRemark = "";
            this.$refs.form.clearValidate();
        },

        async handleClose () {
            await this.resetData();
            this.$emit("update:item", null);

        }
    }
}
</script>


<style lang='scss' scoped>
    .summary {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // align-items: center;
        margin-left: 18px;

        li {
            display: flex;
            margin-bottom: 10px;
        }

        .tag {
            flex: 0 0 6em;
            white-space: nowrap;
            color: gray;
            margin-right: 8px;
            text-align: right;

            &:after {
                content: ":"
            }
        }
    }
</style>
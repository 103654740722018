
export default {
    name: 'UploadFile1',
    props: {
        isDisable: {
            type: Boolean,
            default: false
        },
        showFileList: {
            type: Boolean,
            default: false
        },
        showDelete: {
            type: Boolean,
            default: true
        },
        accept: {
            type: String,
            default: "image/*"
        },
        acceptExplainText: {
            type: String,
            default: ""
        },
        limit: {
            type: Number,
            default: 1
        },
        maxSize: {
            type: Number,
            default: 200 // kB
        },
        value: {
            type: Array,
            default: () => []
        },
        uptype: {
            type: Number,
            default: 0
        },
        body: {
            type: Object,
            default: () => {}
        },
        read: {
            type: Boolean,
            default: true
        },
        maxWidth: {
            type: String,
            default: "100%"
        },
        cols: {
            type: Number,
            default: 2
        }
    },

    data() {
        return {
            action: this.$baseURL + "/api/common/fileSingleUpLoad",
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },
            fileList: [],
            posting: false
        }
    },

    watch: {
        value: {
            immediate: true,
            deep: true,
            handler (files) {
                files.forEach(it => {
                    it.isImage = it.isImage || it.path?.endsWith(".png") ||
                        it.path?.endsWith(".jpg") ||
                        it.path?.endsWith(".jpeg") ||
                        it.path?.endsWith(".gif");
                    it.cname = it.name || it.path.slice(it.path.lastIndexOf(".")+1)+"文件"
                });
                this.fileList = files;
            }
        }
    },

    methods: {
        handleExceed() {
            this.$message({
                message: `最多只能上传${this.limit}个${this.acceptExplainText}`,
                type: "warning",
            });
        },

        beforeFileUpload(file) {
            file.isImage = file.type.startsWith("image/");
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            const suffix4 = img === 'pdf'

            if (this.uptype == 1 && !suffix && !suffix2 && !suffix3 && !suffix4) {
                this.$message.error("请上传图片与pdf文件！");
                return false
            }

            console.log(file.size, (file.size / 1024), this.maxSize);

            const isSizeOk = file.size / 1024 < this.maxSize;



            if (!isSizeOk) {
                const sizeMsg = this.maxSize < 1e3 ? this.maxSize + "KB" :
                    this.maxSize < 1e6 ? this.maxSize / 1e3 + "MB" :
                    this.maxSize / 1e6 + "GB"
                this.$message.error(`上传${file.isImage?"图片":"文件"}大小不能超过${sizeMsg}!`);

                return false
            }
            this.fileList.push(file);

            return true;
        },

        handleProgress(e, file) {
            this.posting = true;
            const index = this.fileList.indexOf(file);
            this.$set(this.fileList, index, file)
        },

        // 上传文件成功
        handleUploadSuccess(res, file) {
            this.posting = false;
            const index = this.fileList.indexOf(file);
            if (res.code === 2000) {
                const { oldName, filePath } = res.data.files;
                file.url = this.$baseURL + "/" + filePath;
                file.path = filePath;
                this.$set(this.fileList, index, 
                    // {
                    //     name: oldName,
                    //     url: this.$baseURL + "/" + filePath,
                    //     path: filePath
                    // }
                    file
                )
                // console.log(file)

                this.$emit("input", this.fileList);
            } else {
                this.fileList.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete(index) {
            this.fileList.splice(index, 1);
            this.$emit("input", this.fileList);
        },
        handleRemove(file, fileList) {
            // console.log("romove")
            const index = this.fileList.indexOf(file);
            // console.log(index);
            if (index >= 0) this.handleIconDelete(index)
        },

        handlePriview (file) {
            if (!this.read) return this.$message({
                message: "您没有查看权限",
                type: "warning"
            });
            if (file.isImage) this.$viewerApi({
                images: [file.url || file.path || file],
                options: {
                    initialViewIndex: 0
                }
            })
            else window.open(file.url || file.path || file)
        }
    }
}
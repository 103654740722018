<!-- 修改渠道结佣信息 -->
<template>
    <el-dialog class="aus-audit"
        :visible="show"
        @close="handleClose"
        title="澳洲区订单审核"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="订单审核" prop="checkState">
                <el-radio-group v-model="PB.checkState">
                    <el-radio :label="1">通过</el-radio>
                    <el-radio :label="0">未通过</el-radio>
                </el-radio-group>
            </el-form-item>
            <template v-if="!!PB.checkState">
                <el-form-item label="业绩核算月份"
                    prop="commCheckTime">
                    <el-date-picker
                        class="width3"
                        type="month"
                        v-model="PB.commCheckTime"
                        format="yyyy-MM"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择"
                    ></el-date-picker>
                </el-form-item>

                <el-form-item label="起息日期"
                    prop="interestTime">
                    <el-date-picker
                        class="width3"
                        v-model="PB.interestTime"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择"
                    ></el-date-picker>
                </el-form-item>
            </template>
            <el-form-item
                label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'AusAudit',
    props: [ "show", "orderlist" ],

    data () {
        return {
            viewIsReady: false,
            PB: {
                checkState   : '', // 审核状态 1通过 0不通过  回显
                remark       : "",
                commCheckTime: "",
                interestTime : ""
            },
            rules: {
                checkState    : {required: true, message: "请选择审核结果"},
                commCheckTime : {required: true, message: "请选择业绩核算月份"},
                interestTime  : {required: true, message: "请选择起息日期"}
            }
        }
    },
    // mounted(){
    //     console.log('orderlist',this.orderlist);
    // },
     watch: {
        show (item, old) {
            if(this.show){
                // if(this.orderlist[0].checkState==3){
                //     this.PB.checkState = 1
                //     if(this.orderlist[0].commCheckTimeName){
                //         this.PB.commCheckTime = this.orderlist[0].commCheckTimeName+'-01'
                //     }
                //     this.PB.interestTime = this.orderlist[0].interestTimeName
                // }
                // 

                if(this.orderlist[0].commCheckTimeName){
                    this.PB.commCheckTime = this.orderlist[0].commCheckTimeName+'-01'
                }
                this.PB.interestTime = this.orderlist[0].interestTimeName
            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".aus-audit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/orderComm/updateTwoCheckState",
                    method: "post",
                    data: {
                        ...this.PB,
                        applyIds: this.orderlist.map(({id})=>id).join()
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.checkState    = "";
            this.PB.remark        = "";
            this.PB.commCheckTime = "";
            this.PB.interestTime  = "";
            // this.$refs.form.clearValidate();
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
        },

        handleClose () {
            this.resetData();
             setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            this.$emit("update:show", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
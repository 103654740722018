export function handleStep1Save1() { //方法名复制测试调试用 
    const loadingRef1 = this.$loading();
    setTimeout(() => {
        loadingRef1 && loadingRef1.close();
        console.log(this.isUpdate);
        if (this.isUpdate) { //修改
            this.$message({
                message: "保存成功",
                type: "success"
            });
            this.fetchData();
            if (this.processNode == 2) this.$refs.part4?.fetchFileList();
        }
        else { //新增
            this.addSaveSuccessDia = true
        }
    }, 1000);
}
// 暂不完善
export function toEioList() {
    this.addSaveSuccessDia = false
    this.$router.replace("/core/eoi");
}
// 去完善EOI附件
export function toEditEoiFile() {
    // 点击后进入编辑页的完善EOI附件页
    this.addSaveSuccessDia = false
    this.$router.push({
        path: `/core/eoi/edit?id=${this.addNewOrderId || 1585}`,  //用保存后返回的id
        query: { fromPage: 'eoiAdd' }  //测试传参 不需要此字段
    });
    // this.$router.replace("/core/eoi");
}


export async function handleStep1Save() {
    // console.log(this.investor); 
    const data2 = this.formatData1();
    console.log(data2);
    console.log(this.orderMap);



    try {
        await this.$refs.part1.validate();
    } catch (reason) {
        console.log("第1部分验证失败", this.$refs.part1);
        return this.$refs.part1.$el.scrollIntoView();
    }
    try {
        await this.$refs.part6.validate();
    } catch (reason) {
        console.log("第6部分验证失败", this.$refs.part6);
        return this.$refs.part6.$el.scrollIntoView();
    }
    try {
        await this.$refs.part2.validate();
    } catch (reason) {
        console.log("第2部分验证失败");
        return this.$refs.part2.$el.scrollIntoView();
    }
    // try {
    //     await this.$refs.part4.validate();
    // } catch (reason) {
    //     console.log("第4部分验证失败");
    //     return this.$refs.part4.$el.scrollIntoView();
    // }

    console.log("可以提交了");
    const loadingRef = this.$loading();
    try {
        const data = this.formatData1();
        console.log(data);
        console.log(this.orderMap);

        data.sysCode = this.orderMap.sysCode
        data.isTransfer = this.orderMap.isTransfer
        data.productIds = this.orderMap.outProductId.join() || null
        data.isTransfer = this.orderMap.isTransfer
        if (data.isTransfer == 0) {
            data.productIds = ''
        }
        data.isPicInvest = this.orderMap.isPicInvest
        data.custCreateTime = this.orderMap.custCreateTimeName
        data.custCreateEmp = this.orderMap.custCreateEmp
        // data.recommendUserCode = this.recommendUserCode
        data.recommendUserCode = this.orderMap.recommendUserCode
        data.recommendUserName = this.orderMap.recommendUserName




        await this.$refs.form.validate();
        const res = await this.$axios({
            url: "/api/order/saveOrder",
            method: "post",
            data
        })

        if (res.code === 2000) {
            loadingRef && loadingRef.close();
            if (this.isUpdate) {
                this.$message({
                    message: "保存成功",
                    type: "success"
                });
                this.fetchData();
                this.getOrderInitInfo()
                if (this.processNode == 2) this.$refs.part4?.fetchFileList();
            }
            else { //新增
                this.addNewOrderId = res.data.orderId || ''
                this.addSaveSuccessDia = true
                // this.$router.replace("/core/eoi");
            }
        } else if (res.code !== 1003)
            throw res;


    } catch (reason) {
        reason && this.$message({
            message: reason.msg || "保存失败",
            type: "error"
        });
        console.warn(reason);
        loadingRef && loadingRef.close()
    }
}
// 编辑保存
export async function handleStep1EditSave() {
    // console.log(this.navIndex);
    if (this.navIndex == 0) {
        try {
            await this.$refs.part1.validate();
        } catch (reason) {
            console.log("第1部分验证失败", this.$refs.part1);
            return this.$refs.part1.$el.scrollIntoView();
        }
        try {
            await this.$refs.part2.validate();
        } catch (reason) {
            console.log("第2部分验证失败");
            return this.$refs.part2.$el.scrollIntoView();
        }

        console.log("可以提交了");
        const loadingRef = this.$loading();
        try {
            const data = this.formatData1();
            // const data = {
            //     ...this.investor,
            //     ...this.investor.backAccount,
            //     ...this.investor.address,
            //     backAccount: undefined,
            //     address: undefined,
            //     imagepath1: this.investor.imagepath1.map(it => it.path).join(),
            //     imagepath2: this.investor.imagepath2.map(it => it.path).join(),
            // };
            // console.log(data);
            // console.log(this.orderMap);

            data.sysCode = this.orderMap.sysCode
            data.isTransfer = this.orderMap.isTransfer
            data.productIds = this.orderMap.outProductId.join() || null
            data.isTransfer = this.orderMap.isTransfer
            if (data.isTransfer == 0) {
                data.productIds = ''
            }
            data.isPicInvest = this.orderMap.isPicInvest
            data.custCreateTime = this.orderMap.custCreateTimeName
            data.custCreateEmp = this.orderMap.custCreateEmp

            // return
            await this.$refs.form.validate();
            const res = await this.$axios({
                url: "/api/order/saveOrder",
                method: "post",
                data
            })

            if (res.code === 2000) {
                loadingRef && loadingRef.close();
                if (this.isUpdate) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.fetchData();
                    this.getOrderInitInfo()
                    if (this.processNode == 2) this.$refs.part4?.fetchFileList();
                }
                else { //新增
                    this.addNewOrderId = res.data.orderId || ''
                    this.addSaveSuccessDia = true
                }
            } else if (res.code !== 1003)
                throw res;


        } catch (reason) {
            reason && this.$message({
                message: reason.msg || "保存失败",
                type: "error"
            });
            console.warn(reason);
            loadingRef && loadingRef.close()
        }
    }
    if (this.navIndex == 1) {
        try {
            await this.$refs.part6.validate();
        } catch (reason) {
            console.log("第6部分验证失败", this.$refs.part6);
            return this.$refs.part6.$el.scrollIntoView();
        }
        console.log("可以提交了");
        const loadingRef = this.$loading();
        try {
            // const data = this.formatData1();
            let data = {
                ...this.investor,
                ...this.investor.backAccount,
                ...this.investor.address,
                backAccount: undefined,
                address: undefined,
                imagepath1: this.investor.imagepath1.map(it => it.path).join(),
                imagepath2: this.investor.imagepath2.map(it => it.path).join(),

                buyPath: this.investor.buyPath.map(it => it.path).join(),
                idCardPath: this.investor.idCardPath.map(it => it.path).join(),
                moneyPath: this.investor.moneyPath.map(it => it.path).join(),

            };
            // console.log(this.investor);
            data.birthday = data.birthDayName
            // return
            await this.$refs.form.validate();
            const res = await this.$axios({
                url: "/api/order/saveOrderCustInfo",
                method: "post",
                data
            })

            if (res.code === 2000) {
                loadingRef && loadingRef.close();
                if (this.isUpdate) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.fetchData();
                    this.getOrderInitInfo()
                    if (this.processNode == 2) this.$refs.part4?.fetchFileList();
                }
                else { //新增
                    this.addNewOrderId = res.data.orderId || ''
                    this.addSaveSuccessDia = true
                }
            } else if (res.code !== 1003)
                throw res;


        } catch (reason) {
            reason && this.$message({
                message: reason.msg || "保存失败",
                type: "error"
            });
            console.warn(reason);
            loadingRef && loadingRef.close()
        }
    }
    if (this.navIndex == 2) {
        let validatepart6 = await this.$refs.part6.validate();
        console.log(validatepart6);
        if (validatepart6) {
            console.log("可以提交了");
            const loadingRef = this.$loading();
            try {
                // const data = this.formatData1();
                // console.log(this.investor);
                const data = {
                    ...this.investor,
                    ...this.investor.backAccount,
                    ...this.investor.address,
                    backAccount: undefined,
                    address: undefined,
                    imagepath1: this.investor.imagepath1.map(it => it.path).join(),
                    imagepath2: this.investor.imagepath2.map(it => it.path).join(),
                    buyPath: this.investor.buyPath.map(it => it.path).join(),
                    idCardPath: this.investor.idCardPath.map(it => it.path).join(),
                    moneyPath: this.investor.moneyPath.map(it => it.path).join(),
                };
                data.successtime = data.successTimeName
                const res = await this.$axios({
                    url: "/api/order/saveOrderCustFileInfo",
                    method: "post",
                    data
                })

                if (res.code === 2000) {
                    this.needPerfect = false
                    loadingRef && loadingRef.close();
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.fetchData();
                    this.getOrderInitInfo()

                    // if (this.isUpdate) {
                    //     this.$message({
                    //         message: "保存成功",
                    //         type: "success"
                    //     });
                    //     this.fetchData();
                    //     if (this.processNode == 2) this.$refs.part4?.fetchFileList();
                    // }
                    // else { //新增
                    //     this.addNewOrderId = res.data.orderId || ''
                    //     this.addSaveSuccessDia = true
                    // }
                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.log(reason);
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        }
    }


    // try {
    //     await this.$refs.part1.validate();
    // } catch (reason) {
    //     console.log("第1部分验证失败", this.$refs.part1);
    //     return this.$refs.part1.$el.scrollIntoView();
    // }
    // try {
    //     await this.$refs.part6.validate();
    // } catch (reason) {
    //     console.log("第6部分验证失败", this.$refs.part6);
    //     return this.$refs.part6.$el.scrollIntoView();
    // }
    // try {
    //     await this.$refs.part2.validate();
    // } catch (reason) {
    //     console.log("第2部分验证失败");
    //     return this.$refs.part2.$el.scrollIntoView();
    // }

    // 原来也没用此代码  try {
    //     await this.$refs.part4.validate();
    // } catch (reason) {
    //     console.log("第4部分验证失败");
    //     return this.$refs.part4.$el.scrollIntoView();
    // }

    // console.log("可以提交了");

    // const loadingRef = this.$loading();
    // try {
    //     const data = this.formatData1();
    //     console.log(data);
    //     await this.$refs.form.validate();
    //     const res = await this.$axios({
    //         url: "/api/order/saveOrder",
    //         method: "post",
    //         data
    //     })

    //     if (res.code === 2000) {
    //         loadingRef && loadingRef.close();
    //         if (this.isUpdate) {
    //             this.$message({
    //                 message: "保存成功",
    //                 type: "success"
    //             });
    //             this.fetchData();
    //             if (this.processNode == 2) this.$refs.part4?.fetchFileList();
    //         }
    //         else { //新增
    //             this.addNewOrderId = res.data.orderId || ''
    //             this.addSaveSuccessDia = true
    //         }
    //     } else if (res.code !== 1003)
    //         throw res;


    // } catch (reason) {
    //     reason && this.$message({
    //         message: reason.msg || "保存失败",
    //         type: "error"
    //     });
    //     console.warn(reason);
    //     loadingRef && loadingRef.close()
    // }





}

export function formatData1 () {
    const data = {
        custCode         : this.orderMap.cust_code,
        custuuid         : this.orderMap.custUuid,
        custphone        : this.orderMap.realCustPhone,
        custName         : this.orderMap.custName,
        // isbuy            : this.orderMap.isBuy ? 1 : 0,
        deptid           : this.orderMap.deptId,
        // buyname          : this.orderMap.buyName,
        // buyphone         : this.orderMap.realBuyPhone,
        dealType: this.orderMap.dealType,
        isconnapply      : this.orderMap.isConnApply,
        connEmpName      : this.orderMap.connEmpName,
        isInspect        : this.orderMap.isInspect,
        inspectTime      : this.orderMap.inspectTimeName,
        productid        : this.orderMap.productId,
        oldProductId     : this.orderMap.oldProductId.join(),
        investment       : this.orderMap.investment,
        outTime          : this.orderMap.outTimeName,
        rate             : this.orderMap.rate,
        finishTime       : this.orderMap.finishTimeName,
        contracttime     : this.orderMap.contractTimeName,
        signtime         : this.orderMap.signTimeName,
        moneytype        : this.orderMap.moneyType,
        contractmoney    : this.orderMap.contractMoney,
        moneySource      : this.orderMap.moneySource,
        isaff            : this.orderMap.isAff,
        remark           : this.orderMap.remark,
        path             : this.orderMap.path.map(it => it.path).join(),
        type             : this.orderMap.type,
        addState         : this.orderMap.addState,
        investReason     : this.orderMap.investReason,
        isChanTreaty     : this.orderEmpInfo.isChanTreaty,
        sourceid         : this.orderEmpInfo.sourceId,
        resourcecreateemp: this.orderEmpInfo.resourceCreateEmp,
        resourcemanage   : this.orderEmpInfo.resourceManage,
        ischancomm       : this.orderEmpInfo.isChanComm,
        channelname      : this.orderEmpInfo.channelName,
        channelCode: this.orderEmpInfo.channelCode,


        chanemp          : this.orderEmpInfo.chanEmp,
        chanmanage       : this.orderEmpInfo.chanManage,
        workdeptid       : this.orderEmpInfo.workDeptId,
        workemp          : this.orderEmpInfo.workEmp,
        workcharge       : this.orderEmpInfo.workCharge,
        workmanage       : this.orderEmpInfo.workManage,

        regionManage: this.orderEmpInfo.regionManage,


        commchief        : this.orderEmpInfo.commChief,
        regionChief      : this.orderEmpInfo.regionChief,
        iscooperation    : this.orderEmpInfo.isCooperation,
        isRelative: this.orderEmpInfo.isRelative,

        cooperationemp   : this.orderEmpInfo.cooperationEmp,
        cooperationmanage: this.orderEmpInfo.cooperationManage,
        cooperationcharge: this.orderEmpInfo.cooperationCharge,
        cooperationRegionManage: this.orderEmpInfo.cooperationRegionManage,

        orderFileListStr : JSON.stringify(this.orderFiles?.map(it => {
            return {
                type  : it.type,
                isall : it.isall,
                remark: it.remark,
                path  : it.path.map(p => p.path).join()
            }
        }) || []),
        isFromAusPlat: this.orderMap.isFromAusPlat
    };
    this.investor.birthday = this.investor.birthDayName


    data.orderCustInfoStr = JSON.stringify({
        ...this.investor,
        ...this.investor.backAccount,
        ...this.investor.address,
        backAccount: undefined,
        address: undefined,
        imagepath1: this.investor.imagepath1.map(it => it.path).join(),
        imagepath2: this.investor.imagepath2.map(it => it.path).join(),
        // imagepath3: this.investor.imagepath3.map(it => it.path).join(),

        buyPath: this.investor.buyPath.map(it => it.path).join(), //认购协议附件
        idCardPath: this.investor.idCardPath.map(it => it.path).join(), //手持ID正脸附件
        moneyPath: this.investor.moneyPath.map(it => it.path).join(),//资金来源说明附件

    })

    data.orderProcessStr = JSON.stringify({
        ...this.orderMap.orderProcess
    })
    if (!this.id) {
        data.orderDiscountIds = this.orderDiscountList.map(
            it => it.id).join();
    } else {
        data.id = this.id
    }

    return data;
}
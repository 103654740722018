<!-- 新增奖金 -->
<template>
    <el-dialog class="add-staff-item" :visible="show" @close="handleClose" title="奖金详情" width="900px">

        <el-form class="private" :model="PB" :rules="rules" label-width="130px" label-suffix=":" ref="form" @submit.prevent="handleSave">
            <el-form-item label="奖金发放日期" prop="commTime">
                <el-date-picker class="width2" v-model="PB.commTime" value-format="yyyy-MM-dd" placeholder="请选择"></el-date-picker>
            </el-form-item>
            <el-form-item label="奖金类型" prop="bonusType">
                <el-select v-model="PB.bonusType" class="width2" clearable @change="changeBonusType">
                    <el-option v-for="opt in typeEnum" :key="opt.value" :label="opt.name" :value="opt.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择员工" required>
                <el-button v-if="true || auth.save" type="text" @click.stop="handleAdd()">添加员工</el-button>
            </el-form-item>
            <el-table :data="PB.list" class="table" style="padding-bottom:8px">
                <el-table-column label="岗位员工">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checked" class="checkbox" style="display: flex;align-items: center;">
                            <div style="white-space: initial;">
                                {{ scope.row.empName }} - {{ scope.row.postName }}
                            </div>
                        </el-checkbox>
                    </template>
                </el-table-column>

                <el-table-column label="奖金比例">
                    <template slot-scope="scope">
                        <el-form-item :prop="'list['+scope.$index+'].bili'" :rules="scope.row.checked ? rules.bili : rules.none" label-width="0" class="width1h">
                            <el-input v-model="scope.row.bili" @change.once="handleInput($event, scope.row)" @input="handleInput2($event, scope.row,scope.$index)" placeholder="请输入奖金比例">
                                <span slot="append">%</span>
                            </el-input>
                        </el-form-item>
                    </template>
                </el-table-column>

                <el-table-column label="奖金金额">
                    <template slot-scope="scope">
                        <el-form-item :prop="'list['+scope.$index+'].commmoney'" :rules="scope.row.checked ? rules.commmoney : rules.none" label-width="0" class="width1h">
                            <el-input v-model="scope.row.commmoney" @change.once="handleInput($event, scope.row)" placeholder="请输入奖金金额">
                                <span slot="append">元</span>
                            </el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>

            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark" type="textarea" maxlength="100" class="width5"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
        <!-- 添加员工 -->

        <el-dialog :append-to-body='true' class="add-emp" :visible="showaddemp" @close="closeAdd" title="添加员工" width="500px">
            <div>
                <el-form class="private" :model="addEmpObj" :rules="addrules" label-width="130px" label-suffix=":" ref="addempform" @submit.prevent="sureAdd">
                    <el-form-item label="员工姓名" prop="empId">
                        <el-autocomplete clearable placeholder="请输入查询" v-model="addEmpObj.empName" :fetch-suggestions="querySearch" @select="handleSelect" class="width2"></el-autocomplete>
                    </el-form-item>
                    <el-form-item label="结佣岗位" prop="postId">
                        <el-select v-model="addEmpObj.postId" class="width2" clearable @change="choosepost">
                            <el-option v-for="p in orderPostListEnum" :key="p.value" :label="p.name" :value="p.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer">
                <el-button @click="closeAdd">取消</el-button>
                <el-button @click="sureAdd" type="primary">添加</el-button>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    name: "AddStaffItem2",
    props: [
        "show",
        "empList",
        "orderId",
        "typeEnum",
        "orderCommInfo",
        "orderPostListEnum",
    ],

    data() {
        return {
            viewIsReady: false,
            PB: {
                bonusType: "",
                commTime: "",
                list: [],
                remark: "",
            },
            rules: {
                commTime: { required: true, message: "请选择奖金发放日期" },
                bonusType: { required: true, message: "请选择奖金类型" },

                bili: {
                    validator: function (r, v, cb) {
                        const isFine = /^\d+(\.\d{1,2})?$/.test(v);
                        if (isFine) cb();
                        else cb("请输入正确的比例, 允许两位小数");
                    },
                },
                commmoney: {
                    validator: function (r, v, cb) {
                        const isFine = /^\d+(\.\d{1,2})?$/.test(v);
                        if (isFine) cb();
                        else cb("请输入正确的金额, 允许两位小数");
                    },
                },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },
            },
            addEmpObj: {
                empName: "",
                empId: "",
                postName: "",
                postId: "",
            },
            addrules: {
                postId: {
                    required: true,
                    message: "请选择结佣岗位",
                    trigger: ["change", "blur"],
                },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },
            },
            oninputindex: "",
            fullarr: [],
            showfull: false,
            showaddemp: false,
        };
    },

    watch: {
        async show(show) {
            if (show) {
                this.PB.list = JSON.parse(JSON.stringify(this.empList)).map(
                    (item) => {
                        item.checked = false;
                        item.bili = item.realBili;
                        item.commmoney = "";
                        return item;
                    }
                );
                await this.$nextTick();
                this.$refs.form.clearValidate();
            }
        },
        "addEmpObj.empName"(val) {
            if (val == "") {
                this.addEmpObj.empId = "";
            }
        },
    },

    methods: {
        changeBonusType() {
            console.log(this.PB.bonusType);

            let userMoney = 0;
            if (this.PB.bonusType == 1) {
                //保底奖金
                console.log("保底奖金");
                userMoney = this.orderCommInfo.guaranteeMoney || 0;
            }
            if (this.PB.bonusType == 2) {
                //投入PK池奖金
                console.log("投入PK池奖金");

                userMoney = this.orderCommInfo.pkRatioMoney || 0;
            }
            if (this.PB.bonusType == 3) {
                //获胜奖金
                console.log("获胜奖金");

                userMoney = this.orderCommInfo.victoryMoney || 0;
            }
            this.PB.list.forEach((element) => {
                element.commmoney = Math.round(
                    (userMoney * 100 * (element.bili * 100)) /
                    1000000*100
                )/100;
            });
        },
        handleAdd() {
            this.showaddemp = true;
        },
        closeAdd() {
            this.showaddemp = false;
            this.addEmpObj = {
                empName: "",
                empId: "",
                postName: "",
                postId: "",
            };
            setTimeout(() => {
                this.$refs.addempform.clearValidate();
            }, 20);
        },
        async sureAdd() {
            try {
                await this.$refs.addempform.validate();
                this.PB.list.push({
                    checked: false,
                    bili: 0,
                    oldbili: 0,
                    commmoney: "",
                    empName: this.addEmpObj.empName,
                    empId: this.addEmpObj.empId,
                    postName: this.addEmpObj.postName,
                    postId: this.addEmpObj.postId,
                    type: this.addEmpObj.postId,
                });

                this.closeAdd();
            } catch (reason) {
                console.warn(reason);
            }
        },
        async querySearch(queryString, cb) {
            this.PB.empId = 0;
            try {
                const res = await this.$axios({
                    url: "/api/employee/queryEmpListIncludeDepart",
                    method: "post",
                    data: {
                        searchName: queryString,
                    },
                });

                if (res.code === 2000) {
                    cb(
                        res.data.empList.map((emp) => {
                            emp.value = [emp.name, emp.reginName]
                                .filter(Boolean)
                                .join(" - ");
                            return emp;
                        })
                    );
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
            }
        },
        handleSelect(item) {
            this.addEmpObj.empName = item.name;
            this.addEmpObj.empId = item.id;
        },
        choosepost(val) {
            let item = this.orderPostListEnum.find((item) => {
                return item.value == val;
            });
            this.addEmpObj.postName = item.name;
        },
        handleInput(value, data) {
            // console.log("change", value, data)
            if (!!value.trim() && !data.checked) {
                data.checked = true;
            }
        },
        handleInput2(value, data, index) {
            let userMoney = 0;
            if (this.PB.bonusType == 1) {
                //保底奖金
                console.log("保底奖金");
                userMoney = this.orderCommInfo.guaranteeMoney || 0;
            }
            if (this.PB.bonusType == 2) {
                //投入PK池奖金
                console.log("投入PK池奖金");

                userMoney = this.orderCommInfo.pkRatioMoney || 0;
            }
            if (this.PB.bonusType == 3) {
                //获胜奖金
                console.log("获胜奖金");

                userMoney = this.orderCommInfo.victoryMoney || 0;
            }
            if (!isNaN(parseFloat(value)) && isFinite(value)) {
                this.PB.list[index].commmoney = Math.round(
                    (userMoney * 100 * (value * 100)) /
                    1000000*100
                )/100;
            }
        },

        async handleSave() {
            const loadingRef = this.$loading({
                target: ".add-staff-item .el-dialog",
            });

            try {
                await this.$refs.form.validate();
                const checkedList = this.PB.list.filter(
                    ({ checked }) => checked
                );
                if (!checkedList.length) {
                    loadingRef.close();
                    return this.$message({
                        message: "请勾选员工",
                        type: "error",
                    });
                }
                const { commTime, remark, bonusType } = this.PB;
                const res = await this.$axios({
                    url: "/api/order/saveBonusRecord",
                    method: "post",
                    data: {
                        calTimeStr: commTime,
                        remark,
                        bonusType,
                        orderId: this.orderId,
                        empPostBonusStr: JSON.stringify(
                            checkedList.map((item) => {
                                let empId = item.empId;
                                let postType = item.type;
                                let calScale = item.bili;
                                let calMoney = item.commmoney;
                                return {
                                    empId,
                                    postType,
                                    calScale,
                                    calMoney,
                                };

                                // const {type,empId: empid,bili,commmoney} = item;
                                // return {
                                //     type,empid,bili,commmoney
                                // };
                            })
                        ),
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "操作失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }
        },

        resetData() {
            this.PB.commTime = "";
            this.PB.remark = "";
            this.PB.bonusType = "";
        },

        handleClose() {
            this.resetData();
            this.$emit("update:show", false);
        },
    },
};
</script>

<style lang='scss' scoped>
.table {
    width: 700px;
    margin-left: 130px;
    margin-top: -10px;
    /deep/.cell {
        overflow: visible;
    }
}
.table /deep/ .el-table__body{
    padding-bottom:8px;
}
.el-form.private .cell .el-form-item {
    margin-bottom: 0;
    margin-right: 0;
}
.el-table::before{
    height:0px;
}
</style>
<!-- 已废弃 20220704 -->
<!-- 添加、编辑字典项 -->
<template>
    <el-dialog class="export-payments"
        :visible="show"
        @close="handleClose"
        title="导出缴费记录"
        width="560px">

        <el-form class="private"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item>
                <el-select
                    class="width1h" style="margin-right:0"
                    @keyup.native.stop v-model="PB.type">
                    <el-option
                        v-for="item in exportTypeEnum"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-date-picker
                    class="width1h" style="margin-left:0"
                    v-model="PB.startTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
                <span>至</span>
                <el-date-picker
                    class="width1h"
                    v-model="PB.endTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">确定导出</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {exportTypeEnum} from "./config";
export default {
    name: 'ExportPayments',
    props: ["show"],

    data () {
        return {
            exportTypeEnum,
            PB: {
                type: 1,
                startTime: "",
                endTime: ""
            }
        }
    },

    methods: {
        async handleSave () {

            const loadingRef = this.$loading({
                target: ".export-payments .el-dialog"
            });

            try {
                await this.validate();
				await this.$confirm("确定导出吗？", "系统提示")

                // const data = this.extractParams(true);
				// if (data.search_sys) {
				// 	const sys = this.systemEnum.find(it =>
				// 		it.id === data.search_sys)
				// 	data.search_sys = sys?.sysCode
				// }

                let data = {}
                data.searchStartTime = this.PB.startTime
                data.searchEndTime = this.PB.endTime
                data.searchTimeType = this.PB.type

                const res = await this.$oxios({
                    url: "/api/order/downOrderPay",
                    method: "post",
					data
                })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;


            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "导出失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        validate () {
            const {startTime, endTime} = this.PB;
            if (startTime || endTime) return Promise.resolve()
            else {
                this.$message({
                    message: "请选择日期",
                    type: "warning"
                });
                return Promise.reject()
            }
        },

        handleClose () {
            this.PB.type      = 1;
            this.PB.startTime = "";
            this.PB.endTime   = "";
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped></style>
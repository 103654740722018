<template>
    <div>
        <!-- {{isUpdate}} -->
            <!-- :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)" -->
        <el-form
            :model="investor"
            :rules="rules"
            class="order-part1 order-part private"
            label-width="180px"
            label-suffix=": "
            ref="form1"
        >
            <div v-show="navIndex!=2">
                <div class="part-title"><h3>投资人信息</h3></div>

                <div class="line">
                    <el-form-item prop="name" class="doubel_line_label" >
                        <template slot="label">
                            <span class="form-item-label">认购人姓名</span>
                            <span class="form-item-vice">Investor Name</span>
                        </template>
                        <el-input v-model.trim="investor.name" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="20"
                            class="width3"></el-input>
                    </el-form-item>

                    <el-form-item prop="phone" class="doubel_line_label " :disabled='false' >
                        <template slot="label">
                            <span class="form-item-label">联系电话</span>
                            <span class="form-item-vice">Telephone Number</span>
                        </template>

                        <el-input
                            v-if="phoneDisabled"
                            v-model.trim="investor.showPhone"
                            disabled
                            class="width3 111"></el-input>
                        <el-input v-else
                            type="number"
                            v-model.trim="investor.phone"
                            class="width3 222"></el-input>
                    </el-form-item>
                </div>

                <div class="line">
                    <el-form-item prop="email" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">电子邮箱</span>
                            <span class="form-item-vice">Email Address</span>
                        </template>
                        <el-input v-model.trim="investor.email" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="50"
                            class="width3"></el-input>
                    </el-form-item>

                    <el-form-item label="身份证号" prop="idcard" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">身份证号</span>
                            <span class="form-item-vice">National ID Number</span>
                        </template>
                        <el-input v-model.trim="investor.idcard" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            class="width3"></el-input>
                    </el-form-item>
                </div>
                <div class="line">
                    <el-form-item required class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">现居地(中&英)</span>
                            <span class="form-item-vice">Current Residential Address</span>
                        </template>
                        <AddressInput
                            ref="addressInput"
                            :address="investor.address"
                            :options="addressOptions"
                            :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            class="width3"
                        ></AddressInput>
                    </el-form-item>
                    <!-- {{investor.birthDayName}} -->
                   

                    <el-form-item prop="birthDayName" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">生日</span>
                            <span class="form-item-vice">Date of Birth</span>
                        </template>
                        <!-- <el-input v-model="investor.birthDayName"
                            class="width3"></el-input> -->
                        <el-date-picker
                            v-model.trim="investor.birthDayName" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            class="width3"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择"
                        ></el-date-picker>
                    </el-form-item>
                </div>

                <div class="line">
                    <el-form-item prop="passport" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">护照号</span>
                            <span class="form-item-vice">Passport Number</span>
                        </template>
                        <el-input v-model.trim="investor.passport" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="50"
                            class="width3"></el-input>
                    </el-form-item>

                    <el-form-item prop="taxnum" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">税号</span>
                            <span class="form-item-vice">Tax File Number</span>
                        </template>
                        <el-input v-model.trim="investor.taxnum" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="50"
                            class="width3"></el-input>
                    </el-form-item>
                </div>

                <div class="line">
                    <el-form-item prop="company" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">所从事的公司</span>
                            <span class="form-item-vice">Company Name</span>
                        </template>
                        <el-input v-model.trim="investor.company" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="100"
                            class="width3"></el-input>
                    </el-form-item>

                    <el-form-item prop="position" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">职位</span>
                            <span class="form-item-vice">Position</span>
                        </template>
                        <el-input v-model.trim="investor.position" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="50"
                            class="width3"></el-input>
                    </el-form-item>
                </div>

                <div class="line">
                    <el-form-item prop="moneysource" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">平日主要收入来源</span>
                            <span class="form-item-vice">Major Source of Income</span>
                        </template>
                        <el-input v-model.trim="investor.moneysource" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="100"
                            class="width3"></el-input> 
                    </el-form-item>

                    <el-form-item prop="taxcountry" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">税务居民所在国</span>
                            <span class="form-item-vice">Country of Tax Residency</span>
                        </template>
                        <el-input v-model.trim="investor.taxcountry" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="50"
                            class="width3"></el-input>
                    </el-form-item>
                </div>

                <div class="line">
                    <el-form-item prop="isold" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">是否是老客户</span>
                            <span class="form-item-vice">Existing/New Client</span>
                        </template>
                        <!-- <el-input v-model="investor.isold" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="50"
                            class="width3"></el-input> -->
                            <el-radio-group v-model="investor.isold"  :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)">
                                <el-radio label="1">是</el-radio>
                                <el-radio label="2">否</el-radio>
                            </el-radio-group>


                    </el-form-item>

                    <el-form-item prop="describe" class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">其他对客户的了解</span>
                            <span class="form-item-vice">Other Comments</span>
                        </template>
                        <el-input v-model.trim="investor.describe" :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                            maxlength="100"
                            class="width3"></el-input>
                    </el-form-item>
                </div>

                <div class="">
                    <el-form-item required class="doubel_line_label">
                        <template slot="label">
                            <span class="form-item-label">账户信息</span>
                            <span class="form-item-vice">Bank Account</span>
                        </template>
                        <BankAccountInput
                            ref="bankAccountInput"
                            class="bank-account-input"
                            :accont="investor.backAccount"
                            :disabled="(orderMap.processCheckState==3||orderMap.processCheckState==4||orderMap.processCheckState==5||orderMap.processCheckState==6||orderMap.processCheckState==7) && !(isWhiteMan)"
                        ></BankAccountInput>
                    </el-form-item>
                </div>
            </div>
            <div v-show="navIndex==2">
                <div class="part-title hw_mt16"><h3>EOI附件</h3></div>
                <span class="uptips">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span>

                <!-- 现金 / 续转签-->
                <template v-if="(orderMap.orderProcess.type == 1 || orderMap.orderProcess.type == 2) && orderInitInfo.isOldOrder!=1 "> 
                    <div class="line">
                        <!-- {{investor.imagepath1}} -->
                        <el-form-item prop="imagepath1" class="full-width" label="《switchform》附件" >
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investor.imagepath1"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="(orderMap.processCheckState ==0||orderMap.processCheckState ==1||orderMap.processCheckState ==2||orderMap.processCheckState ==8||orderMap.processCheckState ==9 ) || !!isWhiteMan"
                                >
                                    <!-- <span class="hint" slot="hint">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span> -->
                                </UploadFile1>
                        </el-form-item>
                    </div>
                    
                    <div class="line">
                        <el-form-item prop="imagepath2" class="full-width" label="签署《switchform》照片">
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investor.imagepath2"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="(orderMap.processCheckState ==0||orderMap.processCheckState ==1||orderMap.processCheckState ==2||orderMap.processCheckState ==8||orderMap.processCheckState ==9 ) || !!isWhiteMan"
                                >
                                    <!-- <span class="hint" slot="hint">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span> -->
                                </UploadFile1>
                        </el-form-item>
                    </div>
                </template>
                <!-- 抵押贷款 -->
                <template v-if="orderMap.orderProcess.type == 3 && orderInitInfo.isOldOrder!=1 "> 
                    <div class="line">
                        <el-form-item label="贷款国家" prop="loancountrytype" class="full-width">
                            <el-radio-group v-model="investor.loancountrytype"
                                @change="handleloancountrytypeChange"
                            >
                                <el-radio :label="1">国内</el-radio>
                                <el-radio :label="2">澳洲</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    
                    <!--澳洲 successTimeName-->
                    <template v-if="investor.loancountrytype == 2">
                        <div class="line">
                            <el-form-item prop="successTimeName" class="full-width" label="预批通过日期">
                                <el-date-picker
                                    v-model="investor.successTimeName"
                                    class="width3"
                                    value-format="yyyy-MM-dd"
                                    placeholder="请选择"
                                ></el-date-picker>
                            </el-form-item>
                        </div>

                        <div class="line">
                            <el-form-item prop="imagepath1" class="full-width" label="预批凭证">
                                <UploadFile1
                                        :isDisable="isDisable"
                                        v-model="investor.imagepath1"
                                        :accept="uploadAccept"
                                        :limit="5"
                                        :maxSize="1e4"
                                        :show-delete="(orderMap.processCheckState ==0||orderMap.processCheckState ==1||orderMap.processCheckState ==2||orderMap.processCheckState ==8||orderMap.processCheckState ==9 ) || !!isWhiteMan"
                                    >
                                        <!-- <span class="hint" slot="hint">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span> -->
                                    </UploadFile1>
                            </el-form-item>
                        </div>
                    </template>

                    <!-- 国内 -->
                    <template v-else-if="investor.loancountrytype == 1">
                        <div class="line">
                            <el-form-item prop="imagepath1" class="full-width" label="国内贷款合同">
                                <UploadFile1
                                        :isDisable="isDisable"
                                        v-model="investor.imagepath1"
                                        :accept="uploadAccept"
                                        :limit="5"
                                        :maxSize="1e4"
                                        :show-delete="(orderMap.processCheckState ==0||orderMap.processCheckState ==1||orderMap.processCheckState ==2||orderMap.processCheckState ==8||orderMap.processCheckState ==9 ) || !!isWhiteMan"
                                    >
                                        <!-- <span class="hint" slot="hint">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span> -->
                                    </UploadFile1>
                            </el-form-item>
                        </div>
                    </template>
                </template>
                <!-- 新签 -->
                <template v-if="orderMap.orderProcess.type == 4&& orderInitInfo.isOldOrder!=1 "> 
                </template>
                <div class="line">
                    <el-form-item prop="buyPath" class="full-width" label="认购协议附件">
                        <UploadFile1
                                :isDisable="isDisable"
                                v-model="investor.buyPath"
                                :accept="uploadAccept"
                                :limit="5"
                                :maxSize="1e4"
                                :show-delete="(orderMap.processCheckState ==0||orderMap.processCheckState ==1||orderMap.processCheckState ==2||orderMap.processCheckState ==8||orderMap.processCheckState ==9 ) || !!isWhiteMan"
                            >
                                <!-- <span class="hint" slot="hint">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span> -->
                            </UploadFile1>
                    </el-form-item>
                </div>
                <div class="line">
                    <el-form-item prop="idCardPath" class="full-width" label="手持ID正脸附件">
                        <UploadFile1
                                :isDisable="isDisable"
                                v-model="investor.idCardPath"
                                :accept="uploadAccept"
                                :limit="5"
                                :maxSize="1e4"
                                :show-delete="(orderMap.processCheckState ==0||orderMap.processCheckState ==1||orderMap.processCheckState ==2||orderMap.processCheckState ==8||orderMap.processCheckState ==9 ) || !!isWhiteMan"
                            >
                                <!-- <span class="hint" slot="hint">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span> -->
                            </UploadFile1>
                    </el-form-item>
                </div>
                <div class="line"  v-if="orderMap.orderProcess.type == 3">
                    <el-form-item  prop="moneyPath" class="full-width" label="资金来源说明附件">
                        <UploadFile1
                                :isDisable="isDisable"
                                v-model="investor.moneyPath"
                                :accept="uploadAccept"
                                :limit="5"
                                :maxSize="1e4"
                                :show-delete="(orderMap.processCheckState ==0||orderMap.processCheckState ==1||orderMap.processCheckState ==2||orderMap.processCheckState ==8||orderMap.processCheckState ==9 ) || !!isWhiteMan"
                            >
                                <!-- <span class="hint" slot="hint">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span> -->
                            </UploadFile1>
                    </el-form-item>
                </div>
                <!--  -->
                <div class="line"  v-if="(orderMap.orderProcess.type == 1 || orderMap.orderProcess.type == 2|| orderMap.orderProcess.type == 4) ">
                    <el-form-item  prop="moneySourceRemark" class="full-width" label="资金来源说明">
                        <el-input
                            v-model="investor.moneySourceRemark"
                            type="textarea"
                            maxlength="500"
                            rows="5"
                        ></el-input>
                    </el-form-item>
                </div>
                <!--  -->
               
            </div>
        </el-form>


    </div>

</template>

<script>
import BankAccountInput from "./BankAccountInput.vue"
import AddressInput from "./AddressInput.vue"
import UploadFile1 from '../../../components/UploadFile1/UploadFile1.vue';
import {form6Rules, pathSlice} from "./config";

export default {
    name: 'FormPart6',
    components: {BankAccountInput, AddressInput, UploadFile1},
    props: [
        "id",
        "orderMap",
        "investor",
        "addressOptions",
        "phoneDisabled",
        "isWhiteMan",
        'navIndex',
        "orderInitInfo",
    ],

    data () {
        return {
            uploadAccept: "image/*,application/pdf,application/x-pdf",
            // form6Rules,
        }
    },
    watch: {
         navIndex() {
            setTimeout(() => {
                this.$refs.form1.clearValidate();
            }, 2);
        },

        "investor.moneyPath": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
        "investor.buyPath": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
        "investor.idCardPath": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
        "investor.imagepath1": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
         "investor.imagepath2": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
         "investor.phone": function (value, oldValue) {
             this.investor.phone= value.slice(0,11)
        },
    },
    // investor.moneyPath

    computed: {
        isDisable(){
            return !!(((this.orderMap.processCheckState==3||this.orderMap.processCheckState==4||this.orderMap.processCheckState==5||this.orderMap.processCheckState==6||this.orderMap.processCheckState==7)) && !(this.isWhiteMan))
        },
        isUpdate () {
            return !!this.orderMap?.id
        },
        rules(){
            if(this.navIndex==2){
                if(this.orderInitInfo.isOldOrder==1){//来数据处理 20230421
                    return  Object.assign({},{
                        buyPath     : {required: true, message: "请上传附件"},
                        idCardPath     : {required: true, message: "请上传附件"},
                        moneyPath     : {required: true, message: "请上传附件"},
                    })
                }else{
                    if(this.orderMap.orderProcess.type == 1 || this.orderMap.orderProcess.type == 2|| this.orderMap.orderProcess.type == 4){
                        return  Object.assign({},{
                            loancountrytype: {required: true, message: "请选择贷款国家"},
                            successTimeName    : {required: true, message: "请选择预批通过时间"},
                            imagepath1: { required: true, message: '请上传附件' },
                            imagepath2     : {required: true, message: "请上传附件"},
                            buyPath     : {required: true, message: "请上传附件"},
                            idCardPath     : {required: true, message: "请上传附件"},
                            moneyPath     : {required: true, message: "请上传附件"},
                            moneySourceRemark     : {required: true, message: "请输入资金来源说明"},
                        })
                    }else if(this.orderMap.orderProcess.type == 3 ){
                        return  Object.assign({},{
                           loancountrytype: {required: true, message: "请选择贷款国家"},
                           successTimeName    : {required: true, message: "请选择预批通过时间"},
                           imagepath1: { required: true, message: '请上传附件' },
                           imagepath2     : {required: true, message: "请上传附件"},
                           buyPath     : {required: true, message: "请上传附件"},
                           idCardPath     : {required: true, message: "请上传附件"},
                           moneyPath     : {required: true, message: "请上传附件"},
                       })
                    }else{
                        return  Object.assign({},{
                           loancountrytype: {required: true, message: "请选择贷款国家"},
                           successTimeName    : {required: true, message: "请选择预批通过时间"},
                           imagepath1: { required: true, message: '请上传附件' },
                           imagepath2     : {required: true, message: "请上传附件"},
                           buyPath     : {required: true, message: "请上传附件"},
                           idCardPath     : {required: true, message: "请上传附件"},
                           moneyPath     : {required: true, message: "请上传附件"},
                       })
                    }
                }
            }else{
                return form6Rules
            }

            // if(this.isUpdate){
            //     return  Object.assign({},form6Rules,{
            //         loancountrytype: {required: true, message: "请选择贷款国家"},
            //         successTimeName    : {required: true, message: "请选择预批通过时间"},
            //         imagepath1: { required: true, message: '请上传附件' },
            //         imagepath2     : {required: true, message: "请上传附件"},
            //         imagepath3     : {required: true, message: "请上传附件"},
            //     })
            // }else{
            //     return form6Rules
            // }
        }


        // curProduct () {
        //     const {productId} = this.orderMap;
        //     if (!productId) return null;
        //     else return this.products.find(
        //         item => item.id === productId)
        // },
        // productRelativeEnum () {
        //     const defaultValue = {
        //         term     : [],
        //         rate     : [],
        //         starttime: "",
        //         endtime  : "",
        //         hasTime  : false
        //     };
            
        //     if (!this.curProduct) return defaultValue;
        //     else {
        //         return {
        //             term     : this.curProduct.investment.split("#").filter(Boolean),
        //             rate     : this.curProduct.rate.split("#").filter(Boolean),
        //             starttime: this.curProduct.starttime,
        //             endtime  : this.curProduct.endtime,
        //             get hasTime() {return !!(this.starttime || this.endtime)}
        //         };
        //     }
        // },
        // validDateRange () {
        //     let min, max;
        //     if (this.id) {
        //         min = new Date(this.orderMap.createTime || undefined);
        //         const max1 = new Date(this.orderMap.contractTime || undefined);
        //         const max2 = new Date(this.orderMap.createTime || undefined);
        //         max = max1 > max2 ? max1 : max2;
        //     } else {
        //         min = new Date();
        //         max = new Date();
        //     }
        //     const day = min.getDay();
        //     let offset = 3;
        //     if (day < 3) offset = 5;
        //     else if (day > 5) offset = 4;
        //     min.setDate(min.getDate()-offset);
        //     return ({min, max})
        // }
    },

    methods: {

        handleSelectBun () {
            this.showPicker = true;
        },

        handlePick (data) {
            this.orderMap.deptId   = data.id;
            this.orderMap.prenames = pathSlice(data.prenames,3);
        },

        handleProductChange () {
            this.orderMap.investment = "";
            this.orderMap.rate       = "";
        },

        handleSuggestClick (text) {
            console.log("点击建议文字", text);
            this.orderMap.investReason += text;
        },

        handleloancountrytypeChange () {
            this.clearAttachment(33)
        },

        clearAttachment (flag) {
            if (flag !== 33)
            this.investor.loancountrytype = "";
            this.investor.successTimeName = "";
            this.investor.imagepath1 = [];
            this.investor.imagepath2 = [];
            this.investor.imagepath3 = [];
        },
        async validate () {
            console.log('navIndex',this.navIndex);
            if(this.navIndex==1){
                await this.$refs.form1.validate()
                await this.$refs.addressInput.validate()
                await this.$refs.addressInput.validate()
                return this.$refs.bankAccountInput.validate()
            }else if(this.navIndex==2){
                //  buyPath idCardPath moneyPath
                const {imagepath1, imagepath2, buyPath,idCardPath,moneyPath} = this.investor;
                 if ([...imagepath1, ...imagepath2, ...buyPath, ...idCardPath, ...moneyPath].some(({percentage,status}) => (percentage < 100 || status =='uploading'))){
                    await this.$message({
                        message: "请等待附件上传完毕",
                        type: "error"
                    });
                    return  false
                }else{
                    return  this.$refs.form1.validate()
                }

            }else{ //新增
                await this.$refs.form1.validate()
                await this.$refs.addressInput.validate()
                await this.$refs.addressInput.validate()
                return this.$refs.bankAccountInput.validate()
            }
        }

        // async validate () {
        //     console.log('navIndex',this.navIndex);
        //     if(this.navIndex==1){
        //         await this.$refs.form1.validate()
        //         await this.$refs.addressInput.validate()
        //         await this.$refs.addressInput.validate()
        //         return this.$refs.bankAccountInput.validate()
        //     }else if(this.navIndex==2){
        //         const {imagepath1, imagepath2, imagepath3} = this.investor;
        //         if ([...imagepath1, ...imagepath2, ...imagepath3].some(({percentage}) => percentage < 100))
        //             return this.$message({
        //                 message: "请等待附件上传完毕",
        //                 type: "error"
        //             });
        //         return  this.$refs.form1.validate()
        //     }else{ //新增
        //         await this.$refs.form1.validate()
        //         await this.$refs.addressInput.validate()
        //         await this.$refs.addressInput.validate()
        //         return this.$refs.bankAccountInput.validate()
        //     }
        // }
    }
}
</script>

<style lang='scss' scoped>
    .product-dates {
        color: #606266;

        &:nth-child(n+2) {
            margin-left: 16px;
        }
    }

    .full-width {
        width: 100%;
        /deep/ .el-form-item__label{
            width: 200px !important;
        }
        /deep/ .el-form-item__content{
            margin-left: 200px;
        }
    }

    .el-form.private .width3 {
        width: 300px
    }

    .old-trigger-btn {
        margin-right: calc(100% - 100px);
    }

    .old-product-list {
        &:empty {
            display: none;
        }
        margin-top: 6px;
        padding: 14px;
        background-color: rgba($color: #000000, $alpha: .05);
        border: 1px solid rgba($color: #000000, $alpha: .05);
    }

    .suggest-list {
        display: flex;

        li {
            margin-left: 10px;
            padding: 0 12px;
            line-height: 30px;
            height: 32px;
            background-color: #f0f6ff;
            border: 1px solid rgba($color: #000000, $alpha: .05);
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                background-color: #f0f3f6;

            }
        }
    }

    .bank-account-input {
        padding: 24px;
        padding-bottom: 8px;
        background: #F3F3F3;
    }


    /deep/ .doubel_line_label {
        .el-form-item__label {
            line-height: 1;
        }

        .form-item-label {
            line-height: 18px;
            &:after {
                content: ":";
            }
        }
        .form-item-vice {
            display: block;
            line-height: 14px;
            font-size: 12px;
        }
    }
    .line /deep/ .upload-row .el-upload--text{
        text-align: left;
    }
    .uptips{
        color: rgb(192, 196, 204);
        font-size: 13px;
        margin-left: 30px;
        margin-bottom: 10px;
        display: inline-block;
    }
            
</style>
<!-- 选择树 -->
<template>
    <el-dialog class="picker-tree"
        :visible="show"
        @close="handleClose"
        :title="title || '选择部门'"
        width="500px">

        <!-- {{expandKeys}} -->
        <!-- default-checked-keys -->
        <!--  -->
            <!-- :default-expanded-keys="expandKeys" -->

            <!-- {{tree}} -->

            <!-- {{value}}{{expandKeys}} -->


        <el-tree
            :data="tree"
            node-key="id"
            ref="tree"
            :expand-on-click-node="false"
            :current-node-key="value"
            :props="treeProps"
            :render-content="render"
            :default-expanded-keys="expandKeys"
            @node-click="handleNodeClick"
        ></el-tree>

        <!-- default-expanded-keys -->
        <!-- default-checked-keys -->

        <!-- {{tree}} -->


        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'PickerTree',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        tree: {
            type: Array,
            default () {
                return {
                    name: "全部",
                    text: "全部",
                    children: []
                }
            }
        },
        value: {
            type: [String,Number],
            default: ""
        },
        treeProps: {
            type: Object,
            default () {
                return {
                    children: 'children',
                    label: 'name'
                }
            }
        },
        title: {
            type: String,
            default: "选择部门"
        },
        // defaultExpandedKeys: {
        //     type: Array,
        //     default () {
        //         return [58]
        //     }
        // },
        expandKeys: {
            type: Array,
            default () {
                return [1]
            }
        }
    },

    data () {
        return {
            currentNode: null
        }
    },

    watch: {
        show: async function () {
            console.log(11111,this.tree);
            await this.$nextTick();
            this.$refs.tree.setCurrentKey(this.value)
        }
    },

    methods: {
         render (createElement, {data}) {
            return createElement(
                "div",
                {
                    class: data.pickable ? "" : "disabled"
                },
                data.text || data.name || ""
            )
        },

        // 点击树的节点
        handleNodeClick1(data) {
            this.$refs.tree.setCurrentNode(data)
        },
        handleNodeClick(data) {
            console.log(data);
            const {pickable} = data;
            if (pickable) {
                this.$refs.tree.setCurrentNode(data);
                this.currentNode = data;
            }
            else {
                this.$message.closeAll();
                this.$message({
                    message: "不能选择该节点",
                    type: "warning"
                })

                console.log("不能选择该节点", data?.code);
                if (this.currentNode)
                    this.$refs.tree.setCurrentNode(this.currentNode);
                else
                    this.$refs.tree.setCurrentKey(this.currentNode);
            }
        },

        handleClose () {
            this.$emit("update:show", false);
        },

        handleSave () {
            const node = this.$refs.tree.getCurrentNode();
            this.$emit("input", node);
            this.handleClose();
        }
    }
}
</script>

<style lang='scss' scoped>
    /deep/ {
        .el-tree-node__content:hover,
        .el-tree-node:focus > .el-tree-node__content {
            background-color: inherit;
        }
        .is-current > .el-tree-node__content {
            background-color: #fff5e8 !important;
        }
        // .is-current {
        //     background-color: #fff5e8;
        // }
         .disabled {
            color: darkgray
        }
    }

</style>
<!-- 填写银行账户信息 -->
<template>
    <div class="bank-account">
        <li class="doubel_line_label">
            <div class="label">
                <span>账户名称</span>
                <span>Account Name</span>
            </div>
            <span class="value">{{ investor.bankAccName }}</span>
        </li>

        <li prop="realBuyPhone" class="doubel_line_label">
            <div class="label">
                <span>分行号</span>
                <span>BSB</span>
            </div>
            <span class="value">{{ investor.bankNo }}</span>
        </li>

        <li class="doubel_line_label">
            <div class="label">
                <span>账号</span>
                <span>Account Number</span>
            </div>
            <span class="value">{{ investor.bankAccNumber }}</span>
        </li>

        <li prop="realBuyPhone" class="doubel_line_label">
            <div class="label">
                <span>国际转账编号</span>
                <span>SWIFT Code</span>
            </div>
            <span class="value">{{ investor.swiftCode }}</span>
        </li>

        <li class="doubel_line_label">
            <div class="label">
                <span>银行</span>
                <span>Bank</span>
            </div>
            <span class="value">{{ investor.bankName }}</span>
        </li>
    </div>
</template>

<script>
export default {
    name: 'BankAccoun',
    props: ["investor"]
}
</script>

<style lang='scss' scoped>
    .bank-account {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 16px;
        background-color: white;
    }

    .doubel_line_label {
        display: flex;
        // align-items: center;
        width: 48%;
        margin-bottom: 16px;
        .label {
            flex-shrink: 0;
            width: 100px;
            margin-right: 8px;
            color: gray;
            text-align: right;
            white-space: nowrap;
            span:first-child {
                line-height: 18px;
                &:after {
                    content: ":";
                }
            }
            span:last-child {
                display: block;
                line-height: 14px;
                font-size: 12px;
            }
        }
    }
</style>
import forList        from "@/mixin/forList";
import {
	searchTypeEnum,
	dateTypeEnum,
	payStateEnum,
	fileStateEnum,
	expireStateEnum,
	actionList
} from "./config";

// 将数字转换为美式表示方式
const formatNumber = function (val) {
    if ((typeof val) !== "number") return "";
    const _val = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return _val;
}

export default {
	name: "EIOList",
	noFetchFirst: true,
	mixins: [forList],
	components: {
	},

	data() {
		return {
			searchTypeEnum,       // 模糊查询字段选项
			dateTypeEnum,         // 供查询的日期类型选项
			enumIsReady           : false, // 以下几个远程的选项数据是否已经获得
			regionList            : [], // 区域选项     name, id
			OrderProcessTypeEnum  : [],
			OrderProcessChildTypeEnum: [],
			OrderProcessEnum      : [],
			OrderProcessStateEnum : [],

			departmentEnum        : [], // 部门小组选项
			OrderStateEnum        : [], // 订单状态选项
			payTypeList           : [], // 支付方式选项 name, type
			moneyTypeList         : [], // 币种选项     name, type
			seasonList            : [], // 赛季选项     name, id
			OrderLaterStateEnum   : [], // 到期状态选项
			OrderAddStateEnum     : [], // 增投类型选项
			payStateEnum,         // 回款状态选项
			fileStateEnum,        // 资料收集状态选项
			expireStateEnum,      // 是否到期选项
			SP_: {
                searchSortType: 0,
                searchSort: 0,
                productName: '',
                searchType: 2,
				searchValue       : "",
				searchTimeType    : 1,
				searchStartTime   : "",
				searchEndTime     : "",

				searchRegin       : [], // 搜索区域
				searchDept        : [], // 搜索部门
				searchMoneyType   : [],
				searchMoneyChildType: [],
				searchState       : [],
                searchCheckState: [],
				searchPayState    : "",
			},
			searchHrefType        : "", // 快捷搜索标识：1待提交佣金申请 2需补录状态订单

			countMap              : {
				fine: false
			}, // 业绩统计
			
			showExportPayments    : false, // 弹出导出缴费的日期选择窗
			showExplain           : false, // 订单状态说明

            showTip: true,//提示
            products: [],//产品名称
            showConfirmLimitDia: false,//确认额度弹窗
            editLimitDia: false, //修改认购额度进行排队

            rengouedu: '',
            contractMoneyData: {

            },
            contractMoneyRules: {

            },
            clickRow: '',
            circulationMoney: '',
            showtable: true,
            emptyText: '暂无数据',
        };
    },





	created () {
		this.auth.save   = this.$hasAuthFor("api/order/saveOrder");
		this.auth.detail = this.$hasAuthFor("api/order/queryOrderDetails");
		this.auth.delete = this.$hasAuthFor("api/orderProcess/deleteOrderProcess");
		this.auth.export = this.$hasAuthFor("api/orderProcess/downOrderProcess");
        this.auth.giveup = this.$hasAuthFor("api/orderProcess/cealrQueue");
        this.auth.confirmLimit = this.$hasAuthFor("api/orderProcess/saveDealStateAndMoney");
        this.auth.batchDelete = this.$hasAuthFor("api/order/eoiListDeleteOrder");
        // this.auth.batchDelete = this.$hasAuthFor("api/orderProcess/downOrderProcess");

		actionList.forEach(action => {
			action.banned = !this.auth[action.name]
		});
        // console.log(this.$route)
		const {params} = this.$route;
		if (Object.keys(params).length) {
			for (let key in params) {
				if (key === "searchHrefType") this.searchHrefType = params[key];
				else if (Object.prototype.hasOwnProperty.call(this.SP_,key))
					this.SP_[key] = params[key]
			}
			this.showAdvanceSearch = true;
			this.handleSearch();
		}
        else this.fetchData();
        this.getPorget()

	},

	methods: {
        sortChange(data) {
            if (data.prop == 'sequence') { //排队序号
                this.SP_.searchSortType = 1
            }
            if (data.prop == 'submitCheckTimeName') { //提交审核时间
                this.SP_.searchSortType = 2
            }

            if (data.prop == 'checkBranchOfficeName') { //分公司审核时间
                this.SP_.searchSortType = 3
            }
            if (data.prop == 'firstTimeName') { //总公司审核通过时间
                this.SP_.searchSortType = 4
            }

            if (data.prop == 'queueTimeName') { //进入排队时间
                this.SP_.searchSortType = 5
            }

            if (data.order == 'descending') {//下
                this.SP_.searchSort = 1
            }
            if (data.order == 'ascending') {//上
                this.SP_.searchSort = 0
            }
            if (!data.order) { //取消
                this.SP_.searchSortType = 0
                this.SP_.searchSort = 0
            }
            this.fetchData();
        },
        // 修改认购额度
        confirmLimitDiaL() {
            this.showConfirmLimitDia = false
            this.editLimitDia = true
        },
        // 继续等待额度
        confirmLimitDiaR() {
            this.showConfirmLimitDia = false
            this.saveDealStateAndMoney(2)
        },
        // 修改认购额度进行排队取消
        editLimitDiaClose() {
            this.editLimitDia = false
        },
        // 修改认购额度进行排队确定
        editLimitDiaSave() {
            this.saveDealStateAndMoney(1)
            this.editLimitDia = false
        },
        async saveDealStateAndMoney(type) {
            let loadingRef;
            try {
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderProcess/saveDealStateAndMoney",
                    method: "post",
                    data: {
                        "id": this.clickRow.id,
                        "type": type,
                        "money": this.circulationMoney,
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    this.editLimitDia = false
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "warning"
                    });
                    this.fetchData();
                }
                loadingRef && loadingRef.close();
            }

        },
        async getPorget() {
            const res = await this.$axios({
                url: "/api/orderProcess/getOrderProcessStateParam",
                method: "post"
            });

            if (res.code === 2000) {
                this.products = res.data.productListAll || []
            } else if (res.code !== 1003)
                throw res
        },
        // 点击复制
        copyCode(code) {
            var input = document.createElement("input"); // 创建input对象
            input.value = code; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.closeAll();
            this.$message.success('复制成功！');
        },
        clearSP_trim () {
            this.SP_.searchType = 2
            this.SP_.searchTimeType = 1
            this.SP_.searchSortType = 0
            this.SP_.searchSort = 0
        },
		
		async fetchData(noloading) {
			let loading;
			if (noloading !== true) loading = this.$loading();

			try {
				this.fetchOrderSummary();
				if (!this.enumIsReady) {
					await this.fetchEnum();
				}
                let _data = {}
                _data = {
                    ...this.extractParams(),
                    searchHrefType: this.searchHrefType,
                    searchProductId: this.SP_.productName || '',
                    searchSortType: this.SP_.searchSortType,
                    searchSort: this.SP_.searchSort,
                }
                if (!this.SP_.searchSortType) {
                    delete _data.searchSortType
                    delete _data.searchSort
                }
				const res = await this.$axios({
					url: "/api/orderProcess/queryOrderProcessList",
					method: "post",
                    data: _data
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.orderProcessList.map(it=>{
                        it.createTimeName = it.createTimeName?.replace(" ", "<br>")
						if (!(it.state == 1 || it.state == 2)) it.sequence = "";
						const _actionList = [...actionList];
						it.actionList = _actionList;
                        // 应只有成交状态为“预定”的单子，才显示“删除”按钮
                        if (it.state !== 0) {
							const deleteActionIndex = it.actionList.findIndex(({name}) => name == "delete");
                            if (deleteActionIndex >= 0) it.actionList.splice(deleteActionIndex, 1)
                        }
                        // 仅“排队中” 和“备序排队”和“已锁定额度”的EOI订单，才显示“放弃排队”按钮，其他均不显示
                        if (it.state != 1 && it.state != 2 && it.state != 3) {
                            const deleteActionIndex2 = it.actionList.findIndex(({ name }) => name == "giveup");
                            if (deleteActionIndex2 >= 0) it.actionList.splice(deleteActionIndex2, 1)
                        }
                        // 数据测试用 用完删
                        // res.data.errFlagList.push({ id: 191, productId: 80 }, { id: 189, productId: 80 })
                        if (res.data.errFlagList.length > 0) {
                            let hasIndex = res.data.errFlagList.findIndex(item => {
                                return item.id == it.id
                            })
                            if (hasIndex >= 0) {
                                it.hasError = true
                            } else {
                                it.hasError = false
                            }
                        }
                        if (!it.hasError) {
                            const deleteActionIndex3 = it.actionList.findIndex(({ name }) => name == "confirmLimit");
                            if (deleteActionIndex3 >= 0) it.actionList.splice(deleteActionIndex3, 1)
                        }
                        // 放弃排队不显示维护按钮
                        if (it.state == 5) {
                            const deleteActionIndex4 = it.actionList.findIndex(({ name }) => name == "save");
                            if (deleteActionIndex4 >= 0) it.actionList.splice(deleteActionIndex4, 1)
                        }
						return it;
					});
                    this.tableData = list;
					this.searchHrefType = "";

					loading?.close();
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading?.close();
			}
		},
		async fetchOrderSummary () {
			const {searchType, searchValue} = this.SP_;
            if (!this.SP_.productName) {
                this.countMap = {
                    fine: false
                }
                return;
            }
			// if (searchType != 1 || !searchValue) return;
			this.countMap.fine = false;
			try {
				const res = await this.$axios({
					url: "/api/orderProcess/queryTotalInfo",
					method: "post",
					data: {
                        // searchProductName: searchValue
                        searchProductId: this.SP_.productName
					}
				});
	
				if (res.code === 2000) {
					if (res.data.map) this.countMap = {
						fine: true,
						...res.data.map
					};
	
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("业绩统计失败", reason)
			}
		},
		async fetchEnum () {
			const res = await this.$axios({
				url: "/api/orderProcess/getOrderProcessParam",
				method: "post"
			});

			if (res.code === 2000) {
				for (let key in res.data) {
					this[key] = res.data[key];
				}
                this.enumIsReady = true;
                // 详情等页面回到列表页时 回显 部门
                if (this.SP_.searchRegin.length == 1) {
                    this.fetchDepartmentEnum(this.SP_.searchRegin[0]);
                }
			} else if (res.code !== 1003)
				throw res
		},

		// 点击高级搜索
		handleGJBtn () {
			if (this.showAdvanceSearch) 
				this.closeAdvanceSearch()
			else this.handleAdvancedSearchBtn()
		},

		handleSearchReginChange (e) {
			this.SP_.searchDept = [];
			this.departmentEnum = [];
			if (e.length == 1) this.fetchDepartmentEnum(e[0]);
		},
		async fetchDepartmentEnum (pid) {
			try {
				const res = await this.$axios({
					url: "/api/employee/queryOrgByPId",
					method: "post",
					data: {pid}
				});

				if (res.code === 2000) {
					this.departmentEnum = res.data.regionList;
				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
			}
		},

		handleSearchMoneyTypeChange (e) {
			this.SP_.searchMoneyChildType = [];
		},

        // 点击导出订单
        async handleExportBtn(flag) {
			if (!this.SP_.searchStartTime && !this.SP_.searchEndTime)
				return this.$message({
					message: "请选择时间范围",
					type: "warning"
				});

			let loadingRef,
				url = flag === 1 ? "/api/order/downOrderPay" :
					"/api/orderProcess/downOrderProcess";
			try {
                await this.$confirm("确定导出吗？", "系统提示")
				loadingRef = this.$loading();

                const data = this.extractParams(true);
                data.searchProductId = this.SP_.productName
                if (!data.searchSortType) {
                    delete data.searchSortType
                    delete data.searchSort
                }


                const res = await this.$axios({
                    method: "post", url, data })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },

		// 点击导出缴费
		handleExportPaymentsBtn () {
			// this.showExportPayments = true
			this.handleExportBtn(1)
		},

		// 点击: 订单状态说明
		handleExplainBtn () {
			this.showExplain = true
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push({
				path: "/core/eoi/add",
				query: {sys_id: this.SP_.search_sys}
			});
		},

		// 点击 表格行的操作按钮
        handleAction (action, scope) {
            this.clickRow = scope.row
            const type = action.name;
            const data = scope.row;
			switch (type) {
				case "save":
					this.$router.push({
						path: `/core/eoi/edit?id=${data.orderId || 5}`,
						query: {sys_id: data.sys_id}
					});
                    break;
				case "detail":
					this.$router.push({
						path: `/core/eoi/detail/${data.orderId || 5}`
					});
					break;
				
				case "delete":
					this.handleBulkDelete([data])
					break;
                case "giveup":
                    // this.handleBulkDelete([data])
                    // const item = this.images[index]
                    this.$confirm(`您确定要放弃排队吗？`, "提示")
                        .then(() => {
                            this.giveupFn(data)
                        })

                        .catch(reason => {
                            console.log("取消", reason)
                        })
                    break;
                case "confirmLimit":
                    this.getCirculationMoney(data)
                    // this.handleBulkDelete([data])
                    break;
				
				default:
					console.log("有没有操作处理程序");
					break;
			}
        },
        // 获取剩排队余额度
        async getCirculationMoney(row) {
            let loadingRef;
            try {
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderProcess/queryProductBalanceMoney",
                    method: "post",
                    data: {
                        id: row.productId
                    }
                })
                if (res.code === 2000) {
                    this.circulationMoney = res.data.circulationMoney || ''
                    loadingRef && loadingRef.close();
                    this.showConfirmLimitDia = true
                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                loadingRef && loadingRef.close();
            }
        },
        // 点击: 放弃排队
        async giveupFn(row) {
            let loadingRef;
            try {
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderProcess/cealrQueue", //接口待改
                    method: "post",
                    data: {
                        id: row.id
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },



		// 点击: 删除
		async handleBulkDelete(list) {
            console.log(list);
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
                    url: "/api/orderProcess/deleteOrderProcess",
					method: "post",
					data: {
						orderProcessId: list.map(
							it => it.id).join()
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
                console.log(reason);
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 批量删除
        async handleBulkBatchDelete() {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/order/deleteOrder",
                    method: "post",
                    data: {
                        orderIds: this.checkedlist.map(
                            it => it.orderId).join()
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                console.log(reason);
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        }
	}
};
<template>
<!-- 佣金岗位比例 -->
    <div class="rateAllocationList page list-page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <div class="fb hw_f16 hw_mt5">
                    房产销售部
                </div>
                <el-table :data="tableData_fcxsb" class="hw_mt13">
                    <!-- <el-table-column align="center" label="" prop="seasonProfit" width="100">
                        <template slot-scope="scope">
                            <div>{{scope.row.colunm0}}</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column align="center" label="客户来源" prop="seasonProfit">
                        <el-table-column align="center" label="成交人" prop="seasonProfit" width="100">
                            <template slot-scope="scope">
                                <div>{{scope.row.colunm1}}</div>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column align="center" label="非渠道部/市场部" prop="seasonName">
                        <el-table-column align="center" label="销售员工" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm2" type="integer" maxlength="10" @input="handleInput('colunm2',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售主管" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm3" type="integer" maxlength="10" @input="handleInput('colunm3',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售经理" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm4" type="integer" maxlength="10" @input="handleInput('colunm4',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售总监/区域总监（二选一，销售总监优先）" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm5" type="integer" maxlength="10" @input="handleInput('colunm5',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column align="center" label="渠道部/市场部" prop="seasonName">
                        <el-table-column align="center" label="销售员工" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm6" type="integer" maxlength="10" @input="handleInput('colunm6',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售主管" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm7" type="integer" maxlength="10" @input="handleInput('colunm7',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售经理" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm8" type="integer" maxlength="10" @input="handleInput('colunm8',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售总监/区域总监（二选一，销售总监优先）" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm9" type="integer" maxlength="10" @input="handleInput('colunm9',scope.$index,'fcxsb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <div class="fb hw_f16 hw_mt32">
                    移民留学部
                </div>
                <el-table :data="tableData_ymlxb" class="hw_mt13">
                    <!-- <el-table-column align="center" label="" prop="seasonProfit" width="100">
                        <template slot-scope="scope">
                            <div>{{scope.row.colunm0}}</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column align="center" label="客户来源" prop="seasonProfit">
                        <el-table-column align="center" label="成交人" prop="seasonProfit" width="100">
                            <template slot-scope="scope">
                                <div>{{scope.row.colunm1}}</div>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column align="center" label="非渠道资源" prop="seasonName">
                        <el-table-column align="center" label="销售员工" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm2" type="integer" maxlength="10" @input="handleInput('colunm2',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售主管" prop="seasonName" min-width="140">
                            <!-- <template slot="header">
                                <div>销售主管/经理</div>
                                <div>（二选一，优先经理）</div>
                            </template> -->
                            <template slot="header">
                                <div>销售主管</div>
                            </template>
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm3" type="integer" maxlength="10" @input="handleInput('colunm3',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售经理" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm4" type="integer" maxlength="10" @input="handleInput('colunm4',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售总监/区域总监（二选一，销售总监优先）" prop="seasonName" min-width="140">
                            <!-- <template slot="header">
                                <div>总监级</div>
                                <div>（根据人数平分）</div>
                            </template> -->
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm5" type="integer" maxlength="10" @input="handleInput('colunm5',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column align="center" label="渠道部资源" prop="seasonName">
                        <el-table-column align="center" label="销售员工" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm6" type="integer" maxlength="10" @input="handleInput('colunm6',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售主管" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm7" type="integer" maxlength="10" @input="handleInput('colunm7',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售经理" prop="seasonName" min-width="140">
                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm8" type="integer" maxlength="10" @input="handleInput('colunm8',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="销售总监/区域总监（二选一，销售总监优先）" prop="seasonName" min-width="140">
                            <!-- <template slot="header">
                                <div>销售主管/经理</div>
                                <div>（二选一，优先经理）</div>
                            </template> -->

                            <template slot-scope="scope">
                                <el-input v-model.trim="scope.row.colunm9" type="integer" maxlength="10" @input="handleInput('colunm9',scope.$index,'ymlxb')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <div class="fb hw_f16 hw_mt32">
                    渠道拓展部
                </div>
                <el-table border :data="tableData_qdtzb" class="hw_mt13 hw_mb32">
                    <!-- <el-table-column align="center" label="" prop="seasonProfit" width="100">
                        <template slot-scope="scope">
                            <div>{{scope.row.colunm0}}</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column align="center" label="成交人" prop="seasonProfit" width="100">
                        <template slot-scope="scope">
                            <div>{{scope.row.colunm1}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="销售员工" prop="seasonName" min-width="140">
                        <template slot-scope="scope">
                            <el-input v-model.trim="scope.row.colunm2" type="integer" maxlength="10" @input="handleInput('colunm2',scope.$index,'qdtzb')" class="width22">
                                <template slot="append">%</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="销售主管" prop="seasonName" min-width="140">
                        <template slot-scope="scope">
                            <el-input v-model.trim="scope.row.colunm3" type="integer" maxlength="10" @input="handleInput('colunm3',scope.$index,'qdtzb')" class="width22">
                                <template slot="append">%</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="销售经理" prop="seasonName" min-width="140">
                        <template slot-scope="scope">
                            <el-input v-model.trim="scope.row.colunm4" type="integer" maxlength="10" @input="handleInput('colunm4',scope.$index,'qdtzb')" class="width22">
                                <template slot="append">%</template>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="销售总监/区域总监（二选一，销售总监优先）" prop="seasonName" min-width="140">
                        <template slot-scope="scope">
                            <el-input v-model.trim="scope.row.colunm5" type="integer" maxlength="10" @input="handleInput('colunm5',scope.$index,'qdtzb')" class="width22">
                                <template slot="append">%</template>
                            </el-input>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="footbar">
                    <el-button @click="handleNavigationBack">返回</el-button>
                    <el-button @click="handleSave" v-if="auth.save" type="primary">保存</el-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import forList from "@/mixin/forList";

export default {
    name: "RateAllocationList",
    // noFetchFirst: true,
    // mixins: [forList],

    data() {
        return {
            // SP_: {
            //     seasonId: '',
            // },
            // seasonList: [],
            tableData_fcxsb: [
                {
                    colunm0: "房产销售部",
                    colunm1: "销售员工",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
                {
                    colunm0: "房产销售部",
                    colunm1: "销售主管",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
                {
                    colunm0: "房产销售部",
                    colunm1: "销售经理",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
                {
                    colunm0: "房产销售部",
                    colunm1: "销售总监",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
            ],
            tableData_ymlxb: [
                {
                    colunm0: "移民留学部",
                    colunm1: "销售员工",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
                {
                    colunm0: "移民留学部",
                    colunm1: "销售主管",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
                {
                    colunm0: "移民留学部",
                    colunm1: "销售经理",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
                {
                    colunm0: "移民留学部",
                    colunm1: "销售总监",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                    colunm6: 0,
                    colunm7: 0,
                    colunm8: 0,
                    colunm9: 0,
                },
            ],
            tableData_qdtzb: [
                {
                    colunm0: "渠道拓展部",
                    colunm1: "渠道员工",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                },
                {
                    colunm0: "渠道拓展部",
                    colunm1: "渠道主管",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                },
                {
                    colunm0: "渠道拓展部",
                    colunm1: "渠道经理",
                    colunm2: 0,
                    colunm3: 0,
                    colunm4: 0,
                    colunm5: 0,
                },
            ],
            bonusConfigList:'',
            auth:{
                save:false
            }
        };
    },
    created() {
        this.fetchData();
        this.auth.save = this.$hasAuthFor("api/commConfig/saveCommConfig");

        // const { params } = this.$route;
        // if (Object.keys(params).length) {
        //     for (let key in params) {
        //         if (key === "searchHrefType") this.searchHrefType = params[key];
        //         else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
        //             this.SP_[key] = params[key]
        //     }
        //     this.handleSearch();
        // }
        // else this.fetchData();
        // this.fetchEnum()
    },

    methods: {
        // 点击返回
        handleNavigationBack() {
            this.$router.back();
        },
        async handleSave(){
            //input数据处理  =='.'  转为=0  其它都 Number
            this.tableData_fcxsb.forEach(element => {
                for (const key in element) {
                    if(key!='colunm0' && key!='colunm1'){
                        if(element[key]=='.'){
                            element[key] = 0
                        }else{
                            element[key] = Number(element[key])
                        }
                    }
                }
            });
            this.tableData_ymlxb.forEach(element => {
                for (const key in element) {
                    if(key!='colunm0' && key!='colunm1'){
                        if(element[key]=='.'){
                            element[key] = 0
                        }else{
                            element[key] = Number(element[key])
                        }
                    }
                }
            });
            this.tableData_qdtzb.forEach(element => {
                for (const key in element) {
                    if(key!='colunm0'&& key!='colunm1'){
                        if(element[key]=='.'){
                            element[key] = 0
                        }else{
                            element[key] = Number(element[key])
                        }
                    }
                }
            });
            console.log(this.tableData_fcxsb);

            

            this.bonusConfigList.forEach((item,index) => {
                if(item.workdepttype==1 && item.succtype==1  && item.sourcetype==0){
                    item.saleempbili = this.tableData_fcxsb[0].colunm2
                    item.salemanagebili = this.tableData_fcxsb[0].colunm3
                    item.saledirectorbili = this.tableData_fcxsb[0].colunm4
                    item.salechiefbili = this.tableData_fcxsb[0].colunm5
                }
                if(item.workdepttype==1 && item.succtype==1  && item.sourcetype==1){
                    item.saleempbili = this.tableData_fcxsb[0].colunm6
                    item.salemanagebili = this.tableData_fcxsb[0].colunm7
                    item.saledirectorbili = this.tableData_fcxsb[0].colunm8
                    item.salechiefbili = this.tableData_fcxsb[0].colunm9
                }
                if(item.workdepttype==1 && item.succtype==2  && item.sourcetype==0){
                    item.saleempbili = this.tableData_fcxsb[1].colunm2
                    item.salemanagebili = this.tableData_fcxsb[1].colunm3
                    item.saledirectorbili = this.tableData_fcxsb[1].colunm4
                    item.salechiefbili = this.tableData_fcxsb[1].colunm5
                }
                if(item.workdepttype==1 && item.succtype==2  && item.sourcetype==1){
                    item.saleempbili = this.tableData_fcxsb[1].colunm6
                    item.salemanagebili = this.tableData_fcxsb[1].colunm7
                    item.saledirectorbili = this.tableData_fcxsb[1].colunm8
                    item.salechiefbili = this.tableData_fcxsb[1].colunm9
                }
                if(item.workdepttype==1 && item.succtype==3  && item.sourcetype==0){
                    item.saleempbili = this.tableData_fcxsb[2].colunm2
                    item.salemanagebili = this.tableData_fcxsb[2].colunm3
                    item.saledirectorbili = this.tableData_fcxsb[2].colunm4
                    item.salechiefbili = this.tableData_fcxsb[2].colunm5
                }
                if(item.workdepttype==1 && item.succtype==3  && item.sourcetype==1){
                    item.saleempbili = this.tableData_fcxsb[2].colunm6
                    item.salemanagebili = this.tableData_fcxsb[2].colunm7
                    item.saledirectorbili = this.tableData_fcxsb[2].colunm8
                    item.salechiefbili = this.tableData_fcxsb[2].colunm9

                   
                }
                if(item.workdepttype==1 && item.succtype==4  && item.sourcetype==0){
                     item.saleempbili = this.tableData_fcxsb[3].colunm2
                    item.salemanagebili = this.tableData_fcxsb[3].colunm3
                    item.saledirectorbili = this.tableData_fcxsb[3].colunm4
                    item.salechiefbili = this.tableData_fcxsb[3].colunm5
                }
                if(item.workdepttype==1 && item.succtype==4  && item.sourcetype==1){
                    item.saleempbili = this.tableData_fcxsb[3].colunm6
                    item.salemanagebili = this.tableData_fcxsb[3].colunm7
                    item.saledirectorbili = this.tableData_fcxsb[3].colunm8
                    item.salechiefbili = this.tableData_fcxsb[3].colunm9
                }
                // 
                if(item.workdepttype==3 && item.succtype==1  && item.sourcetype==0){
                    item.saleempbili = this.tableData_ymlxb[0].colunm2
                    item.salemanagebili = this.tableData_ymlxb[0].colunm3
                    item.saledirectorbili = this.tableData_ymlxb[0].colunm4
                    item.salechiefbili = this.tableData_ymlxb[0].colunm5

                }
                if(item.workdepttype==3 && item.succtype==1  && item.sourcetype==1){
                    item.saleempbili = this.tableData_ymlxb[0].colunm6
                    item.salemanagebili = this.tableData_ymlxb[0].colunm7
                    item.saledirectorbili = this.tableData_ymlxb[0].colunm8
                    item.salechiefbili = this.tableData_ymlxb[0].colunm9
                }

                if(item.workdepttype==3 && item.succtype==2  && item.sourcetype==0){
                    item.saleempbili = this.tableData_ymlxb[1].colunm2
                    item.salemanagebili = this.tableData_ymlxb[1].colunm3
                    item.saledirectorbili = this.tableData_ymlxb[1].colunm4
                    item.salechiefbili = this.tableData_ymlxb[1].colunm5
                }
                if(item.workdepttype==3 && item.succtype==2  && item.sourcetype==1){
                    item.saleempbili = this.tableData_ymlxb[1].colunm6
                    item.salemanagebili = this.tableData_ymlxb[1].colunm7
                    item.saledirectorbili = this.tableData_ymlxb[1].colunm8
                    item.salechiefbili = this.tableData_ymlxb[1].colunm9

                    
                }
                if(item.workdepttype==3 && item.succtype==3  && item.sourcetype==0){
                    item.saleempbili = this.tableData_ymlxb[2].colunm2
                    item.salemanagebili = this.tableData_ymlxb[2].colunm3
                    item.saledirectorbili = this.tableData_ymlxb[2].colunm4
                    item.salechiefbili = this.tableData_ymlxb[2].colunm5
                }
                if(item.workdepttype==3 && item.succtype==3  && item.sourcetype==1){
                    item.saleempbili = this.tableData_ymlxb[2].colunm6
                    item.salemanagebili = this.tableData_ymlxb[2].colunm7
                    item.saledirectorbili = this.tableData_ymlxb[2].colunm8
                    item.salechiefbili = this.tableData_ymlxb[2].colunm9
                }
                if(item.workdepttype==3 && item.succtype==4  && item.sourcetype==0){
                    item.saleempbili = this.tableData_ymlxb[3].colunm2
                    item.salemanagebili = this.tableData_ymlxb[3].colunm3
                    item.saledirectorbili = this.tableData_ymlxb[3].colunm4
                    item.salechiefbili = this.tableData_ymlxb[3].colunm5
                }
                if(item.workdepttype==3 && item.succtype==4  && item.sourcetype==1){
                    item.saleempbili = this.tableData_ymlxb[3].colunm6
                    item.salemanagebili = this.tableData_ymlxb[3].colunm7
                    item.saledirectorbili = this.tableData_ymlxb[3].colunm8
                    item.salechiefbili = this.tableData_ymlxb[3].colunm9
                }
                // 
                if(item.workdepttype==2 && item.succtype==5  && item.sourcetype==1){
                    item.chanempbili = this.tableData_qdtzb[0].colunm2
                    item.chanmagebili = this.tableData_qdtzb[0].colunm3
                     item.chandirectorbili = this.tableData_qdtzb[0].colunm4
                    item.salechiefbili = this.tableData_qdtzb[0].colunm5
                }
                if(item.workdepttype==2 && item.succtype==6  && item.sourcetype==1){
                    item.chanempbili = this.tableData_qdtzb[1].colunm2
                    item.chanmagebili = this.tableData_qdtzb[1].colunm3
                     item.chandirectorbili = this.tableData_qdtzb[1].colunm4
                    item.salechiefbili = this.tableData_qdtzb[1].colunm5
                }
                if(item.workdepttype==2 && item.succtype==7  && item.sourcetype==1){
                    item.chanempbili = this.tableData_qdtzb[2].colunm2
                    item.chanmagebili = this.tableData_qdtzb[2].colunm3
                     item.chandirectorbili = this.tableData_qdtzb[2].colunm4
                    item.salechiefbili = this.tableData_qdtzb[2].colunm5
                }
                

            });
            const loading = this.$loading();
            console.log(this.bonusConfigList);

            // let arr=[]

            // this.bonusConfigList.forEach(element => {
            //     let obj = {}
            //     obj.saleDirectorBili = element.saledirectorbili||0
            //     obj.chanEmpBili = element.chanempbili||0
            //     obj.sourceType = element.sourcetype||0
            //     obj.succType = element.succtype||0
            //     obj.saleEmpBili = element.saleempbili||0
            //     obj.saleManageBili = element.salemanagebili||0
            //     obj.chanMageBili = element.chanmagebili||0
            //     obj.workDeptType = element.workdepttype||0
            //     obj.saleChiefBili = element.salechiefbili||0
            //     obj.regionChiefBili = element.regionchiefbili||0
            //     obj.id = element.id
            //     arr.push(obj)
            // });
            // console.log(arr);
            try {
                // await this.$refs.form.validate();
                // const {
                //     baseBonus,
                //     xqScale,
                //     xzScale,
                //     ztScale,
                //     guaranteeRatio,
                //     pkRatio,
                //     comBaseBonus,
                //     comXqScale,
                //     comXzScale,
                //     comZtScale,
                //     comGuaranteeRatio,
                //     comPkRatio,
                //     id,
                // } = { ...this.PB };
                const res = await this.$axios({
                    method: "post",
                    url: "/api/commConfig/savBonusConfig",
                    data: {
                       bonusConfigJsonStr:JSON.stringify( this.bonusConfigList)
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    // this.getBonusRules();
                    this.fetchData()
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loading.close();
            }
           

        },
         async handleSave1() {
            const loading = this.$loading();

            try {
                await this.$refs.form.validate();
                const {
                    baseBonus,
                    xqScale,
                    xzScale,
                    ztScale,
                    guaranteeRatio,
                    pkRatio,
                    comBaseBonus,
                    comXqScale,
                    comXzScale,
                    comZtScale,
                    comGuaranteeRatio,
                    comPkRatio,
                    id,
                } = { ...this.PB };
                const res = await this.$axios({
                    method: "post",
                    url: "/api/season/saveSeasonBonusConfig",
                    data: {
                        baseBonus,
                        xqScale,
                        xzScale,
                        ztScale,
                        guaranteeRatio,
                        pkRatio,
                        comBaseBonus,
                        comXqScale,
                        comXzScale,
                        comZtScale,
                        comGuaranteeRatio,
                        comPkRatio,
                        id,
                        seasonId: this.seasonId,
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    this.getBonusRules();
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loading.close();
            }
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 4,
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    };
                }
            }
        },
        objectSpanMethod_qdtzb({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 2,
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    };
                }
            }
        },

        async fetchData() {
            const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/commConfig/queryBonusConfigInfo",
                    method: "post",
                    data: {
                        // pageNum: 1,
                        // pageSize: 10,
                        // ...this.extractParams(),
                    },
                });

                if (res.code === 2000) {
                    //workDeptType  类型  1房产销售部 2渠道部 3移民销售部
                    //succtype 成交人岗位类型 1销售员工 2销售主管 3销售经理 4销售总监 5区域总监 6渠道员工 7渠道经理 
                    //sourcetype  客户来源 1渠道部资源 0非渠道部资源 

                    // 获取tableData_fcxsb第1行数据

                    // this.bonusConfigList = res.data.bonusConfigList
                    
                    this.bonusConfigList = res.data.bonusConfigList

                    let fcxsb_row1_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==1  && item.sourcetype==0 )
                    })
                    Object.assign(this.tableData_fcxsb[0],fcxsb_row1_obj)
                    this.tableData_fcxsb[0].id = fcxsb_row1_obj.id
                    this.tableData_fcxsb[0].colunm2 = fcxsb_row1_obj.saleempbili||0
                    this.tableData_fcxsb[0].colunm3 = fcxsb_row1_obj.salemanagebili||0 
                    this.tableData_fcxsb[0].colunm4 = fcxsb_row1_obj.saledirectorbili||0
                    this.tableData_fcxsb[0].colunm5 = fcxsb_row1_obj.salechiefbili||0
                     let fcxsb_row1_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==1  && item.sourcetype==1 )
                    })
                     this.tableData_fcxsb[0].colunm6 = fcxsb_row1_obj2.saleempbili||0
                    this.tableData_fcxsb[0].colunm7 = fcxsb_row1_obj2.salemanagebili||0
                    this.tableData_fcxsb[0].colunm8 = fcxsb_row1_obj2.saledirectorbili||0
                    this.tableData_fcxsb[0].colunm9 = fcxsb_row1_obj2.salechiefbili||0


                    
                    // 获取tableData_fcxsb第2行数据
                    let fcxsb_row2_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==2  && item.sourcetype==0 )
                    })
                    this.tableData_fcxsb[1].id = fcxsb_row2_obj.id
                    this.tableData_fcxsb[1].colunm2 = fcxsb_row2_obj.saleempbili||0
                    this.tableData_fcxsb[1].colunm3 = fcxsb_row2_obj.salemanagebili||0
                    this.tableData_fcxsb[1].colunm4 = fcxsb_row2_obj.saledirectorbili||0
                    this.tableData_fcxsb[1].colunm5 = fcxsb_row2_obj.salechiefbili||0

                  
                     let fcxsb_row2_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==2  && item.sourcetype==1 )
                    })
                    this.tableData_fcxsb[1].colunm6 = fcxsb_row2_obj2.saleempbili||0
                    this.tableData_fcxsb[1].colunm7 = fcxsb_row2_obj2.salemanagebili||0
                    this.tableData_fcxsb[1].colunm8 = fcxsb_row2_obj2.saledirectorbili||0
                    this.tableData_fcxsb[1].colunm9 = fcxsb_row2_obj2.salechiefbili||0
                    // 获取tableData_fcxsb第3行数据
                    let fcxsb_row3_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==3  && item.sourcetype==0 )
                    })
                    this.tableData_fcxsb[2].id = fcxsb_row3_obj.id
                    this.tableData_fcxsb[2].colunm2 = fcxsb_row3_obj.saleempbili||0
                    this.tableData_fcxsb[2].colunm3 = fcxsb_row3_obj.salemanagebili||0
                    this.tableData_fcxsb[2].colunm4 = fcxsb_row3_obj.saledirectorbili||0
                    this.tableData_fcxsb[2].colunm5 = fcxsb_row3_obj.salechiefbili||0
                     let fcxsb_row3_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==3  && item.sourcetype==1 )
                    })
                    this.tableData_fcxsb[2].colunm6 = fcxsb_row3_obj2.saleempbili||0
                    this.tableData_fcxsb[2].colunm7 = fcxsb_row3_obj2.salemanagebili||0
                    this.tableData_fcxsb[2].colunm8 = fcxsb_row3_obj2.saledirectorbili||0
                    this.tableData_fcxsb[2].colunm9 = fcxsb_row3_obj2.salechiefbili||0
                    // 获取tableData_fcxsb第4行数据
                    let fcxsb_row4_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==4  && item.sourcetype==0 )
                    })
                    this.tableData_fcxsb[3].id = fcxsb_row4_obj.id
                    this.tableData_fcxsb[3].colunm2 = fcxsb_row4_obj.saleempbili||0
                    this.tableData_fcxsb[3].colunm3 = fcxsb_row4_obj.salemanagebili||0
                    this.tableData_fcxsb[3].colunm4 = fcxsb_row4_obj.saledirectorbili||0
                    this.tableData_fcxsb[3].colunm5 = fcxsb_row4_obj.salechiefbili||0
                     let fcxsb_row4_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==1 && item.succtype==4  && item.sourcetype==1 )
                    })
                    this.tableData_fcxsb[3].colunm6 = fcxsb_row4_obj2.saleempbili||0
                    this.tableData_fcxsb[3].colunm7 = fcxsb_row4_obj2.salemanagebili||0
                    this.tableData_fcxsb[3].colunm8 = fcxsb_row4_obj2.saledirectorbili||0
                    this.tableData_fcxsb[3].colunm9 = fcxsb_row4_obj2.salechiefbili||0
                    // 
                    // 获取tableData_ymlxb第1行数据
                    let ymlxb_row1_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==1  && item.sourcetype==0 )
                    })
                    this.tableData_ymlxb[0].id = ymlxb_row1_obj.id
                    this.tableData_ymlxb[0].colunm2 = ymlxb_row1_obj.saleempbili||0
                    this.tableData_ymlxb[0].colunm3 = ymlxb_row1_obj.salemanagebili||0
                    this.tableData_ymlxb[0].colunm4 = ymlxb_row1_obj.saledirectorbili||0
                    this.tableData_ymlxb[0].colunm5 = ymlxb_row1_obj.salechiefbili||0


                     let ymlxb_row1_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==1  && item.sourcetype==1 )
                    })
                    this.tableData_ymlxb[0].colunm6 = ymlxb_row1_obj2.saleempbili||0
                    this.tableData_ymlxb[0].colunm7 = ymlxb_row1_obj2.salemanagebili||0
                    this.tableData_ymlxb[0].colunm8 = ymlxb_row1_obj2.saledirectorbili||0
                    this.tableData_ymlxb[0].colunm9 = ymlxb_row1_obj2.salechiefbili||0
                    
                    // 获取tableData_ymlxb第2行数据
                    let ymlxb_row2_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==2  && item.sourcetype==0 )
                    })
                    this.tableData_ymlxb[1].id = ymlxb_row2_obj.id
                    this.tableData_ymlxb[1].colunm2 = ymlxb_row2_obj.saleempbili||0
                    this.tableData_ymlxb[1].colunm3 = ymlxb_row2_obj.salemanagebili||0
                    this.tableData_ymlxb[1].colunm4 = ymlxb_row2_obj.saledirectorbili||0
                    this.tableData_ymlxb[1].colunm5 = ymlxb_row2_obj.salechiefbili||0
                     let ymlxb_row2_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==2  && item.sourcetype==1 )
                    })
                    this.tableData_ymlxb[1].colunm6 = ymlxb_row2_obj2.saleempbili||0
                    this.tableData_ymlxb[1].colunm7 = ymlxb_row2_obj2.salemanagebili||0
                    this.tableData_ymlxb[1].colunm8 = ymlxb_row2_obj2.saledirectorbili||0
                    this.tableData_ymlxb[1].colunm9 = ymlxb_row2_obj2.salechiefbili||0
                    // 获取tableData_ymlxb第3行数据
                    let ymlxb_row3_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==3  && item.sourcetype==0 )
                    })
                    this.tableData_ymlxb[2].id = ymlxb_row3_obj.id
                    this.tableData_ymlxb[2].colunm2 = ymlxb_row3_obj.saleempbili||0
                    this.tableData_ymlxb[2].colunm3 = ymlxb_row3_obj.salemanagebili||0
                    this.tableData_ymlxb[2].colunm4 = ymlxb_row3_obj.saledirectorbili||0
                    this.tableData_ymlxb[2].colunm5 = ymlxb_row3_obj.salechiefbili||0
                     let ymlxb_row3_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==3  && item.sourcetype==1 )
                    })
                    this.tableData_ymlxb[2].colunm6 = ymlxb_row3_obj2.saleempbili||0
                    this.tableData_ymlxb[2].colunm7 = ymlxb_row3_obj2.salemanagebili||0
                    this.tableData_ymlxb[2].colunm8 = ymlxb_row3_obj2.saledirectorbili||0
                    this.tableData_ymlxb[2].colunm9 = ymlxb_row3_obj2.salechiefbili||0
                    // 获取tableData_ymlxb第4行数据
                    let ymlxb_row4_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==4  && item.sourcetype==0 )
                    })
                    this.tableData_ymlxb[3].id = ymlxb_row4_obj.id
                    this.tableData_ymlxb[3].colunm2 = ymlxb_row4_obj.saleempbili||0
                    this.tableData_ymlxb[3].colunm3 = ymlxb_row4_obj.salemanagebili||0
                    this.tableData_ymlxb[3].colunm4 = ymlxb_row4_obj.saledirectorbili||0
                    this.tableData_ymlxb[3].colunm5 = ymlxb_row4_obj.salechiefbili||0

                     let ymlxb_row4_obj2 = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==3 && item.succtype==4  && item.sourcetype==1 )
                    })
                    this.tableData_ymlxb[3].colunm6 = ymlxb_row4_obj2.saleempbili||0
                    this.tableData_ymlxb[3].colunm7 = ymlxb_row4_obj2.salemanagebili||0
                    this.tableData_ymlxb[3].colunm8 = ymlxb_row4_obj2.saledirectorbili||0
                    this.tableData_ymlxb[3].colunm9 = ymlxb_row4_obj2.salechiefbili||0
                    // 

                    //succtype 成交人岗位类型 1销售员工 2销售主管 3销售经理 4销售总监  5渠道员工 6渠道主管   7渠道经理
                    

                   

                    // 获取tableData_qdtzb第1行数据
                    let qdtzb_row1_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==2 && item.succtype==5  && item.sourcetype==1 )
                    })
                    console.log(qdtzb_row1_obj);
                    this.tableData_qdtzb[0].colunm2 = qdtzb_row1_obj.chanempbili||0
                    this.tableData_qdtzb[0].colunm3 = qdtzb_row1_obj.chanmagebili||0
                    this.tableData_qdtzb[0].colunm4 = qdtzb_row1_obj.chandirectorbili||0
                    this.tableData_qdtzb[0].colunm5 = qdtzb_row1_obj.salechiefbili||0
                    // 获取tableData_qdtzb第2行数据
                    let qdtzb_row2_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==2 && item.succtype==6  && item.sourcetype==1 )
                    })
                    this.tableData_qdtzb[1].colunm2 = qdtzb_row2_obj.chanempbili||0
                    this.tableData_qdtzb[1].colunm3 = qdtzb_row2_obj.chanmagebili||0
                    this.tableData_qdtzb[1].colunm4 = qdtzb_row2_obj.chandirectorbili||0
                    this.tableData_qdtzb[1].colunm5 = qdtzb_row2_obj.salechiefbili||0

                    // 获取tableData_qdtzb第3行数据
                    let qdtzb_row3_obj = res.data.bonusConfigList.find((item,index)=>{
                        return (item.workdepttype==2 && item.succtype==7  && item.sourcetype==1 )
                    })
                    this.tableData_qdtzb[2].colunm2 = qdtzb_row3_obj.chanempbili||0
                    this.tableData_qdtzb[2].colunm3 = qdtzb_row3_obj.chanmagebili||0
                    this.tableData_qdtzb[2].colunm4 = qdtzb_row3_obj.chandirectorbili||0
                    this.tableData_qdtzb[2].colunm5 = qdtzb_row3_obj.salechiefbili||0
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning",
                });

                loading.close();
            }
        },
        handleInput(name,index,str) { //两位小数
            let tablebefore= 'tableData_'
            let tablename= ''
            tablename = tablebefore+str
            this[tablename][index][name] = this[tablename][index][name].replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符   
            this[tablename][index][name] = this[tablename][index][name].replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
            this[tablename][index][name] = this[tablename][index][name].replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            this[tablename][index][name] = this[tablename][index][name].replace(/^(\\\\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数     

            if (this[tablename][index][name].indexOf(".") < 0 && this[tablename][index][name] != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
                this[tablename][index][name] = parseFloat(this[tablename][index][name]);
            }
            if (typeof (this[tablename][index][name]) === 'number') {
                if (this[tablename][index][name] >= 0 && this[tablename][index][name] <= 10000000) {
                    this[tablename][index][name] = Number(this[tablename][index][name])
                } else {
                    this[tablename][index][name] = 0
                }
            }
        },
        async fetchEnum() {
            const res = await this.$axios({
                url: "/api/order/queryOrderParam",
                method: "post",
            });

            if (res.code === 2000) {
                this.seasonList = res.data.seasonList;
            } else if (res.code !== 1003) throw res;
        },

        handleCommissionDetail(item) {
            this.$router.push({
                path: `/commission/seasonBonusList/detail/${item.seasonId}`,
            });
        },
    },
};
</script>

<style lang="scss" scoped>

.rateAllocationList /deep/ .el-input-group__append,
.el-input-group__prepend {
    padding: 0px 10px;
}
.rateAllocationList .width22{
    width: 112px;
}
.rateAllocationList .footbar {
        display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
}
.rateAllocationList /deep/ .el-table thead tr th{
    background: #c7c7c7;
    font-size: 16px;
}

.rateAllocationList /deep/ .el-table thead tr + tr th{
    background: #dddcdc;
    font-size: 14px;
    font-weight: 500;
}
.rateAllocationList .el-table--group::after, .rateAllocationList .el-table--border::after{
    width: 0px;
}
.rateAllocationList .el-table::before {
    height: 0px;
}
.rateAllocationList .el-table--border {
    border-top: none;
}
</style>
<!-- 添加、编辑 -->
<template>
    <el-dialog class="season-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="赛季名称" prop="name">
                <el-input v-model.trim="PB.name"
                    maxlength="50"
                    class="width3"></el-input>
            </el-form-item>

       
            <el-form-item label="赛季开始日期"
                prop="startTime"
                class="wrap">
                <el-date-picker
                    v-model="PB.startTime"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                     class="width3"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="赛季结束日期"
                prop="endTime"
                class="wrap">
                <el-date-picker
                 class="width3"
                    v-model="PB.endTime"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    maxlength="200"
                    type="textarea" rows="5"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">提交</el-button>
        </div>

        <el-dialog 
            append-to-body
            class="season-edit"
            :visible="viewIsReady2"
            title="提示"
            @close="handleClose3('top')"
            width="560px">
            <div>
                该产品已存在全款齐的订单，您确认要修改该产品的起息日期么？
            </div>

            <div slot="footer">
                <el-button @click="handleClose1">取消</el-button>
                <el-button @click="handleClose2" type="primary">确定</el-button>
            </div>

        </el-dialog>


    </el-dialog>

    
</template>

<script>
import {BooleanEnum} from "./config";
export default {
    name: 'SeasonEdit',
    props: ["item"],

    data () {
        return {
            viewIsReady2:false,
			BooleanEnum,  // 固定的，是/否选项
            viewIsReady: false,
            PB: {
                id:'',
                name: "",
                remark: "",
                startTime:'',
                endTime:'',


                // investment: "",
                // rate: "",
                // startTime: "",
                // endTime: "",
                // realEndTime: "",
                // circulation: "",
                // iscom: 0,
            },
            // 验证规则
            rules: {
                name       : { required: true, message: "请输入赛季名称",     trigger: "blur" },
                startTime  : { required: true, message: "请选择赛季开始日期", trigger: "blur" },
                endTime    : { required: true, message: "请选择赛季结束日期", trigger: "blur" },
                none: {validator: function (r,v,c) {
                    c();
                }}
            },
            roleList: [],
            diatype:1, //触发来源  1 弹出选择 2 点的清空
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑' : '新增'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                for (let key in item) {
                    const _key = key.toCamel();
                    this.PB[_key] = item[key] == null ? "" : item[key]
                }
                this.viewIsReady = true;

            }
        }
    },

    methods: {

      
        handleClose1(){
            console.log('handleClose1');
            this.viewIsReady2 = false
        },
        handleClose3(data){
            console.log('handleClose3',data);
            this.viewIsReady2 = false
        },
        handleClose2(){
            console.log('handleClose2');
            this.viewIsReady2 = false
        },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".season-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    method: "post",
                    url: "/api/season/saveOrUpdateSeason",
                    data:this.PB
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            for (let key in this.PB) {
                this.PB[key] = "";
            }
            // this.PB.iscom = 0;
            document.querySelector(".season-edit .el-dialog__body").scrollTo(0,0);
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .hint {
        margin-left: 0 !important;
        color: orangered !important;
    }
</style>
<!-- 修改渠道结佣信息 -->
<template>
    <el-dialog class="update-channel"
        :visible="viewIsReady"
        @close="handleClose"
        title="修改渠道结佣信息"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="渠道是否结佣" prop="isChanComm">
                <el-radio-group v-model="PB.isChanComm">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>

            <!-- <el-form-item label="第三方渠道" prop="channelName" class="wrap">
                <el-input v-model="PB.channelName"
                    class="width3"></el-input>
                <span class="hint" style="color: orangered">如没有渠道请填写“无”</span>
            </el-form-item> -->


            <!-- {{PB.channelName}}
            {{PB.channelCode}} -->


            <el-form-item label="第三方渠道" prop="channelName" class="wrap">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="PB.channelName"
                    :fetch-suggestions="querySearchThree"
                    @change="handleAutocompleteChangeChannel($event, 'channel')"
                    @select="handleSelectChannel($event, 'channel')"
                    class="width3"></el-autocomplete>
                <!-- <span class="hint" style="color: orangered">如没有渠道请填写“无”</span> -->
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'UpdateChannel',
    props: [ "item" ],

    data () {
        // const moneyValidator = function (r, val, c) {
        //     if (typeof val === "number") c()
        //     else if (!/^\d+(\.\d+)?$/.test(val)) {
        //         c("只能输入数字")
        //     }
        //     else c()
        // };
        return {
            viewIsReady: false,
            PB: {
                isChanComm : 0,
                channelName: "",
                channelCode: ""

            },
            rules: {
                // 请输入渠道商列表第三方渠道
                isChanComm : {required: true, message: "请选择是否结佣"}, 
                // channelName: {required: true, message: "请选择第三方渠道"}
                channelName: [
                    {required: true, message: "请选择第三方渠道"},
                    {validator: this.threeValidator, trigger: "blur"}
                ]

            }
        }
    },

    computed: {},

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.PB.isChanComm  = item.isChanComm;
                this.PB.channelName = item.channelName;
                this.PB.channelCode = item.channelCode;
                this.viewIsReady = true;
                console.log(item);
            }
        },
        'PB.channelName': {
            handler: function (val, oldVal) {
                console.log(val);
                if(val==''){ 
                    this.PB.channelCode =''
                }
            },
            deep: true,
        },
    },

    methods: {
         threeValidator  (r, val, c) {
            if(!this.PB.channelCode){
                c("请输入渠道商列表第三方渠道")
            }
            else c()
        },
        async querySearchThree(queryString, cb, type) {
            try {
				const res = await this.$axios({
					url: "/api/channelApi/queryChannel",
					method: "post",
					data: {
                        searchName: queryString,
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.channelList.map(emp => {
                        emp.value = [emp.name, this.hidePhoneNumber(emp.contactPhone)].filter(Boolean).join(" - ")
                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
            
        },
        hidePhoneNumber(phoneNumber) {
            const str = phoneNumber.toString();
            return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        },
        handleAutocompleteChangeChannel(e, key) {
            if (e.trim() !== "") return;
            this.PB.channelName = "";
            this.PB.channelCode = "";
        },
        handleSelectChannel(item, key) {
            console.log(item);
            this.PB.channelCode = item.code;
            this.PB.channelName = item.name;
        },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".update-channel .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/order/updateOrderChanComm",
                    method: "post",
                    data: {
                        ...this.PB,
                        orderId: this.item.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "修改成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "修改失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.isChanComm  = 0;
            this.PB.channelName = "";
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
<!-- 投资资料情况 -->
<template>

    <div style="position: relative;">
        <div class="part-title"><h3>投资全套资料</h3></div>
        <!-- {{orderInitInfo.processState}} -->
        <div class="flex a-center checkall hw_mb16">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <el-button  v-show="auth.checkFiles" class="hw_ml16" @click="handleAll"  size="mini">批量审核</el-button>
        </div>
        <el-form ref="form"
            :model="PB"
            class="order-part4 private"
            label-suffix=": "
            label-width="90px"
        >
        <div class="flex" v-for="(files, index) in PB.orderFiles" :key="index">
            <!-- {{files.checkstate}} -->
            <el-checkbox :disabled="files.id?false:true" class="hw_mr17" v-model="files.checkbox"></el-checkbox>
            <el-form-item 
                :rules="rule"
                :prop="'orderFiles['+index+']'"
                :label="files.label"
                class="item wrap flex1"
            >
            <!-- :show-delete="files.checkstate != 1" -->
                <UploadFile1
                    v-model="files.path"
                    :accept="uploadAccept"
                    :limit="5"
                    :maxSize="files.type==6?2e4:1e4"
                    :show-delete="orderInitInfo.processState ==2?false:true"
                    :isDisable="orderInitInfo.processState ==2?true:false"
                >
                    <span class="hint" slot="hint">限制{{files.type==6?20:10}}M，最多5个附件</span>
                </UploadFile1>

                <!-- <div class="item-action" v-if="filesCommited && auth.checkFiles && !files.checkstate && files.path.length"> -->
                <div class="item-action" v-if="filesCommited && auth.checkFiles && !files.checkstate">

                    <label>审核结果：</label>
                    <el-radio-group
                    
                        v-model="files.checkstateTemp"
                        @change="handleItemAllChange($event, files,'',index)"
                    >
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="2">不通过</el-radio>
                    </el-radio-group>
                </div>

                <div class="result" :class="'result'+files.checkstate"></div>

                <!-- <div v-if="auth.saveFiles && files.id && files.checkstate != 1" class="footer">
                    <el-button @click="save(files)">保存修改</el-button>
                </div> -->
                 <div class="footer" v-if="auth.saveFiles && files.id">
                    <el-button @click="save(files)">保存修改</el-button>
                </div>
            </el-form-item>
        </div>


        </el-form>
        <!-- 批量审核 -->
        <el-dialog class="check-eoi"
            :visible="viewIsReady"
            @close="handleClose"
            title="批量审核"
            width="560px">

            <el-form class="private" 
                :model="PB2"
                :rules="rules2"
                label-width="130px"
                label-suffix=":"
                ref="form2"
                @submit.prevent="handleSave"
            >
                <el-form-item label="审核结果" prop="checkState" style="margin-bottom:0;padding-bottom: 0;">
                    <el-radio-group v-model="PB2.checkState">
                        <el-radio :label="1">通过</el-radio>
                        <el-radio :label="2">不通过</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="审核时间" prop="fileCheckTime" v-if="PB2.checkState==1">
                     <el-date-picker
                        class="width1h"
                         type="date"
                        v-model="PB2.fileCheckTime"
                        placeholder="请选择"
                        value-format="yyyy-MM-dd"
                         :picker-options="pickerOptions"
                    ></el-date-picker>
                </el-form-item>
            </el-form>

            <div slot="footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button @click="handleSave" type="primary">确认审核</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
let startDate = '' //2023-10-15 00:00:00

import UploadFile1 from '../../../components/UploadFile1/UploadFile1.vue';
import {orderFiles} from "./config";
export default {
    name: 'FormPart4',
    components: {UploadFile1},
    props: ["orderMap", "filesCommited",'orderInitInfo'],

    data () {
        return {
            uploadAccept: "image/*,application/pdf,application/x-pdf",
            PB: {
                orderFiles: JSON.parse(JSON.stringify(orderFiles)),
                orderId: 0
            },
            rule: [
                {required: false},
                // {
                //     validator (r,v,c) {
                //         console.log(v)
                //         if (v.path.length) c();
                //         else c("请上传文件")
                //     }
                // }
            ],
            isAllReady: false,
            auth: {
                checkFiles: false,
                saveFiles: false
            },

            viewIsReady: false,
            PB2: {
                checkState: "", // 审核状态 3通过 2不通过
                fileCheckTime: "", // 审核时间

            },
            rules2: {
                checkState : {required: true, message: "请选择审核结果"},
                fileCheckTime : {required: true, message: "请选择审核时间"}
            },
            ids:[],
            checkAll:false,
            isIndeterminate:true,
            nowDate:'',
            pickerOptions: { 
                 disabledDate(time) {
                    return ( (time.getTime()- 8.64e7 < new Date(startDate).getTime()- 8.64e7)  ||    (time.getTime()- 8.64e7 > Date.now() - 24 * 60 * 60 * 1000));
                }
             },


           
        }
    },

    created () {
        this.auth.checkFiles = this.$hasAuthFor("api/orderProcess/checkInvest");
        this.auth.saveFiles = this.$hasAuthFor("api/orderProcess/saveInvestFile");
        
        // this.orderFiles = JSON.parse(JSON.stringify(orderFiles));
    },

    mounted () {
        const id = Number(this.$route.query.id);
        if (id) {
            this.PB.orderId = id;
            this.fetchFileList("first");
        }
    },

    methods: {
        // 全选
        handleCheckAllChange(val){
            console.log(val);
            if(val){
                this.isIndeterminate = false;
                this.PB.orderFiles.map(element => {
                    if(element.id){
                        element.checkbox = true
                    }
                });
            }else{
                this.isIndeterminate = true;
                this.PB.orderFiles.map(element => {
                    element.checkbox = false
                });
            }
        },

        handleClose(){
            this.PB2.checkState = ''
            this.PB2.fileCheckTime = ''
            this.viewIsReady = false
            setTimeout(() => {
                this.$refs.form2.clearValidate();
            }, 20);
        },
        async handleSave(){
            let idsStr = this.ids.join()
            // console.log(await this.$refs.form2.validate());

            try {
                await this.$refs.form2.validate()
            } catch (reason) {
                console.log("第1部分验证失败");
                return 
            }
            this.handleItemAllChange(this.PB2.checkState,'',idsStr)



            // return
            // if(this.$refs.form2.validate()){
            //     this.handleItemAllChange(this.PB2.checkState,'',idsStr)
            // }
        },

        async fetchFileList (noloading) {
             let loadingRef = '';
            if(noloading!=='noloading'){
                 loadingRef = this.$loading();
            }
            try {
                const res = await this.$axios({
                    url: "/api/orderProcess/queryInvestFileList",
                    method: "post",
                    data: { orderId: this.PB.orderId }
                });

                loadingRef && loadingRef.close();

                if (res.code !== 2000 && res.code !== 1003) throw res;


                const { orderInvestFileList } = res.data;
                if (orderInvestFileList.length) this.$emit("filesFetched");
                orderInvestFileList.map(item => {
                    const files = this.PB.orderFiles.find(({type}) => type == item.type);
                    files.id             = item.id;
                    files.checkstate     = item.checkstate;
                    files.checkstateTemp = item.checkstate;
                    if(item.file){
                        files.path = item.file?.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + path,
                            path: path,
                        }))||[]
                    }else{
                        files.path = []
                    }

                });

            } catch (reason) {
                console.warn("获取投资资料失败", reason);
                this.$message({
                    message: reason.msg || "获取投资资料失败",
                    type: "warning"
                });	
            }
        },
        handleAll(){
            // 增加是否备序订单验证，如是备序订单则提示“等待排序的订单不能进行后续操作”。
            if(this.orderInitInfo.processState ==2){
                this.$message({
                    message: `等待排序的订单不能进行后续操作。`,
                    type: "warning",
                });
            }else{
                this.ids = []
                this.PB.orderFiles.forEach(element => {
                    if(element.checkbox){
                        this.ids.push(element.id)
                    }
                });
                if(this.ids.length==0){
                    this.$message({
                        message: `请勾选要审核的文件`,
                        type: "warning",
                    });
                }else{
                    this.viewIsReady = true
                    this.queryTimeAddAndReduce()
                    setTimeout(() => {
                        this.$refs.form2.clearValidate();
                    }, 20);
                }
            }

        },
        getDateStr (date) {
			if (date instanceof Date) {
				let Y = date.getFullYear();
				let M = date.getMonth() + 1;
				let D = date.getDate();
				M = M < 10 ? `0${M}` : M;
				D = D < 10 ? `0${D}` : D;
	
				return `${Y}-${M}-${D}`;
			}
			else return ""
		},
        // 选择的时间只能往前选4个工作日
         async queryTimeAddAndReduce() {
            this.nowDate = this.getDateStr(new Date())
            const res = await this.$axios({
                url: "/api/configuration/queryTimeAddAndReduce",
                method: "post",
                data:{
                    num:-1,
                    sreachTime:this.nowDate,
                }
            });

            if (res.code === 2000) {
                startDate = res.data.time+' 00:00:00'
            } else if (res.code !== 1003)
                throw res
        },
        


        async handleItemAllChange (val, files,ids,index) {
            console.log(files);
            if(this.orderInitInfo.processState ==2){
                this.$message({
                    message: `等待排序的订单不能进行后续操作。`,
                    type: "warning",
                });
                console.log(index);
                if(index>-1){
                    this.PB.orderFiles[index].checkstateTemp = 0
                }
                return
            }
            const loadingRef = this.$loading();
            try {
                // if (val == 1) await this.$confirm('您确认资料无误么？审核通过的资料不能在进行更新，请谨慎操作', '温馨提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // });

                // 获取当前时间时分秒
                const now = new Date();
                let hours = now.getHours();
                let minutes = now.getMinutes();
                let seconds = now.getSeconds();


                let YYYY = now.getFullYear();
                let MM = String(now.getMonth() + 1).padStart(2, "0");
                let DD = String(now.getDate()).padStart(2, "0");


                if (hours >= 0 && hours <= 9) {
                    hours = "0" + hours;
                }
                if (minutes >= 0 && minutes <= 9) {
                    minutes = "0" + minutes;
                }
                if (seconds >= 0 && seconds <= 9) {
                    seconds = "0" + seconds;
                }

                const res = await this.$axios({
                    url: "/api/orderProcess/checkInvest",
                    method: "post",
                    data: {
                        orderId: this.PB.orderId,
                        investIds: files.id||ids,
                        state: val,
                        fileCheckTime:(this.PB2.fileCheckTime?this.PB2.fileCheckTime:(YYYY+'-'+MM+'-'+DD))+' '+hours+':'+minutes+':'+seconds
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    if(ids){
                        this.viewIsReady = false
                    }
                    this.PB.orderFiles.map(element => {
                        element.checkbox = false
                    });
                    // loadingRef && loadingRef.close();

                    // files.checkstate = val;

                    this.$emit("change", loadingRef);
                    // if (this.PB.orderFiles.every(item => {
                    //     return item.checkstate == 1
                    // })) this.$emit("everyfine")

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                files.checkstate = 0;
                files.checkstateTemp = 0;
                if (reason != "cancel") {
                    reason && this.$message({
                        message: reason.msg || "操作失败",
                        type: "error"
                    });
                    console.warn(reason);
                }
                loadingRef && loadingRef.close()
            }
        },

        async validate () {
            try {
                for (let i=0; i < 5; i++) {
                    const ref = "form"+i;
                    await this.$refs[ref][0].validate()
                }
                return Promise.resolve()

            } catch (reason) {
                console.warn("第四部分验证时报错", reason);
                throw reason;
            }
        },

        async save (files) {
            
            if(this.orderInitInfo.processState ==2){
                this.$message({
                    message: `等待排序的订单不能进行后续操作。`,
                    type: "warning",
                });
                return
            }


            files = files ? [files] : this.PB.orderFiles
            if (files.some(file => file.path.some(({percentage,status}) => (percentage < 100 || status =='uploading'))))
                return this.$message({
                    message: "请等待附件上传完毕",
                    type: "error"
                });
            // await this.$refs.form.validate();
            const loadingRef = this.$loading();
            try {
                const data = this.formatData(files);
                const res = await this.$axios({
                    url: "/api/orderProcess/saveInvestFile",
                    method: "post",
                    data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    // loadingRef && loadingRef.close();
                    // this.handleNavigationBack();
                    this.$emit("change", loadingRef);

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        formatData (files) {
            const data = {
                orderId: this.PB.orderId,
                investFileStr : JSON.stringify((files).map(it => {
                    return {
                        type  : it.type,
                        file  : it.path.map(p => p.path).join(),
                        id    : it.id || undefined
                    }
                }))
            };

            return data;
        }
    }
}
</script>

<style lang='scss' scoped>
    .el-form-item {
        padding: 24px;
        background: #F3F3F3;
        border-radius: 1px;
    }

    .item-action {
        position: absolute;
        right: 24px;
        display: flex;
    }

    .result {
        $color: #E02020;
        position: absolute;
        top: -24px;
        right: -24px;
        width: 84px;
        line-height: 24px;
        text-align: center;
        border-radius: 2px 2px 2px 16px;
        background-color: rgba($color: $color, $alpha: 0.1) ;
        color: $color;
        &::before {
            content: "待审核"
        }
        
        &.result1 {
            $color: #6DD400;
            background-color: rgba($color: $color, $alpha: 0.1) ;
            color: $color;
            &::before {
                content: "审核通过"
            }
        }
        
        &.result2 {
            $color: #333333;
            background-color: rgba($color: $color, $alpha: 0.1) ;
            color: $color;
            &::before {
                content: "审核不通过"
            }
        }
    }

    .footer {
        margin-top: 16px;
    }
    /deep/ .el-form-item__label{
        width: 120px !important;
    }
    /deep/ .el-upload--text{
        text-align:left;
    }
    .checkall /deep/ .el-checkbox__label{
        margin-left: 17px;
        padding-left: 0;
    }
    .check-eoi .el-form-item{
        background: #fff;
    }
</style>
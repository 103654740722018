/**
 * 跳转调试, ip用本机ip, token在来源页面生成
   http://192.168.110.136:8030/?custCode=AYM6044571&token=
   eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxOTgiLCJuYmYiOjE2ODEzNzY0NTEsInRpbWUiOjE2ODEzNzY0NTE3MTcsInVzZXJOYW1lIjoiZGFzdW4xIn0._zKovTAKfQnvZr5RUYJtgB_IiTZfNPY5MykcKiPU_lQ
   #/core/eoi/add
 */
import { createNewOrderData, orderFiles, processStateMap } from "./config";
import { fetchData, restoreOrder, restoreInvestor, restoreEmployee, restoreFiles } from "./restore";
import { handleStep1Save, handleStep1EditSave, formatData1 } from "./save1";
import FormPart1 from "./FormPart1.vue";
import FormPart2 from "./FormPart2.vue";
import FormPart3 from "./FormPart3.vue";
import FormPart4 from "./FormPart4.vue";
import FormPart5 from "./FormPart5.vue";
import FormPart6 from "./FormPart6.vue";
import FormPart7 from "./FormPart7.vue";
import FormPart8 from "./FormPart8.vue";
import CheckEOI from "./CheckEOI.vue";
import CheckAmount from "./CheckAmount.vue";
import NotifyPay from "./NotifyPay.vue";
import UploadFile1 from '../../../components/UploadFile1/UploadFile1.vue';

import Tree from "../../../utils/Tree";



export default {
    name: 'OrderAdd',
    components: {
        FormPart1,
        FormPart2,
        FormPart3,
        FormPart4,
        FormPart5,
        FormPart6,
        FormPart7,
        FormPart8,
        CheckEOI,
        CheckAmount,
        NotifyPay,
        UploadFile1
    },

    data() {
        return {
            id: undefined,
            phoneWhite: false, // 是否可以查看和修改电话
            orderTimeWhite: false, // 是否可修改订单签约日期 1为可修改 0不可修改
            discountWhite: false, // 是否可修改订单销售信息和优惠信息1为可修改 0不可修改
            countCustWhite: false,
            products: [],         // 所有产品
            orderStateEnum: [],         // 订单状态
            OrderTypeEnum: [],
            OrderProcessTypeEnum: [],
            OrderProcessChildTypeEnum: [],
            moneyTypeList: [],         // 币种
            orgAllList: [],         // 组织架构
            sourceTypeAll: [],         // 所有来源
            disList: [],         // 优惠类型
            workDeptTypeEnum: [],         // 业务部门
            orderProductList: [],         // 原始投资产品
            moneySourceList: [],         // 资金来源
            payTypeList: [],         // 付款方式
            OrderAddStateEnum: [],         // 增投类型
            addressOptions: {},
            processStateMap,

            orderMap: null,         // 订单信息
            investor: null,         // 投资人信息
            orderEmpInfo: null,     // 订单员工信息
            orderDiscountList: [],         // 优惠列表

            orderFiles: null, // 订单资料

            checkingOrder: null,
            checkingAmount: null,
            notifingOrder: null,
            processNode: 1,                // 当前进度
            filesCommited: false,
            auth: {
                checkBranchEOI: false,
                saveTranslateFile: false,
                checkEOI: false,
                checkAmount: false,
                notyfyPay: false,
                saveFiles: false,
                saveTranslateTime: false,
                updateDealTime: false,
                updateContractMoney: false,
                saveOrder: false,
                saveOrderCustInfo: false,
                saveOrderCustFileInfo: false,
            },
            showTip: true,
            navIndex: 0,
            needPerfect: false,//是否需要待完善
            dealTimeDia: false,

            dealTimeData: {
                orderId: this.$route.query.id,
                dealTime: ""
            },
            dealTimeRules: {
                dealTime: { required: true, message: "请选择日期", trigger: "blur" }
            },

            contractMoneyDia: false,
            contractMoneyData: {
                orderId: this.$route.query.id,
                contractMoney: ""
            },
            contractMoneyRules: {
                contractMoney: { required: true, message: "请输入额度", trigger: "blur" }
            },

            translateTimeDia: false,
            translateTimeData: {
                orderId: this.$route.query.id,
                translateTime: "",
                money: '',
                moneyType: '',
            },
            translateTimeRules: {
                translateTime: { required: true, message: "请选择日期", trigger: "blur" },
                money: { required: true, message: "请输入额度", trigger: "blur" }

            },
            eoiFileType: 1,//1.现金基金 2.续签/转签 3.抵押贷款 4.新签

            dealTimeBabel: '回款截止日期',
            orderInitInfo: '',
            investFile: {
                imagepath1: [],
                imagepath2: [],
                imagepath3: [],
                imagepath4: [],
                imagepath5: [],
                imagepath6: [],
            },
            investFileids: {
                investFile1_id: '',
                investFile2_id: '',
                investFile3_id: '',
                investFile4_id: '',
                investFile5_id: '',
                investFile6_id: '',
            },

            checkEOIType: 1,//1分公司审核 2 总公司审核
            showNotifyPay: false,
        }
    },
    computed: {
        isUpdate() {
            return !!this.id
        },
        phoneDisabled() {
            if (this.investor.isUpdatePhone == 1) {
                return false
            } else {
                return true
            }
        },
        part1Auth() {
            if (this.orderInitInfo.processCheckState == 3 || this.orderInitInfo.processCheckState == 4 || this.orderInitInfo.processCheckState == 5 || this.orderInitInfo.processCheckState == 6 || this.orderInitInfo.processCheckState == 7) {
                if (this.orderTimeWhite) {
                    return 1
                } else {
                    return 0
                }
            } else {
                return 1
            }

        },
        part6Auth() {
            if (this.orderInitInfo.processCheckState == 3 || this.orderInitInfo.processCheckState == 4 || this.orderInitInfo.processCheckState == 5 || this.orderInitInfo.processCheckState == 6 || this.orderInitInfo.processCheckState == 7) {
                if (this.countCustWhite) {
                    return 1
                } else {
                    return 0
                }
            } else {
                return 1
            }
        },
        part2Auth() {
            if (this.orderInitInfo.processCheckState == 1 || this.orderInitInfo.processCheckState == 3 || this.orderInitInfo.processCheckState == 4 || this.orderInitInfo.processCheckState == 5 || this.orderInitInfo.processCheckState == 6 || this.orderInitInfo.processCheckState == 7) {
                if (this.discountWhite) {
                    return 1
                } else {
                    return 0
                }
            } else {
                return 1
            }
        },
        part2isWhiteMan() {
            if (this.discountWhite) {
                return 1
            } else {
                return 0
            }
        },
        part8Auth() {
            if (this.orderInitInfo.processCheckState == 3 || this.orderInitInfo.processCheckState == 4 || this.orderInitInfo.processCheckState == 5 || this.orderInitInfo.processCheckState == 6 || this.orderInitInfo.processCheckState == 7) {
                if (this.discountWhite) {
                    return 1
                } else {
                    return 0
                }
            } else {
                return 1
            }
        },

        currentProduct() {
            return this.products.find(item => item.id == this.orderMap.productId)
        }
    },

    created() {
        this.auth.checkBranchEOI = this.$hasAuthFor("api/orderProcess/checkBranchOffice");
        this.auth.saveTranslateFile = this.$hasAuthFor("api/orderProcess/saveTranslateFile");
        this.auth.checkEOI = this.$hasAuthFor("api/orderProcess/checkOrderFirstEoi");
        this.auth.checkAmount = this.$hasAuthFor("api/orderProcess/saveBuyOrder");
        this.auth.notyfyPay = this.$hasAuthFor("api/orderProcess/saveNoticePayTime");
        this.auth.saveFiles = this.$hasAuthFor("api/orderProcess/saveInvestFile");
        this.auth.saveTranslateTime = this.$hasAuthFor("api/orderProcess/saveTranslateTime");
        this.auth.updateDealTime = this.$hasAuthFor("api/orderProcess/updateDealTime");
        this.auth.updateContractMoney = this.$hasAuthFor("api/order/updateContractMoney");
        this.auth.saveOrder = this.$hasAuthFor("api/order/saveOrder"); //编辑EOI订单
        this.auth.saveOrderCustInfo = this.$hasAuthFor("api/order/saveOrderCustInfo"); //保存投资人信息
        this.auth.saveOrderCustFileInfo = this.$hasAuthFor("api/order/saveOrderCustFileInfo"); //编辑保存EOI附件信息

        const { orderMap, investor, orderEmpInfo } = createNewOrderData();
        this.orderMap = orderMap;
        this.investor = investor;
        this.orderEmpInfo = orderEmpInfo;
        this.orderFiles = JSON.parse(JSON.stringify(orderFiles));

        // this.orderMap     = orderMap;
        // this.investor     = investor;
        // this.orderEmpInfo = orderEmpInfo

        const id = Number(this.$route.query.id); //  || 1487
        if (this.$route.query.fromPage == 'eoiAdd') {
            this.navIndex = 2
            // this.needPerfect = true
        }
        // return
        if (id) {
            this.id = id;
        } else {
            let follow = localStorage.getItem("follow");
            if (follow) follow = JSON.parse(follow);
            if (follow?.custCode && !follow.token) {
                const { custCode } = follow;
                this.orderMap.cust_code = custCode
            }
            else this.orderMap.cust_code = "AYM6179735"; // 调试
        }

        if (id || this.orderMap.cust_code) {
            this.fetchData()
            this.getOrderInitInfo()
        } else { console.log("初始化失败, 没有orderid 和 custcode"); }

    },

    beforeDestroy() {
        let follow = localStorage.getItem("follow");
        if (follow) follow = JSON.parse(follow);
        if (follow && !follow.token) {
            console.log("should remove follow storage");
        }
    },
    methods: {
        fetchData,
        restoreOrder,
        restoreInvestor,
        restoreEmployee,
        restoreFiles,
        handleStep1Save,
        handleStep1EditSave,
        formatData1,
        pageclick() {
            if (this.$refs.part1) {
                this.$refs.part1.processflag = false
            }
        },
        // 编辑订单初始化信息
        async getOrderInitInfo() {
            // const loadingRef = this.$loading();
            const res = await this.$axios({
                url: "/api/order/getOrderInitInfo",
                method: "post",
                data: { orderId: this.$route.query.id }
            })
            if (res.code === 2000) {
                this.orderInitInfo = res.data.orderInitInfo
                // this.orderInitInfo.processCheckState = 6

                // loadingRef && loadingRef.close();
            } else if (res.code !== 1003)
                throw res;
        },
        //延后截止时间 取消
        dealTimeClose() {
            this.dealTimeDia = false
            setTimeout(() => {
                this.$refs.dealTime.clearValidate();
            }, 20);
        },
        closeDealTimeDia() {
            this.dealTimeDia = false
            setTimeout(() => {
                this.$refs.dealTime.clearValidate();
            }, 20);
        },
        //延后截止时间 保存
        async dealTimeSave() {
            // this.dealTimeDia = false
            const loadingRef = this.$loading({
                target: ".deal-time .el-dialog"
            });

            try {
                await this.$refs.dealTime.validate();
                const res = await this.$axios({
                    url: "/api/orderProcess/updateDealTime",
                    method: "post",
                    data: { ...this.dealTimeData }
                })

				if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();
                    this.dealTimeDia = false
                    this.fetchData()
                    this.getOrderInitInfo()

                } else if (res.code !== 1003)
                    throw res;
            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handlecontractMoneyBtn() {
            this.contractMoneyData.contractMoney = this.orderInitInfo.contractMoney
            this.contractMoneyDia = true
            setTimeout(() => {
                this.$refs.contractMoney.clearValidate();
            }, 20);
        },
        //变更投资人额度 取消
        contractMoneyClose() {
            this.contractMoneyDia = false
            setTimeout(() => {
                this.$refs.contractMoney.clearValidate();
            }, 20);
        },
        //变更投资人额度 保存
        async contractMoneySave() {
            // if (this.orderMap.contractMoney < Number(this.contractMoneyData.contractMoney)) {
            //     this.$message({
            //         message: "认购额度不能大于" + this.orderMap.contractMoney,
            //         type: "warning"
            //     })
            //     return
            // }
            const loadingRef = this.$loading({
                target: ".contract-money .el-dialog"
            });

            try {
                await this.$refs.contractMoney.validate();
                const res = await this.$axios({
                    url: "/api/order/updateContractMoney",
                    method: "post",
                    data: { ...this.contractMoneyData }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();
                    this.contractMoneyDia = false
                    this.fetchData()
                    this.getOrderInitInfo()

                } else if (res.code !== 1003)
                    throw res;
            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        // 翻译完成
        handleTranslateTimeBtn() {
            this.translateTimeData.translateTime = ''
            this.translateTimeData.money = this.orderMap.contractMoney
            this.translateTimeData.moneyType = this.orderMap.moneyType
            this.translateTimeDia = true
            setTimeout(() => {
                this.$refs.translateTime.clearValidate();
            }, 20);
        },
        //翻译完成 取消
        translateTimeClose() {
            this.translateTimeDia = false
            setTimeout(() => {
                this.$refs.translateTime.clearValidate();
            }, 20);
        },
        //翻译完成 保存
        async translateTimeSave() {
            if (this.orderMap.contractMoney < Number(this.translateTimeData.money)) {
                this.$message({
                    message: "认购额度不能大于" + this.orderMap.contractMoney,
                    type: "warning"
                })
                return
            }
            const loadingRef = this.$loading({
                target: ".translate-time .el-dialog"
            });
            try {
                await this.$refs.translateTime.validate();
                const res = await this.$axios({
                    url: "/api/orderProcess/saveTranslateTime",
                    method: "post",
                    data: { ...this.translateTimeData }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();
                    this.translateTimeDia = false
                    this.fetchData()
                    this.getOrderInitInfo()
                } else if (res.code !== 1003)
                    throw res;
            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        // 预定导航切换
        changeNav(index) {

            if (this.navIndex != index) {
                // 获取EOI附件
                // this.getOrderCustFileInfo()
                // this.fetchData()
            }
            this.navIndex = index
        },
        //获取EOI附件 
        async getOrderCustFileInfo() {
            const loadingRef = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/order/getOrderCustFileInfo",
                    method: "post",
                    data: { orderId: this.$route.query.id }
                })

                if (res.code === 2000) {
                    //EOI资金类型 1.现金基金 2.续签/转签 3.抵押贷款 4.新签
                    this.eoiFileType = res.data.orderCustFileInfo.type
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003)
                    throw res;
            } catch (reason) {

                loadingRef && loadingRef.close()
            }
        },

        handleProcessNode(node) {
            if (this.orderInitInfo.processCheckState == 0 || this.orderInitInfo.processCheckState == 1 || this.orderInitInfo.processCheckState == 2 || this.orderInitInfo.processCheckState == 8 || this.orderInitInfo.processCheckState == 9) {
                if (node == 2 || node == 3 || node == 4) {
                    this.$message({
                        message: "当前签约单还未进入此阶段暂不能进行操作",
                        type: "error"
                    })
                } else {
                    this.processNode = node
                }
            }
            if (this.orderInitInfo.processCheckState == 3 || this.orderInitInfo.processCheckState == 4) {
                if (node == 3 || node == 4) {
                    this.$message({
                        message: "当前签约单还未进入此阶段暂不能进行操作",
                        type: "error"
                    })
                } else {
                    this.processNode = node
                }
            }
            if (this.orderInitInfo.processCheckState == 5) {
                if (node == 3) {
                    this.$message({
                        message: "当前签约单还未进入此阶段暂不能进行操作",
                        type: "error"
                    })
                } else {
                    this.processNode = node
                }
            }
            if (this.orderInitInfo.processCheckState == 6 || this.orderInitInfo.processCheckState == 7) {
                this.processNode = node
            }




            // if (processStateMap[this.orderInitInfo.processCheckState].node >= node) this.processNode = node;

            // else this.$message({
            //     message: "当前签约单还未进入此阶段暂不能进行操作",
            //     type: "error"
            // })
        },

        handleOrderProcessTypeChange() {
            this.$refs.part6?.clearAttachment()
        },

        handleEveryFilesFine() {
            this.orderMap.processCheckState = 5;
            // this.$set(this.orderMap, "processCheckState", 5)
            // this.processNode = 3;
            // this.$router.reload()

        },

        handleCheckOrderBtn() {
            this.checkingOrder = this.orderMap
            this.checkEOIType = 2
        },
        handleCheckBranchOffice() {
            this.checkingOrder = this.orderMap
            this.checkEOIType = 1

        },

        handleCheckingStateChange(state) {
            this.fetchData()
            this.getOrderInitInfo()
        },

        handleCheckAmountBtn() {
            // if (this.orderMap.processState != 1) { // 0.预定 1.排队中 2.备序排队 3.完成认购
            //     return this.$message({
            //         message: "备序的EOI不能进行额度的确认",
            //         type: "error"
            //     })
            // }
            this.checkingAmount = this.orderMap
        },

        handleCheckingAmountChange(data) {
            // this.orderMap.processCheckState = 6;
            // this.orderMap.contractMoney = data.money;
            // this.orderMap.translateTimeName = data.translateTime;
            // this.processNode = 3;

            this.fetchData()
            this.getOrderInitInfo()
        },

        handleNotifyOrderBtn() {
            this.notifingOrder = this.orderMap
            this.showNotifyPay = true
        },
        handleNotifiedAmountUpdate() {
            this.showNotifyPay = false
        },
        // 延长截止日期
        handleDealTimeBtn() {
            // if(this)
            if (this.orderMap.processCheckState == 3) {
                this.dealTimeBabel = '全套资料截止日期'
                this.dealTimeData.dealTime = this.orderInitInfo.fileDealTimeName
            }
            if (this.orderMap.processCheckState == 5) {
                this.dealTimeBabel = '翻译完成截止日期'
                this.dealTimeData.dealTime = this.orderInitInfo.translateDealTimeName
            }
            if (this.orderMap.processCheckState == 7) {
                this.dealTimeBabel = '回款截止日期'
                this.dealTimeData.dealTime = this.orderInitInfo.payDealTimeName
            }
            this.dealTimeDia = true
            // setTimeout(() => {
            //     this.$refs.dealTime.clearValidate();
            // }, 20);

        },


        handleNotifiedAmountChange() {
            this.fetchData();
            this.getOrderInitInfo()
            this.$refs.part7?.fetchData()
            // this.showNotifyPay = false
        },

        handleHuikuanChange() {
            this.fetchData();
            this.getOrderInitInfo()
            this.$refs.part7?.fetchData()
        },

        async handleSubmitButton() {
            switch (this.processNode) {
                case 1:
                    if (this.isUpdate) {
                        this.handleStep1EditSave();
                    } else {
                        this.handleStep1Save();
                    }
                    break;

                case 2:
                    this.$refs.part4.save();
                    break;
                case 4:
                    this.$refs.part8.save();
                    break;

                default:
                    break;
            }
        },

        // 点击返回
        handleNavigationBack() {
            if (this.$route.query.fromPage == 'eoiAdd') {
                this.$router.push({
                    path: "/core/eoi",
                    // query: { sys_id: this.SP_.search_sys }
                });
            } else {
                this.$router.back();
            }
        },

        handleFilesfetched() {
            this.filesCommited = true;
        },
        handlePlatCommited() {
            this.fetchData();
        },

        handleFilesCommited() {
            if (this.processNode == 2) {
                this.fetchData();
            } else {
                this.fetchData('noloading');
            }
            this.getOrderInitInfo()
            this.filesCommited = true;
            if (this.processNode == 2) this.$refs.part4?.fetchFileList('noloading');
        },

        handleTabClick(e) {
            this.navIndex = e.index
            const part_dom = this.$refs[e.name].$el;
            part_dom.scrollIntoView({
                behavior: "smooth",
                // block: "center"
            });
        }
    }
}
<!-- 订单状态说明 -->
<template>
    <el-dialog class="explain-states"
        :visible="show"
        @close="handleClose"
        title="温馨提示"
        width="560px">

        <ul>
            <li v-for="(item, key) in explain"
                :key="key"
            >
                <span class="tag">{{ key  }}</span>
                <span class="val">{{ item }}</span>
            </li>
        </ul>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ExplainStates',
    props: ["show"],

    data () {
        return {
            explain: {
                新签: "是指客户将新的资金认购公司的投资产品，此部分资金是没有购买过公司的投资产品， 没有进入过公司基金账户的新资金",
                续签: "指客户已经在公司认购的投资产品的额度到期后，继续投放到公司的其他产品，此种情况为续签",
                增投: "是客户已在公司购买投资产品基础上将产生的利息投入到新产品中的额度（包含同时 追加一起投入的新资金），或是在已投资的产品到期准备续投到另一个新产品时追加新资金一起投入到新产品的额度",
                接收转让: "是指接收其他客户未到期的产品",
                转出: "是指投资产品未到期，转给其他的客户",
                转签: "指客户认购的投资产品提前到期，投放到公司的其他产品，此种情况为转签"
            }
        }
    },

    methods: {
        handleClose () {
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped>
    li {
        display: flex;
        align-items: flex-start;
        line-height: 1.5;

        + li {
            margin-top: .5em;
        }

        .tag {
            white-space: nowrap;
            color: orangered;

            &::after{
                content: ":";
                margin-right: 6px;
            }
        }
    }
</style>
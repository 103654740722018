import Tree from "./Tree";

// 获取页面数据
export async function fetchData1() {
    const loading = this.$loading();
    try {
        const custCode = this.id ? undefined : this.orderMap.cust_code;
        const res = await this.$axios({
            url: "/api/order/getOrderInfo",
            method: "post",
            data: {
                orderId: this.id,
                custCode
            }
        });
        // if (res.data.sourceTypeAll.length > 2) {
        //     [res.data.sourceTypeAll[0], res.data.sourceTypeAll[1]] = [res.data.sourceTypeAll[1], res.data.sourceTypeAll[0]];
        // }
        if (res.code === 2000) {
            // let _obj = res.data.sourceTypeAll.splice(13, 1);
            // res.data.sourceTypeAll.splice(2, 0, _obj[0])

            const {
                phoneWhite,
                orderTimeWhite,
                discountWhite,
                countCustWhite,
                products,         // 所有产品
                OrderStateEnum,   // 订单状态
                OrderTypeEnum,
                OrderProcessTypeEnum,
                OrderProcessChildTypeEnum,
                moneyTypeList,    // 币种
                orgAllList,       // 组织架构
                sourceTypeAll,    // 所有来源
                disList,          // 优惠类型
                WorkDeptTypeEnum, // 业务部门

                orderMap,         // 订单信息
                custInfoMap,      // 投资人信息
                orderEmpInfo,     // 订单员工信息
                orderFiles,       // 订单资料
                orderProductList, // 原始投资产品
                moneySourceList,  // 资金来源
                payTypeList,      // 付款方式
                OrderAddStateEnum,// 增投类型
            } = res.data;
            orderMap.outProductId = orderMap.productIds ? orderMap.productIds.split() : []
            this.phoneWhite        = !!phoneWhite;
            this.orderTimeWhite    = !!orderTimeWhite;
            this.discountWhite     = !!discountWhite;
            this.countCustWhite    = !!countCustWhite;
            this.orderStateEnum    = OrderStateEnum;
            this.OrderTypeEnum     = OrderTypeEnum;
            this.OrderProcessTypeEnum      = OrderProcessTypeEnum;
            this.OrderProcessChildTypeEnum = OrderProcessChildTypeEnum;
            this.moneyTypeList     = moneyTypeList;
            this.disList           = disList;
            this.workDeptTypeEnum  = WorkDeptTypeEnum;
            this.products          = products;
            this.orderProductList  = orderProductList;
            this.moneySourceList   = moneySourceList;
            this.payTypeList       = payTypeList;
            this.OrderAddStateEnum = OrderAddStateEnum;
            this.orgAllList        = orgAllList;
            //
            // console.log(this.userInfo);
            const
                //  { empInfoList } = this.userInfo,
                // accoutSystem = empInfoList.find(it => it.system == this.system.system),
                accoutLocateId = parseInt(this.userInfo?.locateId),
                accoutManageId = this.userInfo?.locatManage ? this.userInfo.locatManage.split(",").map(id =>
                    parseInt(id)) : [];
            const list = res.data.orgAllList.map(it => {
                let { preids } = it;
                preids = preids.split("/").map(Number).filter(Boolean);
                it.disabled = (accoutManageId.length ? !preids.some(id => accoutManageId.some(_id => _id === id)) : true) //|| (accoutSystem.postLevel > 10 && it.level <= 3);
                it.pickable = (!it.disabled || (accoutLocateId ? preids.some(id => id === accoutLocateId) : false))// && (accoutSystem.postLevel <= 10 || it.level > 3);
                return it;
            });
            this.orgAllList = list; // 保留原始数据
            // console.log('111', list);

            // this.orgAllList = [new Tree({
            //     list,
            //     parentKey: "pid"
            // })]
            // console.log('22', this.orgAllList);

            this.defaultExpandedKeys = [22]


            // this.defaultExpandedKeys = res.data.orgAllList.filter(
            //     ({ level, code }) =>
            //         level == 3 ||                                // 默认展开第三级
            //         code == this.system.locate ||                // 选中的所属需要展开
            //         this.system.manage.some(m => m.code == code) // 选中的负责也一样
            // ).map(({ code }) => code);
            // this.pickerTree = this.checkerTree =
            // JSON.parse(JSON.stringify(this.treeIns.children));
            // 


            this.orderFiles = orderFiles;
            this.sourceTypeAll = sourceTypeAll.map(it => {
                it.pickable = true
                it.parent = it.preid;
                return it;
            });
            // 回显数据
            this.restoreOrder(orderMap);
            // this.restoreInvestor(custInfoMap);
            this.restoreEmployee(orderEmpInfo);
            if (orderFiles) this.restoreFiles(orderFiles);

            // 新增订单时，初始化一些参数
            if (!this.isUpdate) {
                this.orderMap.moneyType = moneyTypeList.find(
                    it => it.name.includes("澳"))?.id || ""
            }
            // loading.close();

        } else if (res.code !== 1003)
            throw res


        const res2 = await this.$axios({
            url: "/api/order/getOrderCustInfo",
            method: "post",
            data: {
                orderId: this.id,
                custCode
            }
        });
        if (res2.code === 2000) {
            let {
                countryList,
                provinceList,
                cityList,
                areaList,

            } = res2.data
            this.addressOptions = {
                countryList,
                provinceList,
                cityList,
                areaList,
            };

            this.restoreInvestor(res2.data.custInfoMap);
        }
        // console.log('res2');
        const res3 = await this.$axios({
            url: "/api/order/getOrderCustFileInfo",
            method: "post",
            data: {
                orderId: this.id,
                custCode
            }
        });
        if (res3.code === 2000) {
            loading.close();
            this.restoreInvestor(res3.data.orderCustFileInfo);
        }
    } catch (reason) {
        console.warn("获取数据失败", reason);
        this.$message({
            message: reason.msg || "获取数据失败",
            type: "warning"
        });

        loading.close();
    }
}
// 获取页面数据
export async function fetchData(flag) {
    let loading = '';
    if (flag != 'noloading') {
        loading = this.$loading();
    }
    try {
        const custCode = this.id ? undefined : this.orderMap.cust_code;
        const res = await this.$axios({
            url: "/api/order/getOrderInfo",
            method: "post",
            data: {
                orderId: this.id,
                custCode
            }
        });
        // if (res.data.sourceTypeAll.length > 2) {
        //     [res.data.sourceTypeAll[0], res.data.sourceTypeAll[1]] = [res.data.sourceTypeAll[1], res.data.sourceTypeAll[0]];
        // }
        if (res.code === 2000) {
            // let _obj = res.data.sourceTypeAll.splice(13, 1);
            // res.data.sourceTypeAll.splice(2, 0, _obj[0])
            const {
                phoneWhite,
                orderTimeWhite,
                discountWhite,
                countCustWhite,
                products,         // 所有产品
                OrderStateEnum,   // 订单状态
                OrderTypeEnum,
                OrderProcessTypeEnum,
                OrderProcessChildTypeEnum,
                moneyTypeList,    // 币种
                orgAllList,       // 组织架构
                sourceTypeAll,    // 所有来源
                disList,          // 优惠类型
                WorkDeptTypeEnum, // 业务部门

                orderMap,         // 订单信息
                custInfoMap,      // 投资人信息
                orderEmpInfo,     // 订单员工信息
                orderFiles,       // 订单资料
                orderProductList, // 原始投资产品
                moneySourceList,  // 资金来源
                payTypeList,      // 付款方式
                OrderAddStateEnum,// 增投类型
            } = res.data;
            orderMap.outProductId = orderMap.productIds ? orderMap.productIds.split() : []
            this.phoneWhite = !!phoneWhite;
            this.orderTimeWhite = !!orderTimeWhite;
            this.discountWhite = !!discountWhite;
            this.countCustWhite = !!countCustWhite;
            this.orderStateEnum = OrderStateEnum;
            this.OrderTypeEnum = OrderTypeEnum;
            this.OrderProcessTypeEnum = OrderProcessTypeEnum;
            this.OrderProcessChildTypeEnum = OrderProcessChildTypeEnum;
            this.moneyTypeList = moneyTypeList;
            this.disList = disList;
            this.workDeptTypeEnum = WorkDeptTypeEnum;
            this.products = products;
            this.orderProductList = orderProductList;
            this.moneySourceList = moneySourceList;
            this.payTypeList = payTypeList;
            this.OrderAddStateEnum = OrderAddStateEnum;
            this.orgAllList = orgAllList;
            //
            // console.log(this.userInfo);
            const
                //  { empInfoList } = this.userInfo,
                // accoutSystem = empInfoList.find(it => it.system == this.system.system),
                accoutLocateId = parseInt(this.userInfo?.locateId),
                accoutManageId = this.userInfo?.locatManage ? this.userInfo.locatManage.split(",").map(id =>
                    parseInt(id)) : [];
            const list = res.data.orgAllList.map(it => {
                let { preids } = it;
                preids = preids.split("/").map(Number).filter(Boolean);
                it.disabled = (accoutManageId.length ? !preids.some(id => accoutManageId.some(_id => _id === id)) : true) //|| (accoutSystem.postLevel > 10 && it.level <= 3);
                it.pickable = (!it.disabled || (accoutLocateId ? preids.some(id => id === accoutLocateId) : false))// && (accoutSystem.postLevel <= 10 || it.level > 3);
                return it;
            });
            this.orgAllList = list; // 保留原始数据
            // console.log('111', list);

            // this.orgAllList = [new Tree({
            //     list,
            //     parentKey: "pid"
            // })]
            // console.log('22', this.orgAllList);

            this.defaultExpandedKeys = [22]


            // this.defaultExpandedKeys = res.data.orgAllList.filter(
            //     ({ level, code }) =>
            //         level == 3 ||                                // 默认展开第三级
            //         code == this.system.locate ||                // 选中的所属需要展开
            //         this.system.manage.some(m => m.code == code) // 选中的负责也一样
            // ).map(({ code }) => code);
            // this.pickerTree = this.checkerTree =
            // JSON.parse(JSON.stringify(this.treeIns.children));
            // 


            this.orderFiles = orderFiles;
            this.sourceTypeAll = sourceTypeAll.map(it => {
                it.pickable = true
                it.parent = it.preid;
                return it;
            });

            // 回显数据
            this.restoreOrder(orderMap);
            // this.restoreInvestor(custInfoMap);
            this.restoreEmployee(orderEmpInfo);
            if (orderFiles) this.restoreFiles(orderFiles);

            // 新增订单时，初始化一些参数
            if (!this.isUpdate) {
                this.orderMap.moneyType = moneyTypeList.find(
                    it => it.name.includes("澳"))?.id || ""
            }
            // loading.close();

        } else if (res.code !== 1003)
            throw res


        const res2 = await this.$axios({
            url: "/api/order/getOrderCustInfo",
            method: "post",
            data: {
                orderId: this.id,
                custCode
            }
        });
        if (res2.code === 2000) {
            let {
                countryList,
                provinceList,
                cityList,
                areaList,

            } = res2.data
            this.addressOptions = {
                countryList,
                provinceList,
                cityList,
                areaList,
            };

            this.restoreInvestor(res2.data.custInfoMap);
        }
        // console.log('res2');
        const res3 = await this.$axios({
            url: "/api/order/getOrderCustFileInfo",
            method: "post",
            data: {
                orderId: this.id,
                custCode
            }
        });
        if (res3.code === 2000) {
            if (flag != 'noloading') {
                loading.close();
            }
            this.restoreInvestor(res3.data.orderCustFileInfo);
        }
    } catch (reason) {
        console.warn("获取数据失败", reason);
        this.$message({
            message: reason.msg || "获取数据失败",
            type: "warning"
        });
        if (flag != 'noloading') {
            loading.close();
        }

    }
}


export function restoreOrder (orderMap) {
    for (let key in orderMap) {
        const value = orderMap[key];
        if (value != null) {
            switch (key) {
                case "path":
                    if (value) this.orderMap[key] = 
                        value.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + path,
                            path: path
                        }))
                    else this.orderMap[key] = [];
                    break;

                case "oldProductId":
                    this.orderMap[key] = (value||"").split(",").map(Number).filter(Boolean);
                    break;

                case "processType":
                    this.orderMap.orderProcess.type = value || "";
                    break;

                case "processChildType":
                    this.orderMap.orderProcess.childtype = value || "";
                    break;

                default:
                    this.orderMap[key] = value
                    break;
            }
        }
    }
    // orderMap.processCheckState = 6
    // this.processNode = this.processStateMap[orderMap.processCheckState]?.node || 1;

    switch (orderMap.processCheckState) {
        case 0:
            this.processNode = 1
            break;
        case 1:
            this.processNode = 1
            break;
        case 2:
            this.processNode = 1
            break;
        case 8:
            this.processNode = 1
            break;
        case 9:
            this.processNode = 1
            break;
        case 3:
            this.processNode = 2
            break;
        case 4:
            this.processNode = 2
            break;
        case 5:
            this.processNode = 4
            break;
        case 6:
            this.processNode = 3
            break;
        case 7:
            this.processNode = 3
            break;

        default:
            break;
    }
}

export function restoreInvestor (custInfoMap) {
    // console.log(custInfoMap);
    for (let key in custInfoMap) {
        const value = custInfoMap[key];
        let flag1 = true,
            flag2 = true;
        if (value != null) {
            switch (key) {
                case "imagePath1":
                    if (value) this.investor.imagepath1 = 
                        value.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + path,
                            path: path
                        }))
                    else this.investor.imagepath1 = [];
                    break;

                case "imagePath2":
                    if (value) this.investor.imagepath2 = 
                        value.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + path,
                            path: path
                        }))
                    else this.investor.imagepath2 = [];
                    break;

                case "imagePath3":
                    if (value) this.investor.imagepath3 = 
                        value.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/")+1),
                            url: this.$baseURL + "/" + path,
                            path: path
                        }))
                    else this.investor.imagepath3 = [];
                    break;
                case "buyPath":
                    if (value) this.investor.buyPath =
                        value.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/") + 1),
                            url: this.$baseURL + "/" + path,
                            path: path
                        }))
                    else this.investor.buyPath = [];
                    break;
                case "idCardPath":
                    if (value) this.investor.idCardPath =
                        value.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/") + 1),
                            url: this.$baseURL + "/" + path,
                            path: path
                        }))
                    else this.investor.idCardPath = [];
                    break;
                case "moneyPath":
                    if (value) this.investor.moneyPath =
                        value.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/") + 1),
                            url: this.$baseURL + "/" + path,
                            path: path
                        }))
                    else this.investor.moneyPath = [];
                    break;
                    
                case "bankAccName":
                case "bankAccNumber":
                case "bankName":
                case "bankNo":
                case "swiftCode":
                    if (flag1) {
                        flag1 = false;
                        this.investor.backAccount = {
                            bankname       :  custInfoMap.bankName || "",
                            bankaccname    :  custInfoMap.bankAccName || "",
                            bankaccnumber  :  custInfoMap.bankAccNumber || "",
                            swiftcode      :  custInfoMap.swiftCode || "",
                            bankno         :  custInfoMap.bankNo || "",
                        }
                    }
                    break;

                case "countryId":
                case "provinceId":
                case "cityId":
                case "areaId":
                case "addInfo":
                    if (flag2) {
                        flag2 = false;
                        this.investor.address = {
                            countryid   :  custInfoMap.countryId || "",
                            provinceid  :  custInfoMap.provinceId || "",
                            cityid      :  custInfoMap.cityId || "",
                            areaid      :  custInfoMap.areaId || "",
                            addinfo     :  custInfoMap.addInfo || "",
                        }
                    }
                    break;

                case "birthday":
                    this.investor.birthDayName = value || "";
                    break;
                case "birthDayName":
                    this.investor.birthDayName = value || "";
                    break;
                case "taxNum":
                    this.investor.taxnum = value || "";
                    break;

                case "moneySource":
                    this.investor.moneysource = value || "";
                    break;

                case "taxCountry":
                    this.investor.taxcountry = value || "";
                    break;

                case "isOld":
                    this.investor.isold = value || "";
                    break;

                case "loanCountryType":
                    this.investor.loancountrytype = value || "";
                    break;

                default:
                    this.investor[key] = value
                    break;
            }
        } else {
            // if (key == 'buyPath') {
            //     this.investor.buyPath = []
            // }
            // if (key == 'idCardPath') {
            //     this.investor.idCardPath = []
            // }
            // if (key == 'moneyPath') {
            //     this.investor.moneyPath = []
            // }

            // this.investor[key] = value
        }
    }
    // console.log(this.investor);

    // 判断资料待完善
    if (this.investor.type == 1 || this.investor.type == 2) {
        this.needPerfect = false
        // console.log(this.investor);
        if (this.investor.imagepath1.length == 0 || this.investor.imagepath2.length == 0 || this.investor.buyPath.length == 0 || this.investor.idCardPath.length == 0 || this.investor.moneySourceRemark == '') {
            this.needPerfect = true
        }
    }
    if (this.investor.type == 3) {
        this.needPerfect = false
        // 没选贷款国家
        if (!this.investor.loancountrytype) this.needPerfect = true
        // 国内
        if (this.investor.loancountrytype == 1) {
            if (this.investor.imagepath1.length == 0) {
                this.needPerfect = true
            }
        }
        // 澳洲
        if (this.investor.loancountrytype == 2) {
            if (!this.investor.successTimeName) {
                this.needPerfect = true
            }
            if (this.investor.imagepath1.length == 0) {
                this.needPerfect = true
            }
        }
        if (this.investor.buyPath.length == 0 || this.investor.idCardPath.length == 0 || this.investor.moneyPath.length == 0) {
            this.needPerfect = true
        }
    }
    if (this.investor.type == 4) {
        this.needPerfect = false
        if (this.investor.buyPath.length == 0 || this.investor.idCardPath.length == 0 || this.investor.moneySourceRemark == '') {
            this.needPerfect = true
        }
    }

    if (this.needPerfect) {
        this.navIndex = 2
    }


    // console.log(this.investor);
}

export function restoreEmployee (orderEmpInfo) {
    for (let key in orderEmpInfo) {
        const value = orderEmpInfo[key];
        if (value != null) this.orderEmpInfo[key] = value
    }
}

export function restoreFiles (orderFiles) {
    orderFiles.forEach(it => {
        const index = this.orderFiles.findIndex(
            oit => oit.type == it.type
        );
        if (index >= 0) {
            if (it.path) it.path = it.path.split(",").map(path => ({
                name: path.slice(path.lastIndexOf("/")+1),
                url: this.$baseURL + "/" + path,
                path: path
            }))
            else it.path = [];
            it.label = this.orderFiles[index].label;
            this.$set(this.orderFiles, index, it);
        }
    })
}


import { nanoid } from "nanoid";

export const BooleanEnum = [
    { name: "是", value: 1 },
    { name: "否", value: 0 }
]

// 函数创建一个空的菜单数据
export function initialPB() {
    return {
        // nanoid: nanoid(),
        // name: "",
        // investment: "",
        // rate: "",
        // startTime: "",
        // endTime: "",
        // circulation: "",
        // iscom: 0,
        // remark: ""

        id: '',
        name: '',
        startTime: '',
        endTime: '',
        remark: '',
    };
}
<!-- 确认认购额度 -->
<template>
    <el-dialog class="check-amount"
        :visible="viewIsReady"
        @close="handleClose"
        title="通知翻译资料"
        width="620px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="150px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="EOI产品">
                {{product? product.name:'' }}
            </el-form-item>
            <el-form-item label="投资年限">
                {{ orderPlace?orderPlace.investment:'' }}
            </el-form-item>

            <el-form-item label="锁定认购额度" prop="money">
                <el-input v-model="PB.money" disabled
                type="number"
                    class="width3">
                    <el-select v-model="PB.moneyType" slot="append"
                        disabled
                        class="width1">
                        <el-option
                            v-for="level in moneyTypeList"
                            :key="'b'+level.id"
                            :label="level.name"
                            :value="level.id"
                        ></el-option>
                    </el-select>
                </el-input>
            </el-form-item>
            <el-form-item label="通知翻译资料日期" prop="noticeTranslateTime">
                <el-date-picker
                    class="width3"
                    v-model="PB.noticeTranslateTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>

            <!-- <el-form-item label="资料翻译完成日期" prop="translateTime">
                <el-date-picker
                    class="width3"
                    v-model="PB.translateTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item> -->

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    // orderInitInfo.processState
    name: 'CheckAmount',
    props: [ "order", "product", "moneyTypeList" ,'orderInitInfo'],

    data () {
        return {
            viewIsReady: false,
            PB: {
                money: "",
                moneyType: "",
                // translateTime: "",
                noticeTranslateTime:'',
            },
            rules: {
                money : {required: true, message: "请输入认购金额",trigger:'blur'},
                // translateTime : {required: true, message: "请选择资料翻译完成日期"}
                noticeTranslateTime : {required: true, message: "请选择通知翻译资料日期"}
            }
        }
    },

    watch: {
        viewIsReady(){
            let today = new Date(),
            Y = today.getFullYear(),
            M = today.getMonth() + 1,
            D = today.getDate();
            M = M < 10 ? `0${M}` : M;
            D = D < 10 ? `0${D}` : D;
            this.PB.noticeTranslateTime = `${Y}-${M}-${D}`
        },
        order (order, old) {
            if (order && order !== old) {
                this.PB.money          = order.contractMoney;
                this.PB.moneyType      = order.moneyType;
                this.viewIsReady = true;
            }
            else this.viewIsReady = false

            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
        }
    },


    computed: {
        orderPlace () {
            return this.order || {}
        }
    },

    methods: {
        async handleSave () {
            if(this.orderInitInfo.processState==2 ){
                 this.$message({
                    message: "备序排队中的EOI不能通知翻译资料",
                    type: "warning"
                })
                return 
            }

            if(this.order.contractMoney<Number(this.PB.money) ){
                 this.$message({
                    message: "认购额度不能大于"+this.order.contractMoney,
                    type: "warning"
                })
                return 
            }

            const loadingRef = this.$loading({
                target: ".check-amount .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const savingData = {
                    orderId: this.orderPlace.id,
                    ...this.PB
                };
                const res = await this.$axios({
                    url: "/api/orderProcess/saveBuyOrder",
                    method: "post",
                    data: savingData
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change", savingData);
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.money  = "";
            this.PB.moneyType  = "";
            this.PB.translateTime  = "";
            this.$refs.form.clearValidate();

        },

        handleClose () {
            this.viewIsReady = false
            
            setTimeout(() => {
                this.$emit("update:order", null);
            }, 200);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
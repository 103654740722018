<!-- 修改渠道结佣信息 -->
<template>
    <el-dialog class="update-channel"
        append-to-body
        :visible="show"
        @close="handleClose"
        title="选择原始投资产品订单"
        width="800px">
        <el-table
            ref="table"
            :data="list"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>

            <el-table-column
                prop="orderNo"
                label="订单编号">
            </el-table-column>

            <el-table-column
                prop="buyName"
                label="认购人姓名">
            </el-table-column>

            <el-table-column
                prop="productName"
                label="产品名称">
            </el-table-column>

            <el-table-column
                prop="contractMoney"
                label="认购金额">
            </el-table-column>

            <el-table-column
                prop="finishTime"
                label="到期日期">
            </el-table-column>
        </el-table>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'OldProductPicker',
    props: [ "show", "list", "value" ],

    data () {
        return {
            checkedList: [],
        }
    },

    // computed: {
    //     checkedId () {
    //         const item = this.checkedList[0];
    //         if (item) {
    //             return item?.id || ""
    //         }
    //         else return ""
    //     }
    // },

    watch: {
        async show () {
            if (this.value) {
                let orig;
                if (typeof this.value === "object")
                    orig = this.value.map(oriItemId => {
                        return this.list.find(({id}) => id == oriItemId);
                    }).filter(Boolean);
                else orig = [this.list.find(({id}) => id == this.value)];

                await this.$nextTick();
                this.checkedList = orig;
                for (var item of orig) {
                    this.$refs.table.toggleRowSelection(item, true);
                }
            }
        }
    },

    methods: {
        handleSave () {    
            this.$emit("input", this.checkedList.map(({id}) => id).filter(Boolean));
            this.handleClose();
        },

        async resetData () {
            this.$refs.table.clearSelection();
            this.checkedList = [];
        },

        handleSelectionChange (e) {
            // console.log("select", e);
            // if (e.length === 1) {
                this.checkedList = e
            // }
            // else if (e.length > 1) {
            //     const orig = this.checkedList[0];
            //     orig && this.$refs.table.toggleRowSelection(orig, false);

            //     this.checkedList = [e.find(({id}) => id !== orig.id)]
            // }
            // else this.checkedList = [];
        },

        handleClose () {
            this.resetData();
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped></style>
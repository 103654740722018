class Tree {
    // 接收扁平的节点数组
    constructor (flatArray, nameKey = "name", childKey = "children") {
        this[nameKey] = "全部";
        this.nameKey = nameKey;
        this.childKey = childKey;

        if (flatArray instanceof Array) {
            this.flatArray = [...flatArray]
            this.id = 0;
            this[childKey] = []
            this.init(flatArray)
        }
    }

    // 将扁平的节点数组格式化
    init (flatArray) {
        if (!flatArray.length) return;

        const {childKey} = this;
        const item = flatArray.pop();
        if (!parseInt(item.parent)) this[childKey].unshift(item);
        else {
            const parent = flatArray.find(it => it.id === item.parent) ||
                this.find(item.parent);
            if (parent) {
                if (!(parent[childKey] instanceof Array))
                    parent[childKey] = [];
                parent[childKey].unshift(item)
            }
        }
        this.init(flatArray)
    }

    // 传入节点id, 查找节点
    find (id, parent=this) {
        if (!id) return this;

        const {childKey} = this;
        if (
            !(parent[childKey] instanceof Array) ||
            !parent[childKey].length
        ) return null;

        parent[childKey].forEach(it => {
            if (it.id === id) return it;

            else {
                const found = this.find(id, it);
                if (found) return found
            }
        });
        return null;
    }

    // 根据传入的条件，找出符合条件的节点
    extract(filterFn) {
        return this.flatArray.filter(filterFn)
    }
}

export default Tree;
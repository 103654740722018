<!-- 配置员工负责的系统 -->
<template>
<div>
    <el-form
        :model="orderEmpInfo"
        :rules="form2RulesAfter"
        :disabled="(orderMap.processCheckState==1 ||orderMap.processCheckState==3 || orderMap.processCheckState==4|| orderMap.processCheckState==5|| orderMap.processCheckState==6|| orderMap.processCheckState==7) && !(isWhiteMan)"
        class="order-part2 order-part private"
        label-width="180px"
        label-suffix=": "
        ref="form2"
    >
        <div class="part-title">
            <h3>销售信息
                <!-- {{orderMap.processCheckState}}{{isWhiteMan}} -->
            </h3>
            <i class="tips right">温馨提示：如未搜索到员工，则该员工未开通债券账号，请先开通债券账号</i>
        </div>

        <div class="line">
            <el-form-item label="业务部门" prop="workDeptId">
                 <!--v4.1 新增时  新增的时候系统默认判断业务部门不能修改-->
                <el-select  disabled  v-show="isUpdate" v-model="orderEmpInfo.workDeptId"
                    class="width3 add">
                    <el-option
                        v-for="w in workDeptTypeEnum"
                        :key="w.value"
                        :label="w.name"
                        :value="w.value"
                    ></el-option>
                </el-select>
                <!--v4.1 修改时 只有白名单能改-->
                <el-select :disabled="(part2isWhiteMan?false:true)" v-show="!isUpdate"  v-model="orderEmpInfo.workDeptId"
                    class="width3 edit" style="margin-left:0;">
                    <el-option
                        v-for="w in workDeptTypeEnum"
                        :key="w.value"
                        :label="w.name"
                        :value="w.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="客户来源">
                <!-- 新增时 只有白名单能改 -->
                <el-input 
                    v-show="isUpdate"
                    v-model="orderEmpInfo.sourceIdName"
                    @focus="handleSelectBun"
                    class="width3"
                    placeholder="请选择"
                ></el-input>

                <el-button v-show="isUpdate"  type="text" class="ml2"
                    @click="handleSelectBun"
                >选择</el-button>

                <!-- 修改时 也只有白名单能改 不管审核状态 这个单独控制-->

                <el-input 
                    style="margin-left:0;"
                    :disabled="(part2isWhiteMan?false:true)"
                    v-show="!isUpdate"
                    v-model="orderEmpInfo.sourceIdName"
                    @focus="handleSelectBun"
                    class="width3"
                    placeholder="请选择"
                ></el-input>

                <el-button :disabled ="(part2isWhiteMan?false:true)" v-show="!isUpdate"  type="text" class="ml2"
                    @click="handleSelectBun"
                >选择</el-button>
                <!-- {{orderEmpInfo.sourceId}} -->
                <PickerTree
                    :expandKeys='expandKeys'
                    @input="handlePick"
                    :value="orderEmpInfo.sourceId"
                    :tree="pickerTree"
                    :show.sync="showPicker"
                    title="变更来源"
                    :treeProps ="{ children: 'children', label: 'name'}"
                ></PickerTree>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="资源创建人">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.resourceCreateEmpName"
                    :fetch-suggestions="querySearch"
                    @change="handleAutocompleteChange($event, 'resourceCreateEmp')"
                    @select="handleSelect($event, 'resourceCreateEmp')"
                    class="width3"></el-autocomplete>
            </el-form-item>

            <el-form-item label="创建人经理">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.resourceManageName"
                    :fetch-suggestions="querySearch"
                    @change="handleAutocompleteChange($event, 'resourceManage')"
                    @select="handleSelect($event, 'resourceManage')"
                    class="width3"></el-autocomplete>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="是否结算外部渠道佣金" prop="isChanComm" class="wrap line-feed">
                <el-radio-group v-model="orderEmpInfo.isChanComm">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
                <div class="hint">请询问渠道对接人后填写，将影响后续业绩核算</div>
            </el-form-item>

            <!-- <el-form-item label="第三方渠道">
                <el-input v-model="orderEmpInfo.channelName"
                    class="width3"></el-input>
            </el-form-item> -->

            <!-- {{orderEmpInfo.channelName}}
            {{orderEmpInfo.channelCode}} -->

            <el-form-item label="第三方渠道" prop="channelName">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.channelName"
                    :fetch-suggestions="querySearchThree"
                    @change="handleAutocompleteChangeChannel($event, 'channel')"
                    @select="handleSelectChannel($event, 'channel')"
                    class="width3"></el-autocomplete>
            </el-form-item>

        </div>

        <div class="line">
            <el-form-item label="渠道员工">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.chanEmpName"
                    :fetch-suggestions="querySearch4"
                    @change="handleAutocompleteChange($event, 'chanEmp')"
                    @select="handleSelect($event, 'chanEmp')"
                    class="width3"></el-autocomplete>
            </el-form-item>
            
            <el-form-item label="渠道经理">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.chanManageName"
                    :fetch-suggestions="querySearch4"
                    @change="handleAutocompleteChange($event, 'chanManage')"
                    @select="handleSelect($event, 'chanManage')"
                    class="width3"></el-autocomplete>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="销售顾问" prop="workEmp">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.workEmpName"
                    :fetch-suggestions="querySearch4"
                    @change="handleAutocompleteChange($event, 'workEmp')"
                    @select="handleSelect($event, 'workEmp')"
                    class="width3"></el-autocomplete>
            </el-form-item>
            
            <el-form-item label="销售主管" prop="workCharge">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.workChargeName"
                    :fetch-suggestions="querySearch4"
                    @change="handleAutocompleteChange($event, 'workCharge')"
                    @select="handleSelect($event, 'workCharge')"
                    class="width3"></el-autocomplete>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="销售经理" prop="workManage">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.workManageName"
                    :fetch-suggestions="querySearch4"
                    @change="handleAutocompleteChange($event, 'workManage')"
                    @select="handleSelect($event, 'workManage')"
                    class="width3"></el-autocomplete>
            </el-form-item>
            
            <el-form-item label="区域销售经理">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.regionManageName"
                    :fetch-suggestions="querySearch3"
                    @change="handleAutocompleteChange($event, 'regionManage')"
                    @select="handleSelect($event, 'regionManage')"
                    class="width3"></el-autocomplete>
            </el-form-item>
        </div>
        <div class="line">
            <el-form-item label="公司总监">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.commChiefName"
                    :fetch-suggestions="querySearch4"
                    @change="handleAutocompleteChange($event, 'commChief')"
                    @select="handleSelect($event, 'commChief')"
                    class="width3"></el-autocomplete>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="区域总监" prop="regionChief">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="orderEmpInfo.regionChiefName"
                    :fetch-suggestions="querySearch3"
                    @change="handleAutocompleteChange($event, 'regionChief')"
                    @select="handleSelect($event, 'regionChief')"
                    class="width3"></el-autocomplete>
            </el-form-item>
            <div >
                <el-form-item label="是否员工/亲属内购" prop="isRelative">
                    <el-radio-group v-model="orderEmpInfo.isRelative">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
        </div>
        <div class="line full">
            <el-form-item label="是否推荐客户" >
                <div style="color:#606266;line-height: inherit;">
                    <span>{{orderMap.isFromAusPlat>0?'是':'否'}}</span>
                    <span v-show="orderMap.isFromAusPlat==1">（移民推荐--推荐人：{{orderMap.recommendUserName}}{{orderMap.recommendUserCode?' -- ' +orderMap.recommendUserCode:''}}）</span>
                    <span v-show="orderMap.isFromAusPlat==2">（房产推荐--推荐人：{{orderMap.recommendUserName}}{{orderMap.recommendUserCode?' -- ' +orderMap.recommendUserCode:''}}）</span>
                    <el-button v-show="id"  type="text" class="ml2"
                        @click="handleSelectPlat"
                    >编辑</el-button>
                </div>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="是否合作成交" prop="isCooperation">
                <el-radio-group v-model="orderEmpInfo.isCooperation">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
        </div>

        <template v-if="orderEmpInfo.isCooperation">
            <div class="line">
                <el-form-item label="合作成交区域">
                    <span>{{ orderEmpInfo.reginName }}</span>
                </el-form-item>

                <el-form-item label="合作成交人" prop="cooperationEmp">
                    <el-autocomplete
                        placeholder="请输入查询"
                        v-model="orderEmpInfo.cooperationEmpName"
                        :fetch-suggestions="querySearch1"
                        @change="handleAutocompleteChange($event, 'cooperationEmp')"
                        @select="handleSelect($event, 'cooperationEmp')"
                        class="width3"></el-autocomplete>
                </el-form-item>
            </div>

            <div class="line">
                <el-form-item label="合作成交主管">
                    <el-autocomplete
                        placeholder="请输入查询"
                        v-model="orderEmpInfo.cooperationChargeName"
                        :fetch-suggestions="querySearch1"
                        @change="handleAutocompleteChange($event, 'cooperationCharge')"
                        @select="handleSelect($event, 'cooperationCharge')"
                        class="width3"></el-autocomplete>
                </el-form-item>

                <el-form-item label="合作成交经理">
                    <el-autocomplete
                        placeholder="请输入查询"
                        v-model="orderEmpInfo.cooperationManageName"
                        :fetch-suggestions="querySearch1"
                        @change="handleAutocompleteChange($event, 'cooperationManage')"
                        @select="handleSelect($event, 'cooperationManage')"
                        class="width3"></el-autocomplete>
                </el-form-item>
            </div>
            <div class="line">
                <el-form-item label="合作成交区域经理">
                    <el-autocomplete
                        placeholder="请输入查询"
                        v-model="orderEmpInfo.cooperationRegionManageName"
                        :fetch-suggestions="querySearch1"
                        @change="handleAutocompleteChange($event, 'cooperationRegionManage')"
                        @select="handleSelect($event, 'cooperationRegionManage')"
                        class="width3"></el-autocomplete>
                </el-form-item>
            </div>
        </template>
    </el-form>

     <el-dialog class="plat-dialog"
        :visible="platDia"
        @close="platDia= false"
        title="编辑是否推荐"
        width="560px">
        <el-form class="private"
            ref="platDialog"
            :model="platData"
            :rules="platRules"
            label-width="130px"
            @submit.prevent="platSave"
        >
            <el-form-item label="是否推荐客户:">
                <el-radio-group v-model="platData.radioType">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>

            </el-form-item>
            <el-form-item label="推荐部门:" v-if="platData.radioType==1" prop="platType">
                <el-radio-group v-model="platData.platType">
                    <el-radio :label="2">房产推荐</el-radio>
                    <el-radio :label="1">移民推荐</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="推荐人:" v-if="platData.radioType==1" prop="recommendUserCode" >
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="platData.recommendUserName"
                    :fetch-suggestions="queryPlatSearch"
                    @change="handlePlatChange($event)"
                    @select="handlePlatSelect($event)"
                    class="width3"></el-autocomplete>
              
            </el-form-item>
        </el-form>
        <div slot="footer">
            <el-button @click="platClose">取消</el-button>
            <el-button @click="platSave" type="primary">保存</el-button>
        </div>
    </el-dialog>

</div>



    


</template>

<script>
import Tree from "../../../utils/Tree";
// import PickerTree from "./PickerTree";
import PickerTree from "./PickerTree copy";
import {form2Rules} from "./config";

export default {
    name: 'FormPart2',
    components: {PickerTree},
    props: [
        "id",
        "orderMap",
        "orderEmpInfo",
        "sourceTypeAll",
        "workDeptTypeEnum",
        "isWhiteMan",
        "part2isWhiteMan",
    ],

    data () {
        return {
            form2Rules,
            treeIns: new Tree(),
            // treeIns: null,

            showPicker: false,
            pickerTree: [],
            showcount:0,
            expandKeys:[],
            platDia:false,

            platData:{
                orderId:'',
                radioType:'',
                platType:'',
                recommendUserCode:"",
                recommendUserName:'',
            },
            platRules: {
                platType: {required: true, message: "请选择推荐部门"},
                recommendUserCode: {required: true, message: "请搜索选择推荐人"},
            }

        }
    },

    computed: {
        isUpdate () {
            return !!this.isystemtem?.id
        },
        form2RulesAfter () {
            let obj1 = {
                channelName: [
                    // { required: true, message: "请选择第三方渠道" },
                    { validator: this.threeValidator, trigger: "change" }
                ],
            }
            return   Object.assign(this.form2Rules, obj1)
        },
        
    },

    watch: {
        orderEmpInfo: async function (data) {
            if (data) {
                data.placeValue = "";
            }
        },
        showPicker: {
            handler: function (val, oldVal) {
                if(val){
                    if(this.showcount==1){  //只有dom第一次初始化的时候才渲染一次树  

                        this.treeIns  = new Tree([...this.sourceTypeAll], 'name');
                        // this.pickerTree = this.treeIns.children;


                        const tree = this.listToTree(this.sourceTypeAll);
                        this.pickerTree = tree


                        let obj = this.sourceTypeAll.find(item => item.id === this.orderEmpInfo.sourceId);
                        if(obj){
                            this.expandKeys = [obj.preid]
                        }
                    }
                }
            },
            deep: true,
        },
        'orderEmpInfo.channelName': {
            handler: function (val, oldVal) {
                if(val==''){ 
                    this.orderEmpInfo.channelCode =''
                }
            },
            deep: true,
        },
     
    },

    methods: {
        handleSelectPlat(){
            if(this.orderMap.isFromAusPlat>0){
                this.platData.radioType = 1
                this.platData.platType = this.orderMap.isFromAusPlat
                this.platData.recommendUserName = this.orderMap.recommendUserName
                this.platData.recommendUserCode = this.orderMap.recommendUserCode
            }else{
                this.platData.radioType = 0
                this.platData.platType = ''
                this.platData.recommendUserName = ''
                this.platData.recommendUserCode = ''
            }
            this.platDia = true
        },
        platClose(){
            this.platDia = false
            setTimeout(() => {
                this.$refs.platDialog.clearValidate();
            }, 20);

        },
        async platSave(){
             const loadingRef = this.$loading({
                target: ".plat-dialog .el-dialog"
            });
            try {
                await this.$refs.platDialog.validate();
                let param = {
                    orderId:this.id,
                    isFromAusPlat:'',
                    recommendUserCode:'',
                    recommendUserName:'',
                }
                
                
                if(this.platData.radioType==1){
                   param.isFromAusPlat=this.platData.platType
                   param.recommendUserCode=this.platData.recommendUserCode
                   param.recommendUserName=this.platData.recommendUserName
                }else{
                   param.isFromAusPlat=0
                }

                const res = await this.$axios({
                    url: "/api/order/updateRecommendUser",
                    method: "post",
                    data: param
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();
                    this.platDia = false
                    this.$emit("change", loadingRef);

                    // this.fetchData()
                    // this.getOrderInitInfo()

                } else if (res.code !== 1003)
                    throw res;
            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
            // this.platDia = false
        },

        listToTree(list) {
            const tree = [];
            const map = {};

            for (const item of list) {
                map[item.id] = item;
                item.children = [];
            }

            for (const item of list) {
                if (item.parent) {
                    map[item.parent].children.push(item);
                } else {
                    tree.push(item);
                }
            }

            return tree;
        },
        threeValidator  (r, val, c) {
            if(!this.orderEmpInfo.channelCode && this.orderEmpInfo.channelName){
                c("请输入渠道商列表第三方渠道")
            }
            else c()
        },
        DEEP_CLONE(source) {
            if (!source || typeof source !== 'object') {
                throw new Error('error arguments', 'shallowClone');
            }
            var targetObj = source.constructor === Array ? [] : {};
            for (var keys in source) {
                if (source.hasOwnProperty.call(source, keys)) {
                if (source[keys] && typeof source[keys] === 'object' && !(source[keys] instanceof Date)) {
                    targetObj[keys] = source[keys].constructor === Array ? [] : {};
                    targetObj[keys] = this.DEEP_CLONE(source[keys]);
                } else {
                    targetObj[keys] = source[keys];
                }
                }
            }
            return targetObj;
        },

        handleSelectBun () {
            this.showPicker = true;
            this.showcount++
        },
        handlePick (data) {
            this.orderEmpInfo.sourceId     = data.id;
            this.orderEmpInfo.sourceIdName = data.prenames
        },

        // 联动搜索
        querySearch1(queryString, cb) {
            return this.querySearch(queryString, cb, 1)
        },
        querySearch4(queryString, cb) {
            return this.querySearch(queryString, cb, 4)
        },
        querySearch3(queryString, cb) {
            return this.querySearch(queryString, cb, 3)
        },

        async querySearch(queryString, cb, type) {
            console.log(this.orderMap); 
            let _this = this
            try {
				const res = await this.$axios({
					url: "/api/employee/queryLikeEmpList",
					method: "post",
					data: {
                        searchName: queryString,
                        type,
                        teamId: type==3||type==4?_this.orderMap.deptId:'',
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.empList.map(emp => {
                        if (type == 1) {
                            emp.value = [emp.reginName, emp.name, emp.postName].filter(Boolean).join(" - ")
                        }
                        else {
                            emp.value = [emp.name, emp.postName].filter(Boolean).join(" - ")
                        }
                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
            
        },
        async queryPlatSearch(queryString, cb) {
            try {
				const res = await this.$axios({
					url: "/api/employee/queryAllEmpList",
					method: "post",
					data: {
                        searchName: queryString,
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.empList.map(emp => {
                        emp.value = [emp.name, emp.postName].filter(Boolean).join(" - ")

                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
            
        },
        // 第三方渠道 搜索
        async querySearchThree(queryString, cb, type) {
            try {
				const res = await this.$axios({
					url: "/api/channelApi/queryChannel",
					method: "post",
					data: {
                        searchName: queryString,
                    }
				});
				if (res.code === 2000) {
                    cb(res.data.channelList.map(emp => {
                        emp.value = [emp.name, this.hidePhoneNumber(emp.contactPhone)].filter(Boolean).join(" - ")
                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
        },

        hidePhoneNumber(phoneNumber) {
            const str = phoneNumber.toString();
            return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        },
        
        handleAutocompleteChange(e, key) {
            if (e.trim() !== "") return;
            this.orderEmpInfo[key] = "";
            if (key === "cooperationEmp") {
                this.orderEmpInfo.reginName = "";
            }

        },
        handleAutocompleteChangeChannel(e, key) {
            if (e.trim() !== "") return;
            this.orderEmpInfo.channelCode = "";
            this.orderEmpInfo.channelName = "";
        },
        handlePlatChange(e, key) {
            if (e.trim() !== "") return;
            this.platData.recommendUserCode = "";
            this.platData.recommendUserName = "";
        },
        handlePlatSelect(item, key) {
            this.platData.recommendUserCode = item.userCode;
            this.platData.recommendUserName = item.value;
        },
    
        

        handleSelectChannel(item, key) {
            this.orderEmpInfo.channelCode = item.code;
            this.orderEmpInfo.channelName = item.name;
        },

        handleSelect(item, key) {
            this.orderEmpInfo[key] = item.id;
            if (key === "cooperationEmp") {
                this.orderEmpInfo.reginName = item.reginName;
            }
        },

        async validate () {
            return this.$refs.form2.validate()
        }
    }
}
</script>

<style lang='scss' scoped>
    // .state-radio-group {
    //     flex-wrap: wrap;
    // }
    .el-form.private .width3 {
        width: 300px
    }

    /deep/ {
        .line-feed .el-form-item__label {
            line-height: 18px;
        }
    }
    .full .el-form-item {
        width:100% !important ;
    }
</style>
<!-- 通知打款 -->
<template>
    <el-dialog class="remind-deposit"
        :visible="!!PB.orderId"
        @close="handleClose"
        title="业绩相关日期"
        width="560px">

        <el-form class="private"
            ref="form"
            :model="PB"
            :rules="rules"
            label-width="130px"
            @submit.prevent="handleSave"
        >
            <el-form-item label="认购日期">
                <span>{{ contractTimeName }}</span>
            </el-form-item>

            <el-form-item label="回款10%日期">
                <span>{{ sumPay10 }}</span>
            </el-form-item>

            <el-form-item
                label="资料齐日期"
                prop="allFileTime">

                <el-date-picker
                    class="width3"
                    v-model="PB.allFileTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>

            <el-form-item
                label="通知打款日期"
                prop="noticePayTime">

                <el-date-picker
                    class="width3"
                    v-model="PB.noticePayTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {today} from "./config";
export default {
    name: 'UpdateDates',
    props: ["item"],

    data () {
        return {
            PB: {
                orderId: 0,
                allFileTime  : "",
                noticePayTime: ""
            },
            rules: {
                allFileTime  : {required: true, message: "请选择日期", trigger: "blur"},
                noticePayTime: {required: true, message: "请选择日期", trigger: "blur"}
            }
        }
    },

    computed: {
        contractTimeName () {
            return this.item?.contractTimeName || ""
        },
        sumPay10 () {
            return this.item?.sumPay10 || ""
        }
    },

    watch: {
        item (obj) {
            this.PB.orderId    = obj?.id || 0;
            this.PB.allFileTime   = obj?.allFileTimeName   || "" // today
            this.PB.noticePayTime = obj?.noticePayTimeName || "" // today
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".remind-deposit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/order/noticePayTime",
                    method: "post",
					data: { ...this.PB }
                })

				if (res.code === 2000) {
					this.$message({
                        message: "操作成功",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose()
	
				} else if (res.code !== 1003) 
					throw res;


            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped></style>
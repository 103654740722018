export const genderEnum = [
    { name: "男", value: 1},
    { name: "女", value: 2}
]

export const booleanEnum = [
    { name: "是", value: 1},
    { name: "否", value: 0}
]

const today = (function () {
    let today = new Date(),
        Y = today.getFullYear(),
        M = today.getMonth() + 1,
        D = today.getDate();
    M = M < 10 ? `0${M}` : M;
    D = D < 10 ? `0${D}` : D;

    return `${Y}-${M}-${D}`;
}) ();

export const pathSlice = function (pathstr, start=0, end) {
    const arr = pathstr?.split(/[/, \\, -]/).filter(str=>!!(str?.length));
    end = end == null ? arr.length : end;
    if (arr instanceof Array && arr.length > start) {
        return Array.prototype.slice.call(arr, start, end).join('/');
    }
    else return "";
}

export function createNewOrderData () {
    return {
        /** 这部分从 orderMap 中返回 */
        orderMap: {
            orderNo              : "", 
            custUuid             : "",    // 客户uuid
            cust_code            : "",
            custName             : "",
            custPhone            : "",    // 客户电话
    
            // isBuy                : true,  // 同为债券认购人
            deptId               : "",    // 业绩归属
            departmentMixName    : "",    // -- 业绩归属的组合名
            // 这是类型与子类型
            orderProcess: {
                type     : "", // (EOI资金类型),
                childtype: "", // (eoi子集资金类型 1现金新签 2续转新签 3普通新签)
            },
            // buyName              : "",    // 认购人姓名
            // buyPhone             : "",    // 联系电话
            // realBuyPhone         : "",    // 没隐藏的电话
            dealType: '', //成交形式
            isConnApply          : 0,     // 是否有联合申请人 1是 0否
            connEmpName          : "",    // 联合申请人名称
            isInspect            : 0,     // 是否考察团
            inspectTimeName      : "",    // 考察团出发日期
            // connEmpId            : 0,  // 联合申请人id
            productId            : "",    // 产品id
            investment           : "",    // 投资年限
            rate                 : "",    // 利率
            signTimeName         : today, // 同意函签署签署时间
            contractTimeName     : today, // 认购日期
            contractMoney        : "",    // 金额
            moneySource          : "",    // 资金来源
            moneyType            : "",    // 币种
            type                : 1,     // 状态
            addState             : 1,     // 增投类型
            oldProductId         : [],    // 原始投资产品id
            outProductId: [], //原始投资订单
            outTimeName          : "",    // 转出/赎回/退订时间
            investReason         : "",    // 投资目的
            finishTimeName       : "",    // 订单到期日期
    
            isAff                : 0,     // 投资确认书 1是 0否
            remark               : "",    // 备注
            path                 : [],    // 投资确认书附件
            // id                   : 0, 
            // allFileTime          : "",
            // createEmp            : 0,
            // createTime           : "",   

            // 以下是一些非用户输入的响应式数据
            processCheckState: 1,
            isTransfer: 0,
            isPicInvest: 0,
            custCreateTimeName: '',
            custCreateEmp: '',
            isFromAusPlat: '',
        },


        /** 这部分是投资人信息 */
        investor: {
            name           :  "", // "认购人姓名",
            phone          :  "", // "联系电话",
            email          :  "", // "电子邮箱",
            idcard         :  "", // "身份证号",
            birthday: "", // "生日",
            birthDayName: "", // "生日",
            passport       :  "", // "护照号",
            taxnum         :  "", // "税号",
            company        :  "", // "所从事的公司",
            position       :  "", // "职位",
            moneysource    :  "", // "平日主要收入来源",
            taxcountry     :  "", // "税务居民所在国",
            isold          :  "", // "是否老客户",
            describe       :  "", // "其他对客户的了解",
            backAccount    : {
                bankname       :  "", // "银行Bank",
                bankaccname    :  "", // "账户名称",
                bankaccnumber  :  "", // "账号",
                swiftcode      :  "", // "国际转账编号",
                bankno         :  "", // "分行号BSB",
            },
            address        : {
                countryid      :  "", // "国家id",
                provinceid     :  "", // "省id",
                cityid         :  "", // "市id",
                areaid         :  "", // "区id",
                addinfo        :  "", // "详细地址",
            },
            loancountrytype:  "", // "贷款国家 默认0 1国内 2澳洲 只有在eoi类型为贷款时有效",
            successtime    :  "", // "预批通过时间",
            successTimeName: "", // "预批通过时间",
            imagepath1     :  [], // "第一个附件地址 附件含义跟随eoi类型变化",
            imagepath2     :  [], // "第二个附件地址 附件含义跟随eoi类型变化"
            imagepath3     :  [], // "第三个附件地址 附件含义跟随eoi类型变化"

            buyPath: [],
            idCardPath: [],
            moneyPath: [],
            moneySourceRemark: '',
            isUpdatePhone: 0,
        },


        /** 这部分从 orderEmpInfo 中返回 */
        orderEmpInfo : {
            sourceId             : "",    // 客户来源id
            sourceIdName         : null,  // 客户来源名称
            resourceCreateEmp    : "",    // 资源创建人id
            resourceCreateEmpName: "",    // 资源创建人名称
            resourceManage       : "",    // 创建任经理id
            resourceManageName   : "",    // 创建人经理名称
            isChanComm           : "",    // 是否结渠道佣金 1是0否
            channelName          : "",    // 第三方渠道商
            channelCode: "",    // 第三方渠道商
            chanEmp              : "",    // 渠道员工id
            chanEmpName          : "",    // 渠道员工名称
            chanManage           : "",    // 渠道经理id
            chanManageName       : "",    // 渠道经理名称
            workDeptId           : "",    // 业务部门
            workEmp: "",    // 销售顾问id
            workEmpName: null,  // 销售顾问名称
            workCharge: "",    // 销售主管id
            workChargeName: null,  // 销售主管名称
            workManage: "",    // 销售经理id
            workManageName: null,  // 销售经理名称
            commChief            : "",    // 公司总监id
            commChiefName        : null,  // 公司总监名称
            regionManage: "",    // 区域销售经理id
            regionManageName: null,  //区域销售经理名称
            regionChief          : "",    // 区域总监id
            regionChiefName      : null,  // 区域总监名称
            isCooperation        : 0,     // 是否合作成交 1是 0否
            isRelative: 0,     // 是否员工/亲属内购 1是 0否


            reginName            : null,  // 合作成交区域
            cooperationEmp       : "",    // 合作成交人id 
            cooperationEmpName   : null,  // 合作成交人名称
            cooperationCharge    : "",    // 合作成交主管
            cooperationChargeName: null,  // 合作成交主管名称
            cooperationManage    : "",    // 合作成交经理id 
            cooperationManageName: null,  // 合作成交经理名称
            cooperationRegionManage: "",    // 合作成交区域经理id 
            cooperationRegionManageName: null,  // 合作成交区域经理名称
        }


        // systemName: "",
        // departmentMixName: "",
        // saveState: 0, // 0为保存，1已保存，2自保存后有新数据为保存
        // active: false,
        // nanoid: nanoid(),
    }
}

export const form1Rules = {
    deptId           : {required: true, message: "请选择业绩归属"},
    // buyName          : {required: true, message: "请填写认购人姓名"},
    // buyPhone         : {required: true, message: "请填写联系电话"},
    "orderProcess.type" : {required: true, message: "请选择EOI资金类型"},
    "orderProcess.childtype" : {required: true, message: "请选择新签类型"},
    buyPhone: { required: true, message: "请填写联系电话" },
    dealType: { required: true, message: "请选择成交形式" }, 
    isConnApply      : {required: true, message: "请选择是否有联合申请人"}, 
    connEmpName      : {required: true, message: "请填写联合申请人名称"},
    isInspect        : {required: true, message: "请选择是否参加考察团"}, 
    inspectTimeName  : {required: true, message: "请选择参加考察团时间"},
    productId        : {required: true, message: "请选择产品"},
    investment       : {required: true, message: "请选择投资年限"},
    rate             : {required: true, message: "请选择利率"},
    contractTimeName : {required: true, message: "请选择认购日期"},
    contractMoney    : [
        {required: true, message: "请填写金额", trigger: "blur"},
        { validator (r, val, c) {
            if (typeof val === "number") c()
            else if (!/^\d+(\.\d+)?$/.test(val)) {
                c("只能输入数字")
            }
            else c()
        }, trigger: "blur"}
    ],
    // moneyType        : {required: true, message: "请选择币种"},
    moneySource: { required: true, message: "请选择资金来源" }, 
    // moneySourceStr: { required: true, message: "请选择资金来源" }, 

    type             : {required: true, message: "请选择签约分类"}, 
    isPicInvest: { required: true, message: "请选择是否PIC投资" }, 

    // addState         : [
    //     {required: true, message: "请选择状态类型"},
    //     { validator (r, val, c) {
    //         if (!val) {
    //             c("请选择状态类型")
    //         }
    //         else c()
    //     }, trigger: "blur"}
    // ],
    oldProductId     : {required: true, message: "请选择原始投资产品"},
    outProductId: { required: true, message: "请选择原始投资订单" },
    outTimeName      : {required: true, message: "请选择时间"},
    finishTimeName   : {required: true, message: "请选择订单到期日期"},
    investReason     : {required: true, message: "请填写投资目的"},
}
function testEmail(r, value, cb) {
    if (!(/^[A-Za-z0-9\u4e00-\u9fa5._]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value))) { //一般邮箱格式
        cb("请输入正确的邮箱格式")
    } else if (value.length < 4) {
        cb("请输入正确的邮箱格式")
    } else {
        let spstr = value.substr(-4);
        console.log(spstr);
        if (spstr != '.com') { //要求最后必须是.com结尾
            cb("请输入正确的邮箱格式")
        } else {
            cb()
        }
    }
}

export const form6Rules = {
    name: { required: true, message: "请输入认购人姓名", trigger: 'blur' },
    phone          : {required: true, message: "请输入联系电话"},

    email          : [
        { required: true, message: "请输入正确的邮箱", trigger: 'blur' },
        {validator (r, v, callback) {
            if (/^[a-zA-Z0-9\u4e00-\u9fa5._,-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(v)) callback();
            else callback("请输入正确的邮箱");
        }, trigger: 'blur'
        },
    ],
    // email: [
    //     { required: true, message: "请输入正确的邮箱" },
    //     { validator: testEmail },
    // ],

    // idcard: { required: true, message: "请输入身份证号", trigger: 'blur' },
    idcard: [
        { required: true, message: "请输入正确的身份证号", trigger: 'blur' },
        {

            //身份证为15位或者18位，15位的全为数字，18位的前17位为数字，最后一位为数字或者大写字母”X“   ——————简单验证——————  (^\d{15}$)|(^\d{17}([0-9]|X)$)


            validator(r, v, callback) {
                if (/^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/.test(v)) callback();
                else callback("请输入正确的身份证号");
            }, trigger: 'blur'
        },
    ],


    taxnum: { required: true, message: "请输入税号" },
    passport: { required: true, message: "请输入护照号" },

    isold          : {required: true, message: "请输入是否老客户"},
    // backAccount    : [
    //     {required: true, message: "请输入完整的账户信息"},
    //     {validator (r, v, callback) {
    //         console.log("changeeeeee");
    //         const {bankname: a, bankaccname: b, bankaccnumber: c, swiftcode: d, bankno: e} = v;
    //         if (a && b && c && d && e) callback();
    //         else callback("请输入完整的账户信息");
    //     }, trigger: "change"},
    // ],
    // address        : {required: true, message: "请输入完整的居住地址"},


    // loancountrytype: {required: true, message: "请选择贷款国家"},
    // successtime    : {required: true, message: "请选择预批通过时间"},
    // imagepath1: { required: true, message: '请上传附件' },
    // imagepath2     : {required: true, message: "请上传附件"},
    // imagepath3     : {required: true, message: "请上传附件"},
};

export const form2Rules = {
    isChanComm       : {required: true, message: "请选择是否结渠道佣金"},
    workDeptId       : {required: true, message: "请选择业务部门"},
    workEmp: { required: true, message: "请选择销售顾问" },
    // workCharge       : {required: true, message: "请选择销售主管"},
    // workManage       : {required: true, message: "请选择销售经理"},
    regionChief: { required: false, message: "请选择区域总监" },
    isCooperation    : {required: true, message: "请选择是否合作成交"},
    cooperationEmp   : {required: true, message: "请选择合作成交人"},

    // channelName: { required: true, message: "请选择第三方渠道" },




}

export const orderFiles = [
    {
        id     : 0,
        type   : 1,           // 类型
        label: "身份证件",
        isall  : 0,           // 是否收齐
        remark : "",          // 备注
        path   : [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id     : 0,
        type   : 2,           // 类型
        label: "地址证明",
        isall  : 0,           // 是否收齐
        remark : "",          // 备注
        path   : [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id     : 0,
        type   : 3,           // 类型
        label: "银行流水",
        isall  : 0,           // 是否收齐
        remark : "",          // 备注
        path   : [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id     : 0,
        type   : 4,           // 类型
        label: "资金辅助证明",
        isall  : 0,           // 是否收齐
        remark : "",          // 备注
        path   : [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id     : 0,
        type   : 5,           // 类型
        label: "会计师信",
        isall  : 0,           // 是否收齐
        remark : "",          // 备注
        path   : [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id: 0,
        type: 6,           // 类型
        label: "签字版认购协议",
        isall: 0,           // 是否收齐
        remark: "",          // 备注
        path: [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
]
export const tzfyzl = [ //投资翻译资料
    {
        id: 0,
        type: 1,           // 类型
        label: "身份证件翻译版",
        isall: 0,           // 是否收齐
        remark: "",          // 备注
        path: [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id: 0,
        type: 2,           // 类型
        label: "地址证明翻译版",
        isall: 0,           // 是否收齐
        remark: "",          // 备注
        path: [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id: 0,
        type: 3,           // 类型
        label: "银行流水翻译版",
        isall: 0,           // 是否收齐
        remark: "",          // 备注
        path: [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id: 0,
        type: 4,           // 类型
        label: "资金辅助证明翻译版",
        isall: 0,           // 是否收齐
        remark: "",          // 备注
        path: [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id: 0,
        type: 5,           // 类型
        label: "会计师信翻译版",
        isall: 0,           // 是否收齐
        remark: "",          // 备注
        path: [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
    {
        id: 0,
        type: 6,           // 类型
        label: "签字版认购协议翻译版",
        isall: 0,           // 是否收齐
        remark: "",          // 备注
        path: [],          // 附件路径
        checkstate: 0,
        checkstateTemp: 0,
        checkbox: false,
    },
]

export const processStateMapOld = {
    0: {
        name: "待提交审核",
        value: 0,
        node: 1
    },


    1: {
        name : "待审核",
        value: 1,
        node : 1
    },
    2: {
        name : "审核不通过",
        value: 2,
        node : 1
    },
    3: {
        name: "待审核全套资料",
        value: 3,
        node : 2
    },
    4: {
        name: "待通知翻译",
        value: 4,
        node: 2
    },
    5: {
        name: "已确认额度",
        value: 5,
        node: 3
    },
    6: {
        name: "翻译完成",
        value: 6,
        node: 3
    },
    7: {
        name: "通知打款",
        value: 7,
        node: 3

    },
    // 申请状态 0待提交审核 1待审核 2审核不通过 3待审核全套资料 4 待通知翻译 5已确认额度  6翻译完成 7通知打款


    // 4: {
    //     name : "待审核全套资料",
    //     value: 4,
    //     node : 2
    // },
    // 5: {
    //     name : "待确认额度",
    //     value: 5,
    //     node : 2
    // },

}
//
// 状态为0 待提交审核      第一步显示 待提交审核     能点第1步    不能点 第234步

// 状态为8 待分公司审核   第一步显示 能点第1步  不能点 第234步     分公司审核

// 状态为9 分公司退回   第一步显示 分公司退回   能点第1步  不能点 第234步

// 状态为1 待总公司审核    第一步显示  待总公司审核    能点第1步    不能点 第234步  审核EOI——总公司审核

// 状态为2 总公司退回      第一步显示  总公司退回  能点第1步  不能点 第234步

// 状态为3 待审核全套资料   第一步显示 审核通过 第2步显示 待审核全套资料     能点第12步   不能点 第34步  延长截止日期

// 状态为4 待通知翻译   第一步显示 审核通过 第2步显示 待通知翻译  能点第12步  不能点 第34步  通知翻译资料

// 状态为5 待完成翻译   第一步显示 审核通过  第2步显示 审核通过  第3步显示 待完成翻译  能点第123步  不能点 第4步  翻译完成 延长截止日期

// 状态为6 翻译完成   第一步显示 审核通过  第2步显示 审核通过  第3步显示 翻译完成  能点第1234步  通知打款 编辑认购额度

// 状态为7 通知打款    第一步显示 审核通过  第2步显示 审核通过  第3步显示 翻译完成  能点第1234步  延长截止日期 编辑认购额度

export const processStateMap = {
    0: {
        name: "待提交审核",
        value: 0,
        node: 1
    },


    1: {
        name: "待总公司审核",
        value: 1,
        node: 1
    },
    2: {
        name: "总公司退回",
        value: 2,
        node: 1
    },
    3: {
        name: "待审核全套资料",
        value: 3,
        node: 2
    },
    4: {
        name: "待通知翻译",
        value: 4,
        node: 2
    },
    5: {
        name: "待完成翻译",
        value: 5,
        node: 3
    },
    6: {
        name: "翻译完成",
        value: 6,
        node: 4
    },
    7: {
        name: "通知打款",
        value: 7,
        node: 3

    },
    8: {
        name: "待分公司审核",
        value: 8,
        node: 1

    },
    9: {
        name: "分公司退回",
        value: 9,
        node: 1

    }  
    // 申请状态 0待提交审核EOI 1待总公司审核 2总公司退回 3待审核全套资料 4 待通知翻译 5待完成翻译  6翻译完成 7通知打款  8待分公司审核 9分公司退回
}
<template>
    <div>
        <!-- {{isUpdate}} -->
            <!-- :disabled="orderMap.processCheckState > 2 && !(isWhiteMan)" -->
            <!-- :rules="rules" -->
        <el-form
            :model="investFile"
            class="order-part1 order-part private"
            label-width="180px"
            label-suffix=": "
            ref="form1"
        >
           
            <div>
                <div class="part-title hw_mt16"><h3>投资翻译资料</h3></div>
                <span class="uptips">附件上传文件类型：图片，pdf，zip，rar（.rar .zip .doc .docx .pdf .jpg），小于10M,最多5个附件</span>
                <!-- 现金 / 续转签-->
                <template> 
                    <!-- {{investFile}} -->
                    <div class="line">
                        <el-form-item prop="imagepath1" class="full-width" label="身份证件翻译版" >
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investFile.imagepath1"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="orderMap.processCheckState < 3 || !!isWhiteMan"
                                >
                                </UploadFile1>
                        </el-form-item>
                    </div>
                    
                    <div class="line">
                        <el-form-item prop="imagepath2" class="full-width" label="地址证明翻译版">
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investFile.imagepath2"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="orderMap.processCheckState < 3 || !!isWhiteMan"
                                >
                                </UploadFile1>
                        </el-form-item>
                    </div>
                    <div class="line">
                        <el-form-item prop="imagepath3" class="full-width" label="银行流水翻译版">
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investFile.imagepath3"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="orderMap.processCheckState < 3 || !!isWhiteMan"
                                >
                                </UploadFile1>
                        </el-form-item>
                    </div>
                    <div class="line">
                        <el-form-item prop="imagepath4" class="full-width" label="资金辅助证明翻译版">
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investFile.imagepath4"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="orderMap.processCheckState < 3 || !!isWhiteMan"
                                >
                                </UploadFile1>
                        </el-form-item>
                    </div>
                    <div class="line">
                        <el-form-item prop="imagepath5" class="full-width" label="会计师信翻译版">
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investFile.imagepath5"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="orderMap.processCheckState < 3 || !!isWhiteMan"
                                >
                                </UploadFile1>
                        </el-form-item>
                    </div>
                    <div class="line">
                        <el-form-item prop="imagepath6" class="full-width" label="签证版认购协议翻译版">
                            <UploadFile1
                                    :isDisable="isDisable"
                                    v-model="investFile.imagepath6"
                                    :accept="uploadAccept"
                                    :limit="5"
                                    :maxSize="1e4"
                                    :show-delete="orderMap.processCheckState < 3 || !!isWhiteMan"
                                >
                                </UploadFile1>
                        </el-form-item>
                    </div>
                </template>
            </div>
        </el-form>


    </div>

</template>

<script>

import UploadFile1 from '../../../components/UploadFile1/UploadFile1.vue';
import {form6Rules, pathSlice} from "./config";

export default {
    name: 'FormPart6',
    components: {UploadFile1},
    props: [
        "id",
        "orderMap",
        "investor",
        'investFile',
        'investFileids',
        "addressOptions",
        "phoneDisabled",
        "isWhiteMan",
        'navIndex',
        "orderInitInfo",
    ],

    data () {
        return {
            uploadAccept: "image/*,application/pdf,application/x-pdf",
            // form6Rules,
        }
    },
    mounted () {
        this.fetchFileList("first");
    },
    watch: {
         navIndex() {
            setTimeout(() => {
                this.$refs.form1.clearValidate();
            }, 2);
        },

        "investor.moneyPath": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
        "investor.buyPath": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
        "investor.idCardPath": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
        "investor.imagepath1": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
         "investor.imagepath2": function (n, o) {
            if(n){
                this.$refs.form1.clearValidate();
            }
        },
    },
    // investor.moneyPath

    computed: {
        isDisable(){
            // return !!(this.orderMap.processCheckState > 2 && !(this.isWhiteMan))
            return false
        },
        isUpdate () {
            return !!this.orderMap?.id
        },
        rules(){
            if(this.navIndex==2){
                if(this.orderInitInfo.isOldOrder==1){//来数据处理 20230421
                    return  Object.assign({},{
                        buyPath     : {required: true, message: "请上传附件"},
                        idCardPath     : {required: true, message: "请上传附件"},
                        moneyPath     : {required: true, message: "请上传附件"},
                    })
                }else{
                    return  Object.assign({},{
                       loancountrytype: {required: true, message: "请选择贷款国家"},
                       successTimeName    : {required: true, message: "请选择预批通过时间"},
                       imagepath1: { required: true, message: '请上传附件' },
                       imagepath2     : {required: true, message: "请上传附件"},
                       // imagepath3     : {required: true, message: "请上传附件"},
   
                       buyPath     : {required: true, message: "请上传附件"},
                       idCardPath     : {required: true, message: "请上传附件"},
                       moneyPath     : {required: true, message: "请上传附件"},
                   })
                }
            }else{
                return form6Rules
            }

            // if(this.isUpdate){
            //     return  Object.assign({},form6Rules,{
            //         loancountrytype: {required: true, message: "请选择贷款国家"},
            //         successTimeName    : {required: true, message: "请选择预批通过时间"},
            //         imagepath1: { required: true, message: '请上传附件' },
            //         imagepath2     : {required: true, message: "请上传附件"},
            //         imagepath3     : {required: true, message: "请上传附件"},
            //     })
            // }else{
            //     return form6Rules
            // }
        }


        // curProduct () {
        //     const {productId} = this.orderMap;
        //     if (!productId) return null;
        //     else return this.products.find(
        //         item => item.id === productId)
        // },
        // productRelativeEnum () {
        //     const defaultValue = {
        //         term     : [],
        //         rate     : [],
        //         starttime: "",
        //         endtime  : "",
        //         hasTime  : false
        //     };
            
        //     if (!this.curProduct) return defaultValue;
        //     else {
        //         return {
        //             term     : this.curProduct.investment.split("#").filter(Boolean),
        //             rate     : this.curProduct.rate.split("#").filter(Boolean),
        //             starttime: this.curProduct.starttime,
        //             endtime  : this.curProduct.endtime,
        //             get hasTime() {return !!(this.starttime || this.endtime)}
        //         };
        //     }
        // },
        // validDateRange () {
        //     let min, max;
        //     if (this.id) {
        //         min = new Date(this.orderMap.createTime || undefined);
        //         const max1 = new Date(this.orderMap.contractTime || undefined);
        //         const max2 = new Date(this.orderMap.createTime || undefined);
        //         max = max1 > max2 ? max1 : max2;
        //     } else {
        //         min = new Date();
        //         max = new Date();
        //     }
        //     const day = min.getDay();
        //     let offset = 3;
        //     if (day < 3) offset = 5;
        //     else if (day > 5) offset = 4;
        //     min.setDate(min.getDate()-offset);
        //     return ({min, max})
        // }
    },

    methods: {
        async fetchFileList (isInit) {
             const loadingRef = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/orderProcess/queryTranslateFileList",
                    method: "post",
                    data: { orderId: this.id }
                });
                loadingRef && loadingRef.close();
                if (res.code !== 2000 && res.code !== 1003) throw res;
                const { orderTranslateFileList } = res.data;

                if(orderTranslateFileList.length>0){
                    this.investFileids.investFile1_id = orderTranslateFileList[0].id
                    this.investFileids.investFile2_id = orderTranslateFileList[1].id
                    this.investFileids.investFile3_id = orderTranslateFileList[2].id
                    this.investFileids.investFile4_id = orderTranslateFileList[3].id
                    this.investFileids.investFile5_id = orderTranslateFileList[4].id
                    this.investFileids.investFile6_id = orderTranslateFileList[5].id

                    for (let i = 1; i < 7; i++) {
                        if(orderTranslateFileList[i-1].file){
                            orderTranslateFileList[i-1].path = orderTranslateFileList[i-1].file?.split(",").map(path => ({
                                name: path.slice(path.lastIndexOf("/")+1),
                                url: this.$baseURL + "/" + path,
                                path: path,
                            }))||[]
                        }else{
                            orderTranslateFileList[i-1].path = ''
                            orderTranslateFileList[i-1].name = ''
                            orderTranslateFileList[i-1].url = ''
                        }
                        // console.log(orderTranslateFileList[i-1]);
                        this.investFile['imagepath'+i] = orderTranslateFileList[i-1].path? orderTranslateFileList[i-1].path:[]
                    }
                }
                //  this.investFile.imagepath1 = [ { "name": "1car.jpg", "url": "http://120.25.85.101:8050/upload/real/168431660206032/1car.jpg", "path": "upload/real/168431660206032/1car.jpg", "isImage": true, "cname": "1car.jpg", "uid": 1684323139760, "status": "success" } ]
                //  this.investFile.imagepath1 = [ { "name": "1car.jpg", "url": "http://120.25.85.101:8050/upload/real/168431660206032/1car.jpg", "path": "upload/real/168431660206032/1car.jpg" } ]



                // const { orderInvestFileList } = res.data;
                // if (orderInvestFileList.length) this.$emit("filesFetched");
                // orderInvestFileList.map(item => {
                //     const files = this.PB.orderFiles.find(({type}) => type == item.type);
                //     files.id             = item.id;
                //     files.checkstate     = item.checkstate;
                //     files.checkstateTemp = item.checkstate;
                //     if(item.file){
                //         files.path = item.file?.split(",").map(path => ({
                //             name: path.slice(path.lastIndexOf("/")+1),
                //             url: this.$baseURL + "/" + path,
                //             path: path,
                //         }))||[]
                //     }else{
                //         files.path = []
                //     }
                // });


            } catch (reason) {
                console.warn("获取投资资料失败", reason);
                this.$message({
                    message: reason.msg || "获取投资资料失败",
                    type: "warning"
                });	
            }
        },
        formatData (files) {
            let _arr = []
            for (let i = 0; i < 6; i++) {
                let _obj = {
                    type  : Number(1+i),
                    file  :'',
                    id    : this.investFileids['investFile'+Number(i+1)+'_id']||''
                }
                let patharr = []
                files['imagepath'+Number(1+i)].forEach(item => {
                    if(item.path){
                        patharr.push(item.path)
                    }
                });
                _obj.file = patharr.join()
                _arr.push(_obj)
            }
            const data = {
                orderId: this.id,
                translateFileStr : JSON.stringify(_arr)
            };
            return data;
        },


        async save (files) {
            // console.log(this.investFile);
            //      orderId:1483
// investFileStr:[{"id":(编辑保存时必传)"type":1（类型）,"file":"/123/123"（文件地址）}]


            // files = files ? [files] : this.PB.orderFiles
            // if (files.some(file => file.path.some(({percentage}) => percentage < 100)))
            //     return this.$message({
            //         message: "请等待附件上传完毕",
            //         type: "error"
            //     });
            // await this.$refs.form1.validate();

            // await this.validate()
            // console.log(this.validate());
            // if(!this.validate()){
            //     return false
            // }

            // await this.$refs.form1.validate();

             const {imagepath1, imagepath2, imagepath3, imagepath4, imagepath5, imagepath6} = this.investFile;
                if ([...imagepath1, ...imagepath2, ...imagepath3, ...imagepath4, ...imagepath5, ...imagepath6].some(({percentage,status}) =>(percentage < 100 || status =='uploading'))){
                    this.$message({
                        message: "请等待附件上传完毕",
                        type: "error"
                    });
                    return false
                }
            const loadingRef = this.$loading();
            try {
                const data = this.formatData(this.investFile);
                const res = await this.$axios({
                    url: "/api/orderProcess/saveTranslateFile",
                    method: "post",
                    data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    // this.handleNavigationBack();
                    this.$emit("change", loadingRef);
                    this.fetchFileList()

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleSelectBun () {
            this.showPicker = true;
        },

        handlePick (data) {
            this.orderMap.deptId   = data.id;
            this.orderMap.prenames = pathSlice(data.prenames,3);
        },

        handleProductChange () {
            this.orderMap.investment = "";
            this.orderMap.rate       = "";
        },

        handleSuggestClick (text) {
            console.log("点击建议文字", text);
            this.orderMap.investReason += text;
        },

        handleloancountrytypeChange () {
            this.clearAttachment(33)
        },

        clearAttachment (flag) {
            if (flag !== 33)
            this.investor.loancountrytype = "";
            this.investor.successTimeName = "";
            this.investor.imagepath1 = [];
            this.investor.imagepath2 = [];
            this.investor.imagepath3 = [];
        },
    

        async validate () {
            console.log('navIndex',this.navIndex);
            if(this.navIndex==1){
                await this.$refs.form1.validate()
            // }else if(this.navIndex==2){
            }else if(this.navIndex==0){
                const {imagepath1, imagepath2, imagepath3} = this.investFile;
                console.log(this.investFile);

                if ([...imagepath1, ...imagepath2, ...imagepath3].some(({percentage,status}) => (percentage < 100 || status =='uploading')))
                    return this.$message({
                        message: "请等待附件上传完毕",
                        type: "error"
                    });
                return  this.$refs.form1.validate()
            }else{ //新增
                await this.$refs.form1.validate()
               
            }
        }
    }
}
</script>

<style lang='scss' scoped>
    .product-dates {
        color: #606266;

        &:nth-child(n+2) {
            margin-left: 16px;
        }
    }

    .full-width {
        width: 100%;
        /deep/ .el-form-item__label{
            width: 200px !important;
        }
        /deep/ .el-form-item__content{
            margin-left: 200px;
        }
    }

    .el-form.private .width3 {
        width: 300px
    }

    .old-trigger-btn {
        margin-right: calc(100% - 100px);
    }

    .old-product-list {
        &:empty {
            display: none;
        }
        margin-top: 6px;
        padding: 14px;
        background-color: rgba($color: #000000, $alpha: .05);
        border: 1px solid rgba($color: #000000, $alpha: .05);
    }

    .suggest-list {
        display: flex;

        li {
            margin-left: 10px;
            padding: 0 12px;
            line-height: 30px;
            height: 32px;
            background-color: #f0f6ff;
            border: 1px solid rgba($color: #000000, $alpha: .05);
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                background-color: #f0f3f6;

            }
        }
    }

    .bank-account-input {
        padding: 24px;
        padding-bottom: 8px;
        background: #F3F3F3;
    }


    /deep/ .doubel_line_label {
        .el-form-item__label {
            line-height: 1;
        }

        .form-item-label {
            line-height: 18px;
            &:after {
                content: ":";
            }
        }
        .form-item-vice {
            display: block;
            line-height: 14px;
            font-size: 12px;
        }
    }
    .line /deep/ .upload-row .el-upload--text{
        text-align: left;
    }
    .uptips{
        color: rgb(192, 196, 204);
        font-size: 13px;
        margin-left: 30px;
        margin-bottom: 10px;
        display: inline-block;
    }
            
</style>
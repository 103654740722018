<!-- 配置员工负责的系统 -->
<template>
    <div class="order-part3 order-part">
        <div class="part-title">
            <h3>公司优惠</h3>
            <el-button
                v-if="auth.save && (!id || isWhiteMan || orderInitInfo.processCheckState ==0|| orderInitInfo.processCheckState ==8|| orderInitInfo.processCheckState ==9|| orderInitInfo.processCheckState ==1|| orderInitInfo.processCheckState ==2 )"
                type="text"
                @click="handleAddBtn"
            >添加优惠</el-button><!-- || !disabled-->
            <i class="tips right">温馨提示: 全款齐后2个工作日不能修改公司优惠，白名单岗位的没有限制</i>
        </div>

        <el-table :data="orderDiscountList">
            <el-table-column label="序号"
                type="index"></el-table-column>
            <el-table-column label="优惠类型"
                prop="discountTypename"></el-table-column>
            <el-table-column label="核算价值"
                prop="worth"></el-table-column>
            <el-table-column label="币种"
                prop="moneyTypeName"></el-table-column>
            <el-table-column label="附件">                
                <template slot-scope="scope">
                    <thumbnail-list
                        :images="scope.row.path"
                        type="text"
                        text-type="index"
                        class="attachment-list"
                    ></thumbnail-list>
                </template>
            </el-table-column>
            <!-- <el-table-column label="附件"> -->
                <!-- <template slot-scope="scope">
                    <el-button v-if="auth.save"
                        type="text"
                        @click.stop="handlePreview(scope.row)"
                    >预览</el-button>
                </template> -->
            <!-- </el-table-column> -->
            <el-table-column label="备注"
                prop="remark"></el-table-column>
            <el-table-column label="操作" v-if="!id || isWhiteMan || orderInitInfo.processCheckState ==0|| orderInitInfo.processCheckState ==8|| orderInitInfo.processCheckState ==9|| orderInitInfo.processCheckState ==1|| orderInitInfo.processCheckState ==2">
                <template slot-scope="scope">
                    <el-button v-if="auth.save"
                        type="text"
                        @click.stop="handleEdit(scope.row)"
                    >编辑</el-button>
                    <el-button v-if="auth.delete"
                        type="text"
                        @click.stop="handleDelete(scope.row, scope.$index)"
                    >作废</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog class="privilege-edit"
            :visible="showForm"
            @close="handleClose"
            :title="dialogTitle"
            width="500px">

            <el-form
                :model="editingItem"
                :rules="rules"
                class="private"
                label-width="100px"
                label-suffix=":"
                ref="form"
                @submit.prevent="handleSave"
                style="padding-right: 30px"
            >
                <el-form-item label="优惠类型" prop="discounttype">
                    <el-select v-model="editingItem.discounttype"
                        class="width3">
                        <el-option
                            v-for="it in disList"
                            :key="it.id"
                            :label="it.name"
                            :value="it.id"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="核算价值" prop="worth">
                    <el-input v-model.trim="editingItem.worth"
                        class="width3">
                    </el-input> 
                    <el-select
                        v-model="editingItem.moneyType"
                        class="width1h">
                        <el-option
                            v-for="s in moneyTypeList"
                            :key="s.id"
                            :label="s.name"
                            :value="s.id"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="附件凭证" prop="path" class="wrap">
                    <UploadFile1
                        v-model="editingItem.path"
                        :cols="1"
                        :accept="uploadAccept"
                        :limit="5"
                        :maxSize="5000"
                    >
                        <span class="hint" slot="hint">只能上传图片或pdf文件, 最多上传5个附件</span>
                    </UploadFile1>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                    <el-input v-model="editingItem.remark"
                        type="textarea" rows="5"
                        maxlength="100"
                        class="width3"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button @click="handleSave" type="primary">保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import UploadFile1 from '../../../components/UploadFile1/UploadFile1.vue'
export default {
    name: 'FormPart3',
    components: {UploadFile1},
    props: [
        "id",
        "orderMap",
        "disList",
        "moneyTypeList",
        "orderDiscountList",
        "isWhiteMan",
        "orderInitInfo"
    ],

    data () {
        return {
            disabled        : false, // 已经到达全款齐后两个工作日
            showForm        : false,
            uploadAccept    : "image/*,application/pdf,application/x-pdf",
            editingItem     : {
                orderid     : this.id,
                id          : undefined,
                discounttype: "",
                moneyType   : this.moneyTypeList.find(it=>it.name.includes("澳"))?.id||"",
                worth       : "",
                path        : [],
                remark      : ""
            },
            rules: {
                discounttype : { required: true, message: "请选择优惠类型", trigger: "blur" },
                path         : { required: true, message: "请上传附件凭证", trigger: "blur" },
                remark       : { required: true, message: "请填写备注",     trigger: "blur" },
                worth        : { validator (r, val, c) {
                                    if (
                                        typeof val === "number" ||
                                        val === ""
                                    ) c()
                                    else if (!/^\d+(\.\d+)?$/.test(val)) {
                                        c("只能输入数字")
                                    }
                                    else c()
                                }, trigger: "blur"}
            },
            auth: {
                save: true,
                delete: true
            }
        }
    },

    computed: {
        isUpdate () {
            return !!this.editingItem?.id
        },
        dialogTitle () {
            return this.isUpdate ? "编辑优惠" : "新增优惠"
        }
    },

    watch: {
        id: {
            immediate: true,
            handler (val) {
                // console.log("orderid 变化了", val);
                this.fetchDiscountList(val)
            }
        } 
    },

    created () {
		this.auth.save = this.$hasAuthFor("api/order/saveOrderDisCount");
		this.auth.delete = this.$hasAuthFor("api/order/deleteOrderDisCount");
    },

    methods: {
        async fetchDiscountList(orderId) {
            orderId = orderId || this.id;
            try {
                if (!orderId && !this.orderDiscountList.length)
                    throw "没有orderid 和 优惠列表";
                
                const data = {};
                if (orderId) data.orderId = orderId;
                else data.ids = this.orderDiscountList.map(
                    it => it.id).join();

				const res = await this.$axios({
					url: "/api/order/queryOrderDiscountList",
					method: "post",
					data
				});

				if (res.code === 2000) {
                    let {sumPayAllTime} = res.data; // 全款齐的时间
                    if (sumPayAllTime) {
                        const arr = sumPayAllTime.split("-").map(str=>parseInt(str));
                        arr[1]--;
                        const date = new Date(...arr),
                            now = new Date();
                        date.setDate(date.getDate()+3);
                        this.disabled = date < now;
                    }

                    this.orderDiscountList.splice(0,
                        this.orderDiscountList.length,
                        ...res.data.orderDiscountList.map(item => {
                            if (item.path) item.path =
                                item.path.split(",").filter(Boolean).map(path => {
                                    return {
                                        name: path.slice(path.lastIndexOf("/")+1),
                                        path: path,
                                        url: this.$baseURL + "/" + path,
                                    }
                                })
                            return item;
                        }))

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取优惠列表失败", reason);
			}
        },

        resetForm () {
            this.editingItem.orderid      =  this.id,
            this.editingItem.id           =  undefined,
            this.editingItem.discounttype =  "",
            this.editingItem.moneyType    =  this.moneyTypeList.find(it=>it.name.includes("澳"))?.id||"",
            this.editingItem.worth        =  "",
            this.editingItem.path         =  [],
            this.editingItem.remark       =  ""
        },

        handleAddBtn () {
            this.resetForm();
            this.showForm = true;
        },

        handleEdit (item) {
            this.editingItem.orderid     = this.id;
            this.editingItem.id          = item.id;
            this.editingItem.discounttype= item.discountType;
            this.editingItem.moneyType   = item.moneyType;
            this.editingItem.worth       = item.worth;
            this.editingItem.remark      = item.remark;
            this.editingItem.path        = item.path || [];
            // this.editingItem.path        =
            //     item.path ? item.path.split(",").map(path => ({
            //         name: path.slice(path.lastIndexOf("/")+1),
            //         url: this.$baseURL + "/" + path,
            //         path: path
            //     })) : [];

            this.showForm = true;
        },

		// 点击表格行操作按钮: 作废
		async handleDelete(data, index) {
			let loadingRef;
			try {
				await this.$confirm("确定要作废吗", "系统提示")
				loadingRef = this.$loading({
                    target: ".order-discount-edit .el-dialog"
                });
				const res = await this.$axios({
					url: "/api/order/deleteOrderDisCount",
					method: "post",
					data: {
						id: parseInt(data.id)
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "作废成功",
						type: "success"
					});

                    this.orderDiscountList.splice(index, 1);
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "作废失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

        handleClose () {
            this.showForm = false;
            this.$refs.form.clearValidate()
            // this.editingItem = null;
        },

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".order-discount-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();

                const {orderid, id, discounttype, moneyType,
                    worth, remark, path} = this.editingItem;

                if ([...path].some(({percentage,status}) =>(percentage < 100 || status =='uploading'))){
                    this.$message({
                        message: "请等待附件上传完毕",
                        type: "error"
                    });
                    loadingRef && loadingRef.close();
                    return false
                }


                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/saveOrderDisCount",
                    data: {
                        orderid, id, discounttype, moneyType,
                        worth, remark,
                        path: path.map(file => file.path).join()
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    if (!this.id && !this.isUpdate) {
                        this.orderDiscountList.push({
                            id: res.data.orderDiscountId
                        })
                    }
                    this.fetchDiscountList();
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
    }
}
</script>

<style lang='scss' scoped>
    .part-title .el-button {
        padding: 0;
        height: 20px;
        margin-left: 20px;
    }

    /deep/.el-upload {
        text-align: unset;
    }
</style>
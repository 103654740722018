import {nanoid} from "nanoid";

export const BooleanEnum = [
    { name: "是", value: 1},
    { name: "否", value: 0}
]
export const calculateTypes = [
    { name: "按公司债核算", value: '1' },
    { name: "按非公司债核算", value: '0' }
]

// 函数创建一个空的菜单数据
export function initialPB () {
    return {
        nanoid: nanoid(),
        name: "",
        investment: "",
        rate: "",
        starttime: "",
        endtime: "",
        circulation: "",
        iscom: 0,
        remark: "",
        calculateType: '',
    };
}
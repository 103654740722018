<!-- 核算赛季业绩 -->
<template>
    <el-dialog class="check-season"
        :visible="viewIsReady"
        @close="handleClose"
        title="业绩核算"
        width="560px">
        <el-form id="myDiv" class="private" 
            :model="PB"
            :rules="rules"
            label-width="120px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
        <!-- {{this.changeNo}} -->
            <el-form-item label="客户姓名">
                <span>{{ buyName }}</span>
            </el-form-item>

            <el-form-item label="投资产品">
                <span>{{ productName }}</span>
            </el-form-item>

            <el-form-item label="是否核算业绩" prop="isCheck">
                <el-radio-group v-model="PB.isCheck">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>

            <template v-if="PB.isCheck">
                <el-form-item label="核算赛季" prop="seasonId">
                    <el-select v-model="PB.seasonId" @change="changeSeason"
                        class="width4">
                        <el-option
                            v-for="level in seasonList"
                            :key="'a'+level.id"
                            :label="level.seasonName"
                            :value="level.id"
                            
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="赛季业绩金额" prop="seasonProfit">
                    <el-input v-model="PB.seasonProfit"
                        class="width3"></el-input>
                </el-form-item>
                <el-form-item label="赛季业绩单数" prop="performanceNum">
                    <el-input v-model="PB.performanceNum"
                        class="width3"></el-input>
                </el-form-item>
                <el-form-item label="应发奖金金额" prop="payableBonus">
                    <el-input v-model="PB.payableBonus"
                        class="width3"></el-input>
                </el-form-item>
                <el-form-item label="渠道费用" prop="chanMoney">
                    <el-input v-model="PB.chanMoney"
                        class="width3"></el-input>
                </el-form-item>
                <!-- {{changeNo}} -->

                <el-form-item label="保底奖金" prop="guaranteeMoney">
                    <span>{{PB.guaranteeMoney}}</span>
                </el-form-item>
                <el-form-item label="投入PK池奖金" prop="pkRatioMoney">
                    <span>{{PB.pkRatioMoney}}</span>
                </el-form-item>
                <el-form-item label="获胜奖金" prop="victoryMoney">
                    <el-input v-model="PB.victoryMoney"
                        class="width3"></el-input>
                </el-form-item>
                <!-- {{comGuaranteeRatio}}
                {{comPkRatio}} -->



                <!-- <el-form-item label="奖金金额" prop="bonusMoney">
                    <el-input v-model="PB.bonusMoney"
                        class="width3"></el-input>
                </el-form-item>

                <el-form-item label="佣金金额" prop="commMoney">
                    <el-input v-model="PB.commMoney"
                        class="width3"></el-input>
                </el-form-item> -->

                <el-form-item label="备注" prop="remark">
                    <el-input v-model="PB.remark"
                        type="textarea" rows="5"
                        maxlength="100"
                        class="width4"></el-input>
                </el-form-item>
            </template>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'CheckSeason',
    props: [
        "item",
        "seasonList"
    ],

    data () {
        const moneyValidator = function (r, val, c) {
            if (typeof val === "number") c()
            else if (!/^\d+(\.\d+)?$/.test(val)) {
                c("只能输入数字")
            }
            else c()
        };
        return {
            viewIsReady: false,
            comPkRatio:'',
            comGuaranteeRatio:'',
            PB: {
                isCheck      : 0,
                seasonId     : "",
                seasonProfit : "",
                performanceNum:'',
                // bonusMoney   : "",
                // commMoney    : "",
                payableBonus:'',
                chanMoney:'',

                guaranteeMoney:'',
                pkRatioMoney:'',
                victoryMoney:'',
                remark       : ""
            },
            rules: {
                isCheck      : {required: true, message: "请选择是否核算业绩", trigger: "blur"}, 
                seasonId     : {required: true, message: "请选择赛季", trigger: "blur"}, 
                seasonProfit : {required: true, message: "请填写业绩金额", trigger: "blur"},
                performanceNum : {required: true, message: "请填写业绩单数", trigger: "blur"},

                
                // bonusMoney   : [
                //     {required: true, message: "请输入奖金", trigger: "blur"},
                //     {validator: moneyValidator, trigger: "blur"},
                // ],
                // commMoney    : [
                //     {required: true, message: "请输入佣金", trigger: "blur"},
                //     {validator: moneyValidator, trigger: "blur"},
                // ],
                payableBonus   : [
                    {required: true, message: "请输入金额", trigger: "blur"},
                    {validator: moneyValidator, trigger: "blur"},
                ],
                chanMoney    : [
                    {required: true, message: "请输入金额", trigger: "blur"},
                    {validator: moneyValidator, trigger: "blur"},
                ],
                victoryMoney    : [
                    {required: true, message: "请输入金额", trigger: "blur"},
                    {validator: moneyValidator, trigger: "blur"},
                ],
            },
            changeNo:0,
        }
    },

    computed: {
        buyName () {
            return this.item?.buyName || ""
        },
        productName () {
            return this.item?.productName || ""
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                for (let key in this.PB) {
                    switch (key) {
                        case "isCheck":
                            this.PB[key] = item[key]||0;
                            break;
                        case "remark":
                            this.PB[key] = item?.seasonRemark||"";
                            break;
                        case "seasonId":
                            this.PB[key] = item?.[key] || "";
                            break;
                        default:
                            this.PB[key] = item[key] == null ? "" : item[key]
                            break;
                    }
                }
                this.changeNo=0
                this.viewIsReady = true;
                this.getRatio()
            }
        },
        // (应发奖金金额-渠道费用) * 比例   (1500-500)/(3+5)*3
        'PB.payableBonus' (val, old) {
            this.changeNo++
            if(this.changeNo>2){
                this.PB.guaranteeMoney =( Number(this.PB.payableBonus) - Number(this.PB.chanMoney) ) / (Number(this.comPkRatio)+Number(this.comGuaranteeRatio)) * Number(this.comGuaranteeRatio)
    
                if(isNaN(this.PB.guaranteeMoney)){
                    this.PB.guaranteeMoney = ''
                }else{
                    this.PB.guaranteeMoney =  parseFloat(Math.round(this.PB.guaranteeMoney*100)/100)
                }

                if(this.PB.guaranteeMoney<0){
                    this.PB.guaranteeMoney =0
                }

    
                 this.PB.pkRatioMoney =( Number(this.PB.payableBonus) - Number(this.PB.chanMoney) ) / (Number(this.comPkRatio)+Number(this.comGuaranteeRatio)) * Number(this.comPkRatio)
                 if(isNaN(this.PB.pkRatioMoney)){
                    this.PB.pkRatioMoney = ''
                }else{
                    this.PB.pkRatioMoney =  parseFloat(Math.round(this.PB.pkRatioMoney*100)/100)
                }
                if(this.PB.pkRatioMoney<0){
                    this.PB.pkRatioMoney =0
                }

            }

        },
        'PB.chanMoney' (val, old) {
            this.changeNo++
            if(this.changeNo>2){
                this.PB.guaranteeMoney =( Number(this.PB.payableBonus) - Number(this.PB.chanMoney) ) / (Number(this.comPkRatio)+Number(this.comGuaranteeRatio)) * Number(this.comGuaranteeRatio)
                if(isNaN(this.PB.guaranteeMoney)){
                    this.PB.guaranteeMoney = ''
                }else{
                    this.PB.guaranteeMoney =  parseFloat(Math.round(this.PB.guaranteeMoney*100)/100)
                }
                if(this.PB.guaranteeMoney<0){
                    this.PB.guaranteeMoney =0
                }
               
    
                 this.PB.pkRatioMoney =( Number(this.PB.payableBonus) - Number(this.PB.chanMoney) ) / (Number(this.comPkRatio)+Number(this.comGuaranteeRatio)) * Number(this.comPkRatio)
                 if(isNaN(this.PB.pkRatioMoney)){
                    this.PB.pkRatioMoney = ''
                }else{
                    this.PB.pkRatioMoney =  parseFloat(Math.round(this.PB.pkRatioMoney*100)/100)
                }
                if(this.PB.pkRatioMoney<0){
                    this.PB.pkRatioMoney =0
                }
            }

        },
    },

    methods: {
        async getRatio() {
            if(this.PB.seasonId){
                const loadingRef = this.$loading({
                    target: ".check-season .el-dialog"
                });
                try {
                    if(this.PB.seasonId){
                        const res = await this.$axios({
                            url: "/api/order/queryPerformanceCalData",
                            method: "post",
                            data:{
                                seasonId:this.PB.seasonId,
                                orderId:this.item.id
                            }
                        });
            
                        if (res.code === 2000) {
                            this.comPkRatio = res.data.resultMap.comPkRatio
                            this.comGuaranteeRatio = res.data.resultMap.comGuaranteeRatio
                            loadingRef && loadingRef.close();
                        } else if (res.code !== 1003)
                            throw res
                    }
    
                }catch (reason){
                     reason && this.$message({
                        message: reason.msg || "操作失败",
                        type: "error"
                    });
                    console.warn(reason);
                    loadingRef && loadingRef.close()
                }
            }
        },
        
        async changeSeason() {
            if(this.PB.seasonId){
                const loadingRef = this.$loading({
                    target: "#myDiv"
                });
                try{
                     if(this.PB.seasonId){
                        const res = await this.$axios({
                            url: "/api/order/queryPerformanceCalData",
                            method: "post",
                            data:{
                                seasonId:this.PB.seasonId,
                                orderId:this.item.id
                            }
                        });
            
                        if (res.code === 2000) {
                            console.log(1);
                            this.PB.guaranteeMoney = res.data.resultMap.comGuaranteeMoney
                            this.PB.pkRatioMoney = res.data.resultMap.comPkRatioMoney
                            this.comPkRatio = res.data.resultMap.comPkRatio
                            this.comGuaranteeRatio = res.data.resultMap.comGuaranteeRatio
    
                            this.PB.payableBonus = res.data.resultMap.shouldMoney
                            this.PB.chanMoney = res.data.resultMap.chanMoney
                            loadingRef && loadingRef.close();
                        } else if (res.code !== 1003)
                            throw res
                    }
                }catch(reason){
                    reason && this.$message({
                        message: reason.msg || "操作失败",
                        type: "error"
                    });
                    this.PB.guaranteeMoney =''
                    this.PB.pkRatioMoney =''
                    this.comPkRatio =''
                    this.comGuaranteeRatio =''
                    this.PB.payableBonus =''
                    this.PB.chanMoney =''

                    console.warn(reason);
                    loadingRef && loadingRef.close()
                }
            }

           
        },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".check-season .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/order/checkOrderSeason",
                    method: "post",
                    data: {
                        ...this.PB,
                        orderId: this.item.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.isCheck      = 0;
            this.PB.seasonId     = "";
            this.PB.seasonProfit = "";
            this.PB.performanceNum = "";
            // this.PB.bonusMoney   = "";
            // this.PB.commMoney    = "";
            this.PB.payableBonus    = "";
            this.PB.chanMoney    = "";
            this.PB.remark       = "";
            this.comGuaranteeRatio       = "";
            this.comPkRatio       = "";

            this.PB.guaranteeMoney       = "";
            this.PB.pkRatioMoney       = "";
            this.PB.victoryMoney       = "";

            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
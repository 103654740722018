import {nanoid} from "nanoid";

export const dateTypeEnum = [
    { name: "认购日期",     value: 1, type: "date", format: "yyyy-MM-dd" },
    { name: "业绩核算月份", value: 2, type: "month", format: "yyyy-MM" },
    { name: "全款齐日期", value: 3, type: "date", format: "yyyy-MM-dd" },
]

// 函数创建一个空的菜单数据
export function initialPB () {
    return {
        nanoid: nanoid(),
        name: "",
        sysId: "",
        roleId: "",
        busiDir: "",
        order: 99,
        level: 99,
        content: "",
    };
}

export const stateEnum = [
    {
        name: "待中国区审核",
        value: 1
    },
    {
        name: "待澳洲区审核",
        value: 2
    },
    {
        name: "待薪酬审核",
        value: 4
    },
    {
        name: "审核通过",
        value: 3
    },
    {
        name: "审核不通过",
        value: 0
    },
    {
        name: "已撤销",
        value: 5
    }
]
// 表格项（行）的操作菜单
export const actionList = [
    {
        label: "撤销",
        name: "handleRevokeApply",
        banned: false
    },
    {
        label: "薪酬审核",
        name: "handleUpdateMoneyCheckState",
        banned: false
    },
    {
        label: "申请详情",
        name: "handleOrderCommRecord",
        banned: false
    },
    {
        label: "订单详情",
        name: "handleOrderDetail",
        banned: false
    },
    {
        label: "结佣",
        name: "handleCommissionDetail",
        banned: false
    },
];
import AddStaffItem from "./AddStaffItem2.vue";

export default {
    name: 'CommissionDetail',
    components: { AddStaffItem },
    data() {
        return {
            id: undefined,
            showAddWindow: false,
            tableData: [],
            reductionData: [],

            orderCommInfo: [],
            postEmpList: [],
            auth: {
                save: true,
                delete: true,
                saveOrderBonusReduction: true,
                batchDeleteBonusRecord: true,
            },
            viewIsReady: false,
            PB: {
                calTime: '',
                bonusType: '',
                postType: '',
                calScale: '',
                calMoney: '',
                remark: "",
                // commtime: "",
                // commmoney: "",
                // bonusType: '',
            },
            typeEnum: [],
            rules: {
                calTime: { required: true, message: "请选择奖金发放日期" },
                bonusType: { required: true, message: "请选择奖金类型" },
                postType: { required: true, message: "请选择奖金结算岗位" },
                calScale: {
                    validator: function (r, v, cb) {
                        const isFine = /^\d+(\.\d{1,2})?$/.test(v);
                        if (isFine) cb();
                        else cb("请输入正确的比例, 允许两位小数");
                    },
                },
                calMoney: [
                    { required: true, message: "请填写奖金金额" },
                    {
                        validator(r, val, c) {
                            if (typeof val === "number") c()
                            else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                                c("请输入数字, 最多包含两位小数")
                            }
                            else c()
                        }
                    }
                ]
                // bonusType: { required: true, message: "请选择奖金类型" },
                // commtime: { required: true, message: "请选择奖金发放日期" },
                // commmoney: [
                //     { required: true, message: "请填写奖金金额" },
                //     {
                //         validator(r, val, c) {
                //             if (typeof val === "number") c()
                //             else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                //                 c("请输入数字, 最多包含两位小数")
                //             }
                //             else c()
                //         }
                //     }
                // ]
            },
            orderPostListEnum: [],
            placeItem: '',
            clickrow: '',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            activeName: 'first',
            pageNum_re: 1,
            pageSize_re: 10,
            total_re: 0,
            addReDia: false,
            reData: {
                reductionTime: '',
                list: [],
                commmoney: '',
                remark: '',

            },
            reRule: {
                reductionTime: { required: true, message: "请选择扣回日期" },
                commmoney: {
                    validator: function (r, v, cb) {
                        const isFine = /^\d+(\.\d{1,2})?$/.test(v);
                        if (isFine) cb();
                        else cb("请输入正确的金额, 允许两位小数");
                    },
                },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },


            },


            multipleSelection: [],
            bonusInfo: {},//奖金信息


        }
    },

    created() {
        const id = Number(this.$route.params.id);
        if (id) {
            this.id = id;
            this.fetchData()
            // this.fetchCommList();

        }
        else { console.log("初始化失败, 没有orderid"); }

        // this.auth.save = this.$hasAuthFor("api/orderComm/saveOrderComm");
        this.auth.save = this.$hasAuthFor("api/order/saveBonusRecord");


        // this.auth.delete = this.$hasAuthFor("api/orderComm/deleteOrderComm");
        this.auth.delete = this.$hasAuthFor("api/order/deleteOrderBonusRecord");
        this.auth.saveOrderBonusReduction = this.$hasAuthFor("api/order/saveOrderBonusReduction");
        this.auth.batchDeleteBonusRecord = this.$hasAuthFor("api/order/batchDeleteBonusRecord");

        //

        // 

    },

    methods: {
        handleInput(value, data) {
            // console.log("change", value, data)
            if (!!value.trim() && !data.checked) {
                data.checked = true;
            }
        },
        // 获取添加扣回 — 员工回显
        async addOrderReduction() {
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/order/addOrderBonusReduction",
                    method: "post",
                    data: { orderId: this.id }
                });

                if (res.code === 2000) {
                    res.data.reductionEmpList.forEach(element => {
                        element.checked = false;
                        element.commmoney = element.calAllMoney;
                    });
                    this.reData.list = res.data.reductionEmpList;
                    this.addReDia = true;
                    loading.close();

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        // 添加扣回
        handleAddReButton() {
            this.addOrderReduction()
        },
        handleReClose() {
            this.reData.reductionTime = "";
            this.reData.list = [];
            this.reData.commmoney = "";
            this.reData.remark = "";
            this.addReDia = false;
            setTimeout(() => {
                this.$refs.reform.clearValidate();
            }, 20);
        },
        //添加 扣回奖金 保存
        async handleReSave() {
            // this.handleReClose()
            const loadingRef = this.$loading({
                target: ".add-re .el-dialog",
            });

            try {
                await this.$refs.reform.validate();
                const checkedList = this.reData.list.filter(
                    ({ checked }) => checked
                );
                if (!checkedList.length) {
                    loadingRef.close();
                    return this.$message({
                        message: "请勾选员工",
                        type: "error",
                    });
                }
                let savelist = []
                checkedList.forEach(element => {
                    savelist.push({
                        empId: element.empId,
                        reductionMoney: element.commmoney
                    })
                });

                const { reductionTime, remark } = this.reData;
                const res = await this.$axios({
                    url: "/api/order/saveOrderBonusReduction",
                    method: "post",
                    data: {
                        orderId: this.id,
                        reductionTime,
                        remark,
                        reductionEmpStr: JSON.stringify(savelist),
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.handleReClose();
                    this.fetchData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "操作失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }

        },

        // 批量删除
        async handleDelButton(item) {
            let loadingRef;
            if (this.multipleSelection.length <= 0) {
                return this.$message({
                    message: "请勾选操作数据",
                    type: "error",
                });
            } else {
                let ids = []
                this.multipleSelection.forEach(element => {
                    ids.push(element.id)
                });
                try {
                    await this.$confirm("确定要删除吗", "系统提示")
                    loadingRef = this.$loading();
                    const res = await this.$axios({
                        url: "/api/order/batchDeleteBonusRecord",
                        method: "post",
                        data: {
                            ids: ids.join()
                        }
                    })
                    if (res.code === 2000) {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        });
                        loadingRef && loadingRef.close();
                        this.fetchData();

                    } else if (res.code !== 1003)
                        throw res;

                } catch (reason) {
                    if (reason !== "cancel") {
                        this.$message({
                            message: reason.msg || "删除失败",
                            type: "warning"
                        });
                    }
                    loadingRef && loadingRef.close();
                }
            }
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchCommList();

        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchCommList();
        },
        // 翻页相关
        handlePageSizeChange_re(size) {
            this.pageSize_re = size;
            this.fetchReductionList();
        },
        // 翻页相关
        handlePageNoChange_re(no) {
            this.pageNum_re = no;
            this.fetchReductionList();
        },



        handleOrderDetail() {
            const url = new URL(location.href);
            url.hash = `/core/order/detail/${this.id}`
            window.open(url)
        },

        // 获取页面数据
        async fetchData() {
            const loading = this.$loading();

            try {
                const res = await this.$axios({
                    // url: "/api/orderComm/queryOrderCommInfo",
                    url: "/api/order/queryOrderBonusDetail",
                    // 
                    method: "post",
                    // data: { applyId: 114 || this.id }
                    data: { orderId: this.id }

                });

                if (res.code === 2000) {
                    // const {
                    //     orderCommInfo,   // 订单信息
                    //     postEmpList,     // 订单员工信息
                    // } = res.data;
                    // this.orderCommInfo = orderCommInfo;
                    // this.postEmpList = postEmpList;


                    this.orderCommInfo = res.data.detail
                    // this.orderCommInfo.guaranteeMoney = 2000

                    this.orderPostListEnum = res.data.OrderBonusPostEnums;


                    this.postEmpList = res.data.postEmpList;
                    this.typeEnum = res.data.OrderBonusTypeEnums || []
                    await this.fetchCommList();
                    await this.fetchReductionList();
                    await this.fetchOrderBonusInfo();
                    loading.close();

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        // 查询奖金信息
        async fetchOrderBonusInfo() {
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/order/queryOrderBonusInfo",
                    method: "post",
                    data: { orderId: this.id }
                });
                if (res.code === 2000) {
                    this.bonusInfo = res.data.bonusInfo || {}
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },

        async fetchCommList() {
            try {
                const res = await this.$axios({
                    method: "post",
                    // url: "/api/orderComm/queryOrderCommList",
                    url: "/api/order/queryOrderBonusRecordList",
                    data: {
                        orderId: this.id,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                    }
                })

                if (res.code === 2000) {
                    const list = res.data.data;
                    this.tableData = list;
                    this.total = res.data.count


                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
            }
        },
        async fetchReductionList() {
            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/queryOrderBonusReductionList",
                    data: {
                        orderId: this.id,
                        pageNum: this.pageNum_re,
                        pageSize: this.pageSize_re,
                    }
                })

                if (res.code === 2000) {
                    const list = res.data.list;
                    this.reductionData = list;
                    this.total_re = res.data.count

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
            }
        },

        // 添加奖金
        handleAddButton() {
            this.showAddWindow = true;
        },

        // 删除奖金
        async handleDelete(item) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/order/deleteOrderBonusRecord",
                    method: "post",
                    data: { id: item.id }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData()

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 删除扣回记录
        async handleDelete_re(item) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/order/deleteOrderReductionRecord",
                    method: "post",
                    data: { id: item.id }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },

        // 编辑
        handleEdit(row) {
            this.clickrow = row
            this.PB.calTime = this.clickrow.calTimeName
            this.PB.bonusType = this.clickrow.bonusType
            this.PB.postType = this.clickrow.postType
            this.PB.calScale = this.clickrow.calScale
            this.PB.calMoney = this.clickrow.calMoney
            this.PB.remark = this.clickrow.remark
            this.viewIsReady = true
        },
        handleClose() {
            this.resetData();
            this.viewIsReady = false

        },
        resetData() {
            // this.PB.commtime = "";
            // this.PB.bonusType = "";
            // this.PB.commmoney = "";
            // this.PB.remark = "";
            this.$refs.form.clearValidate();
        },
        async handleSave() {
            const loadingRef = this.$loading({
                target: ".settle-commission .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/order/updateOrderBonusRecord",
                    method: "post",
                    data: {
                        id: this.clickrow.id,
                        ...this.PB
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change");
                    this.fetchData()
                    this.handleClose();

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
    }
}
import BankAccoun from "./BankAccoun.vue";
import newDealInfoDialog from "../../../components/newDealInfoDialog";
import { orderFiles, tzfyzl } from "../orderEdit/config";
const formatNumber = function (val) {
    if ((typeof val) !== "number") return "";
    const _val = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return _val;
}

export default {
    name: 'OrderDetail',
    components: { BankAccoun, newDealInfoDialog },
    data () {
        return {
            id               : undefined,
            logAuth          : false,
            activeName       : "part1",
            orderMap: {
                newCustMap: {}
            },
            custInfoMap      : {},
            orderEmpInfo     : {},
            orderDiscountList: [],
            orderFileList    : orderFiles,
            tzfyzlFileList: tzfyzl,
            paymentList      : [],
            pageNum          : 1,
            pageSize         : 10,
            total            : 0,
            logs             : [],
            showTop: true,
            searchVal: '',
            orderHisTypeEnum: [],
            jjAuth: false,
            jyAuth: false,
            checkPayAuth:false,
            orderDetailChanCommList: [],
            orderCommBonusDetail: '',

            pageNum_jy: 1,
            pageSize_jy: 10,
            total_jy: 0,
            logs_jy: [],

            pageNum_jj: 1,
            pageSize_jj: 10,
            total_jj: 0,
            logs_jj: [],
            showdia: false,
            editingItem: {},
            contractMoney: '', //认购总金额
            payMoney: '',//回款金额
            residuePayMoney: '',//应收未收金额
            gainTimeDia: false,//确认到账日期弹窗
            gainTimedata: {
                gainTime: '',
                id: '',
            },
            gainTimeRules: {
                gainTime: { required: true, message: "请选择日期", trigger: "blur" }
            },
        }
    },

    computed: {
        totalPage () {
            return Math.ceil(this.total / this.pageSize);
        },
        // allFilesReady () {
        //     return this.orderFileList.every(it=>!!it.isall)
        // },
        oldProductMixText () {
            return this.orderMap?.oldOrderProductInfoList.map(item =>
                item.oldOrderProductInfo).filter(Boolean).join("； ")
        }
    },

    created () {
        const id = Number(this.$route.params.id);
        if (id) {
            this.id = id;
            this.fetchData();
            this.fetchFileList();
            this.fetchTranslateFileList();
        }
        else {console.log("初始化失败, 没有orderid");}

        this.logAuth = this.$hasAuthFor("api/order/queryOrderHis");

        this.jjAuth = this.$hasAuthFor("api/order/queryOrderCommBonusDetail");

        this.jyAuth = this.$hasAuthFor("api/orderChanComm/queryOrderDetailChanCommInfo");
        this.checkPayAuth = this.$hasAuthFor("api/order/payOrderExamine");
    },
    mounted() {
        // const scrollview = this.$refs['scrollview']
        var element = document.getElementById("order-detail")
        // 添加滚动监听，该滚动监听了拖拽滚动条
        // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
        element.addEventListener('scroll', this.scrollChange, true)
    },

    methods: {
        //确认到账日期 取消
        gainTimeClose() {
            this.$refs.gainTimeForm.clearValidate();
            this.gainTimeDia = false
        },
        // 保存确认到账日期
        async gainTimeSave() {
            let loadingRef = this.$loading({
                target: ".gainTimeDia .el-dialog"
            });
            try {
                await this.$refs.gainTimeForm.validate();

                const praData = { "id": this.gainTimedata.id, "examineStatus": 1, gainTime: this.gainTimedata.gainTime }
                console.log(praData)
                // loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/order/payOrderExamine",
                    method: "post",
                    data: praData
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "确认成功",
                        type: "success"
                    })
                    this.gainTimeDia = false
                    loadingRef && loadingRef.close();
                    this.queryOrderPayTotal()
                    this.fetchPayList()
                } else if (res.code !== 1003)
                    throw res;
            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        openshowdia() {
            this.showdia = true
        },
        closedia() {
            this.showdia = false
        },
        // 监听事件
        scrollChange() {
            console.log('滚动中')
        },


        // 置顶
        goTopClick() {
            var element = document.getElementById("order-detail")
            element.scrollIntoView({
                behavior: "smooth"
            })
        },

        // 获取页面数据
        async fetchData() {
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "/api/order/queryOrderDetails",
					method: "post",
					data: { orderId: this.id }
				});

				if (res.code === 2000) {
                    const {
                        orderMap,         // 订单信息
                        custInfoMap,
                        orderEmpInfo,     // 订单员工信息
                        // orderFileList,       // 订单资料
                    } = res.data;

                    this.orderEmpInfo = orderEmpInfo;
                    this.orderMap = this.formatOrderMap(orderMap);
                    this.custInfoMap = this.formatInvestorMap(custInfoMap);

                    // this.$set()

                    // if (orderFileList)
                    //     this.orderFileList = this.setOrderFiles(orderFileList);
                    await this.fetchDiscountList();
                    await this.fetchPayList();
                    await this.queryOrderPayTotal();

                    if (this.logAuth) await this.fetchLogs(true);

                    if (this.jjAuth) await this.queryOrderCommBonusDetail(true);

                    if (this.jyAuth) await this.fetchjyLogs(true);

                    await this.fetchCommList();
                    await this.fetchJJList();



					loading.close();

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
        },
        formatOrderMap(orderMap) {
            for (let key in orderMap) {
                const value = orderMap[key];
                if (value != null) {
                    switch (key) {
                        case "path":
                            if (value) orderMap[key] = 
                                value.split(",").map(path => ({
                                    name: path.slice(path.lastIndexOf("/")+1),
                                    url: this.$baseURL + "/" + path,
                                    path: path
                                }))
                            else orderMap[key] = [];
                            break;

                        case "isBuy":
                            orderMap[key] = !!value
                            break;

                        default:
                            break;
                    }
                }
            }
            return orderMap;
        },
        formatInvestorMap(custInfoMap) {
            for (let key in custInfoMap) {
                const value = custInfoMap[key];
                if (value != null) {
                    switch (key) {
                        case "buyPath":
                        case "idCardPath":
                        case "moneyPath":
                        case "imagePath1":
                        case "imagePath2":
                            if (value) custInfoMap[key] = 
                                value.split(",").map(path => ({
                                    name: path.slice(path.lastIndexOf("/")+1),
                                    url: this.$baseURL + "/" + path,
                                    path: path
                                }))
                            else custInfoMap[key] = [];
                            break;

                        default:
                            break;
                    }
                }
            }
            console.log(custInfoMap);
            return custInfoMap;
        },
        async fetchTranslateFileList() {
            try {
                const res = await this.$axios({
                    url: "/api/orderProcess/queryTranslateFileList",
                    method: "post",
                    data: { orderId: this.id }
                });

                if (res.code !== 2000 && res.code !== 1003) throw res;

                const { orderTranslateFileList } = res.data;
                orderTranslateFileList.forEach(item => {
                    const files = this.tzfyzlFileList.find(({ type }) => type == item.type);
                    files.id = item.id;
                    files.checkstate = item.checkstate;
                    files.checkstateTemp = item.checkstate;
                    if (item.file) {
                        files.path = item.file.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/") + 1),
                            url: this.$baseURL + "/" + path,
                            path: path,
                        }))
                    } else {
                        files.path = []
                    }
                });

            } catch (reason) {
                console.warn("获取投资翻译文件资料失败", reason);
                this.$message({
                    message: reason.msg || "获取投资翻译文件资料失败",
                    type: "warning"
                });
            }
        },

        async fetchFileList () {
            try {
                const res = await this.$axios({
                    url: "/api/orderProcess/queryInvestFileList",
                    method: "post",
                    data: { orderId: this.id }
                });

                if (res.code !== 2000 && res.code !== 1003) throw res;

                const { orderInvestFileList } = res.data;
                orderInvestFileList.forEach(item => {
                    const files = this.orderFileList.find(({type}) => type == item.type);
                    files.id             = item.id;
                    files.checkstate     = item.checkstate;
                    files.checkstateTemp = item.checkstate;
                    if (item.file) {
                        files.path = item.file.split(",").map(path => ({
                            name: path.slice(path.lastIndexOf("/") + 1),
                            url: this.$baseURL + "/" + path,
                            path: path,
                        })) 
                    } else {
                        files.path = []
                    }
                });

            } catch (reason) {
                console.warn("获取投资资料失败", reason);
                this.$message({
                    message: reason.msg || "获取投资资料失败",
                    type: "warning"
                });	
            }
        },
        // setOrderFiles (orderFileList) {
        //     orderFileList.forEach(it => {
        //         if (it.path) it.path = it.path.split(",").map(path => ({
        //             name: path.slice(path.lastIndexOf("/")+1),
        //             url: this.$baseURL + "/" + path,
        //             path: path
        //         }))
        //         else it.path = [];
        //     });
        //     return orderFileList;
        // },
        
        async fetchDiscountList() {
            try {
				const res = await this.$axios({
					url: "/api/order/queryOrderDiscountList",
					method: "post",
					data: {orderId: this.id}
				});

				if (res.code === 2000) {
                    this.orderDiscountList.splice(0,
                        this.orderDiscountList.length,
                        ...res.data.orderDiscountList.map(item => {
                            if (item.path) item.path =
                                item.path.split(",").filter(Boolean).map(path => {
                                    return {
                                        name: path.slice(path.lastIndexOf("/")+1),
                                        path: path,
                                        url: this.$baseURL + "/" + path,
                                    }
                                })
                            return item;
                        }))

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
				console.warn("获取优惠列表失败", reason);
			}
        },
        async fetchPayList () {
            const loadingRef = this.$loading();

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/queryOrderPayList",
                    data: {orderId: this.id}
                })

                if (res.code === 2000) {
                    const list = res.data.orderPayList.map(item => {
                        item.createTimeArr = item.createTimeName.split(
                            " ").filter(Boolean);
                        if (item.path) item.path =
                            item.path.split(",").filter(Boolean).map(
                                path => {
                                    return {
                                        name: path.slice(path.lastIndexOf("/")+1),
                                        path: path,
                                        url: this.$baseURL + "/" + path,
                                    }
                                })
                        return item;
                    });
                    this.paymentList.splice(0, this.paymentList.length, ...list) 
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
                loadingRef && loadingRef.close()
            }
        },
        async queryOrderPayTotal() {
            const loadingRef = this.$loading();
            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/queryOrderPayTotal",
                    data: { orderId: this.id }
                })

                if (res.code === 2000) {
                    this.contractMoney = formatNumber(res.data.payMap.contractMoney)
                    this.payMoney = formatNumber(res.data.payMap.payMoney)
                    this.residuePayMoney = formatNumber(res.data.payMap.residuePayMoney)
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取失败",
                    type: "error"
                });
                loadingRef && loadingRef.close()
            }
        },


        handleClear() {
            this.searchVal = ''
            this.fetchLogs()
        },

        async fetchLogs(noLoading=false) {
            let loading; 
            if (noLoading !== true) loading = this.$loading();

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/queryOrderHis",
                    data: {
                        orderId : this.id,
                        pageNum : this.pageNum,
                        pageSize: this.pageSize,
                        searchVal: this.searchVal
                    }
                })
                if (res.code === 2000) {
                    this.logs  = res.data.orderHisList;
                    this.total = res.data.count;
                    this.orderHisTypeEnum = res.data.orderHisTypeEnum
    
                    loading?.close();
    
                } else if (res.code !== 1003)
                    throw res
                
            } catch (reason) {
                this.$message({
					message: reason.msg || "获取日志失败",
					type: "warning"
				});
                loading?.close()
            }
        },
        async queryOrderCommBonusDetail(noLoading = false) {
            let loading;
            if (noLoading !== true) loading = this.$loading();

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/queryOrderCommBonusDetail",
                    data: {
                        orderId: this.id,
                        // pageNum: this.pageNum,
                        // pageSize: this.pageSize,
                        // searchVal: this.searchVal
                    }
                })
                if (res.code === 2000) {
                    this.orderCommBonusDetail = res.data.orderMap
                    // this.logs = res.data.orderHisList;
                    // this.total = res.data.count;
                    // this.orderHisTypeEnum = res.data.orderHisTypeEnum

                    loading?.close();

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                this.$message({
                    message: reason.msg || "获取日志失败",
                    type: "warning"
                });
                loading?.close()
            }
        },
        // 渠道商结佣记录
        async fetchjyLogs(noLoading = false) {
            let loading;
            if (noLoading !== true) loading = this.$loading();

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/orderChanComm/queryOrderDetailChanCommInfo",
                    data: {
                        orderId: this.id,
                        // pageNum: this.pageNum,
                        // pageSize: this.pageSize,
                        // searchVal: this.searchVal
                    }
                })
                if (res.code === 2000) {
                    // res.data.orderDetailChanCommList = [
                    //     {
                    //         "isInvoicingName": "否",
                    //         "commMoney": 202.0,
                    //         "remark": "",
                    //         "id": 39,
                    //         "createTimeName": "2023-12-11",
                    //         "commChanName": "广州丽丽",
                    //         "commTimeName": "2023-12-10",
                    //         "channelCode": "CH-CRM219",
                    //         "isInvoicing": 0
                    //     }
                    // ]
                    this.orderDetailChanCommList = res.data.orderDetailChanCommList;
                    // this.total = res.data.count;
                    // this.orderHisTypeEnum = res.data.orderHisTypeEnum
                    loading?.close();

                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                this.$message({
                    message: reason.msg || "获取日志失败",
                    type: "warning"
                });
                loading?.close()
            }
        },
        // 结佣记录
        async fetchCommList() {
            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/orderComm/queryOrderCommList",
                    data: {
                        orderId: this.id,
                        pageNum: this.pageNum_jy,
                        pageSize: this.pageSize_jy,
                    }
                })

                if (res.code === 2000) {
                    const list = res.data.orderCommList;
                    this.logs_jy = list;
                    this.total_jy = res.data.count

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                console.log(reason);
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
            }
        },
        async fetchJJList() {
            try {
                const res = await this.$axios({
                    method: "post",
                    // url: "/api/orderComm/queryOrderCommList",
                    url: "/api/order/queryOrderBonusRecordList",
                    data: {
                        orderId: this.id,
                        pageNum: this.pageNum_jj,
                        pageSize: this.pageSize_jj,
                    }
                })

                if (res.code === 2000) {
                    const list = res.data.data;
                    this.logs_jj = list;
                    this.total_jj = res.data.count


                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
            }
        },

        async handleUpdateState(data) {
            console.log(data)
            const examine_status= data.examine_status
            let loadingRef;
            if(examine_status==1){
                await this.$confirm("您确定该笔回款取消审核吗？", "系统提示")
                const praData={"id":data.id,"examineStatus":0}
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/order/payOrderExamine",
                    method: "post",
					data:praData
                })

				if (res.code === 2000) {
					this.$message({
                        message: "取消审核成功",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
                    this.fetchPayList()
                    this.queryOrderPayTotal()
				} else if (res.code !== 1003) 
					throw res;
             
            }else{
                this.gainTimedata.gainTime = ''
                this.gainTimedata.id = data.id
                this.gainTimeDia = true
            }
        },

        // 分页组件发生 每页数据条数 改变事件
        handlePageSizeChange(e) {
            this.pageSize = e;
            this.pageNum = 1; this.gainTimedata.gainTime
            this.fetchLogs();
        },

        // 分页组件发生 当前页 改变事件
        handlePageNoChange(e) {
            this.pageNum = e;
            this.fetchLogs();
        },

        // 分页组件发生 每页数据条数 改变事件
        handlePageSizeChange_jy(e) {
            this.pageSize_jy = e;
            this.pageNum_jy = 1;
            this.fetchCommList();
        },

        // 分页组件发生 当前页 改变事件
        handlePageNoChange_jy(e) {
            this.pageNum_jy = e;
            this.fetchCommList();
        },

        // 分页组件发生 每页数据条数 改变事件
        handlePageSizeChange_jj(e) {
            this.pageSize_jj = e;
            this.pageNum_jj = 1;
            this.fetchJJList();
        },

        // 分页组件发生 当前页 改变事件
        handlePageNoChange_jj(e) {
            this.pageNum_jj = e;
            this.fetchJJList();
        },




        handleNavigateBack () {
            this.$router.back()
        }
    }
}
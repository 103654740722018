<!-- 变更订单状态 -->
<template>
    <el-dialog class="update-state"
        :visible="viewIsReady"
        @close="handleClose"
        title="变更订单状态"
        width="650px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="120px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >

            <el-form-item label="订单状态" prop="state">
                <el-radio-group
                @change="changeradio"
                    class="state-radio-group"
                    v-model="PB.state">
                    <el-radio
                        v-for="s in filterOrderStateEnum"
                        :key="s.value"
                        :label="s.value"
                    >{{ s.name }}</el-radio>
                </el-radio-group>
            </el-form-item>

            <!-- <el-form-item v-if="PB.state == 4" label="状态类型" prop="addState">
                <el-radio-group
                    class="state-radio-group"
                    v-model="PB.addState">
                    <el-radio
                        v-for="s in OrderAddStateEnum"
                        :key="s.value"
                        :label="s.value"
                    >{{ s.name }}</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="原始投资产品"
                prop="oldProductId"
                v-if="PB.state>=2 && PB.state<=4"
                class="wrap"
            >
                <el-button @click="handleOldProductTextFocus"
                    class="old-trigger-btn"
                    >选择产品</el-button>
                <ul class="old-product-list width3">
                    <li v-for="txt in oldProductText"
                        :key="txt">{{ txt }}</li>
                </ul>
                <OldProductPicker
                    :list="oldProductList"
                    :show.sync="showTablePicker"
                    v-model="PB.oldProductId"
                ></OldProductPicker>
            </el-form-item> -->
             <el-form-item  prop="redeemMoney"   v-if="PB.state==3" label="赎回金额" :rules="PB.state==3 ? rules.redeemMoney : rules.none"  >
                <el-input v-model="PB.redeemMoney"
                    maxlength="11" class="width2">
                </el-input>
            </el-form-item>

           



            <el-form-item :label="stateName+'日期'"
                prop="outTime"
                v-if="PB.state>=2 && PB.state<=4">
                <el-date-picker
                    v-model="PB.outTime"
                    class="width3"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    maxlength="100"
                    type="textarea" rows="5"
                    class="width3"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
// import OldProductPicker from "./OldProductPicker.vue"
export default {
    name: 'UpdateState',
    props: [
        "item",
        "OrderStateEnum",
        "OrderAddStateEnum"
    ],
    
    // components: { OldProductPicker },

    data () {
        return {
            viewIsReady: false,
            PB: {
                state       : "",
                addState    : "",
                oldProductId: [],
                outTime     : "",
                remark      : "",
                redeemMoney:'',
            },
            rules: {
                state        : {required: true, message: "请选择状态", trigger: "blur"},
                addState     : {required: true, message: "请选择状态类型", trigger: "blur"},
                oldProductId : {required: true, message: "请选择原始投资产品" },
                // outTime      : {required: true, message: "请选择日期", trigger: "blur"},
                outTime: [
                    // { required: true, message: "请选择日期",trigger: "blur" },
                    {required: true, validator: this.outTimeValidator, trigger: "blur" }
                ],
             
                remark       : {required: true, message: "请输入备注信息", trigger: "blur"},
                redeemMoney: [
                    { required: true, message: "请输入赎回金额",trigger: "blur" },
                    { validator: this.biliValidator, trigger: "blur" }
                ],
                
                // redeemMoney: [
                //     { required: true, message: "请填写赎回金额" },
                //     {
                //         validator(r, val, c) {
                //             if (typeof val === "number") c()
                //             else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                //                 c("请输入数字, 最多包含两位小数")
                //             }
                //             else c()
                //         }
                //     }
                // ],
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },
            },
            oldProductList : [],
            showTablePicker: false,
            ljshje:'', //累积赎回金额
            contractMoney:'',
        }
    },

    computed: {
        stateName () {
            const {state} = this.PB,
                found = this.OrderStateEnum.find(
                    item => item.value === state);

            return found?.name || ""
        },
        oldProductText () {
            // const {oldProductId: value} = this.PB;
            return this.PB.oldProductId.map(oid => {
                const item = this.oldProductList.find(({id}) => id == oid);
                const strArr = [item.orderNo, item.custName, item.productName, item.finishTime].filter(Boolean)
                return strArr.join("-") || ""
            })
        },
        filterOrderStateEnum () {
            return this.OrderStateEnum?.filter(item => (item.value != 5 && item.value != 0))
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.viewIsReady = true;
                this.fetchData();
                this.querySumRedeemMoney();
                this.PB.state        = item.state        || "";
                this.PB.addState     = item.addState     || "";
                this.PB.outTime      = item.outTimeName  || "";
                this.PB.remark       = item.stateRemark  || "";
                this.PB.redeemMoney       = item.contractMoney  || "";
                this.contractMoney = item.contractMoney  || ""
                // this.ljshje= item.contractMoney||100

            }
        },
    },

    methods: {
        changeradio(){
            this.$refs.form.clearValidate();
        },
        biliValidator(r, val, cb) {
            // console.log(this.oninputindex);
            // console.log(this.PB.list[this.oninputindex].oldbili);
            // console.log(Number(val));
            // console.log(this.PB.list[this.oninputindex].oldbili> Number(val));
            console.log((Number(val)*100 + Number(this.ljshje)*100)/100);
            console.log(this.contractMoney);


             const isFine = /^\d+(\.\d{1,2})?$/.test(val);
                if (isFine){
                    if((Number(val)*100 + Number(this.ljshje)*100)/100   >  Number(this.contractMoney ) ){
                        cb(`所有的累计赎回金额不能大于认购金额${this.contractMoney}`)
                    }else{
                        cb();
                    }
                } 
                else cb("请输入正确的金额, 允许两位小数");
        },
        outTimeValidator(r, val, cb){
            console.log(val);
            if(!val){
                if(this.PB.state==2){
                    cb("请选择转出日期");
                }
                if(this.PB.state==3){
                    cb("请选择赎回日期");
                }
                if(this.PB.state==4){
                    cb("请选择退订日期");
                }

            }else{
                cb();
            }
            

        },
        async querySumRedeemMoney () {
            const loadingRef = this.$loading({
                target: ".update-state .el-dialog"
            });

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/querySumRedeemMoney",
                    data: {orderId: this.item.id}
                })

                if (res.code === 2000) {
                    this.ljshje= res.data.redeemMoney||0
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        async fetchData () {
            const loadingRef = this.$loading({
                target: ".update-state .el-dialog"
            });

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/queryOldProduct",
                    data: {orderId: this.item.id}
                })

                if (res.code === 2000) {
                    const {oldProductList} = res.data;
                    this.oldProductList = oldProductList;
                    const {oldProductId} = this.item;
                    if (oldProductId) { //  && oldProductList.some(it=>it.id==oldProductId)
                        this.PB.oldProductId = typeof oldProductId === "object" ?
                            oldProductId : [oldProductId];
                    }

                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleOldProductTextFocus () {
            this.showTablePicker = true;
        },

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".product-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();

                let redeemMoney = ''
                if(this.PB.state==3){
                    redeemMoney = this.PB.redeemMoney
                }
                const res = await this.$axios({
                    url: "/api/order/updateOrderState",
                    method: "post",
                    data: {
                        orderId      : this.item.id,
                        state        : this.PB.state,
                        addState     : this.PB.addState,
                        oldOrderIds  : this.PB.oldProductId.join(),
                        outTime      : this.PB.outTime,
                        remark       : this.PB.remark,
                        redeemMoney,
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.oldProductList  = [];
            this.PB.state        = "";
            this.PB.addState     = "";
            this.PB.oldProductId = [];
            this.PB.outTime      = "";
            this.PB.remark       = "";
            this.PB.redeemMoney       = "";
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }

    .old-trigger-btn {
        margin-right: 50%;
    }

    .old-product-list {
        &:empty {
            display: none;
        }
        margin-top: 6px;
        padding: 14px;
        background-color: rgba($color: #000000, $alpha: .05);
        border: 1px solid rgba($color: #000000, $alpha: .05);

        // li + li {
        //     margin-top: 10px;
        // }
    }
</style>
<!-- 添加结佣人员 -->
<template>
    <el-dialog class="add-staff-item" :visible="show" @close="handleClose" title="结佣详情" width="900px">
        <el-form class="private" :model="PB" :rules="rules" label-width="130px" label-suffix=":" ref="form" @submit.prevent="handleSave">
            <div class="flex">
                <el-form-item label="结佣日期" prop="commTime">
                    <el-date-picker class="width2" v-model="PB.commTime" value-format="yyyy-MM-dd" placeholder="请选择"></el-date-picker>
                </el-form-item>
                <el-form-item label="汇率" prop="rateBili" style="position: relative;" class="ratebili">
                    <div style="position: absolute;left: 0px;top: 24px;font-size: 12px; color: red;">澳币--人民币</div>
                    <el-input v-model="PB.rateBili" placeholder="请输入汇率">
                    </el-input>

                </el-form-item>
            </div>
            <el-form-item label="类型" prop="checkState" v-if="orderCommInfo.isAddCalculate==1 && orderCommInfo.addCalculateSourceMoney!=0 && orderCommInfo.addCalculateMoney!=0" >
                <el-radio-group v-model="PB.checkState">
                    <el-radio v-show="orderCommInfo.addCalculateMoney!=0" :label="1">增投：{{orderCommInfo.addCalculateMoney}}</el-radio>
                    <el-radio v-show="orderCommInfo.addCalculateSourceMoney!=0"  :label="0">续签/转签：{{orderCommInfo.addCalculateSourceMoney}}</el-radio>
                </el-radio-group>
            </el-form-item>


            <el-form-item label="选择结佣员工" required>
                <el-button v-if="true || auth.save" type="text" @click.stop="handleAdd()">添加员工</el-button>
            </el-form-item>

            <el-table :data="PB.list" class="table">
                <el-table-column label="员工">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checked" class="checkbox" style="display: flex;align-items: center;">
                            <div style="white-space: initial;">
                                {{ scope.row.empName?scope.row.empName+' - ':'' }}{{ scope.row.postName }}
                            </div>
                        </el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="岗位比例" width="100">
                    <template slot-scope="scope">
                        <div>{{scope.row.gangweibili}}<span slot="append">%</span></div>
                    </template>
                </el-table-column>


                <el-table-column label="结佣比例">
                    <template slot-scope="scope">
                        <el-form-item :prop="'list['+scope.$index+'].bili'" :rules="scope.row.checked ? rules.bili : rules.none" label-width="0" class="width1haa">
                            <el-input v-model="scope.row.bili" @input="handleInput2($event, scope.row,scope.$index)" @change.once="handleInput($event, scope.row)" placeholder="请输入结佣比例">
                                <span slot="append">%</span>
                            </el-input>
                        </el-form-item>
                    </template>
                </el-table-column>

                <el-table-column label="结佣金额">
                    <template slot-scope="scope">
                        <el-form-item :prop="'list['+scope.$index+'].commmoney'" :rules="scope.row.checked ? rules.commmoney : rules.none" label-width="0" class="width1haa">
                            <el-input v-model="scope.row.commmoney" @change.once="handleInput($event, scope.row)" placeholder="请输入结佣金额">
                                <span slot="append">元</span>
                            </el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>

            <el-form-item label="备注" prop="remark">
                <el-input maxlength="200" v-model="PB.remark" type="textarea" class="width5"></el-input>
            </el-form-item>
        </el-form>

        <el-dialog :append-to-body='true' class="settle-commission" :visible="showfull" @close="showfull=false" title="提示" width="500px">
            <div>
                <span v-for="(item,index) in fullarr" :key="index">{{item.name}} {{index==fullarr.length-1?'':','}} </span>
                结佣比例已超过标准，确定要添加吗？
            </div>

            <div slot="footer">
                <el-button @click="showfull=false">取消</el-button>
                <el-button @click="fullhandleSave" type="primary">确定</el-button>
            </div>
        </el-dialog>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleBefore" type="primary">保存</el-button>
        </div>
        <!-- 添加员工 -->

        <el-dialog :append-to-body='true' class="add-emp" :visible="showaddemp" @close="closeAdd" title="添加员工" width="500px">
            <div>
                <el-form class="private" :model="addEmpObj" :rules="addrules" label-width="130px" label-suffix=":" ref="addempform" @submit.prevent="sureAdd">
                    <el-form-item label="员工姓名" prop="empId" >
                        <el-autocomplete
                            placeholder="请输入查询"
                            v-model="addEmpObj.empName"
                            :fetch-suggestions="querySearch"
                            @select="handleSelect"
                            class="width2"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item label="结佣岗位" prop="postId" >
                       <el-select v-model="addEmpObj.postId" class="width2" clearable @change="choosepost">
                        <el-option v-for="p in orderPostListEnum" :key="p.value" :label="p.name" :value="p.value"></el-option>
                    </el-select>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer">
                <el-button @click="closeAdd">取消</el-button>
                <el-button @click="sureAdd" type="primary">添加</el-button>
            </div>
        </el-dialog>

    </el-dialog>

</template>

<script>
const Big = require('big.js');
export default {
    name: "AddStaffItem",
    props: ["show", "empList", "orderId", "orderCommInfo",'orderPostListEnum'],

    data() {
        return {
            viewIsReady: false,
            PB: {
                rateBili: "",
                commTime: "",
                list: [],
                remark: "",
                checkState:'',
            },
            
            rules: {
                commTime: { required: true, message: "请选择结佣日期" },
                rateBili: [
                    { required: true, message: "请填写汇率" },
                    {
                        validator(r, val, c) {
                            if (typeof val === "number") c();
                            else if (!/^\d{1,11}(\.\d{1,4})?$/.test(val)) {
                                c("请输入正确的汇率, 最多包含四位小数");
                            } else c();
                        },
                    },
                ],

                // bili: {
                //     validator: function (r, v, cb) {
                //         const isFine = /^\d+(\.\d{1,2})?$/.test(v);
                //         if (isFine) cb();
                //         else cb("请输入正确的比例, 允许两位小数");
                //     },
                // },
                
                checkState: [
                    { required: true, message: "请选择类型",trigger:['change', 'blur']  },
                ],

                bili: [
                    // { required: true, message: "请选择第三方渠道" },
                    { validator: this.biliValidator, trigger: "blur" },
                ],

                commmoney: {
                    validator: function (r, v, cb) {
                        const isFine = /^\d+(\.\d{1,2})?$/.test(v);
                        if (isFine) cb();
                        else cb("请输入正确的金额, 允许两位小数");
                    },
                },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },
            },
            addEmpObj:{
                empName:'',
                empId:'',
                postName:'',
                postId:'',
            },
            addrules: {
                postId: { required: true, message: "请选择结佣岗位",trigger:['change', 'blur'] },
                none: {
                    validator: function (r, v, cb) {
                        cb();
                    },
                },
            },
            

            oninputindex: "",
            fullarr: [],
            showfull: false,
            showaddemp: false,
            cloleList:[],
        };
    },

    watch: {
        async show(show) {
            if (show) {
                this.PB.list = JSON.parse(JSON.stringify(this.empList)).map(
                    (item) => {
                        item.checked = false;
                        item.gangweibili = item.bili || 0;
                        item.bili = item.realBili || 0;
                        item.oldbili = item.realBili || 0;
                        item.commmoney = "";
                        return item;
                    }
                );
                this.cloleList = JSON.parse(JSON.stringify(this.empList)).map(
                    (item) => {
                        item.checked = false;
                        item.gangweibili = item.bili || 0;
                        item.bili = item.realBili || 0;
                        item.oldbili = item.realBili || 0;
                        item.commmoney = "";
                        return item;
                    }
                );
                if(this.orderCommInfo.isAddCalculate==1){
                    if(this.orderCommInfo.addCalculateMoney==0){
                        this.PB.checkState=0
                    }
                    if(this.orderCommInfo.addCalculateSourceMoney==0){
                        this.PB.checkState=1
                    }
                }

                await this.$nextTick();
                this.$refs.form.clearValidate();
            }
        },
        "PB.rateBili"(val) {
            console.log(1111);
            if (!isNaN(Number(val))) {
                if(this.orderCommInfo.isAddCalculate==1){
                    if(this.PB.checkState==1){
                        this.PB.list.forEach((element) => {
                            // 除法
                                const bili = Big(element.bili).div(100).toString();
                                console.log('bili',bili);
                                // 乘法
                                const resultSubtraction = Big(this.orderCommInfo.addCalculateMoney).times(bili);
                                console.log('resultSubtraction',resultSubtraction);
                                // 四舍五入并保留两位小数
                                const roundedResult = resultSubtraction.toFixed(2);
                                console.log('roundedResult',roundedResult);
                                // 乘法
                                const resultSubtraction2 = Big(roundedResult).times(val);
                                // 四舍五入并保留两位小数
                                const roundedResult2 = resultSubtraction2.toFixed(2);
                                console.log('roundedResult2',roundedResult2);
                                element.commmoney = Number(roundedResult2)
                                console.log('element.commmoney',element.commmoney);

                           

                            // element.commmoney=Math.round(Number(Math.round(this.orderCommInfo.addCalculateMoney*element.bili)/100)*val*100)/100


                            

                        });
                    }
                    if(this.PB.checkState===0){
                        this.PB.list.forEach((element) => {
                            // element.commmoney=Math.round(Number( Math.round(this.orderCommInfo.addCalculateSourceMoney*element.bili)/100)*val*100)/100

                            // 除法
                            const bili = Big(element.bili).div(100).toString();
                            console.log('bili',bili);
                            // 乘法
                            const resultSubtraction = Big(this.orderCommInfo.addCalculateSourceMoney).times(bili);
                            console.log('resultSubtraction',resultSubtraction);
                            // 四舍五入并保留两位小数
                            const roundedResult = resultSubtraction.toFixed(2);
                            console.log('roundedResult',roundedResult);
                            // 乘法
                            const resultSubtraction2 = Big(roundedResult).times(val);
                            // 四舍五入并保留两位小数
                            const roundedResult2 = resultSubtraction2.toFixed(2);
                            console.log('roundedResult2',roundedResult2);
                            element.commmoney = Number(roundedResult2)
                            console.log('element.commmoney',element.commmoney);


                            
                        });
                    }
                }else{
                    this.PB.list.forEach((element) => {
                        // 除法
                        const bili = Big(element.bili).div(100).toString();
                        console.log('bili',bili);
                        // 乘法
                        const resultSubtraction = Big(this.orderCommInfo.contractMoney).times(bili);
                        console.log('resultSubtraction',resultSubtraction);
                        // 四舍五入并保留两位小数
                        const roundedResult = resultSubtraction.toFixed(2);
                        console.log('roundedResult',roundedResult);
                        // 乘法
                        const resultSubtraction2 = Big(roundedResult).times(val);
                        // 四舍五入并保留两位小数
                        const roundedResult2 = resultSubtraction2.toFixed(2);
                        console.log('roundedResult2',roundedResult2);
                        element.commmoney = Number(roundedResult2)
                        console.log('element.commmoney',element.commmoney);
                    });
                }
            }
        },
        "PB.checkState"(val) {
            console.log(this.PB.rateBili);
            if (!isNaN(Number(this.PB.rateBili))) {
                if(this.orderCommInfo.isAddCalculate==1){
                    if(val==1){
                        if(this.orderCommInfo.addCalculateMoney<200000){
                            this.PB.list.forEach((element,index) => {
                                if(index>=this.cloleList.length){
                                    //  element.bili = 0
                                }else{
                                    element.bili = Math.round(Number(this.cloleList[index].bili)*this.orderCommInfo.addCalculateBili/100*1000000)/1000000;
                                }
                            });
                        }else{
                            this.PB.list.forEach((element,index) => {
                                if(index>=this.cloleList.length){
                                    //  element.bili = 0
                                }else{
                                    element.bili = this.cloleList[index].bili ;
                                }
                            });

                        }
                        this.PB.list.forEach((element) => {


                            // // 除法
                            const bili = Big(element.bili).div(100).toString();
                            console.log('bili',bili);
                            // 乘法
                            const resultSubtraction = Big(this.orderCommInfo.addCalculateMoney).times(bili);
                            console.log('resultSubtraction',resultSubtraction);
                            // 四舍五入并保留两位小数
                            let roundedResult = Number(resultSubtraction.toFixed(2)); 
                            console.log('roundedResult',roundedResult);
                            // 乘法
                            console.log('roundedResult',roundedResult,this.PB.rateBili);

                            const resultSubtraction2 = Big(roundedResult).times(this.PB.rateBili===''?0:this.PB.rateBili);
                            // 四舍五入并保留两位小数
                            const roundedResult2 = resultSubtraction2.toFixed(2);
                            console.log('roundedResult2',roundedResult2);
                            element.commmoney = Number(roundedResult2)
                            console.log('element.commmoney',element.commmoney);

                            //  element.commmoney = Math.round( Number(Math.round(this.orderCommInfo.addCalculateMoney*element.bili)/100) *this.PB.rateBili*100)/100
                        });
                    }
                    if(val===0){
                        this.PB.list.forEach((element,index) => {
                            if(index>=this.cloleList.length){
                                //  element.bili = 0
                            }else{
                                element.bili = Math.round(Number(this.cloleList[index].bili)*this.orderCommInfo.addCalculateBili/100*1000000)/1000000 ;
                                console.log(element.bili);
                            }
                        });
                        this.PB.list.forEach((element) => {
                            //  element.commmoney =Math.round( Number(Math.round(this.orderCommInfo.addCalculateSourceMoney*element.bili)/100) *this.PB.rateBili*100)/100
                            

                            // // 除法
                            const bili = Big(element.bili).div(100).toString();
                            console.log('bili',bili);
                            // 乘法
                            const resultSubtraction = Big(this.orderCommInfo.addCalculateSourceMoney).times(bili);
                            console.log('resultSubtraction',resultSubtraction);
                            // 四舍五入并保留两位小数
                            const roundedResult = resultSubtraction.toFixed(2);
                            console.log('roundedResult',roundedResult);
                            // 乘法
                            const resultSubtraction2 = Big(roundedResult).times(this.PB.rateBili===''?0:this.PB.rateBili);
                            // 四舍五入并保留两位小数
                            const roundedResult2 = resultSubtraction2.toFixed(2);
                            console.log('roundedResult2',roundedResult2);
                            element.commmoney = Number(roundedResult2)
                            console.log('element.commmoney',element.commmoney);

                        });
                    }
                }else{
                    this.PB.list.forEach((element) => {

                        // // 除法
                        const bili = Big(element.bili).div(100).toString();
                        console.log('bili',bili);
                        // 乘法
                        const resultSubtraction = Big(this.orderCommInfo.contractMoney).times(bili);
                        console.log('resultSubtraction',resultSubtraction);
                        // 四舍五入并保留两位小数
                        const roundedResult = resultSubtraction.toFixed(2);
                        console.log('roundedResult',roundedResult);
                        // 乘法
                        const resultSubtraction2 = Big(roundedResult).times(this.PB.rateBili===''?0:this.PB.rateBili);
                        // 四舍五入并保留两位小数
                        const roundedResult2 = resultSubtraction2.toFixed(2);
                        console.log('roundedResult2',roundedResult2);
                        element.commmoney = Number(roundedResult2)
                        console.log('element.commmoney',element.commmoney);


                        // element.commmoney =Math.round( Number(Math.round(this.orderCommInfo.contractMoney*element.bili)/100) *this.PB.rateBili*100)/100
                        
                    });
                }
            }
        },
        "addEmpObj.empName"(val) {
            if (val=='') {
                this.addEmpObj.empId=''
            }
        },
    },

    methods: {
        handleAdd() {
            this.showaddemp = true;
        },
        closeAdd() {
            this.showaddemp = false;
             this.addEmpObj={
                empName:'',
                empId:'',
                postName:'',
                postId:'',
            }
            setTimeout(() => {
                this.$refs.addempform.clearValidate();
            }, 20);
        },
        async sureAdd() {
            try {
                await this.$refs.addempform.validate();
                this.PB.list.push({
                    checked: false,
                    bili: 0,
                    oldbili: 0,
                    commmoney:'',
                    empName:this.addEmpObj.empName,
                    empId:this.addEmpObj.empId,
                    postName:this.addEmpObj.postName,
                    postId:this.addEmpObj.postId,
                    type:this.addEmpObj.postId
                })

                this.closeAdd()
            } catch (reason) {
                console.warn(reason);
            }
        },
        async querySearch(queryString, cb) {
            this.PB.empId = 0;
            try {
				const res = await this.$axios({
					url: "/api/employee/queryEmpListIncludeDepart",
					method: "post",
					data: {
                        searchName: queryString,
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.empList.map(emp => {
                        emp.value = [emp.name, emp.reginName].filter(Boolean).join(" - ")
                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
        },
        handleSelect(item) {
            this.addEmpObj.empName=item.name;
            this.addEmpObj.empId=item.id;
        },
        choosepost(val){
            let item =  this.orderPostListEnum.find(item=>{
                return item.value==val
            })
            this.addEmpObj.postName = item.name
        },

        biliValidator(r, val, cb) {
            // console.log(this.oninputindex);
            // console.log(this.PB.list[this.oninputindex].oldbili);
            // console.log(Number(val));

            // console.log(this.PB.list[this.oninputindex].oldbili> Number(val));

            const isFine = /^\d+(\.\d{1,6})?$/.test(val);
            if (isFine) {
                cb();
                // if(Number(val) > this.PB.list[this.oninputindex].oldbili){
                //     console.log(12);
                //     cb(`佣金比例不能大于${this.PB.list[this.oninputindex].oldbili}`)
                // }else{
                //     cb();
                // }
            } else cb("请输入正确的比例, 允许六位小数");
        },

        handleInput(value, data) {
            // console.log("change", value, data)
            if (!!value.trim() && !data.checked) {
                data.checked = true;
            }
        },
        handleInput2(value, data, index) {
            this.oninputindex = index;
            if(this.orderCommInfo.isAddCalculate==1){
                if(this.PB.checkState==1){
                    if (!isNaN(parseFloat(value)) && isFinite(value)) {
                        if (
                            !isNaN(parseFloat(this.PB.rateBili)) &&
                            isFinite(this.PB.rateBili)
                        ) {
                            //  this.PB.list[index].commmoney =Math.round( Number(Math.round(this.orderCommInfo.addCalculateMoney*value)/100) *this.PB.rateBili*100)/100


                             // 除法
                            const bili = Big(value).div(100).toString();
                            console.log('bili',bili);
                            // 乘法
                            const resultSubtraction = Big(this.orderCommInfo.addCalculateMoney).times(bili);
                            console.log('resultSubtraction',resultSubtraction);
                            // 四舍五入并保留两位小数
                            const roundedResult = resultSubtraction.toFixed(2);
                            console.log('roundedResult',roundedResult);
                            // 乘法
                            const resultSubtraction2 = Big(roundedResult).times(this.PB.rateBili===''?0:this.PB.rateBili);
                            // 四舍五入并保留两位小数
                            const roundedResult2 = resultSubtraction2.toFixed(2);
                            console.log('roundedResult2',roundedResult2);
                            this.PB.list[index].commmoney = Number(roundedResult2)
                        }
                    }
                }
                if(this.PB.checkState===0){
                    if (!isNaN(parseFloat(value)) && isFinite(value)) {
                        if (
                            !isNaN(parseFloat(this.PB.rateBili)) &&
                            isFinite(this.PB.rateBili)
                        ) {
                            //  this.PB.list[index].commmoney =Math.round(Number(Math.round(this.orderCommInfo.addCalculateSourceMoney*value)/100) *this.PB.rateBili*100)/100


                              // 除法
                            const bili = Big(value).div(100).toString();
                            console.log('bili',bili);
                            // 乘法
                            const resultSubtraction = Big(this.orderCommInfo.addCalculateSourceMoney).times(bili);
                            console.log('resultSubtraction',resultSubtraction);
                            // 四舍五入并保留两位小数
                            const roundedResult = resultSubtraction.toFixed(2);
                            console.log('roundedResult',roundedResult);
                            // 乘法
                            const resultSubtraction2 = Big(roundedResult).times(this.PB.rateBili===''?0:this.PB.rateBili);
                            // 四舍五入并保留两位小数
                            const roundedResult2 = resultSubtraction2.toFixed(2);
                            console.log('roundedResult2',roundedResult2);
                            this.PB.list[index].commmoney = Number(roundedResult2)
                           
                        }
                    }
                   
                }


            }else{
                if (!isNaN(parseFloat(value)) && isFinite(value)) {
                    if (
                        !isNaN(parseFloat(this.PB.rateBili)) &&
                        isFinite(this.PB.rateBili)
                    ) {
                        // this.PB.list[index].commmoney =Math.round(Number(Math.round(this.orderCommInfo.contractMoney*value)/100) *this.PB.rateBili*100)/100

                        // 除法
                        const bili = Big(value).div(100).toString();
                        console.log('bili',bili);
                        // 乘法
                        const resultSubtraction = Big(this.orderCommInfo.contractMoney).times(bili);
                        console.log('resultSubtraction',resultSubtraction);
                        // 四舍五入并保留两位小数
                        const roundedResult = resultSubtraction.toFixed(2);
                        console.log('roundedResult',roundedResult);
                        // 乘法
                        const resultSubtraction2 = Big(roundedResult).times(this.PB.rateBili===''?0:this.PB.rateBili);
                        // 四舍五入并保留两位小数
                        const roundedResult2 = resultSubtraction2.toFixed(2);
                        console.log('roundedResult2',roundedResult2);
                        this.PB.list[index].commmoney = Number(roundedResult2)
                        
                    }
                }
            }

        },
        handleSave() {
            const checkedList = this.PB.list.filter(({ checked }) => checked);

            this.fullarr = [];
            checkedList.forEach((element) => {
                if (Number(element.bili) > element.oldbili) {
                    let obj = {};
                    obj.name = element.postName;
                    this.fullarr.push(obj);
                }
            });

            if (this.fullarr.length > 0) {
                this.showfull = true;
            } else {
                this.handleSaveafter();
            }
        },
        fullhandleSave() {
            this.handleSaveafter();
        },

        async handleBefore() {
            try {
                await this.$refs.form.validate();
                const checkedList = this.PB.list.filter(
                    ({ checked }) => checked
                );
                if (!checkedList.length) {
                    // loadingRef.close();
                    return this.$message({
                        message: "请勾选结佣员工",
                        type: "error",
                    });
                }
                await this.handleSave();
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "操作失败",
                        type: "error",
                    });
                console.warn(reason);
            }
        },

        async handleSaveafter() {
            this.showfull = false;
            const loadingRef = this.$loading({
                target: ".add-staff-item .el-dialog",
            });

            try {
                await this.$refs.form.validate();
                const checkedList = this.PB.list.filter(
                    ({ checked }) => checked
                );
                if (!checkedList.length) {
                    loadingRef.close();
                    return this.$message({
                        message: "请勾选结佣员工",
                        type: "error",
                    });
                }
                const { commTime, remark, rateBili } = this.PB;
                const res = await this.$axios({
                    url: "/api/orderComm/saveOrderComm",
                    method: "post",
                    data: {
                        commTime,
                        remark,
                        orderId: this.orderId,
                        orderCommListStr: JSON.stringify(
                            checkedList.map((item) => {
                                const {
                                    type,
                                    empId: empid,
                                    bili,
                                    commmoney,
                                } = item;
                                return {
                                    type,
                                    empid,
                                    bili,
                                    commmoney,
                                    rateBili,
                                };
                            })
                        ),
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "操作失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }
        },

        resetData() {
            this.PB.rateBili = "";
            this.PB.commTime = "";
            this.PB.remark = "";
            this.PB.checkState = "";
        },

        handleClose() {
            this.resetData();
            this.$emit("update:show", false);
        },
    },
};
</script>

<style lang='scss' scoped>
.table {
    width: 700px;
    margin-left: 130px;
    margin-top: -10px;
    margin-bottom: 16px;

    /deep/.cell {
        overflow: visible;
    }
}
  .table /deep/ .el-table__body{
    padding-bottom:8px;
}
.el-table::before{
    height:0px;
}
.el-form.private .cell .el-form-item {
    margin-bottom: 0;
    margin-right: 0;
}
.ratebili /deep/ .el-form-item__error {
    left: 80px;
}
</style>
<!-- 修改渠道结佣信息 -->
<template>
    <el-dialog class="update-channel"
        append-to-body
        :visible="show"
        @close="handleClose"
        title="选择已转出订单"
       
        width="800px">
        <div  style="display: flex;flex-direction: column;">
            <div style="display: flex;align-items: center;">
                <span style="margin-right:10px;">关键词:</span>
                <el-input style="margin-right:10px;width: 50%;" v-model="search_value" maxlength="50" placeholder="请输入订单编号或认购人姓名或产品名称进行搜索"></el-input>
                <el-button @click="fetchData" type="primary">搜索</el-button>
            </div>
            <!-- {{checkedList}} -->
        <el-table
            @select="selectItem"
            @row-click="onSelectOp"
            @select-all="onSelectAll"

            ref="table"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%;flex: 1;overflow: auto;"
            @selection-change="handleCheckChange">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>

            <el-table-column
                prop="orderNo"
                label="订单编号">
            </el-table-column>

            <el-table-column
                prop="custName"
                label="认购人姓名">
            </el-table-column>

            <el-table-column
                prop="productName"
                label="产品名称">
            </el-table-column>

            <el-table-column
                prop="contractMoney"
                label="认购金额">
            </el-table-column>

            <el-table-column
                prop="finishTimeName"
                label="到期日期">
            </el-table-column>
            <el-table-column
                prop="outTimeName"
                label="转出日期">
            </el-table-column>
        </el-table>
        </div>
       
        <!-- <list-foot-bar>
        </list-foot-bar> -->

        <div slot="footer">
             <el-pagination style="text-align: right;margin-bottom:12px;padding-bottom: 12px;border-bottom: 1px solid #EBEDF0;};" layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'OldProductPicker',
    props: [ "show", "list", "value" ],

    data () {
        return {
            checkedList: [],

            pageNum: 1,
            pageSize: 10,
            total: 0,
            search_value:'',
            tableData:[],
        }
    },

    // computed: {
    //     checkedId () {
    //         const item = this.checkedList[0];
    //         if (item) {
    //             return item?.id || ""
    //         }
    //         else return ""
    //     }
    // },

    watch: {
        async show (_show) {
            if(_show){
                this.search_value = ''
                this.fetchData()
            }

            // if (this.value) {
            //     let orig;
            //     if (typeof this.value === "object")
            //         orig = this.value.map(oriItemId => {
            //             return this.list.find(({id}) => id == oriItemId);
            //         }).filter(Boolean);
            //     else orig = [this.list.find(({id}) => id == this.value)];

            //     await this.$nextTick();
            //     this.checkedList = orig;
            //     for (var item of orig) {
            //         this.$refs.table.toggleRowSelection(item, true);
            //     }
            // }
        }
    },

    methods: {
        onSelectAll() {
      this.$refs.table.clearSelection();
    },

    selectItem(rows) {
      if (rows.length > 1) {
        const newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.table.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.table.toggleRowSelection(it, false);
            return false;
          }
        });
        this.checkedList = newRows;
      } else {
        this.checkedList = rows;
      }
    },
    onSelectOp(row) {
      this.$refs.table.clearSelection();
      this.$refs.table.toggleRowSelection(row, true);
      this.checkedList = [];
      this.checkedList.push(row);
    },
  
    handleCheckChange(data, checked, indeterminate) {
    //   if (checked) {
    //     this.$refs.table.setCheckedKeys([data.lobbyCode]);
    //   }
    },
    // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },

        
        async fetchData() {
            this.checkedList = []
            try {
                const res = await this.$axios({
                    url: "/api/order/selectAllOutOrderList",
                    method: "post",
                    data: {
                        search_value: this.search_value,
                        pageSize: this.pageSize,
                        pageNum: this.pageNum,
                    }

                });
                if (res.code === 2000) {
                    this.total = res.data.count; 
                    const list = res.data.data.map(it => {
                        // it.createTimeName = it.createTimeName?.replace(" ", "<br>")
                        // it.queueTimeName = it.queueTimeName?.replace(" ", "<br>")
                        // if (!(it.state == 1 || it.state == 2)) it.sequence = "";
                        // const _actionList = [...actionList];
                        // it.actionList = _actionList;
                        
                        return it;
                    });
                    this.tableData = list;
                    // this.tableData = [{},{},{},{},{},{},{},{},{},{}];


                    // if (this.tableData.length == 0) {
                    //     this.emptyText = '暂无数据'
                    // }
                    // this.searchHrefType = "";

                    // loading?.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                // loading?.close();
            }
        },
        handleSave () {    
            console.log(this.checkedList);
            // this.$emit("input", this.checkedList.map(({id}) => id).filter(Boolean));
            this.$emit("mychoose", this.checkedList);
            this.handleClose();
        },

        async resetData () {
            this.$refs.table.clearSelection();
            this.checkedList = [];
        },

        

        handleClose () {
            this.resetData();
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped></style>
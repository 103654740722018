<!-- 审核eoi订单 -->
<template>
    <el-dialog class="check-eoi"
        :visible="viewIsReady"
        @close="handleClose"
        title="EOI审核"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="审核状态" prop="checkState">
                <el-radio-group v-model="PB.checkState">
                    <el-radio :label="3">通过</el-radio>
                    <el-radio :label="2">不通过</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if='type==2 && PB.checkState==3' label="审核时间" prop="fileCheckTime">
                  <!-- <el-date-picker
                    class="width1h"
                    type="date"
                    v-model="PB.fileCheckTime"
                    placeholder="请选择"
                    value-format="yyyy-MM-dd"
                     :picker-options="pickerOptions"
                ></el-date-picker> -->
                <!-- {{PB.fileCheckTime}} -->
                <el-date-picker
                    class="width1h"
                    type="datetime"
                    v-model="PB.fileCheckTime"
                    placeholder="请选择"
                    value-format="yyyy-MM-dd HH:mm:ss"
                     :picker-options="pickerOptions"
                ></el-date-picker>
            </el-form-item>

            <!-- <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea"
                    maxlength="100"
                    class="width3"></el-input>
            </el-form-item> -->

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
let startDate = '' //2023-10-15 00:00:00
export default {
    name: 'CheckEOI',
    props: [ "order",'type' ],

    data () {
        return {
            viewIsReady: false,
            PB: {
                checkState: "", // 审核状态 3通过 2不通过
                fileCheckTime: "", // 审核时间
                // remark    : ""
            },
            rules: {
                checkState : {required: true, message: "请选择审核结果"},
                fileCheckTime : {required: true, message: "请选择审核时间"}

            },
            pickerOptions: { 
                 disabledDate(time) {
                    return ( (time.getTime()- 8.64e7 < new Date(startDate).getTime()- 8.64e7)  ||    (time.getTime()- 8.64e7 > Date.now() - 24 * 60 * 60 * 1000));
                }
             },

        }
    },

    watch: {
        order (order, old) {
            if(order){
                this.viewIsReady = true;
                this.queryTimeAddAndReduce()
            }

            // if (order && order !== old) {
            //     // console.log(2)
            //     // this.PB.isChanComm  = order.isChanComm;
            //     // this.PB.channelName = order.channelName;
            //     this.viewIsReady = true;
            //     // this.$refs.form.clearValidate();
            // }
            // else this.viewIsReady = false
        }
    },

    methods: {
        getDateStr (date) {
			if (date instanceof Date) {
				let Y = date.getFullYear();
				let M = date.getMonth() + 1;
				let D = date.getDate();
				M = M < 10 ? `0${M}` : M;
				D = D < 10 ? `0${D}` : D;
	
				return `${Y}-${M}-${D}`;
			}
			else return ""
		},

        // 选择的时间只能往前选4个工作日
         async queryTimeAddAndReduce() {
            this.nowDate = this.getDateStr(new Date())
            const res = await this.$axios({
                url: "/api/configuration/queryTimeAddAndReduce",
                method: "post",
                data:{
                    num:-4,
                    sreachTime:this.nowDate,
                }
            });

            if (res.code === 2000) {
                startDate = res.data.time+' 00:00:00'
            } else if (res.code !== 1003)
                throw res
        },
        // handleSave(){
        //     console.log(this.type);
        // },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".check-eoi .el-dialog"
            });
            // 
            let url = '/api/orderProcess/checkBranchOffice'
            let data = {
                id: this.order.processId,
                state: this.PB.checkState
            }

            if(this.type==1){ //分公司
                 url = '/api/orderProcess/checkBranchOffice'
                 data = {
                    processId: this.order.processId,
                    state: this.PB.checkState==3?1:0
                }
            }else{ //总公司
                // 获取当前时间时分秒
                const now = new Date();
                let hours = now.getHours();
                let minutes = now.getMinutes();
                let seconds = now.getSeconds();


                if (hours >= 0 && hours <= 9) {
                    hours = "0" + hours;
                }
                if (minutes >= 0 && minutes <= 9) {
                    minutes = "0" + minutes;
                }
                if (seconds >= 0 && seconds <= 9) {
                    seconds = "0" + seconds;
                }
                 url = '/api/orderProcess/checkOrderFirstEoi'
                 data = {
                    id: this.order.processId,
                    state: this.PB.checkState,
                    // checkTime: this.PB.fileCheckTime+' '+hours+':'+minutes+':'+seconds
                    checkTime: this.PB.fileCheckTime
                }

            }
            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: url,
                    method: "post",
                    data:data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change", this.order.orderProcess.type == 1 && this.PB.checkState == 3 ? 5 : this.PB.checkState);
                    this.$emit("change",);
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.$refs.form.clearValidate();
            this.PB.checkState  = "";
            this.PB.fileCheckTime  = "";
            // this.PB.remark      = "";
        },

        handleClose () {
            this.viewIsReady = false
            setTimeout(() => {
                this.resetData();
                this.$emit("update:order", null);
                setTimeout(() => {
                    this.$refs.form.clearValidate();
                }, 20);
            }, 200);

        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
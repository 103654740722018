<!-- 添加缴费 -->
<template>
    <el-dialog class="add-payments"
        :visible="viewIsReady"
        @close="handleClose"
        title="缴费"
        width="800px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="100px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="缴费方式" prop="paytype">
                <el-select v-model="PB.paytype"
                    class="width4">
                    <el-option
                        v-for="level in payTypeList"
                        :key="'a'+level.id"
                        :label="level.name"
                        :value="level.id"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="票据日期" prop="paytime">
                <el-date-picker
                    class="width4"
                    v-model="PB.paytime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>

            <el-form-item label="缴费金额" prop="money">
                <el-input v-model="PB.money"
                    class="width4">
                    <el-select v-model="PB.moneyType" slot="append"
                        class="width1h">
                        <el-option
                            v-for="level in moneyTypeList"
                            :key="'b'+level.id"
                            :label="level.name"
                            :value="level.id"
                        ></el-option>
                    </el-select>
                </el-input>
            </el-form-item>

            <el-form-item label="缴费凭证" prop="path" class="wrap">
                <UploadFile
                    v-model="PB.path"
                    :show-file-list="true"
                    :accept="uploadAccept"
                    :limit="5"
                    :maxSize="5000"
                >
                    <span class="hint" slot="hint">只能上传图片与pdf文件, 最多上传5张, 大小不超过5M</span>
                </UploadFile>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea" rows="5"
                    maxlength="100"
                    class="width4"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">{{ onSaveTab ? '取消' : '关闭' }}</el-button>
            <el-button @click="handleSave" v-if="onSaveTab"
                type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UploadFile from '../../../components/UploadFile/UploadFile.vue'
export default {
    name: 'AddPayments',
    components: {UploadFile},
    props: [
        "item",
        "payTypeList",
        "moneyTypeList"
    ],

    data () {
        return {
            auth: {
                delete: false,
            },
            viewIsReady: false,
            originData: {id: 0},
            paymentList: [],
            activeTabName: "save",
            uploadAccept: "image/*,application/pdf,application/x-pdf",
            checkedlist: [],
            PB: {
                paytype: "",
                paytime: "",
                money: "",
                moneyType: "",
                path: [],
                remark: ""
            },
            // 验证规则
            rules: {
                paytype: { required: true, message: "请选择缴费方式", trigger: "blur" },
                paytime: { required: true, message: "请选择票据日期", trigger: "blur" },
                money  : [
                    { required: true, message: "请输入缴费金额", trigger: "blur" },
                    { validator (r, val, c) {
                        if (typeof val === "number") c()
                        else if (!/^\d+(\.\d+)?$/.test(val)) {
                            c("只能输入数字")
                        }
                        else c()
                    }, trigger: "blur"}
                ],
                path: { required: true, message: "请上传缴费凭证", trigger: "blur" },
            }
        }
    },

    computed: {
        onSaveTab () {
            return this.activeTabName === "save"
        },
        collectedAmount () {
            return this.paymentList.reduce((t, c) => {
                return t+c.money
            }, 0)
        },
        isEnough () {
            return this.collectedAmount >= this.originData?.contractMoney;
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.originData = item;
                this.PB.moneyType = item.moneyType
                // if (item.paymentList == null) {
                //     item.paymentList = [];
                //     this.fetchList()
                // }
                // if (item.id == null)
                //     item.id = 0;
                // this.paymentList = item.paymentList;
                this.viewIsReady = true;
            } else {
                // this.originData = {id: 0};
                // this.paymentList = []
                this.viewIsReady = false
            }
        },
        moneyTypeList (list) {
            console.log(11)
            if (list) setTimeout(() => {
                this.PB.moneyType = list.find(it=>it.name.includes("澳"))?.id || "".id;
            }, 1e3);
        }
    },

    created () {
		this.auth.delete = this.$hasAuthFor("api/order/deleteOrderPay");
    },

    methods: {
        async fetchList () {
            const loadingRef = this.$loading({
                target: ".add-payments .el-dialog"
            });

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/order/queryOrderPayList",
                    data: {orderId: this.originData.id}
                })

                if (res.code === 2000) {
                    const list = res.data.orderPayList.map(item => {
                        item.createTimeArr = item.createTimeName.split(
                            " ").filter(Boolean);
                        if (item.path) item.path =
                            item.path.split(",").filter(Boolean).map(
                                path => {
                                    return {
                                        name: path.slice(path.lastIndexOf("/")+1),
                                        path: path,
                                        url: this.$baseURL + "/" + path,
                                    }
                                })
                        return item;
                    });
                    this.paymentList.splice(0, this.paymentList.length, ...list) 
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "获取记录失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.checkedlist   = [];
            this.PB.paytype    = "";
            this.PB.paytime    = "";
            this.PB.money      = "";
            this.PB.moneyType  = this.moneyTypeList.find(it=>it.name.includes("澳"))?.id || "";
            this.PB.path       = [];
            this.PB.remark     = "";
            this.$refs.form.clearValidate();
        },

        handlePreview (row) {
            console.log("预览快照", row);
        },

        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        async handleBuckDelete () {
			let loadingRef;
			try {
				await this.$confirm("确定要作废吗", "系统提示")
				loadingRef = this.$loading({
                    target: ".add-payments .el-dialog"
                });
				const res = await this.$axios({
					url: "/api/order/deleteOrderPay",
					method: "post",
					data: {
						orderPayIds: this.checkedlist.map(it=>it.id).join()
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "作废缴费记录成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchList();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "作废失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
        },
        
        async handleSave () {
            if (this.isEnough) return this.$message({
                message: "订单回款已收齐，不能添加缴费",
                type: "warning"
            })
            const isUploading = this.PB.path.some(it=>it.status!="success")
            if (isUploading) return this.$message({
                message: "请等待上传完成",
                type: "warning"
            });

            const loadingRef = this.$loading({
                target: ".product-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                // console.log(this.collectedAmount, this.PB.money, this.originData?.contractMoney)
                if (this.collectedAmount + Number(this.PB.money) > this.originData?.contractMoney) {
                    await this.$confirm("累积缴费金额已大于订单总金额，还要继续缴费吗？", "提示")
                }
                const res = await this.$axios({
                    url: "/api/order/saveOrderPay",
                    method: "post",
                    data: {
                        ...this.PB,
                        orderid: this.originData.id,
                        path: this.PB.path.map(it=>it.path).join()
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.activeTabName = "list";
                    // this.fetchList();
                    this.$emit("change");
                    this.handleClose();


                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                if (reason && reason !="cancel") {
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error"
                    });
                    console.warn(reason);
                }
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.resetData();
            this.activeTabName = "save";
            // this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .title {
        display: flex;
        line-height: 24px;
        h3 {
            font-size: 16px;
            font-weight: bold;
        }
        p {
            margin-left: 20px;
            font-size: 14px;
            color: gray;
        }
        .tips {
            color: orangered;
        }
        a {
            margin-left: 20px;
            color: #FD9A17;
        }
    }

    /deep/.el-dialog__body {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .hint {
        margin-left: 0 !important;
        color: orangered !important;
    }
    
    .el-radio-group {
        margin-bottom: -10px;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
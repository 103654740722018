import {nanoid} from "nanoid";

export const today = (function () {
    let today = new Date(),
        Y = today.getFullYear(),
        M = today.getMonth() + 1,
        D = today.getDate();
    M = M < 10 ? `0${M}` : M;
    D = D < 10 ? `0${D}` : D;

    return `${Y}-${M}-${D}`;
}) ();

export const searchTypeEnum = [
    { name: "客户编号",   value: 1 },
    { name: "认购人姓名", value: 2 },
    { name: "订单编号",   value: 3 },
    { name: "产品名称",   value: 4 },
    { name: "销售员工",   value: 5 },
    { name: "维护员工",   value: 6 }
]

export const dateTypeEnum = [
    { name: "认购日期",     value: 1 },
    { name: "到期日期",     value: 2 },
    { name: "缴费日期",     value: 3 },
    { name: "全款齐日期",   value: 4 },
    { name: "订单创建日期", value: 5 },
    { name: "缴费创建日期", value: 6 },
]

export const payStateEnum = [
    { name: "全部"    , value: "" },
    { name: "未回款"  , value: 1 },
    { name: "部分回款", value: 2 },
    { name: "全款齐"  , value: 3 }
]

// export const fileStateEnum = [
//     { name: "全部"    , value: "" },
//     { name: "已齐全"  , value: 1 },
//     { name: "未齐全"  , value: 2 },
// ]

export const expireStateEnum = [
    { name: "全部"    , value: "" },
    { name: "生效中"  , value: 1 },
    { name: "已到期"  , value: 2 },
]
export const searchCheckarr = [
    { name: "全部", value: "" },
    { name: "是", value: 1 },
    { name: "否", value: 0 },
]

export const exportTypeEnum = [
    // { name: "缴费日期", value: 1 },
    // { name: "签约日期", value: 2 }

    // 20230809  4.0.1 订单导出缴费要弹窗选日期 改成下面的选项  不清除会不会影响到其它功能
    { name: "缴费日期", value: 1 },
    { name: "认购日期", value: 2 },
    { name: "缴费创建日期", value: 3 }

]
export const exportInComeTypeEnum = [
    { name: "全款齐日期", value: 1 }
]

// 表格项（行）的操作菜单
export const actionList = [
    {
        label: "维护",
        name: "save",
        banned: false
    },
    {
        label: "缴费",
        name: "addPayment",
        banned: false
    },
    {
        label: "详情",
        name: "detail",
        banned: false
    },
    {
        label: "变更状态",
        name: "updateState",
        banned: false
    },
    {
        label: "业绩相关日期",
        name: "updateDates",
        banned: false
    },
    {
        label: "修改渠道结佣",
        name: "updateChannel",
        banned: false
    },
    {
        label: "补录到期状态",
        name: "updateFollowUp",
        banned: false
    },
    {
        label: "提交业绩审核",
        name: "commitCommission",
        banned: false
    },
    {
        label: "核算赛季业绩",
        name: "checkSeason",
        banned: false
    },
    {
        label: "申请外部渠道佣金",
        name: "applyChannelCommission",
        banned: false
    },
];

// 函数创建一个空的菜单数据
export function initialPB () {
    return {
        nanoid: nanoid(),
        name: "",
        sysId: "",
        roleId: "",
        busiDir: "",
        order: 99,
        level: 99,
        content: "",
    };
}
import forList from "@/mixin/forList";

export default {
    name: "CommissionList",
    noFetchFirst: true,
    mixins: [forList],

    data() {
        return {
            SP_: {
                seasonId: '',
            },
            seasonList: [],
        };
    },
    created() {
        this.auth.orderDetail = this.$hasAuthFor("api/order/querySeasonBonusDetailList");
        const { params } = this.$route;
        if (Object.keys(params).length) {
            for (let key in params) {
                if (key === "searchHrefType") this.searchHrefType = params[key];
                else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
                    this.SP_[key] = params[key]
            }
            this.handleSearch();
        }
        else this.fetchData();
        this.fetchEnum()
    },

    methods: {
        async fetchEnum() {
            const res = await this.$axios({
                url: "/api/order/queryOrderParam",
                method: "post"
            });

            if (res.code === 2000) {
                this.seasonList = res.data.seasonList
            } else if (res.code !== 1003)
                throw res
        },
        async fetchData() {
            const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/season/querySeasonBonusList",
                    method: "post",
                    data: {
                        ...this.extractParams(),
                    }
                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData = res.data.seasonBonusList;
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        handleCommissionDetail(item) {
            this.$router.push({
                path: `/commission/seasonBonusList/detail/${item.seasonId}`
            });
        },
    }
};
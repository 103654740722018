<!-- 填写国家 -->
<template>

    <el-form
        class="address-input"
        ref="form"
        :disabled="disabled"
        :model="address"
        :rules="rules"
    >
        <el-form-item prop="countryid">
            <el-select v-model="address.countryid"
                @change="handleSelect($event, 1)"
                placeholder="请选择国家">
                <el-option
                    v-for="p in list0"
                    :key="p.id"
                    :label="p.chinesename"
                    :value="p.id"
                ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item prop="provinceid"
            v-if="address.countryid && list1.length">
            <el-select
                v-model="address.provinceid"
                @change="handleSelect($event, 2)"
                placeholder="请选择">
                <el-option
                    v-for="p in list1"
                    :key="p.id"
                    :label="p.chinesename"
                    :value="p.id"
                ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item prop="cityid"
            v-if="address.provinceid && list2.length">
            <el-select
                v-model="address.cityid"
                @change="handleSelect($event, 3)"
                placeholder="请选择">
                <el-option
                    v-for="p in list2"
                    :key="p.id"
                    :label="p.chinesename"
                    :value="p.id"
                ></el-option>
            </el-select>
        </el-form-item>    


        <el-form-item prop="areaid"
            v-if="address.cityid && list3.length">
            <el-select
                v-model="address.areaid"
                placeholder="请选择">
                <el-option
                    v-for="p in list3"
                    :key="p.id"
                    :label="p.chinesename"
                    :value="p.id"
                ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item prop="addinfo" class="full-width">
            <el-input
                v-model="address.addinfo"
                type="textarea"
                maxlength="100"
                rows="5"
            ></el-input>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: 'AddressInput',
    props: ["address", "options", "disabled"],

    data () {
        return {
            list0: [],
            list1: [],
            list2: [],
            list3: [],
            rules: {
                countryid : {required: true, message: "请选择"},
                provinceid: {required: true, message: "请选择"},
                cityid    : {required: true, message: "请选择"},
                areaid    : {required: true, message: "请选择"},
                addinfo   : {required: true, message: "请输入地址"},
            }
        }
    },

    watch: {
        // accont: {
        //     immediate: true,
        //     handler (newObj, old) {}
        // },
        options: {
            immediate: true,
            handler (n) {
                const {
                    countryList,
                    provinceList,
                    cityList,
                    areaList
                } = n;
                if (Array.isArray(countryList))  this.list0 = countryList;
                if (Array.isArray(provinceList)) this.list1 = provinceList;
                if (Array.isArray(cityList))     this.list2 = cityList;
                if (Array.isArray(areaList))     this.list3 = areaList;
            }
        }
    },

    created () {
        this.fetchOption(0, 0)
    },

    methods: {
        async fetchOption (pId, level) {
			try {
				const res = await this.$axios({
					url: "/api/orderProcess/queryCountryAreaList",
					method: "post",
                    data: {pId}
				})
				if (res.code !== 2000) throw res;
                
                const list = res.data.countryAreaList;
                this[`list${level}`] = list;
					
				
			} catch (reason) {
				console.warn("获取岗位配置参数出错", reason);
			}
        },

        handleSelect(pId, level) {
            ["countryid", "provinceid", "cityid", "areaid"].forEach((key, index) => {
                if (index >= level) this.address[key] = "";
            });

            this.fetchOption(pId, level)
        },

        validate () {
            return this.$refs.form.validate()
        }
    }
}
</script>

<style lang='scss' scoped>
    .address-input {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .el-form-item {

            width: 49%;
            &:not(.full-width) {
                margin-bottom: 16px;

            }
        }

        .full-width {
            width: 100%;
        }

        /deep/ .el-form-item__error {
            z-index: 1;
        }
        // .el-input {
        //     width: 100%;
        // }
    }
</style>
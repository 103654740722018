<!-- 批量编辑维护员工 -->
<template>
    <el-dialog class="update-expire"
        :visible="show"
        @close="handleClose"
        title="批量修改订单到期日期"
        width="560px">

        <el-form
            :model="PB"
            :rules="rules"
            class="private"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item>
                <p>您已选择{{list.length}}个订单</p>
            </el-form-item>

            <el-form-item label="订单到期日期" prop="finishTime">
                <el-date-picker
                    class="width1h"
                    v-model="PB.finishTime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'UpdateExpire',
    props: ["show", "list"],

    data () {
        return {
            PB: {
                finishTime: ""
            },
            rules: {
                finishTime: { required: true, message: "请选择订单到期日期" }
            }
        }
    },

    methods: {
        async handleSave () {
            let loadingRef;

            try {
                // await this.$refs.form.validate();
                await this.validate();
                await this.$confirm(`您确定要同时修改${this.list.length}条订单的到期日期么？`, "系统提示")
                loadingRef = this.$loading({
                    target: ".update-expire .el-dialog"
                });

                const res = await this.$axios({
                    url: "/api/order/updateFinishTime",
                    method: "post",
					data: {
                        orderIds  : this.list.map(({id})=>id).join(),
                        finishTime: this.PB.finishTime
                    }
                })

				if (res.code === 2000) {
					this.$message({
                        message: "操作成功",
                        type: "success"
                    })
                    
                    this.$emit("change");
                    this.handleClose();
	
				} else if (res.code !== 1003) 
					throw res;


            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
            }
            loadingRef && loadingRef.close()
        },

        validate () {
            const {finishTime} = this.PB;
            if (finishTime) return Promise.resolve()
            else {
                this.$message({
                    message: "请选择订单到期日期",
                    type: "warning"
                });
                return Promise.reject()
            }
        },

        handleClose () {
            this.PB.finishTime = "";
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped></style>
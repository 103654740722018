import { nanoid } from "nanoid";

export const dateTypeEnum = [
    // { name: "认购日期",     value: 1, type: "date", format: "yyyy-MM-dd" },
    // { name: "业绩核算月份", value: 2, type: "month", format: "yyyy-MM" },
    { name: "全款齐日期", value: 1, type: "date", format: "yyyy-MM-dd" },
]

// 函数创建一个空的菜单数据
export function initialPB() {
    return {
        nanoid: nanoid(),
        name: "",
        sysId: "",
        roleId: "",
        busiDir: "",
        order: 99,
        level: 99,
        content: "",
    };
}

export const stateEnum = [
    {
        name: "待中国区审核",
        value: 1
    },
    {
        name: "待澳洲区审核",
        value: 2
    },
    {
        name: "待薪酬审核",
        value: 4
    },
    {
        name: "审核通过",
        value: 3
    },
    {
        name: "审核不通过",
        value: 0
    }
]
/**
 * 跳转调试, ip用本机ip, token在来源页面生成 可以在中台取token 要本地启移民
   http://192.168.110.136:8030/?custCode=CRM6045100&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxOTgiLCJuYmYiOjE3MDk4Nzc4NzMsInRpbWUiOjE3MDk4Nzc4NzM0NjIsInVzZXJOYW1lIjoienkifQ.Wh01o_HZJsjiySZUXTrzuZBP4ZlqN7zgG_O4V3WArw0s#/core/eoi/add
 */

import {createNewOrderData, orderFiles, processStateMap} from "./config";
import {fetchData, restoreOrder, restoreInvestor, restoreEmployee, restoreFiles} from "./restore";
import { handleStep1Save, formatData1, toEioList, toEditEoiFile } from "./save1";
import FormPart1 from "./FormPart1.vue";
import FormPart2 from "./FormPart2.vue";
import FormPart3 from "./FormPart3.vue";
import FormPart4 from "./FormPart4.vue";
import FormPart5 from "./FormPart5.vue";
import FormPart6 from "./FormPart6.vue";
import FormPart7 from "./FormPart7.vue";
import CheckEOI  from "./CheckEOI.vue";
import CheckAmount  from "./CheckAmount.vue";
import NotifyPay  from "./NotifyPay.vue";

export default {
    name: 'OrderAdd',
    components: {
        FormPart1,
        FormPart2,
        FormPart3,
        FormPart4,
        FormPart5,
        FormPart6,
        FormPart7,
        CheckEOI,
        CheckAmount,
        NotifyPay
    },

    data () {
        return {
            id               : undefined,
            phoneWhite       : false, // 是否可以查看和修改电话
            orderTimeWhite   : false, // 是否可修改订单签约日期 1为可修改 0不可修改
            discountWhite    : false, // 是否可修改订单销售信息和优惠信息1为可修改 0不可修改
            countCustWhite   : false,
            products         : [],         // 所有产品
            orderStateEnum   : [],         // 订单状态
            OrderTypeEnum    : [],
            OrderProcessTypeEnum     : [],
            OrderProcessChildTypeEnum: [],
            moneyTypeList    : [],         // 币种
            orgAllList       : [],         // 组织架构
            sourceTypeAll    : [],         // 所有来源
            disList          : [],         // 优惠类型
            workDeptTypeEnum : [],         // 业务部门
            orderProductList : [],         // 原始投资产品
            moneySourceList  : [],         // 资金来源
            payTypeList      : [],         // 付款方式
            OrderAddStateEnum: [],         // 增投类型
            addressOptions  : {},
            processStateMap,

            orderMap         : null,         // 订单信息
            investor         : null,         // 投资人信息
            orderEmpInfo     : null,     // 订单员工信息
            orderDiscountList: [],         // 优惠列表
            
            orderFiles       : null, // 订单资料

            checkingOrder: null,
            checkingAmount: null,
            notifingOrder: null,
            processNode: 1,                // 当前进度
            filesCommited: false,
            auth: {
                checkEOI: false,
                checkAmount: false,
                notyfyPay: false,
                saveFiles: false
            },
            addSaveSuccessDia: false,
            addNewOrderId: '',//新增成功后返回的orderId
            orderInitInfo: {
                isOldOrder: 0,
            },
            // recommendUserCode: '', //推荐员工code V4.2.4
        }
    },

    computed: {
        isUpdate () {
            return !!this.id
        },
        phoneDisabled () {
            return !(!this.isUpdate || this.phoneWhite)
        },
        part1Auth () {
            return !this.isUpdate || this.orderTimeWhite
        },
        part6Auth () {
            return !this.isUpdate || this.countCustWhite
        },
        part2Auth () {
            return !this.isUpdate || this.discountWhite
            // return this.discountWhite
        },
        part2isWhiteMan() {
            if (this.discountWhite) {
                return 1
            } else {
                return 0
            }
        },
        currentProduct () {
            return this.products.find(item => item.id == this.orderMap.productId)
        }
    },

    created () {
		this.auth.checkEOI    = this.$hasAuthFor("api/orderProcess/checkOrderFirstEoi");
		this.auth.checkAmount = this.$hasAuthFor("api/orderProcess/saveBuyOrder");
		this.auth.notyfyPay   = this.$hasAuthFor("api/orderProcess/saveNoticePayTime");
        this.auth.saveFiles = this.$hasAuthFor("api/orderProcess/saveInvestFile");
        
        const { orderMap, investor, orderEmpInfo } = createNewOrderData();
        this.orderMap = orderMap;
        this.investor = investor;
        this.orderEmpInfo = orderEmpInfo;
        this.orderFiles = JSON.parse(JSON.stringify(orderFiles));

        // this.orderMap     = orderMap;
        // this.investor     = investor;
        // this.orderEmpInfo = orderEmpInfo

        const id = Number(this.$route.query.id); //  || 1487
        if (id) {
            this.id = id;
        } else {
            let follow = localStorage.getItem("follow");
            if (follow) follow = JSON.parse(follow);
            if (follow?.custCode && !follow.token) {
                const { custCode } = follow;
                this.orderMap.cust_code = custCode
            }
            else this.orderMap.cust_code = "AYM6179735"; // 调试


            // if (follow?.recommendUserCode && !follow.token) {
            //     const { recommendUserCode } = follow;
            //     this.recommendUserCode = recommendUserCode
            // }

        }

        if (id || this.orderMap.cust_code) this.fetchData()
        else { console.log("初始化失败, 没有orderid 和 custcode"); }
    },

    beforeDestroy () {
        let follow = localStorage.getItem("follow");
        if (follow) follow = JSON.parse(follow);
        if (follow && !follow.token) {
            console.log("should remove follow storage");
        }
    },

    methods: {
        fetchData,
        restoreOrder,
        restoreInvestor,
        restoreEmployee,
        restoreFiles,

        handleStep1Save,
        formatData1,
        toEioList,
        toEditEoiFile,
        pageclick() {
            if (this.$refs.part1) {
                this.$refs.part1.processflag = false
            }
        },
        handleProcessNode (node) {
            if (processStateMap[this.orderMap.processCheckState].node >= node) this.processNode = node;
            else this.$message({
                message: "当前签约单还未进入此阶段暂不能进行操作",
                type: "error"
            })
        },

        handleOrderProcessTypeChange () {
            this.$refs.part6?.clearAttachment()
        },

        handleEveryFilesFine () {
            // console.log(111);
            this.orderMap.processCheckState = 5;
            // this.$set(this.orderMap, "processCheckState", 5)
            // this.processNode = 3;
            // this.$router.reload()

        },

        handleCheckOrderBtn () {
            this.checkingOrder = this.orderMap
        },

        handleCheckingStateChange (state) {
            this.orderMap.processCheckState = state;
            if (state == 3) this.processNode = 2; 
        },

        handleCheckAmountBtn () {
            if (this.orderMap.processState != 1) { // 0.预定 1.排队中 2.备序排队 3.完成认购
                return this.$message({
                    message: "备序的EOI不能进行额度的确认",
                    type: "error"
                })
            }
            this.checkingAmount = this.orderMap
        },

        handleCheckingAmountChange (data) {
            this.orderMap.processCheckState = 6;
            this.orderMap.contractMoney = data.money;
            this.orderMap.translateTimeName = data.translateTime;
            this.processNode = 3;
        },

        handleNotifyOrderBtn () {
            this.notifingOrder = this.orderMap
        },

        handleNotifiedAmountChange () {
            this.fetchData();
            this.$refs.part7?.fetchData()
        },

        async handleSubmitButton () {
            switch (this.processNode) {
                case 1:
                    this.handleStep1Save();
                    break;

                case 2:
                    this.$refs.part4.save();
                    break;
                
                default:
                    break;
            }
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        },

        handleFilesfetched () {
            this.filesCommited = true;
        },

        handleFilesCommited () {
            this.fetchData();
            this.filesCommited = true;
            if (this.processNode == 2) this.$refs.part4?.fetchFileList();
        },
        
        handleTabClick (e) {
            const part_dom = this.$refs[e.name].$el;
            part_dom.scrollIntoView({
                behavior: "smooth",
                // block: "center"
            });
        }
    }
}
import forList from "@/mixin/forList";
// import ChinaAudit from "./ChinaAudit.vue";
// import AusAudit   from "./AusAudit.vue";
// import { searchTypeEnum } from "../orderList/config";
import { dateTypeEnum, stateEnum } from "./config";
const tabEnum = [
    {
        name: "全部",
        value: ""
    },
    stateEnum[0],
    stateEnum[1],
    stateEnum[2],

]
export default {
    name: "CommissionList",
    noFetchFirst: true,
    mixins: [forList],
    // components: {ChinaAudit, AusAudit},

    data() {
        return {
            searchTypeEnum: [
                { name: "认购人姓名", value: 1 },
                { name: "客户编号", value: 2 },

            ],     // 模糊查询字段选项
            stateEnum,            // 状态
            tabEnum,
            dateTypeEnum,         // 供查询的日期类型选项
            SP_: {
                // seasonId: '',
                seasonId: [],

                searchType: 1,
                // searchValue: "",
                // searchCheckState: "",
                searchTimeType: 1,
                // searchStartTime: "",
                // searchEndTime: "",
            },
            showChinaAudit: false, // 中国区审核
            showAusAudit: false, // 澳洲取审核
            showTip: true,
            showPicDia: false,
            showOrderCommRecord: false,
            orderCommRecordList: [],
            updateMoneyCheckStateDia: false,

            PB: {
                state: '',
                calMonth: '',
                remark: "",



                // performanceEmpStr:'',
                // seasonId: "",
                // seasonProfit: "",
                // // bonusMoney   : "",
                // // commMoney    : "",
                // payableBonus: '',
                // chanMoney: '',

                // guaranteeMoney: '',
                // pkRatioMoney: '',
                // victoryMoney: '',
            },
            rules: {
                state: { required: true, message: "请选择审核状态", trigger: "blur" },
                calMonth: { required: true, message: "请选择绩效核算月份", trigger: "blur" },
            },

            jjDiaData: {
                calTime: '',
                bonusType: '',
                file: [],
            },
            jjRules: {
                calTime: { required: true, message: "请选择奖金发放日期" },
                bonusType: { required: true, message: "请选择奖金类型" },
                file: { required: true, message: "请上传文件", trigger: "change" },
            },

            clickRow: '',
            performanceEmpInfo: '',

            seasonList: [],

            showCheckAchievementDia: false,//批量核算业绩奖金

            fileList: [],
            uploadHeaders: { // 图片上传的请求头
                token: this.$store.state.token
            },
            allGrantData: {},
            typeEnum: []

        };
    },

    computed: {
        timeType() {
            const { searchTimeType } = this.SP_;
            return dateTypeEnum.find(({ value }) => value === searchTimeType) || dateTypeEnum[0];
        }
    },

    watch: {
        "SP_.searchCheckState"(val) {
            this.handleSearch()
        }
    },

    created() {
        this.auth.exportOrder = this.$hasAuthFor("api/order/querySeasonBonusDetail");
        this.auth.batchGrantBonus = this.$hasAuthFor("api/order/batchGrantBonus");
        this.auth.exportSeasonBonusGrantDetail = this.$hasAuthFor("api/order/exportSeasonBonusGrantDetail");

        this.auth.bonusDetail = this.$hasAuthFor("api/order/queryOrderBonusDetail");

        this.auth.chinaAudit = this.$hasAuthFor("api/orderComm/updateOneCheckState");
        this.auth.ausAudit = this.$hasAuthFor("api/orderComm/updateTwoCheckState");
        this.auth.delete = this.$hasAuthFor("api/orderComm/deleteOrderCommApply");
        this.auth.detail = this.$hasAuthFor("api/orderComm/queryOrderCommInfo");
        this.auth.orderDetail = this.$hasAuthFor("api/order/queryOrderDetails");

        const { params } = this.$route;
        // this.SP_.seasonId = Number(this.$route.params.id)


        if (Object.keys(params).length) {
            for (let key in params) {
                if (key === "searchHrefType") this.searchHrefType = params[key];
                else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
                    this.SP_[key] = params[key]
            }
            this.handleSearch();
        }
        else this.fetchData();
        // this.fetchEnum()
        console.log(this.$route.params.id);
    },

    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleFileChange(files) {
            this.jjDiaData.file = [files]
        },

        async submitUpload() {
            try {
                // await this.validateUpdate();
                await this.$refs.jjform.validate();
                this.allGrantData.calTime = this.jjDiaData.calTime
                this.allGrantData.bonusType = this.jjDiaData.bonusType

                // this.allGrantData.grantMonth = this.allGrant.grantMonth
                this.uploading = this.$loading({
                    target: ".allGrantDia .el-dialog"
                });
                // loadingRef && loadingRef.close();


                this.$refs.jjupload.submit();
                // this.uploading = this.$loading();
                // this.$refs.upload.submit();

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);

            }

        },

        handleUploadSuccess(res, file) {
            console.log(res);
            console.log(file);
            this.uploading.close();
            if (res.code == 2001) {
                this.$message({
                    message: res.msg || "文件有误",
                    type: "warning"
                });
                setTimeout(() => {
                    this.$refs.jjupload.clearFiles();
                    this.jjDiaData.file = []
                }, 20);
            }
            if (res.code == 2000) {
                // this.$message({
                //     message: '上传成功，结果已自动导出',
                //     type: "success"
                // });

                if (res.data.errNum > 0) {
                    this.$message({
                        message: `存在${res.data.errNum}条错误数据，结果已自动导出`,
                        type: "warning"
                    });
                    setTimeout(() => {
                        // window.open(this.$baseURL + '/' + res.data.errJson.path, "download");
                        window.open(this.$baseURL + res.data.errJson.path, "download");
                    }, 2000);
                } else {
                    this.$message({
                        message: '保存成功',
                        type: "success"
                    });
                }
                setTimeout(() => {
                    this.$refs.jjupload.clearFiles();
                    this.jjDiaData.file = []
                    this.showCheckAchievementDia = false
                    this.fetchData();
                }, 500);

            }
        },

        async downLoadfile() {
            let loadingRef,
                url = '/api/order/downGrantBonusTemplate'
            loadingRef = this.$loading();
            try {
                const res = await this.$axios({
                    method: "post", url, data: {}
                })
                console.log(res);
                if (res.code === 2000) {
                    // this.$message({
                    //     message: "下载成功",
                    //     type: "success"
                    // });
                    if (res.data.path) {
                        window.open(this.$baseURL + '/' + res.data.path, "download");
                    }
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003) {
                    loadingRef && loadingRef.close();
                    throw res;
                }
            } catch (reason) {
                console.log(reason);
                loadingRef && loadingRef.close();
            }
        },
        // 点击批量核算业绩奖金
        handleCheckAchievementDiaBtn() {
            this.showCheckAchievementDia = true
            setTimeout(() => {
                this.fileList = []
            }, 20);
            setTimeout(() => {
                this.$refs.jjupload.clearFiles();
            }, 20);
        },
        cancelCheckAchievement() {
            this.jjDiaData = {
                calTime: '',
                bonusType: '',
                file: [],
            }
            this.showCheckAchievementDia = false
            this.$refs.jjupload.clearFiles();
            setTimeout(() => {
                this.$refs.jjform.clearValidate();
            }, 20);
        },


        async fetchEnum() {
            const res = await this.$axios({
                url: "/api/order/queryOrderParam",
                method: "post"
            });

            if (res.code === 2000) {
                this.seasonList = res.data.seasonList

                this.SP_.seasonId = [Number(this.$route.params.id)] 

            } else if (res.code !== 1003)
                throw res
        },



        // 点击撤销
        async handleRevokeApply(row) {
            let loadingRef;
            try {
                await this.$confirm(`您是否要撤销${row.buyName}客户订单的业绩审核？`, "撤销业绩审核")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderComm/revokeApply",
                    method: "post",
                    data: {
                        id: row.id
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "撤销成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "撤销失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleClose() {
            this.updateMoneyCheckStateDia = false
            // this.resetData();
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            // this.$emit("update:show", false);
        },

        async handUpdateMoneyCheckState() {
            const loadingRef = this.$loading({
                target: ".updateMoneyCheckStateDia .el-dialog"
            });
            let obj = {
                subscribeName: this.clickRow.buyName,
                subscribeMoney: this.clickRow.contractMoney,
                calNum: this.performanceEmpInfo.calNum,
                saleEmp: this.performanceEmpInfo.workEmpId,
                saleCharge: this.performanceEmpInfo.workChargeId,
                saleManager: this.performanceEmpInfo.workManageId,
                isCooperation: this.performanceEmpInfo.isCooperation,
                coopEmp: this.performanceEmpInfo.cooperationEmpId,
                coopCharge: this.performanceEmpInfo.cooperationChargeId,
                coopManager: this.performanceEmpInfo.cooperationManageId,
            }
            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/orderComm/updateMoneyCheckState",
                    method: "post",
                    data: {
                        ...this.PB,
                        id: this.clickRow.id,
                        performanceEmpStr: JSON.stringify(obj)
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change");
                    this.handleClose();
                    this.fetchData()

                } else if (res.code !== 1003)
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        handleUpdateMoneyCheckState(row) {
            this.clickRow = row
            this.queryPerformanceCalData(row)
            this.updateMoneyCheckStateDia = true

        },
        async queryPerformanceCalData(data) {
            let loadingRef = this.$loading({
                target: ".updateMoneyCheckStateDia .el-dialog"
            });

            try {
                const res = await this.$axios({
                    url: "/api/order/queryOrderSaleAndCoopInfo",
                    method: "post",
                    data: {
                        type: 1, //0：提交绩效审核 1：薪酬审核
                        orderId: data.orderId
                    }
                })
                if (res.code === 2000) {
                    this.performanceEmpInfo = res.data.data
                    loadingRef && loadingRef.close();
                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "获取失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        async handleOrderCommRecord(row) {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/orderComm/queryOrderCommRecordList",
                method: "post",
                data: {
                    orderCommId: row.id
                }
            });
            if (res.code === 2000) {
                this.showOrderCommRecord = true
                this.orderCommRecordList = res.data.orderCommRecordList
                // this.total = res.data.count;
                // const list = res.data.orderCommApplyList.map(item => {
                //     const state = this.stateEnum.find(({ value }) =>
                //         value === item.checkState)
                //     item.checkStateName = state?.name || "";
                //     item.commCheckTimeName = item.commCheckTimeName?.slice(0, 7)
                //     return item;
                // });
                // this.tableData = list;

                loading.close();
            } else if (res.code !== 1003)
                throw res

        },
        showPic() {
            this.showPicDia = true
        },
        clearSP_trim() {
            this.SP_.searchType = 1,
                this.SP_.searchTimeType = 1;
        },
        updateSPtoSP_trim() {
            return 'seasonBonusDetail'
        },

        async fetchData() {
            const loading = this.$loading();

            try {
                if (this.seasonList.length == 0) {
                    const res_season = await this.$axios({
                        url: "/api/order/queryOrderParam",
                        method: "post"
                    });

                    if (res_season.code === 2000) {
                        this.seasonList = res_season.data.seasonList
                        this.SP_.seasonId = [Number(this.$route.params.id)] 

                    } else if (res_season.code !== 1003)
                        throw res_season
                }
                const res = await this.$axios({
                    url: "/api/order/querySeasonBonusDetailList",
                    method: "post",
                    data: {
                        ...this.extractParams(),
                        seasonId: this.SP_.seasonId.join(',')
                        // searchCheckDest: ""
                    }
                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    // const list = res.data.orderCommApplyList.map(item => {
                    //     const state = this.stateEnum.find(({ value }) =>
                    //         value === item.checkState)
                    //     item.checkStateName = state?.name || "";
                    //     item.commCheckTimeName = item.commCheckTimeName?.slice(0, 7)
                    //     return item;
                    // });
                    // this.tableData = list;

                    this.typeEnum = res.data.orderBonusTypeEnums;

                    this.tableData = res.data.data;


                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },

        handleChinaAuditBtn() {
            if (this.checkedlist.some(({ checkState }) => checkState !== 1))
                return this.$message({
                    message: "请选择待中国区审核的申请",
                    type: "error"
                })
            this.showChinaAudit = true;
        },
        handleAusAuditBtn() {
            if (this.checkedlist.some(({ checkState }) => (checkState !== 2 && checkState !== 3)))
                return this.$message({
                    message: "请选择待澳洲区审核或审核通过的申请",
                    type: "error"
                })
            this.showAusAudit = true;
        },

        // 点击导出订单
        async handleExportBtn(flag) {
            // if (!this.SP_.searchStartTime && !this.SP_.searchEndTime)
            //     return this.$message({
            //         message: "请选择时间范围",
            //         type: "warning"
            //     });

            if (this.SP_.seasonId.length == 0)
                return this.$message({
                    message: "请选择赛季名称",
                    type: "warning"
                });



            let loadingRef,
                url = "/api/order/querySeasonBonusDetail";
            try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();

                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 点击导出奖金发放明细
        async handleExportBtn2(flag) {
            if (this.SP_.seasonId.length == 0)
                return this.$message({
                    message: "请选择赛季名称",
                    type: "warning"
                });

            let loadingRef,
                url = "/api/order/exportSeasonBonusGrantDetail";
            try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();

                const data = this.extractParams(true);
                const res = await this.$axios({
                    method: "post", url, data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    loadingRef && loadingRef.close();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },

        // 点击快速筛选
        handleTabClick(state) {
            if (state !== this.SP_.searchCheckState) {
                this.SP_.searchCheckState = state;
            }
        },

        handleOrderDetail(item) {
            const url = new URL(location.href);
            url.hash = `/core/order/detail/${item.orderId}`
            window.open(url)
        },

        handleCommissionDetail(data) {
            this.$router.push({
                path: `/core/eoi/detail/${data.id}`
            });
        },
        // 发放奖金
        toDetail(data) {
            this.$router.push({
                path: `/commission/seasonBonusList/detail/detail/${data.id}`
            });
        },


        // 点击: 批量删除
        async handleBulkDelete() {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/orderComm/deleteOrderCommApply",
                    method: "post",
                    data: {
                        applyIds: this.checkedlist.map(
                            it => it.id).join()
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        }
    }
};
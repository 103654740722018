<!-- 配置员工负责的系统 -->
<template>
    <el-form
        :model="orderMap"
        :rules="rules"
        class="order-part5 order-part private"
        label-width="130px"
        label-suffix=": "
        ref="form1"
    >
        <div class="part-title">
            <h3>投资确认书</h3>
        </div>

        <el-form-item label="投资确认书">
            <el-radio-group v-model="orderMap.isAff">
                <el-radio :label="1">客户已收到</el-radio>
                <el-radio :label="0">客户未收到</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label="备注">
            <el-input v-model="orderMap.remark"
                type="textarea" rows="5"
                maxlength="200"
                class="width4"></el-input>
        </el-form-item>

        <el-form-item label="附件上传" class="wrap">
            <UploadFile
                v-model="orderMap.path"
                :show-file-list="true"
                :accept="uploadAccept"
                :limit="5"
                :maxSize="1e4"
                max-width="440px"
            >
                <span class="hint" slot="hint">文件上传最多只能上传5张</span>
            </UploadFile>
        </el-form-item>

    </el-form>
</template>

<script>
import UploadFile from '../../../components/UploadFile/UploadFile.vue'

export default {
    name: 'FormPart5',
    components: {UploadFile},
    props: [
        "orderMap"
    ],

    data () {
        return {
            uploadAccept: "image/*,application/pdf,application/x-pdf",
            rules: {},
        }
    },

    computed: {
        isUpdate () {
            return !!this.orderMap?.id
        }
    },

    watch: {},

    methods: {}
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
<!-- 修改渠道结佣信息 -->
<template>
    <el-dialog class="update-follow"
        :visible="viewIsReady"
        @close="handleClose"
        title="补录到期状态"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="到期后投资状态" prop="laterState">
                <el-radio-group v-model="PB.laterState">
                    <el-radio
                        v-for="e in opts"
                        :key="e.value"
                        :label="e.value"
                    >{{e.name}}</el-radio>
                </el-radio-group>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'UpdateFollowUp',
    props: [ "item", "opts" ],

    data () {
        return {
            viewIsReady: false,
            PB: {
                laterState : 0
            },
            rules: {
                laterState : {required: true, message: "请选择是否结佣"}
            }
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.PB.laterState  = item.laterState;
                this.viewIsReady = true;
            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".update-follow .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/order/updateOrderLaterState",
                    method: "post",
                    data: {
                        ...this.PB,
                        orderId: this.item.id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "修改成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "修改失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.laterState = 0;
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>
<template>
    <el-dialog :append-to-body='true' class="newDealInfoDialog" :visible="showdia" @close="closedia" title="是否核算新签客户说明" width="500px">
        <div>
            <div class="dc-discount-con">
                <div style="display: flex;margin-bottom: 12px;">
                    <div style="width: 80px;flex-shrink: 0; text-align: right;color: #333;">用途：</div>
                    <div style="color: #707070;">
                        用于市场部、渠道部绩效考核中“新资源”的判定。
                    </div>
                </div>
                <div style="display: flex;margin-bottom: 12px;">
                    <div style="width: 80px;flex-shrink: 0; text-align: right;color: #333;">判断规则：</div>
                    <div style="color: #707070;">
                        客户在公司第一次成交房产/移民/债券，成交月内的所有订单核算为新客户；非第一次成交月份的均核算为老客户。
                    </div>
                </div>
                <div style="display: flex;margin-bottom: 12px;color: #333;">
                    <div style="width: 80px;flex-shrink: 0; text-align: right;">特别注意：</div>
                    <div style="color:#ff4500;">
                        <div style="margin-bottom: 8px;">1.“是否核算为新资源成交”字段功能上线为2023年9月。</div>
                        <div style="margin-bottom: 8px;">2.2023年10月之前的历史数据根据判断规则由系统自行判定（历史数据辩证查看，有问题联系总经办）。</div>
                        <div style="margin-bottom: 8px;">3.2023年10月及之后数据，在每赛季人工核对完成后，由总经办导入最终数据封档。</div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="footer">
            <el-button @click="closedia" type="primary">确认</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'newDealInfoDialog',
    props: {
        showdia: {        // slot 容器盒子是否时 flex 模型
            type: Boolean,
            default: false
        },
    },
    data () {
        return {}
    },
    computed: {
    },
    methods: {
        closedia(){
            this.$emit("close"); // 
        },
    },
    created () {},
    beforeMount () {},
    mounted () {},
    components: {},
    watch: {}
}
</script>

<style lang='scss' scoped>
    
</style>
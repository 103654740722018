<template>
    <div class="wrapper">
        <div class="wrapperbox">

            <el-form class="private" :model="PB" :rules="rules" label-width="130px" label-suffix=":" ref="form" @submit.prevent="handleSave">
                <div style="text-align: center;font-weight: 600;padding-top: 24px;font-size:16px;color:#FD9A17;">{{seasonInfo.name}}</div>

                <div class="flex box">
                    <div class="fcom">
                        <div class="title">非公司债</div>
                        <div>
                            <el-form-item label="奖金基数" prop="baseBonus">
                                <el-input v-model.trim="PB.baseBonus" type="integer" maxlength="5" @input="handleInput('baseBonus')" class="width22">
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>

                            <el-form-item label="新签奖金" prop="xqScale" style="margin-bottom:12px;">
                                <el-input v-model.trim="PB.xqScale" type="integer" maxlength="5" @input="handleInput('xqScale')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <div class="flex" style="align-items: center;    width: 100%;padding-left:130px;">
                                <el-form-item label="保底奖金与PK奖金比例" prop="guaranteeXqRatio" class="ratioform ratioforml childform" style="width: calc(50% + 10px);">
                                    <div slot="label" style="line-height: normal;">
                                        <span style="color: #D40000;">*</span>
                                        保底奖金与<br />PK奖金比例:
                                    </div>
                                    <el-input v-model.trim="PB.guaranteeXqRatio" type="integer" maxlength="5" @input="handleInput('guaranteeXqRatio')" class="width1111 ratiol" style="flex: 1;"></el-input>
                                </el-form-item>
                                <div style="width: 60px;margin-bottom: 24px;text-align: center;flex-shrink: 0;">:</div>

                                <el-form-item label="" prop="pkXqRatio" class="ratioform ratioformr" style="width: calc(50% - 70px);">
                                    <el-input v-model.trim="PB.pkXqRatio" type="integer" maxlength="5" @input="handleInput('pkXqRatio')" class="width1111"></el-input>
                                </el-form-item>
                            </div>

                            <el-form-item label="续转奖金" prop="xzScale" style="margin-bottom:12px;">
                                <el-input v-model.trim="PB.xzScale" type="integer" maxlength="5" @input="handleInput('xzScale')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>

                            <div class="flex" style="align-items: center;    width: 100%;padding-left:130px;">
                                <el-form-item label="保底奖金与PK奖金比例" prop="guaranteeXzRatio" class="ratioform ratioforml childform" style="width: calc(50% + 10px);">
                                    <div slot="label" style="line-height: normal;">
                                        <span style="color: #D40000;">*</span>
                                        保底奖金与<br />PK奖金比例:
                                    </div>
                                    <el-input v-model.trim="PB.guaranteeXzRatio" type="integer" maxlength="5" @input="handleInput('guaranteeXzRatio')" class="width1111 ratiol" style="flex: 1;"></el-input>
                                </el-form-item>
                                <div style="width: 60px;margin-bottom: 24px;text-align: center;flex-shrink: 0;">:</div>

                                <el-form-item label="" prop="pkXzRatio" class="ratioform ratioformr" style="width: calc(50% - 70px);">
                                    <el-input v-model.trim="PB.pkXzRatio" type="integer" maxlength="5" @input="handleInput('pkXzRatio')" class="width1111"></el-input>
                                </el-form-item>
                            </div>

                            <el-form-item label="增投奖金" prop="ztScale" style="margin-bottom:12px;">
                                <el-input v-model.trim="PB.ztScale" type="integer" maxlength="5" @input="handleInput('ztScale')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <div class="flex" style="align-items: center;    width: 100%; padding-left:130px;">
                                <el-form-item label="保底奖金与PK奖金比例" prop="guaranteeRatio" class="ratioform ratioforml childform" style="width: calc(50% + 10px);">
                                    <div slot="label" style="line-height: normal;">
                                        <span style="color: #D40000;">*</span>
                                        保底奖金与<br />PK奖金比例:
                                    </div>
                                    <el-input v-model.trim="PB.guaranteeRatio" type="integer" maxlength="5" @input="handleInput('guaranteeRatio')" class="width1111 ratiol" style="flex: 1;"></el-input>
                                </el-form-item>
                                <div style="width: 60px;margin-bottom: 24px;text-align: center;flex-shrink: 0;">:</div>

                                <el-form-item label="" prop="pkRatio" class="ratioform ratioformr" style="width: calc(50% - 70px);">
                                    <el-input v-model.trim="PB.pkRatio" type="integer" maxlength="5" @input="handleInput('pkRatio')" class="width1111"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="com">
                        <div class="title">公司债</div>
                        <div>
                            <el-form-item label="奖金基数" prop="comBaseBonus">
                                <el-input v-model.trim="PB.comBaseBonus" type="integer" maxlength="5" @input="handleInput('comBaseBonus')" class="width22">
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="新签奖金" prop="comXqScale" style="margin-bottom:12px;">
                                <el-input v-model.trim="PB.comXqScale" type="integer" maxlength="5" @input="handleInput('comXqScale')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <div class="flex" style="align-items: center;    width: 100%;padding-left:130px;">
                                <el-form-item label="保底奖金与PK奖金比例" prop="comGuaranteeXqRatio" class="ratioform ratioforml childform" style="width: calc(50% + 10px);">
                                    <div slot="label" style="line-height: normal;">
                                        <span style="color: #D40000;">*</span>
                                        保底奖金与<br />PK奖金比例:
                                    </div>
                                    <el-input v-model.trim="PB.comGuaranteeXqRatio" type="integer" maxlength="5" @input="handleInput('comGuaranteeXqRatio')" class="width1111 ratiol" style="flex: 1;"></el-input>
                                </el-form-item>
                                <div style="width: 60px;margin-bottom: 24px;text-align: center;flex-shrink: 0;">:</div>

                                <el-form-item label="" prop="comPkXqRatio" class="ratioform ratioformr" style="width: calc(50% - 70px);">
                                    <el-input v-model.trim="PB.comPkXqRatio" type="integer" maxlength="5" @input="handleInput('comPkXqRatio')" class="width1111"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item label="续转奖金" prop="comXzScale" style="margin-bottom:12px;">
                                <el-input v-model.trim="PB.comXzScale" type="integer" maxlength="5" @input="handleInput('comXzScale')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <div class="flex" style="align-items: center;    width: 100%;padding-left:130px;">
                                <el-form-item label="保底奖金与PK奖金比例" prop="comGuaranteeXzRatio" class="ratioform ratioforml childform" style="width: calc(50% + 10px);">
                                    <div slot="label" style="line-height: normal;">
                                        <span style="color: #D40000;">*</span>
                                        保底奖金与<br />PK奖金比例:
                                    </div>
                                    <el-input v-model.trim="PB.comGuaranteeXzRatio" type="integer" maxlength="5" @input="handleInput('comGuaranteeXzRatio')" class="width1111 ratiol" style="flex: 1;"></el-input>
                                </el-form-item>
                                <div style="width: 60px;margin-bottom: 24px;text-align: center;flex-shrink: 0;">:</div>

                                <el-form-item label="" prop="comPkXzRatio" class="ratioform ratioformr" style="width: calc(50% - 70px);">
                                    <el-input v-model.trim="PB.comPkXzRatio" type="integer" maxlength="5" @input="handleInput('comPkXzRatio')" class="width1111"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item label="增投奖金" prop="comZtScale" style="margin-bottom:12px;">
                                <el-input v-model.trim="PB.comZtScale" type="integer" maxlength="5" @input="handleInput('comZtScale')" class="width22">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <div class="flex" style="align-items: center;    width: 100%;padding-left:130px;">
                                <el-form-item label="保底奖金与PK奖金比例" prop="comGuaranteeRatio" class="ratioform ratioforml childform" style="width: calc(50% + 10px);">
                                    <div slot="label" style="line-height: normal;">
                                        <span style="color: #D40000;">*</span>
                                        保底奖金与<br />PK奖金比例:
                                    </div>
                                    <el-input v-model.trim="PB.comGuaranteeRatio" type="integer" maxlength="5" @input="handleInput('comGuaranteeRatio')" class="width1111 ratiol"></el-input>
                                </el-form-item>
                                <div style="width: 60px;margin-bottom: 24px;text-align: center;flex-shrink: 0;">:</div>
                                <el-form-item label="" prop="comPkRatio" class="ratioform ratioformr" style="width: calc(50% - 70px);">
                                    <el-input v-model.trim="PB.comPkRatio" type="integer" maxlength="5" @input="handleInput('comPkRatio')" class="width1111"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
            <div style="display: flex;justify-content: center;">
                <el-button @click="handleNavigationBack">返回</el-button>
                <el-button @click="handleSave" type="primary">保存</el-button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            seasonId: "",
            seasonBonusConfig: "",
            PB: {
                // 非公司
                baseBonus: "", //非公司债奖金基数
                xqScale: "", //非公司债新签奖金比例
                xzScale: "", //非公司债续转比例
                ztScale: "", //非公司债增投比例
                guaranteeRatio: "", //非公司债保底奖金比值
                pkRatio: "", //非公司债pk奖金比值
                guaranteeXqRatio: "", //非公司债保底奖金比值新签
                pkXqRatio: "", //非公司债pk奖金比值 — 新签
                guaranteeXzRatio: "", //非公司债保底奖金比值 续转
                pkXzRatio: "", //非公司债pk奖金比值 — 续转
                // 公司
                comBaseBonus: "", //公司债奖金基数
                comXqScale: "", //公司债新签奖金比例
                comXzScale: "", //公司债续转比例
                comZtScale: "", //公司债增投比例
                comGuaranteeRatio: "", //公司债保底奖金比值
                comPkRatio: "", //公司债pk奖金比值
                comGuaranteeXqRatio: "", //公司债保底奖金比值新签
                comPkXqRatio: "", //公司债pk奖金比值 — 新签
                comGuaranteeXzRatio: "", //公司债保底奖金比值 续转
                comPkXzRatio: "", //公司债pk奖金比值 — 续转

                id: "",
            },
            rules: {
                baseBonus: {
                    required: true,
                    message: "请输入奖金基数",
                    trigger: "blur",
                },
                xqScale: {
                    required: true,
                    message: "请输入新签奖金比例",
                    trigger: "blur",
                },
                xzScale: {
                    required: true,
                    message: "请输入续转比例",
                    trigger: "blur",
                },
                ztScale: {
                    required: true,
                    message: "请输入增投比例",
                    trigger: "blur",
                },
                guaranteeRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                guaranteeXqRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                pkXqRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                guaranteeXzRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                pkXzRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },

                pkRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },

                comGuaranteeXqRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                comPkXqRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                comGuaranteeXzRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                comPkXzRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                comBaseBonus: {
                    required: true,
                    message: "请输入奖金基数",
                    trigger: "blur",
                },
                comXqScale: {
                    required: true,
                    message: "请输入新签奖金比例",
                    trigger: "blur",
                },
                comXzScale: {
                    required: true,
                    message: "请输入续转比例",
                    trigger: "blur",
                },
                comZtScale: {
                    required: true,
                    message: "请输入增投比例",
                    trigger: "blur",
                },
                comGuaranteeRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
                comPkRatio: {
                    required: true,
                    message: "请输入比值",
                    trigger: "blur",
                },
            },
            seasonInfo: "",
        };
    },
    created() {
        console.log(this.$route.params.id);
        this.seasonId = this.$route.params.id;
        this.getBonusRules();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        handleInput(name) {
            this.PB[name] = this.PB[name].replace(/[^0-9]/g, "");
            if (this.PB[name].length > 5) {
                this.PB[name] = this.PB[name].slice(0, 5);
            }
            if (!Number.isInteger(+this.PB[name])) {
                this.PB[name] = "0";
            }
            if (this.PB[name].length > 1 && this.PB[name][0] === "0") {
                // 如果第一位是0,则不允许输入大于0的数字
                this.PB[name] = "0";
            } else if (this.PB[name][0] !== "0") {
                // 如果第一位不是0,则允许输入大于0的数字
                this.PB[name] = this.PB[name].replace(/^0+/, ""); // 去掉开头的0
            }
        },
        async handleSave() {
            const loading = this.$loading();

            try {
                await this.$refs.form.validate();
                const {
                    baseBonus,
                    xqScale,
                    xzScale,
                    ztScale,
                    guaranteeRatio,
                    pkRatio,
                    guaranteeXqRatio,
                    pkXqRatio,
                    guaranteeXzRatio,
                    pkXzRatio,
                    comBaseBonus,
                    comXqScale,
                    comXzScale,
                    comZtScale,
                    comGuaranteeRatio,
                    comPkRatio,
                    comGuaranteeXqRatio,
                    comPkXqRatio,
                    comGuaranteeXzRatio,
                    comPkXzRatio,
                    id,
                } = { ...this.PB };
                const res = await this.$axios({
                    method: "post",
                    url: "/api/season/saveSeasonBonusConfig",
                    data: {
                        baseBonus,
                        xqScale,
                        xzScale,
                        ztScale,
                        guaranteeRatio,
                        pkRatio,
                        guaranteeXqRatio,
                        pkXqRatio,
                        guaranteeXzRatio,
                        pkXzRatio,
                        comBaseBonus,
                        comXqScale,
                        comXzScale,
                        comZtScale,
                        comGuaranteeRatio,
                        comPkRatio,
                        comGuaranteeXqRatio,
                        comPkXqRatio,
                        comGuaranteeXzRatio,
                        comPkXzRatio,
                        id,
                        seasonId: this.seasonId,
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    this.getBonusRules();
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loading.close();
            }
        },

        // 点击返回
        handleNavigationBack() {
            this.$router.back();
        },
        async getBonusRules() {
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/season/querySeasonBonusConfig",
                    method: "post",
                    data: {
                        seasonId: this.seasonId,
                    },
                });
                if (res.code === 2000) {
                    if (res.data.seasonBonusConfig) {
                        this.PB = res.data.seasonBonusConfig;
                    }
                    this.seasonInfo = res.data.season;
                    loading.close();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning",
                });

                loading.close();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.box {
    padding: 0px 24px 0px 24px;
    display: flex;
    justify-content: space-around;
}
.title {
    text-align: left;
    padding: 0 0 20px 0;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #ebedf0;
    margin-bottom: 16px;
    color: #333;
}
.fcom {
    margin-right: 24px;
}
.fcom,
.com {
    width: 40%;
    padding: 24px;
    border-radius: 4px;
    background: #fff;
    padding-top: 26px;
}
.ratioform {
    display: flex;
    align-items: center;
}
.ratioform /deep/ .el-form-item__content {
    margin-left: 0 !important;
}
.wrapper {
    padding: 16px;
}
.wrapperbox {
    background: #fff;
    padding-bottom: 40px;
}
.el-form.private .el-form-item {
    margin-bottom: 24px;
}
.ratioforml /deep/ .el-form-item__content {
    flex: 1;
}
.ratioformr /deep/ .el-form-item__content {
    width: 100%;
}
.childform /deep/ .el-form-item__label {
    font-size: 12px;
    width: 80px !important;
}
</style>

<style>
.ratioform.is-required:not(.is-no-asterisk) > .el-form-item__label:before,
.ratioform.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before {
    content: "";
    color: blue !important;
    display: none;
}
</style>
<!-- 结算渠道商佣金 -->
<template>
    <el-dialog class="settle-commission"
        :visible="viewIsReady"
        @close="handleClose"
        title="结算外部渠道佣金"
        width="800px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="120px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <ul class="summary">
                <li><span class="tag">客户编号</span><span class="value">{{ placeItem.cust_code }}</span></li>
                <li><span class="tag">客户姓名</span><span class="value">{{ placeItem.buyName }}</span></li>
                <li><span class="tag">区域</span><span class="value">{{ placeItem.regionName }}</span></li>
                <li><span class="tag">认购产品</span><span class="value">{{ placeItem.productName }}</span></li>
                <li><span class="tag">认购金额</span><span class="value">{{ placeItem.contractMoney }}澳元</span></li>
                <li><span class="tag">是否结算外部渠道佣金</span><span class="value">{{ placeItem.isChanCommName}}</span></li>
            </ul>
            <el-form-item label="第三方渠道">
                {{ placeItem.orderChanName }}
            </el-form-item>

             <el-form-item label="是否开票" prop="isInvoicing">
                <el-radio-group v-model="PB.isInvoicing">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>


            <el-form-item label="结佣日期" prop="commtime">
                <el-date-picker
                    class="width4"
                    v-model="PB.commtime"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>

            <el-form-item label="结佣金额" prop="commmoney">
                <el-input v-model="PB.commmoney"
                    maxlength="11" class="width4">
                </el-input>
            </el-form-item>
            <el-form-item label="附件" prop="file" class="wrap fileForm">
                <UploadFile1
                    v-model="PB.file"
                    :cols="1"
                    :accept="uploadAccept"
                    :limit="3"
                    :maxSize="5000"
                    :uptype='1'
                    :acceptExplainText='acceptExplainText'
                >
                    <span class="hint" slot="hint">最多3个附件信息，允许上传图片与pdf文件</span>
                </UploadFile1>
            </el-form-item>
            <el-form-item
                label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea"
                    maxlength="100"
                    class="width4"></el-input>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UploadFile1 from '../../../components/UploadFile1/UploadFile1.vue'

export default {
    name: 'SettleCommission',
    props: [ "item" ],
    components: {UploadFile1},
    data () {
        return {
            acceptExplainText:'文件',
            uploadAccept    : "image/*,application/pdf,application/x-pdf",
            viewIsReady: false,
            PB: {
                commtime : "",
                commmoney: "",
                remark   : "",
                isInvoicing   : "",
                file:[],
            },
            rules: {
                commtime : {required: true, message: "请选择结佣日期",trigger: "blur"},
                isInvoicing : {required: true, message: "请选择是否开票",trigger: "blur"},
                commmoney: [
                    {required: true, message: "请填写结佣金额",trigger: "blur"},
                    { validator (r, val, c) {
                        if (typeof val === "number") c()
                        else if (!/^\d{1,11}(\.\d{1,2})?$/.test(val)) {
                            c("请输入数字, 最多包含两位小数")
                        }
                        else c()
                    },trigger: "blur"}
                ],
                file: { required: true, message: "请上传附件", trigger:['change', 'blur'] },


            }
        }
    },

    computed: {
        placeItem () {
            return this.item || {}
        }
    },

    watch: {
        item (item, old) {
            console.log(item);
            if (item && item !== old) {
                // setTimeout(() => {
                //     this.$refs.form.clearValidate();
                // }, 20);

                this.PB.isInvoicing = item.isInvoicing;
                this.PB.commtime = item.commTimeName;
                this.PB.commmoney = item.commMoney;
                this.PB.remark = item.remark;


                 if (item.filePath) this.PB.file =
                    item.filePath.split(",").filter(Boolean).map(
                        path => {
                            return {
                                name: path.slice(path.lastIndexOf("/")+1),
                                path: path,
                                url: this.$baseURL + "/" + path,
                            }
                        })
                this.viewIsReady = true;
            }
        },
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".settle-commission .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {file} = this.PB;
                if ([...file].some(({percentage,status}) =>(percentage < 100 || status =='uploading'))){
                    this.$message({
                        message: "请等待附件上传完毕",
                        type: "error"
                    });
                    loadingRef && loadingRef.close();
                    return false
                }



                if(this.item.commChanCode!=null&&this.item.orderChanCode!=this.item.commChanCode){
                    await this.$confirm("此订单已结算渠道佣金，不可重新结算，渠道商为"+this.item.commChanName, "系统提示");
                    loadingRef && loadingRef.close();
                        this.handleClose();

                }else{
                    console.log(this.PB.file);
                        const res = await this.$axios({
                        url: "/api/orderChanComm/saveChanCommInfo",
                        method: "post",
                        data: {
                            orderId:this.item.orderId,
                            id: this.item.commId,
                            channelcode: this.item.orderChanCode||'',
                            commtime:this.PB.commtime,
                            commmoney:this.PB.commmoney,
                            remark:this.PB.remark,
                            isInvoicing:this.PB.isInvoicing,
                            filePath: file.map(item => item.path).join()
                        }
                    })

                    if (res.code === 2000) {
                        this.$message({
                            message: "操作成功",
                            type: "success"
                        });
                        loadingRef && loadingRef.close();
                        this.$emit("change");
                        this.handleClose();

                    } else if (res.code !== 1003) 
                        throw res;
                }
                


            } catch (reason) {
                console.log(reason);
                if(reason!='cancel' ){
                    reason && this.$message({
                        message: reason.msg || "操作失败",
                        type: "error"
                    });
                }

                // console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.isInvoicing  = '';
            this.PB.commtime  = "";
            this.PB.commmoney = "";
            this.PB.remark    = "";
            this.PB.file    = [];
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            setTimeout(() => {
                this.$refs.form.clearValidate();
                // setTimeout(() => {
                //     this.viewIsReady = false;
                // }, 20);
            }, 20);
            this.$emit("update:item", null);
        }
    }
}
</script>


<style lang='scss' scoped>
    .summary {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-left: 34px;

        li {
            flex: 0 0 48%;
            margin-bottom: 10px;
        }

        .tag {
            color: gray;
            margin-right: 8px;

            &:after {
                content: ":"
            }
        }
    }
    .fileForm /deep/ .el-upload{
        display: flex;
        align-items: center;
    }
    .fileForm /deep/ ul{
        min-width: initial !important;
    }
    .fileForm /deep/ .thumbnail-list{
        margin-top: 16px;
        width: calc(100% - 98px);
    }
    
</style>
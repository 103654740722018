import {nanoid} from "nanoid";

export const today = (function () {
    let today = new Date(),
        Y = today.getFullYear(),
        M = today.getMonth() + 1,
        D = today.getDate();
    M = M < 10 ? `0${M}` : M;
    D = D < 10 ? `0${D}` : D;

    return `${Y}-${M}-${D}`;
}) ();

export const searchTypeEnum = [
    { name: "渠道商名称", value: 1 },
    { name: "认购人姓名", value: 2 },
    { name: "客户编号",   value: 3 }
]

export const dateTypeEnum = [
    { name: "结佣日期",   value: 1 }
]

export const checkStateEnum = [
    { name: "待审核",     value: 0 },
    { name: "审核通过",   value: 1 },
    { name: "审核不通过", value: 2 },
]
export const checkStateEnum2 = [
    // { name: "请选择", value: 0 },
    { name: "待结佣", value: 0 },
    { name: "已结佣", value: 1 },
]

// 表格项（行）的操作菜单
export const actionList = [
    {
        label: "订单详情",
        name: "detail",
        banned: false
    },
    {
        label: "审核详情",
        name: "checkingDetails",
        banned: false
    },
    {
        label: "去审核",
        name: "checkCommission",
        banned: false
    },
    {
        label: "结算渠道佣金",
        name: "settleCommission",
        banned: false
    },
];

// 函数创建一个空的菜单数据
export function initialPB () {
    return {
        nanoid: nanoid(),
        name: "",
        sysId: "",
        roleId: "",
        busiDir: "",
        order: 99,
        level: 99,
        content: "",
    };
}
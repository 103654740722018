<!-- 批量编辑维护员工 -->
<template>
    <el-dialog class="update-manager"
        :visible="show"
        @close="handleClose"
        title="批量编辑维护员工"
        width="560px">

        <el-form
            class="private"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item>
                <p>您已选择{{list.length}}个订单</p>
            </el-form-item>

            <el-form-item label="维护员工" required>
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="showText"
                    :fetch-suggestions="querySearch"
                    @select="handleSelect"
                    class="width3"
                ></el-autocomplete>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import {exportTypeEnum} from "./config";
export default {
    name: 'UpdateManager',
    props: ["show", "list"],

    data () {
        return {
            exportTypeEnum,
            showText: "",
            PB: {
                type: 1,
                empId: 0
            }
        }
    },

    methods: {
        async querySearch(queryString, cb) {
            this.PB.empId = 0;
            try {
				const res = await this.$axios({
					url: "/api/employee/queryLikeEmpList",
					method: "post",
					data: {
                        searchName: queryString,
                        type: 2
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.empList.map(emp => {
                        emp.value = [emp.name, emp.reginName, emp.postName].filter(Boolean).join(" - ")
                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
        },

        handleSelect(item) {
            this.PB.empId = item.id;
        },

        async handleSave () {
            let loadingRef;

            try {
                await this.validate();
                await this.$confirm("确定要修改选中订单的维护员工吗？", "系统提示")
                loadingRef = this.$loading({
                    target: ".update-manager .el-dialog"
                });

                const res = await this.$axios({
                    url: "/api/order/updateServiceEmp",
                    method: "post",
					data: {
                        orderIds: this.list.map(({id})=>id).join(),
                        empId: this.PB.empId
                    }
                })

				if (res.code === 2000) {
					this.$message({
                        message: "操作成功",
                        type: "success"
                    })
                    
                    this.$emit("change");
                    this.handleClose();
	
				} else if (res.code !== 1003) 
					throw res;


            } catch (reason) {
                reason && (reason !== "cancel") && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
            }
            loadingRef && loadingRef.close()
        },

        validate () {
            const {empId} = this.PB;
            if (empId) return Promise.resolve()
            else {
                this.$message({
                    message: "请选择员工",
                    type: "warning"
                });
                return Promise.reject()
            }
        },

        handleClose () {
            this.PB.empId = 0;
            this.showText = "";
            this.$emit("update:show", false);
        }
    }
}
</script>

<style lang='scss' scoped></style>
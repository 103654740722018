import forList from "@/mixin/forList";

export default {
    name: "empSeasonBonusDetail",
    noFetchFirst: true,
    mixins: [forList],
    // noSaveState: 1,
    data() {
        return {
            SP_: {
                seasonId: '',
                orderNo: '',
                empId: '',
            },
        };
    },
    created() {
        console.log(this.$route.query.empId);
        const { params } = this.$route;
        this.SP_.seasonId = this.$route.params.id
        this.SP_.empId = this.$route.query.empId


        if (Object.keys(params).length) {
            for (let key in params) {
                if (key === "searchHrefType") this.searchHrefType = params[key];
                else if (Object.prototype.hasOwnProperty.call(this.SP_, key))
                    this.SP_[key] = params[key]
            }
            this.handleSearch();
        }
        else this.fetchData();
    },

    methods: {
        // 点击返回
        handleNavigationBack() {
            localStorage.setItem("formempSeasonBonusDetail", 1);
            this.$router.push({
                path: `/commission/empSeasonBonusList`,
                query: {
                    type: 1,
                }
            });
        },
        clearSP_trim() {
            this.SP_.seasonId = this.$route.params.id
            this.SP_.empId = this.$route.query.empId

        },
        async fetchData() {
            const loading = this.$loading();

            try {
                const res = await this.$axios({
                    url: "/api/season/queryGrantEmpBonusDetailList",
                    method: "post",
                    data: {
                        ...this.extractParams(),
                    }
                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData = res.data.empBonusList;
                    // this.tableData = res.data.seasonBonusList;


                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
    }
};
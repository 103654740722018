<!-- 申请外部渠道佣金 -->
<template>
    <el-dialog class="apply-channel"
        :visible="viewIsReady"
        @close="handleClose"
        title="申请外部渠道佣金"
        width="660px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >

            <ul class="summary">
                <li><span class="tag">客户编号</span><span class="value">{{ placeItem.cust_code }}</span></li>
                <li><span class="tag">客户姓名</span><span class="value">{{ placeItem.buyName }}</span></li>
                <li><span class="tag">区域</span><span class="value">{{ placeItem.reginName }}</span></li>
                <li><span class="tag">认购产品</span><span class="value">{{ placeItem.productName }}</span></li>
                <li><span class="tag">认购金额</span><span class="value">{{ placeItem.contractMoney }}澳元</span></li>
                <li><span class="tag">是否结算外部渠道佣金</span><span class="value">{{ placeItem.isChanCommName }}</span></li>
            </ul>

            <!-- <el-form-item label="第三方渠道" prop="commname">
                <el-input v-model.trim="PB.commname"
                    class="width3"></el-input>
            </el-form-item> -->

            <!-- {{PB.commname}}
            {{PB.channelCode}} -->


            <el-form-item label="第三方渠道" prop="commname">
                <el-autocomplete
                    placeholder="请输入查询"
                    v-model="PB.commname"
                    :fetch-suggestions="querySearchThree"
                    @change="handleAutocompleteChangeChannel($event, 'channel')"
                    @select="handleSelectChannel($event, 'channel')"
                    class="width3"></el-autocomplete>
            </el-form-item>


            <el-form-item label="上传钉钉审批图" prop="path" class="wrap">
                <UploadFile
                    v-model="PB.path"
                    :show-file-list="true"
                    :accept="uploadAccept"
                    accept-explain-text="文件"
                    :limit="5"
                    :maxSize="5000"
                >
                    <span class="hint" slot="hint">只能上传图片, 最多上传5张, 大小不超过5M</span>
                </UploadFile>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import UploadFile from '../../../components/UploadFile/UploadFile.vue'
export default {
    name: 'ApplyChannel',
    components: {UploadFile},
    props: [
        "item",
    ],

    data () {
        return {
            viewIsReady: false,
            uploadAccept: "image/*",
            PB: {
                commname: "",
                channelCode:'',
                path: []
            },
            rules: {
                commname: [
                    {required: true, message: "请选择第三方渠道"},
                    {validator: this.threeValidator, trigger: "blur"}
                ],
                path    : {required: true, message: "请上传钉钉审批图", trigger: "blur"}, 

            },
        }
    },

    computed: {
        placeItem () {
            return this.item || {}
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                for (let key in this.PB) {
                    switch (key) {
                        case "commname":
                            this.PB[key] = item.channelName||"";
                            break;
                        case "channelCode":
                            this.PB[key] = item.channelCode||"";
                            break;
                        default:
                            break;
                    }
                }
                this.viewIsReady = true;
                setTimeout(() => {
                    this.$refs.form.clearValidate();
                }, 20);
            }
        },
         'PB.commname': {
            handler: function (val, oldVal) {
                console.log(val);
                if(val==''){ 
                    this.PB.channelCode =''
                }
            },
            deep: true,
        },
    },

    methods: {
        threeValidator  (r, val, c) {
            if(!this.PB.channelCode){
                c("请输入渠道商列表第三方渠道")
            }
            else c()
        },
        async querySearchThree(queryString, cb, type) {
            try {
				const res = await this.$axios({
					url: "/api/channelApi/queryChannel",
					method: "post",
					data: {
                        searchName: queryString,
                    }
				});

				if (res.code === 2000) {
                    cb(res.data.channelList.map(emp => {
                        emp.value = [emp.name, this.hidePhoneNumber(emp.contactPhone)].filter(Boolean).join(" - ")
                        return emp;
                    }));

				} else if (res.code !== 1003)
					throw res

			} catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
			}
            
        },
        hidePhoneNumber(phoneNumber) {
            const str = phoneNumber.toString();
            return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        },
        handleAutocompleteChangeChannel(e, key) {
            if (e.trim() !== "") return;
            this.PB.commname = "";
            this.PB.channelCode = "";
        },
        handleSelectChannel(item, key) {
            console.log(item);
            this.PB.commname = item.name;
            this.PB.channelCode = item.code;
        },
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".apply-channel .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/orderChanComm/saveOrderChanCommApply",
                    method: "post",
                    data: {
                        orderId: this.item.id,
                        commname: this.PB.commname,
                        channelCode: this.PB.channelCode,
                        path: this.PB.path.map(file => file.path).join()
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change");
                    // this.handleClose();
                    this.$router.push({
                        path: "/commission/outChannelCommission"
                    });

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.commname = "";
            this.PB.path    = []
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .summary {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-left: 20px;

        li {
            flex: 0 0 48%;
            margin-bottom: 10px;
        }

        .tag {
            color: gray;
            margin-right: 8px;

            &:after {
                content: ":"
            }
        }
    }
</style>
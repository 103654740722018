import forList from "@/mixin/forList";
import ProductEdit from "./ProductEdit.vue";
import {initialPB} from "./config";

export default {
	name: "ProductList",
	mixins: [forList],
	components: {ProductEdit},

	data() {
		return {
			SP_: {
				searchName: "",
                searchTimeType: 1,
                searchStartTime: "",
                searchEndTime: "",
			},
            dateTypeEnum: [
                { name: "产品发行日期", value: 1 },
                { name: "产品到期日期", value: 2 }
            ],
			editingItem: null
		};
	},

	methods: {
        clearSP_trim() {
            this.SP_.searchTimeType = 1
        },

		async fetchData() {
			const loading = this.$loading();

			try {
				const res = await this.$axios({
					url: "/api/product/queryProductList",
					method: "post",
					data:this.extractParams()
				});

				if (res.code === 2000) {
					this.total = res.data.count;
					const list = res.data.productList;
					this.tableData = list;

					loading.close();
				} else if (res.code !== 1003)
					throw res
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});

				loading.close();	
			}
		},

        // 点击导出
        async handleExportBtn () {
			let loadingRef;
			try {
                await this.$confirm("确定导出吗？", "系统提示")
                loadingRef = this.$loading();
                const data = this.extractParams(true);
                const res = await this.$axios({
                    url: "/api/product/downProductInfo",
                    method: "post",
					data
                })

				if (res.code === 2000) {
					this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },

		// 点击 "新增菜单"
		handleAddBtn() {
			this.editingItem = initialPB();
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.editingItem = {
				id: data.id,
				name: data.name,
				investment: data.investment,
				rate: data.rate,
				starttime: data.startTimeName,
				endtime: data.endTimeName,
				realEndTime: data.realEndTimeName,
                valueDateTime: data.valueDateTimeName,
				circulation: data.circulation,
				iscom: data.isCom,
                remark: data.remark,
                calculateType: (data.calculateType !== null && data.calculateType !== undefined) ? data.calculateType + '' : '',
			};
		},

		// 启用禁用
		async handleUpdate (data) {
			let {state, id} = data;
			let actionText = state == 1 ? "禁用" : "启用";
			let loadingRef;
			try {
				await this.$confirm(`确定要${actionText}吗`, "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/product/updateProductState",
					method: "post",
					data: { id, state: state == 1 ? 0 : 1 }
				})

				if (res.code === 2000) {
					this.$message({
						message: `${actionText}成功`,
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();

				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: `${actionText}失败`,
						type: "warn"
					});
				}
				loadingRef && loadingRef.close();
			}
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			let loadingRef;
			try {
				await this.$confirm("确定要删除吗", "系统提示")
				loadingRef = this.$loading();
				const res = await this.$axios({
					url: "/api/product/deleteProduct",
					method: "post",
					data: {
						id: parseInt(data.id)
					}
				})
				if (res.code === 2000) {
					this.$message({
						message: "删除成功",
						type: "success"
					});
					loadingRef && loadingRef.close();
					this.fetchData();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
						message: reason.msg || "删除失败",
						type: "warning"
					});
				}
				loadingRef && loadingRef.close();
			}
		}

	},

	created () {
		this.auth.save   = this.$hasAuthFor("api/product/saveProduct");
		this.auth.delete = this.$hasAuthFor("api/product/deleteProduct");
		this.auth.update = this.$hasAuthFor("api/product/updateProductState");
		this.auth.export = this.$hasAuthFor("api/product/downProductInfo");
	}
};
<!-- 填写银行账户信息 -->
<template>
    <el-form
        class="bank-account-input private"
        ref="form"
        label-width="110px"
        :disabled="disabled"
        :model="accont"
        :rules="rules"
        style="width:100%;"
    >
        <div class="line">
            <el-form-item prop="bankaccname" class="doubel_line_label">
                <template slot="label">
                    <span class="form-item-label">账户名称</span>
                    <span class="form-item-vice">Account Name</span>
                </template>
                <el-input v-model="accont.bankaccname"
                    @blur="handleInputBlur"
                    class="width2"></el-input>
            </el-form-item>

            <el-form-item prop="bankno" class="doubel_line_label" style="width:50%;">
                <template slot="label">
                    <span class="form-item-label">分行号</span>
                    <span class="form-item-vice">BSB</span>
                </template>
                <el-input v-model="accont.bankno"
                    @blur="handleInputBlur"
                    class="width2"></el-input>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item prop="bankaccnumber" class="doubel_line_label">
                <template slot="label">
                    <span class="form-item-label">账号</span>
                    <span class="form-item-vice">Account Number</span>
                </template>
                <el-input v-model="accont.bankaccnumber"
                    @blur="handleInputBlur"
                    type="number"
                    class="width2"></el-input>
            </el-form-item>

            <el-form-item prop="swiftcode" class="doubel_line_label" style="width:50%;">
                <template slot="label">
                    <span class="form-item-label">国际转账编号</span>
                    <span class="form-item-vice">SWIFT Code</span>
                </template>
                <el-input v-model="accont.swiftcode"
                    @blur="handleInputBlur"
                    class="width2"></el-input>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item prop="bankname" class="doubel_line_label">
                <template slot="label">
                    <span class="form-item-label">银行</span>
                    <span class="form-item-vice">Bank</span>
                </template>
                <el-input v-model="accont.bankname"
                    @blur="handleInputBlur"
                    class="width2"></el-input>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'BankAccountInput',
    props: ["accont", "disabled"],

    data () {
        return {
            rules: {
                bankaccname: {required: true, message: "请输入账户名称"},
                bankno: {required: true, message: "请输入分行号"},
                bankaccnumber: {required: true, message: "请输入账号"},
                swiftcode: {required: true, message: "请输入国际转账编号"},
                bankname: {required: true, message: "请输入银行"},
            }
        }
    },

    watch: {
        accont: {
            immediate: true,
            handler (newObj, old) {}
        }
    },

    methods: {
        // async fetchPostAll () {
		// 	try {
		// 		const res = await this.$axios({
		// 			url: "/api/employee/queryPostAll",
		// 			method: "post"
		// 		})
		// 		if (res.code === 2000) {
		// 			const list = res.data.postList;
		// 			this.postList = list;

		// 		} else if (res.code !== 1003)
		// 			throw res
				
		// 	} catch (reason) {
		// 		console.warn("获取岗位配置参数出错", reason);
		// 	}
        // },
        handleInputBlur () {
            console.log("in changeeeee");
            this.$emit("change")
        },

        handleSelect(item) {
            this.PB.empList.push(item);
            this.showText = "";
        },

        validate () {
            return this.$refs.form.validate()
        }
    }
}
</script>

<style lang='scss' scoped></style>
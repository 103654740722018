import forList from "@/mixin/forList";
import { searchTypeEnum } from "./config";

export default {
    name: "StaffList",
    noFetchFirst: true,
    mixins: [forList],

    data() {
        return {
            searchBarLabelWidth: 120, // 不会改变，不用追踪

            SP_: {
                searchType: 1,
                searchValue: "",
            },
            searchTypeEnum,
        };
    },

    methods: {
        async fetchData() {
            const loading = this.$loading();
            try {
                const res = await this.$axios({
                    url: "/api/channelApi/queryChannelList",
                    method: "post",
                    data: { ...this.extractParams() }
                })
                if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData = res.data.channelList;

                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },
        clearSP_trim() {
            this.SP_.searchType = 1
        },

    },

    created() {
        this.fetchData();
    }
};
<template>
    <el-form
        :model="orderMap"
        :rules="form1Rules"
        :disabled="(orderMap.processCheckState ==3||orderMap.processCheckState ==4 ||orderMap.processCheckState ==5 ||orderMap.processCheckState ==6 ||orderMap.processCheckState ==7 ) && !(isWhiteMan)"
        class="order-part1 order-part private"
        label-width="180px"
        label-suffix=": "
        ref="form1"
    >
        <div class="part-title"><h3>客户基础资料</h3></div>
        <!-- {{orderMap.prenames}} -->
        <div class="line">
            <el-form-item label="业绩归属" prop="deptId">
                <el-input
                    v-model="orderMap.prenames"
                    @focus="handleSelectBun"
                    class="width3"
                    placeholder="请选择"
                ></el-input>

                <el-button type="text" class="ml2"
                    @click="handleSelectBun"
                >选择</el-button>

                <PickerTree
                    @input="handlePick"
                    :value="orderMap.deptId"
                    :tree="pickerTree"
                    :show.sync="showPicker"
                    :expand-keys="defaultExpandedKeys"
                    title="变更所属"
                    :treeProps ="{ children: 'children', label: 'text'}"
                    ></PickerTree>
            </el-form-item>
        </div>

        <div class="line ">
            <div style="display:flex;flex-direction: column;">
                <el-form-item label="EOI资金类型" prop="orderProcess.type" class="needTipLabel" style="margin-bottom:0;">
                    <el-tooltip manual :value='processflag'  style="position: absolute;left: -20px;top: 9px; " class="item" effect="dark" content="系统登记的客户欠借款的总金额" placement="top-start">
                            <img src="../../../assets/images/tip.jpg" class="f14 hover mb2" @click.stop="replyContent" alt="">
                            <div slot="content">
                                <div class="flex hw_mb3">
                                    <div style="width:160px;text-align: right;">现金基金（优先级1）：</div>
                                    <div>当前产品募集期前存在于基金账户的新资金；</div>
                                </div>
                                <div class="flex hw_mb3">
                                    <div style="width:160px;text-align: right;">续签/转签（优先级2）：</div>
                                    <div>进入过公司其他投资产品且原产品已到期的资金；</div>
                                </div>
                                <div class="flex hw_mb3">
                                    <div style="width:160px;text-align: right;">抵押贷款（优先级3）：</div>
                                    <div>国内或国外房屋在银行抵押贷款资金；</div>
                                </div>
                                <div class="flex hw_mb3">
                                    <div style="width:160px;text-align: right;">新签（优先级4）：</div>
                                    <div>
                                        <div>从未进入公司任何投资产品的普通新资金；</div>
                                        <!-- <div>2.新签+现金基金：普通新签资金+现金基金资金的组合；</div>
                                        <div>3.新签+续/转签：普通新签资金+续/转签资金的组合；</div>
                                        <div>4.新签+抵押贷款：普通新签资金+抵押贷款资金的组合；</div> -->
                                    </div>
                                </div>
                            </div>
                    </el-tooltip>

                    <el-radio-group
                        :disabled="orderMap.processCheckState ==3 || orderMap.processCheckState == 4||orderMap.processCheckState ==5||orderMap.processCheckState ==6||orderMap.processCheckState ==7"
                        v-model="orderMap.orderProcess.type"
                        @change="handleOrderProcessTypeChange"
                    >

                    <el-tooltip v-for="s in OrderProcessTypeEnum"
                            :key="s.value" class="item" effect="dark" content="1" placement="top-start">

                            <div slot="content" v-if="s.value==1">（优先级1）当前产品募集期前存在于基金账户的新资金；</div>
                            <div slot="content" v-if="s.value==2">（优先级2）进入过公司其他投资产品且原产品已到期的资金；</div>
                            <div slot="content" v-if="s.value==3">（优先级3）国内或国外房屋在银行抵押贷款资金；</div>
                             <div slot="content" v-if="s.value==4">（优先级4）从未进入公司任何投资产品的普通新资金</div>
                            <!-- <div slot="content" v-if="s.value==4">1.普通新签：从未进入公司任何投资产品的普通新资金；<br/>2.新签+现金基金：普通新签资金+现金基金资金的组合；<br/>3.新签+续/转签：普通新签资金+续/转签资金的组合；<br/>4.新签+抵押贷款：普通新签资金+抵押贷款资金的组合；</div> -->


                        <el-radio
                            :label="s.value"
                        >{{ s.name }}</el-radio>
                    </el-tooltip>
                        <!-- <el-radio
                            v-for="s in OrderProcessTypeEnum"
                            :key="s.value"
                            :label="s.value"
                        >{{ s.name }}</el-radio> -->
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item v-if="orderMap.orderProcess.type == 4" label="新签类型" prop="orderProcess.childtype">
                    <el-radio-group
                        class="state-radio-group"
                        v-model="orderMap.orderProcess.childtype">
                        <el-radio
                            v-for="s in OrderProcessChildTypeEnum"
                            :key="s.value"
                            :label="s.value"
                        >{{ s.name }}</el-radio>
                    </el-radio-group>
                </el-form-item> -->
            </div>

            <div>
                <div style="display:flex;">
                    <div style="width:180px;text-align: right;padding-right:12px;"><span style="color:#D40000;"> *</span> 资金来源:</div>
                    <el-select @change='moneySourceChange' @visible-change="visibleChange" multiple  v-model="mydata"
                        class="width3">
                        <el-option
                            v-for="p in moneySourceList"
                            :key="p.id"
                            :label="p.name"
                            :value="p.id"
                        ></el-option>
                    </el-select>
                </div>
                <div>
                    <el-form-item  prop="moneySource" class="moneySource">
                        <el-input style="margin-top:5px;" v-model="orderMap.moneySource"
                            type="textarea" rows="3"
                            class="width3" maxlength="200"></el-input>
                    </el-form-item>
                </div>
            </div>
            
        </div>
        <div class="line">
            <el-form-item label="EOI产品" prop="productId">
                <el-select
                    :disabled="orderMap.processCheckState ==3 || orderMap.processCheckState == 4||orderMap.processCheckState ==5||orderMap.processCheckState ==6||orderMap.processCheckState ==7"
                    v-model="orderMap.productId"
                    @change="handleProductChange"
                    filterable
                    class="width3">
                    <el-option
                        v-for="p in products"
                        :key="p.id"
                        :label="p.name"
                        :value="p.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <!-- payState=1全款齐不能修改 -->
            <el-form-item label="投资年限" prop="investment">
                <el-select v-model="orderMap.investment" :disabled="orderInitInfo.payState ==1"
                    class="width3">
                    <el-option
                        v-for="t in productRelativeEnum.term"
                        :key="t"
                        :label="t"
                        :value="t"
                    ></el-option>
                </el-select>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="产品利率" prop="rate">
                <el-select v-model="orderMap.rate" :disabled="orderInitInfo.payState ==1"
                    class="width3">
                    <el-option
                        v-for="r in productRelativeEnum.rate"
                        :key="r"
                        :label="r"
                        :value="r"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="产品发行日期"
                v-if="productRelativeEnum.hasTime">
                <span class="product-dates">
                    {{productRelativeEnum.starttime}}</span>
                <span class="product-dates">
                    产品到期日期: {{productRelativeEnum.endtime}}</span>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="EOI额度" prop="contractMoney">
                <el-input
                    :disabled="orderMap.processCheckState ==3 || orderMap.processCheckState == 4||orderMap.processCheckState ==5||orderMap.processCheckState ==6||orderMap.processCheckState ==7"
                    v-model="orderMap.contractMoney"
                    class="width3">
                    <el-select
                        :disabled="true"
                        slot="append"
                        v-model="orderMap.moneyType"
                        class="width1">
                        <el-option
                            v-for="m in moneyTypeList"
                            :key="m.id"
                            :label="m.name"
                            :value="m.id"
                        ></el-option>
                    </el-select>
                </el-input> 
            </el-form-item>

            <el-form-item label="签约日期" prop="contractTimeName">
                <el-date-picker
                    v-model="orderMap.contractTimeName"
                    :picker-options="pickerOptions"
                    class="width3"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="订单到期日期"
                v-if="curProduct && !!curProduct.iscom" prop="finishTimeName">
                <el-date-picker
                    v-model="orderMap.finishTimeName"
                    class="width3"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>

            <el-form-item label="同意函签署">
                <el-date-picker
                    v-model="orderMap.signTimeName"
                    class="width3"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="签约分类" prop="type"
                 >
                <el-radio-group
                    v-model="orderMap.type">
                    <el-radio
                        v-for="s in OrderTypeEnum"
                        :key="s.value"
                        :label="s.value"
                    >{{ s.name }}</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="是否PIC投资" prop="isPicInvest"
                >
                <el-radio-group
                    v-model="orderMap.isPicInvest">
                    <el-radio
                        v-for="s in isPICarr"
                        :key="s.value"
                        :label="s.value"
                    >{{ s.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
        </div>

        <!-- <el-form-item v-if="orderMap.type == 3" label="状态类型" prop="addState">
            <el-radio-group
                class="state-radio-group"
                v-model="orderMap.addState">
                <el-radio
                    v-for="s in OrderAddStateEnum"
                    :key="s.value"
                    :label="s.value"
                >{{ s.name }}</el-radio>
            </el-radio-group>
        </el-form-item> -->

        <div class="line">
            <el-form-item label="原始投资产品"
                class="full-width wrap"
                prop="oldProductId"
                v-if="orderMap.type==2 || orderMap.type==4"
                style="min-width: 300px">
                <el-button @click="handleOldProductTextFocus"
                    class="old-trigger-btn"
                    >选择产品</el-button>
                <ul class="old-product-list">
                    <li v-for="txt in oldProductText"
                        :key="txt">{{ txt }}</li>
                </ul>
                <!-- {{orderMap.oldProductId}} -->
                <OldProductPicker
                    :list="orderProductList"
                    :show.sync="showTablePicker"
                    v-model="orderMap.oldProductId"
                ></OldProductPicker>
            </el-form-item>
            <el-form-item :label="stateName+'日期'"
                prop="outTimeName"
                v-if="orderMap.type>=6 && orderMap.type<=8">
                <el-date-picker
                    v-model="orderMap.outTimeName"
                    class="width3"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </div>
        <div class="line">
             <el-form-item label="是否接收转让" prop="isTransfer"
                class="full-width">
                <el-radio-group
                    v-model="orderMap.isTransfer">
                    <el-radio
                        v-for="s in isTransferarr"
                        :key="s.value"
                        :label="s.value"
                    >{{ s.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
        </div>
        <div class="line">
            <el-form-item label="原始投资订单"
                class="full-width wrap"
                prop="outProductId"
                v-if="orderMap.isTransfer==1"
                style="min-width: 300px">
                <el-button @click="handleOldOrderTextFocus"
                    class="old-trigger-btn"
                    >选择关联订单</el-button>
                <ul class="old-product-list" v-if="outProductText">
                    <li >{{ outProductText }}</li>
                    <!-- <li v-for="txt in outProductText"
                        :key="txt">{{ txt }}</li> -->
                </ul>
                <!-- {{orderMap.oldProductId}} -->
                <!-- {{orderMap.outProductId}} -->

                <!-- {{orderMap.outProductId}} -->
                <!-- {{orderMap.productIds}} -->



                <OldOrderPicker
                    @mychoose='mychoose'
                    :list="orderProductList"
                    :show.sync="showTableOrderPicker"
                    v-model="orderMap.outProductId"
                ></OldOrderPicker>
            </el-form-item>
            <el-form-item :label="stateName+'日期'"
                prop="outTimeName"
                v-if="orderMap.type>=6 && orderMap.type<=8">
                <el-date-picker
                    v-model="orderMap.outTimeName"
                    class="width3"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="投资目的" prop="investReason"
                class="full-width">
                <el-input v-model.trim="orderMap.investReason"
                    class="width4">
                </el-input> 

                <ul class="suggest-list">
                    <li v-for="suggest in suggestList"
                        :key="suggest"
                        @click="handleSuggestClick(suggest)"
                    >{{ suggest }}</li>
                </ul>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="成交形式" prop="dealType"  class="full-width">
                <el-radio-group v-model="orderMap.dealType">
                    <el-radio :label="1">中国区</el-radio>
                    <el-radio :label="2">澳洲散客团</el-radio>
                    <el-radio :label="3">澳洲看房团</el-radio>
                </el-radio-group>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="是否有联合申请人" prop="isConnApply">
                <el-radio-group v-model="orderMap.isConnApply">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="联合申请人"
                prop="connEmpName"
                v-if="!!orderMap.isConnApply">
                <el-input v-model="orderMap.connEmpName"
                    class="width3"></el-input>
            </el-form-item>
        </div>

        <div class="line">
            <el-form-item label="是否参加考察团" prop="isInspect">
                <el-radio-group v-model="orderMap.isInspect">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="考察团出行日期"
                prop="inspectTimeName"
                v-if="!!orderMap.isInspect">
                <el-date-picker
                    v-model="orderMap.inspectTimeName"
                    class="width3"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                ></el-date-picker>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>
import Tree from "../../../utils/Tree";
import PickerTree from "./PickerTree copy";
import OldProductPicker from "../orderList/OldProductPicker.vue"
import OldOrderPicker from "../orderList/OldOrderPicker.vue"

import {form1Rules, pathSlice} from "./config";
const suggestList = ["房产交割", "理财","PIC投资"];

export default {
    name: 'FormPart1',
    components: {PickerTree,OldProductPicker,OldOrderPicker},
    props: [
        "id",
        "orderMap",
        "products",
        "orderStateEnum",
        "OrderTypeEnum",
        "OrderProcessTypeEnum",
        "OrderProcessChildTypeEnum",
        "moneyTypeList",
        "orgAllList",
        "orderProductList",
        "moneySourceList",
        "OrderAddStateEnum",
        "phoneDisabled",
        "updateTimeAllowed",
        "isWhiteMan",
        "orderInitInfo"
        // 'defaultExpandedKeys'
    ],

    data () {
        const disabledDate = date => {
            if (this.updateTimeAllowed) return false;
            else {
                const {min, max} = this.validDateRange;
                return !(date < max && date > min);
            }
        }
        return {
            
            // defaultExpandedKeys:[172],
            form1Rules,
            treeIns: new Tree(),
            showPicker: false,
            pickerTree: [],
            pickerOptions: { disabledDate },
            suggestList,
            showTablePicker: false,
            showTableOrderPicker: false,
            mydata:[],
            processflag:false,
            isTransferarr:[
                {value:0,name:'否'},
                {value:1,name:'是'},
            ],
            isPICarr:[
                {value:0,name:'否'},
                {value:1,name:'是'},
            ],
            outProductText:'',
            showcount:0,
        }
    },

    computed: {
        defaultExpandedKeys(){
            return this.orderMap.deptId?[this.orderMap.deptId]:[] 
        },
        isUpdate () {
            return !!this.orderMap?.id
        },
        curProduct () {
            const {productId} = this.orderMap;
            if (!productId) return null;
            else return this.products.find(
                item => item.id === productId)
        },
        productRelativeEnum () {
            const defaultValue = {
                term     : [],
                rate     : [],
                starttime: "",
                endtime  : "",
                hasTime  : false
            };
            
            if (!this.curProduct) return defaultValue;
            else {
                return {
                    term     : this.curProduct.investment.split("#").filter(Boolean),
                    rate     : this.curProduct.rate.split("#").filter(Boolean),
                    starttime: this.curProduct.starttime,
                    endtime  : this.curProduct.endtime,
                    get hasTime() {return !!(this.starttime || this.endtime)}
                };
            }
        },
        stateName () {
            const {type} = this.orderMap,
                found = this.orderStateEnum.find(
                    item => item.value === type);
            
            return found?.name || ""
        },
        oldProductText () {
            // const {oldProductId: value} = this.orderMap;
            // if (value) {
            //     const item = this.orderProductList.find(({id}) => id === value);
            //     if (item) {
            //         const strArr = [item.orderNo, item.custName, item.productName, item.finishTime].filter(Boolean)
            //         return strArr.join("-") || ""
            //     }
            //     else return ""
            // }
            // else return ""

            return this.orderMap.oldProductId.map(oid => {
                const item = this.orderProductList.find(({id}) => id == oid);
                const strArr = [item.orderNo, item.custName, item.productName, item.finishTime].filter(Boolean)
                return strArr.join("-") || ""
            })
        },
        
        
        validDateRange () {
            let min, max;
            if (this.id) {
                min = new Date(this.orderMap.createTime || undefined);
                const max1 = new Date(this.orderMap.contractTime || undefined);
                const max2 = new Date(this.orderMap.createTime || undefined);
                max = max1 > max2 ? max1 : max2;
            } else {
                min = new Date();
                max = new Date();
            }
            const day = min.getDay();
            let offset = 3;
            if (day < 3) offset = 5;
            else if (day > 5) offset = 4;
            min.setDate(min.getDate()-offset);
            return ({min, max})
        }
    },

    watch: {
        // orgAllList: {
        //     immediate: true,
        //     handler: function (list) {
        //         this.treeIns = new Tree([...list], 'text');
        //         const _list = this.treeIns.extract(({level}) => level == 4);
        //         this.pickerTree = _list;
        //     },
        // } ,
        showPicker: {
            handler: function (val, oldVal) {
                if(val){
                    // console.log('this.showcount',this.showcount);
                    if(this.showcount==1){  //只有dom第一次初始化的时候才渲染一次树  
                        this.treeIns = new Tree([...this.orgAllList], 'text');
                        const _list = this.treeIns.extract(({level}) => level == 4);
                        this.pickerTree = _list;
                    }
                }
            },
            deep: true,
        },



        'orderMap.oldOutOrderList': {
            immediate: true,
            handler: function (list) {
                if(list){
                    if(list.length>0){
                        this.outProductText = list[0].oldOrderProductInfo
                    }
                }
               
            },
        } 
    },

    methods: {
        mychoose(checkdata){
            if(checkdata.length>0){
                this.orderMap.outProductId = [checkdata[0].id]
                console.log('mychoose',checkdata);
                this.outProductText =checkdata[0].orderNo+'-'+checkdata[0].custName+'-'+checkdata[0].productName+'-'+checkdata[0].finishTimeName
                console.log(this.outProductText );
                console.log(this.orderMap.outProductId);
            }else{
                // this.orderMap.outProductId = ''
                // this.outProductText = ''
            }
        },
        replyContent(){
            this.processflag = !this.processflag
        },
        moneySourceChange(data){
            // console.log(data);
        },
        visibleChange(data){
            this.mydata.forEach(element => {
                let _item = this.moneySourceList.find((item=>{
                    return item.id == element
                }))
                this.orderMap.moneySource +=_item.name+';'
            });
            console.log(data);
            if(!data){
                this.mydata = []
            }
        },

        handleSelectBun () {
            this.showPicker = true;
            this.showcount++
        },

        handlePick (data) {
            this.orderMap.deptId   = data.id;
            this.orderMap.prenames = pathSlice(data.prenames,3);
        },

        handleProductChange (data) {
            this.orderMap.investment = "";
            this.orderMap.rate       = "";
            // console.log(data);
            //  orderMap.investment productRelativeEnum.term orderMap.rate productRelativeEnum.rate
            //  console.log(this.productRelativeEnum.term);
            //  console.log(this.productRelativeEnum.rate);
            // 如果只有一条 默认回显
             if(this.productRelativeEnum.term.length==1){
                this.orderMap.investment = this.productRelativeEnum.term[0]
             }
             if(this.productRelativeEnum.rate.length==1){
                this.orderMap.rate = this.productRelativeEnum.rate[0]
             }

        },

        handleOldOrderTextFocus () {
            console.log(123);
            this.showTableOrderPicker = true;
        },
        handleOldProductTextFocus () {
            this.showTablePicker = true;
        },

        handleSuggestClick (text) {
            console.log("点击建议文字", text);
            this.orderMap.investReason += text;
        },

        handleOrderProcessTypeChange () {
            this.$emit("orderProcessTypeChange")
        },

        async validate () {
            return this.$refs.form1.validate()
        }
    }
}
</script>

<style lang='scss' scoped>
    .product-dates {
        color: #606266;

        &:nth-child(n+2) {
            margin-left: 16px;
        }
    }

    .full-width {
        width: 100%;
    }

    .el-form.private .width3 {
        width: 300px
    }

    .old-trigger-btn {
        margin-right: calc(100% - 100px);
    }

    .old-product-list {
        &:empty {
            display: none;
        }
        margin-top: 6px;
        padding: 14px;
        background-color: rgba($color: #000000, $alpha: .05);
        border: 1px solid rgba($color: #000000, $alpha: .05);
    }

    .suggest-list {
        display: flex;

        li {
            margin-left: 10px;
            padding: 0 12px;
            line-height: 30px;
            height: 32px;
            background-color: #f0f6ff;
            border: 1px solid rgba($color: #000000, $alpha: .05);
            border-radius: 3px;
            cursor: pointer;

            &:hover {
                background-color: #f0f3f6;

            }
        }
    }
    .moneySource /deep/ .el-form-item__content{
        display: flex;flex-direction: column;

    }
    .needTipLabel /deep/ .el-form-item__label{
        padding-right: 20px;
    }
   
</style>
<style>
    .el-tooltip__popper{
        background-color: rgba(0, 0, 0, 0.4)!important;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
    }
    .el-tooltip__popper[x-placement^="top"] .popper__arrow{
        border-top-color: rgba(0, 0, 0, 0.4)!important;
    }
    .el-tooltip__popper[x-placement^="top"] .popper__arrow::after{
        border-top-color: rgba(0, 0, 0, 0)!important;
    }
</style>